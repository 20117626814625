/* eslint-disable react/no-array-index-key */
import { useSelector } from 'react-redux';
import {
  memo, useEffect, useRef, useState,
} from 'react';

import Scrollable from '../../Shared/Scrollable';
import ShowButton from './ShowButton';
import NewsCard from '../../HomePage/TopNews/NewsCard';
import SentimentTag from '../../SEO_pages/TopicsSentimentAnalysis/SentimentTags/SentimentTag';
import SentimentModal from '../../SEO_pages/TopicsSentimentAnalysis/SentimentTags/SentimentModal';
import BookmarkStoryIcon from '../../BookmarksComponent/BookmarkStoryIcon';

import { parseQueryLogicalStructure, queryItemWatchlistParser } from '../../../helpers/watchlistHelperFunctions';
import handleTopicSelectSentimentTag from '../../../helpers/handleTopicSelectSentimentTag';

import * as searchActions from '../../../actions/searchActions';

import { SECTORS_PATH_ITEM } from '../../../data/directory/constants';

import SectorsSvc from '../../../services/SectorsSvc';
import TopicsSvc from '../../../services/TopicsSvc';
import EntitiesSvc from '../../../services/dbServices/EntitiesSvc';

import withComponentName from '../../../decorators/withComponentName';
import withRouter from '../../../decorators/withRouter';

import convertPermalinkToPath from '../../../helpers/urlHelpers';
import useSizes from '../../../hooks/sizes';
import { CF_URL_FOR_FINPROMPT } from '../../../data/environment';

const Stories = (props) => {
  const {
    handleShowMoreButton, isChatbotOpened, payload, isSectors, history,
    scrollToBottomOnWidgetRendered, isFinpromptPages,
  } = props;

  const messageDate = payload.date;

  const width = useSelector(({ common }) => (common.width));
  const expanded = useSelector(({ chatbot }) => (chatbot.expanded));
  const isModalDisplayed = useSelector(({ chatbot }) => (chatbot.isModalDisplayed));
  const promoHeight = useSelector(({ promobar }) => (promobar.height));
  const searchBarActive = useSelector(({ searchReducers }) => searchReducers.searchBarActive);

  const sentimentContentRef = useRef(null);

  const { components } = payload;

  const [stories, setStories] = useState([]);
  const [bookmarkedStories, setBookmarkedStories] = useState([]);

  const [topics, setTopics] = useState([]);

  const [sentimentsList, setSentimentsList] = useState([]);
  const [sentimentsListPositive, setSentimentsListPositive] = useState([]);
  const [sentimentsListNegative, setSentimentsListNegative] = useState([]);

  const [selectedTopic, setSelectedTopic] = useState(null);
  const [indexActiveTagSentimentScrolled, setIndexActiveTagSentimentScrolled] = useState(null);
  const [query, setQuery] = useState('');

  const { height: screenHeight } = useSizes();

  const isTablet = useSelector(({ common }) => (
    !(common.width > 1024)
  ));
  const extractPathAfterAI = (inputString) => {
    const indexOfAI = inputString.indexOf('.ai/');
    const indexOfCom = inputString.indexOf('.com/');
    if (indexOfAI !== -1) return inputString.substring(indexOfAI + 4);
    if (indexOfCom !== -1) return inputString.substring(indexOfCom + 5);

    return '/news';
  };

  const handleShowMore = async (path = '/news', item = {}) => {
    const redirectPath = isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}${path}` : path;
    try {
      if (!item.entities_dynamic_cfscore) {
        handleShowMoreButton(redirectPath);
        return;
      }

      const { permalink } = await EntitiesSvc.getEntityPermalink({
        entityType: item.entities_dynamic_cfscore?.group_type,
        entityId: item.entities_dynamic_cfscore?.group_name,
      }, false, searchActions);

      if (permalink) {
        handleShowMoreButton(`${CF_URL_FOR_FINPROMPT}/${extractPathAfterAI(permalink)}`);
      } else {
        handleShowMoreButton(redirectPath);
      }
    } catch (error) {
      handleShowMoreButton(redirectPath);
    }
  };

  const getCurrentTopicName = (asset = {}) => {
    let name;
    if (asset.logical_structure instanceof Object) {
      name = parseQueryLogicalStructure(asset.logical_structure, false);
    } else {
      ({ name } = asset.query ? queryItemWatchlistParser(asset.query) : asset);
    }

    return name;
  };

  const handleModalClose = () => {
    setSelectedTopic(null);
    setQuery('');
  };

  const handleTopicRedirect = async (selectedTopic) => {
    let topic;
    let topicURL = '';
    if (isSectors) {
      topic = await SectorsSvc.getCategory(selectedTopic.entity_id);
      topicURL = `/news/directory/${SECTORS_PATH_ITEM}/${topic.subindustry.industry.sector.slug}/${topic.subindustry.industry.slug}/${topic.subindustry.slug}/${topic.slug}`;
    } else {
      topic = await TopicsSvc.getTopicById(selectedTopic.entity_id);
      const assetClassSlug = topic?.asset_class?.slug;
      const topicSlug = topic?.slug;

      topicURL = `/news/directory/${assetClassSlug}/${topicSlug}`;
    }

    if (isFinpromptPages) {
      setTimeout(() => {
        window.open(`${CF_URL_FOR_FINPROMPT}${topicURL}`, '_blank')?.focus?.();
      }, 100);
      return;
    }
    history.push(topicURL);
  };

  const handleTopicSelectMethod = (event, topic, ref) => {
    if (!expanded || searchBarActive) return;

    const passedProps = {
      event,
      topic,
      ref,
      width,
      promoHeight,
      isSectors: false,
      chatbot: true,
      screenHeight,
      isFinpromptPages,
      handleTopicRedirect,
    };
    const { query, selectedTopic } = handleTopicSelectSentimentTag(passedProps);
    if (!expanded) {
      selectedTopic.y -= promoHeight;
    }

    if (query || selectedTopic) {
      setSelectedTopic({ ...selectedTopic });
      setQuery(query || '');
    }
  };

  const toggleRemoveBookmark = (e, story) => {
    if (e) e.stopPropagation();

    const filteredStories = bookmarkedStories.filter((item) => item.id !== story.id);
    setBookmarkedStories([...filteredStories]);
  };

  const getLink = async (item) => {
    if (item) {
      return await EntitiesSvc.getEntityPermalink({
        entityType: item?.groupType || item?.group_type,
        entityId: item?.groupName || item?.group_name,
      });
    }
  };

  const handleTopicClick = (topic) => {
    if (!topic) return;

    const link = getLink(topic?.logical_structure);
    link
      .then((data) => {
        history.push(convertPermalinkToPath(data.permalink));
      })
      .catch((error) => { console.log(error); });
  };

  const saveIndexTopicChangedPosition = (activeIndex) => {
    setIndexActiveTagSentimentScrolled(activeIndex);
  };

  const resetIndexActiveTagSentimentScrolled = () => {
    setIndexActiveTagSentimentScrolled(null);
  };

  useEffect(() => {
    components?.forEach((component) => {
      const { component_data: componentData, component_name: componentName } = component;

      if (componentData?.search_queries) {
        setTopics([...componentData?.search_queries]);
      } else if (componentData?.list) {
        setSentimentsList([...componentData?.list]);
      } else if (componentData?.stories && componentName?.trim() === 'bookmarked_stories') {
        setBookmarkedStories([...componentData?.stories]);
      } else if (componentData?.stories) {
        setStories((prevState) => [...prevState, ...componentData?.stories]);
      }
    });
  }, [components]);

  useEffect(() => {
    const negativeSentiments = [];
    const withoutSentiments = [];
    const filteredPositifSentiments = sentimentsList.filter((item) => {
      if (item.sentiment?.length > 1) {
        const value = Array.from(item.sentiment);
        value.pop();

        if (Number(value.join('')) < 0) negativeSentiments.push(item);

        return Number(value.join('')) > 0;
      } if (item.sentiment === null) {
        item.sentiment = 0;
        withoutSentiments.push(item);
      }
      return false;
    });

    setSentimentsListPositive([...filteredPositifSentiments]);
    setSentimentsListNegative([...negativeSentiments.reverse(), ...withoutSentiments]);
  }, [sentimentsList]);

  useEffect(() => {
    if (!isChatbotOpened) return;
    if (scrollToBottomOnWidgetRendered) {
      setTimeout(() => {
        scrollToBottomOnWidgetRendered();
      }, 50);
    }
  }, [stories, topics, sentimentsList, bookmarkedStories, isChatbotOpened]);

  useEffect(() => {
    // close sentiments tag modal when another modal opens
    if (isModalDisplayed?.displayed && (isModalDisplayed?.type !== 'sentimentsTag')) handleModalClose();
  }, [isModalDisplayed?.displayed, isModalDisplayed?.type]);

  const renderComponent = () => {
    if (stories.length) {
      return (
        <>
          <Scrollable
            wrapperClassName="chatbot-widget__scrollable stories"
            arrowClass="top-news-stories-arrow"
            disabled={false}
            hideArrows={isTablet}
            isAllowFull={!isTablet}
            chatbotWidget
            arrowAndContentAlignment
          >
            {stories.map((story) => (
              <div
                key={story.id}
                className="story__item"
              >
                <NewsCard
                  story={story}
                />
              </div>
            ))}
          </Scrollable>
          <ShowButton
            expanded={expanded}
            handleClick={() => handleShowMore('/news', stories[0])}
            isChatbotOpened={isChatbotOpened}
          />
        </>
      );
    }

    if (topics.length) {
      return (
        <>
          <Scrollable
            wrapperClassName="chatbot-widget__scrollable stories"
            arrowClass="top-news-stories-arrow"
            disabled={false}
            hideArrows={isTablet}
            isAllowFull={!isTablet}
            chatbotWidget
            arrowAndContentAlignment
          >
            {topics.map((topic, index) => (
              <div
                key={index}
                className="topics__item topics"
                onClick={() => handleTopicClick(topic)}
              >
                {getCurrentTopicName(topic)}
              </div>
            ))}
          </Scrollable>
          <ShowButton
            expanded={expanded}
            handleClick={() => handleShowMore('/watchlists')}
            isChatbotOpened={isChatbotOpened}
          />
        </>
      );
    }
    if (sentimentsList.length && (
      sentimentsListPositive.length || sentimentsListNegative.length
    )) {
      return (
        <div
          ref={sentimentContentRef}
        >
          {[sentimentsListPositive, sentimentsListNegative].map((items, index) => {
            const activeIndexSentimentTopic = items
              .findIndex((item) => item.entity_id === selectedTopic?.entity_id);

            return (
              <div
                key={index}
                className="sentiment-list"
              >
                <Scrollable
                  wrapperClassName="chatbot-widget__scrollable sentiments-list"
                  arrowClass="top-news-stories-arrow"
                  disabled={false}
                  hideArrows={isTablet}
                  isAllowFull={!isTablet}
                  chatbotWidget
                  sentiments
                  arrowAndContentAlignment
                  activeIndexSelectedItem={activeIndexSentimentTopic}
                  saveIndexTopicChangedPosition={saveIndexTopicChangedPosition}
                >
                  {!items.length ? (
                    <div className="sentiment-tags-content-no-data">
                      {index === 0 ? (
                        <>
                          This time period doesn&apos;t look so good.
                          No positive sentiment to show.
                        </>
                      ) : (
                        <>
                          This time period looks great! No negative sentiment to show.
                        </>
                      )}
                    </div>
                  ) : (
                    items.map((item, index) => (
                      <SentimentTag
                        key={item.entity_id}
                        item={item}
                        handleTopicSelect={(e, item, ref) => handleTopicSelectMethod(e, item, ref)}
                        selectedTopic={selectedTopic}
                        chatbot
                        isChatbotExpanded={expanded}
                        searchBarActive={searchBarActive}
                        isActiveTopic={activeIndexSentimentTopic === index}
                        indexActiveTagSentimentScrolled={indexActiveTagSentimentScrolled}
                        resetIndexActiveTagSentimentScrolled={resetIndexActiveTagSentimentScrolled}
                      />
                    ))
                  )}
                </Scrollable>
              </div>
            );
          })}
          <ShowButton
            expanded={expanded}
            handleClick={() => handleShowMore('/news/sentiment')}
            isChatbotOpened={isChatbotOpened}
          />
          {selectedTopic && (
            <SentimentModal
              isTopic
              activePeriod="1w"
              slugType={selectedTopic.entity_type}
              topicId={selectedTopic.entity_id}
              topicName={selectedTopic.entity_name}
              topic={selectedTopic}
              x={selectedTopic.x}
              y={selectedTopic.y}
              arrowLeft={selectedTopic.arrowLeft}
              sentimentContentRef={sentimentContentRef}
              handleModalClose={handleModalClose}
              query={query}
              parentSentiment={selectedTopic.sentiment}
              chatbot
              arrowBottom={selectedTopic.arrowBottom}
              isFinpromptPages={isFinpromptPages}
            />
          )}
        </div>
      );
    }
    if (bookmarkedStories.length) {
      return (
        <>
          <Scrollable
            wrapperClassName="chatbot-widget__scrollable"
            arrowClass="top-news-stories-arrow"
            hideArrows={width < 1024}
            chatbotWidget
            isAllowFull
            arrowAndContentAlignment
          >
            {bookmarkedStories?.map((story) => (
              <div key={story?.id} style={{ position: 'relative' }}>
                <BookmarkStoryIcon
                  width={width}
                  toggleRemovedBookmark={toggleRemoveBookmark}
                  bookmark={story}
                  chatbot
                />
                <NewsCard
                  key={story.id}
                  story={story}
                />
              </div>
            ))}
          </Scrollable>
          <ShowButton
            expanded={expanded}
            handleClick={() => handleShowMore('/bookmarks')}
            isChatbotOpened={isChatbotOpened}
          />
        </>
      );
    }
    return null;
  };

  if (renderComponent() === null) return null;

  return (
    <div
      data-date={messageDate}
      className="react-chatbot-kit__widget__wrapper stories show-more"
    >
      {renderComponent()}
    </div>
  );
};

export default memo(withComponentName(withRouter(Stories)));
