import { combineReducers } from 'redux';

import stories from './stories.reducers';
import storyFilters from './storyFilters.reducer';
import searchReducers from './searchReducers';
import watchlistReducer from './watchlistReducer';
import trendingBarReducer from './trendingBarReducer';
import userPreferencesReducer from './userPreferencesReducer';
import promobar from './promobarReducer';
import account from './account.reducer';
import subscriptions from './subscriptions.reducer';
import leftBar from './leftBar.reducer';
import common from './common.reducer';
import filings from './filings.reducer';
import authentication from './authentication.reducer';
import cryptoCurrencies from './cryptoCurrencies.reducer';
import investorRelations from './investorRelations.reducer';
import esgReducer from './esg.reducer';
import press from './press.reducer';
import locationsSentiment from './locationsSentiment.reducers';
import header from './header.reducer';
import bookmarksReducer from './bookmarks.reducer';
import recommendedStories from './recommendedStories.reducer';
import chatbot from './chatbot.reducer';
import ukPrivateCompany from './ukPrivateCompany.reducer';
import finprompt from './finprompt.reducer';
import dcscSectors from './dcscSectors.reducer';

const rootReducer = combineReducers({
  watchlistReducer,
  stories,
  storyFilters,
  searchReducers,
  trendingBarReducer,
  userPreferencesReducer,
  promobar,
  account,
  subscriptions,
  leftBar,
  common,
  filings,
  authentication,
  cryptoCurrencies,
  investorRelations,
  esgReducer,
  press,
  locationsSentiment,
  header,
  bookmarksReducer,
  recommendedStories,
  chatbot,
  ukPrivateCompany,
  finprompt,
  dcscSectors,
});

export default rootReducer;
