import { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash-es/isEqual';
import classNames from 'classnames/bind';
import autoBind from 'react-autobind';
import loadable from '@utils/loadable';

import withComponentName from '../../decorators/withComponentName';

import SentimentsSvc from '../../services/dbServices/SentimentsSvc';

import {
  getPeriodType,
  generateWLChartData,
  dateTooltipFormat,
  generateTopicChartData,
  generateChartData,
} from '../../helpers/sentimentHelpers';

import { getTrendingBarAssetQuery } from '../../helpers/watchlistHelperFunctions';

import {
  timeFilter, timeFilterMobile, defaultChartGraphData, entityType,
} from '../../data/sentimentAnalysis';
import { DCSC_SECTORS } from '../../data/directory/topicTypes';

import PlayVideoComponent from '../PlayVideoComponent';

import Styles from './styles.module.scss';

const SentimentChart = loadable(() => import('./SentimentChart'));
const SentimentChartModal = loadable(() => import('./SentimentChartModal'));
const ChartTimeFilters = loadable(() => import('../SEO_pages/ChartFilters/ChartTimeFilters'));

const cx = classNames.bind(Styles);

class SentimentAnalysis extends Component {
  constructor(props) {
    super(props);

    const { chatbotChartPeriod } = this.props;
    this.state = {
      isChartLoading: true,
      isAvgLoading: true,
      isChartHidden: false,
      chartPeriod: chatbotChartPeriod || '1m',
      chartDateFormat: 'DD MMM YYYY',
      isChartExpanded: false,
      avgPoint: {
        type: 'neutral',
        text: 'Neutral',
        value: 0,
      },
      averageConfidence: null,
      confidenceY: [],
      chartGraph: defaultChartGraphData,
      chartGraphArr: [defaultChartGraphData],
      lastPointData: null,
      query: '',
      isWatchListFirstCall: true,
      isWatchlistUpdated: false,
    };
    autoBind(this);
  }

  componentDidMount() {
    const {
      isWatchlistPage, activeWatchlistTopics, isTopic, isFullLocations,
    } = this.props;

    if (isWatchlistPage && activeWatchlistTopics && activeWatchlistTopics.length) {
      this.setState({
        isWatchListFirstCall: false,
      }, () => {
        this.generateWLData();
      });
    } else if (isTopic || isFullLocations) {
      this.generateTopicData(true, true);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isWatchlistPage, activeWatchlistTopics, isTopic, topic,
      selectedLocation, activateFilingsPreloader, showChatbot,
    } = this.props;
    if (
      isWatchlistPage
      && (!isEqual(activeWatchlistTopics, prevProps.activeWatchlistTopics)
        || (!activateFilingsPreloader
          && activateFilingsPreloader !== prevProps.activateFilingsPreloader
          && !activeWatchlistTopics.length))
    ) {
      const currentId = activeWatchlistTopics.map(({ id }) => id);
      const oldId = prevProps.activeWatchlistTopics.map(({ id }) => id);
      const isWatchlistUpdated = !isEqual(currentId, oldId);
      this.setState(({ isWatchListFirstCall }) => ({
        isWatchListFirstCall: false,
        isWatchlistUpdated: isWatchListFirstCall ? false : isWatchlistUpdated,
      }), () => {
        this.generateWLData();
      });
    } else if (
      (isTopic && topic?.id !== prevProps.topic?.id)
      || (!isEqual(selectedLocation, prevProps.selectedLocation))
    ) {
      this.generateTopicData();
    }
    if ((prevProps.showChatbot !== showChatbot) && showChatbot) {
      this.handleCloseModal();
    }
  }

  async callWLAPI(params) {
    try {
      const { data } = await SentimentsSvc.getWatchlistSentiments(params);
      return data;
    } catch (e) {
      console.log(e);
    }
    return { data: [] };
  }

  async callTopicAPI(params) {
    try {
      const { data } = await SentimentsSvc.getTopicSentiments(params);
      return data;
    } catch (e) {
      console.log(e);
    }
    return { data: [] };
  }

  async generateWLData(shouldCallAvg = true) {
    this.setState({
      isChartLoading: true,
      isAvgLoading: shouldCallAvg,
    });
    const { activeWatchlist, activeWatchlistTopics } = this.props;
    const isAtLeastOneSelected = activeWatchlistTopics.some((s) => !s.hidden);

    if (!isAtLeastOneSelected) {
      this.setState({
        chartGraph: defaultChartGraphData,
        chartGraphArr: [defaultChartGraphData],
        isChartHidden: true,
        avgPoint: {},
        averageConfidence: null,
        confidenceY: [],
        lastPointData: null,
        isChartLoading: false,
        isAvgLoading: false,
      });
      return;
    }

    const { chartPeriod, isWatchlistUpdated } = this.state;
    const periodTypeObj = getPeriodType(chartPeriod);
    const params = {
      watchlist_id: activeWatchlist.id,
      period: periodTypeObj.period,
      isRefresh: isWatchlistUpdated,
      duration: 'daily',
    };
    const data = await this.callWLAPI(params);
    const newData = generateWLChartData(data.data, activeWatchlistTopics, periodTypeObj);
    const {
      chartColor, chartGraph, chartGraphArr,
    } = generateChartData(newData);

    this.setState({
      chartColor,
      chartGraph,
      chartGraphArr,
      isChartHidden: !newData.x.length,
      lastPointData: newData.lastPointData,
      isChartLoading: false,
      isAvgLoading: false,
      avgPoint: newData.avgPoint,
      averageConfidence: Math.round((data.average_confidence || 0) * 100),
      confidenceY: newData.newConfidence,
      isWatchlistUpdated: false,
    });
  }

  async generateTopicData(shouldCallAvg = true, firstRender = false) {
    this.setState({
      isChartLoading: true,
      isAvgLoading: shouldCallAvg,
    });

    const { chartPeriod } = this.state;
    const {
      isTopic, slugType, topic, isFullLocations, selectedLocation,
      dataWebhook, chatbotWapiCallParams,
    } = this.props;
    const periodTypeObj = getPeriodType(chartPeriod);
    const params = {
      period: periodTypeObj.period,
      duration: 'daily',
    };
    let query = '';

    if (selectedLocation) {
      params.entity_type = selectedLocation.entity_type;
      params.entity_id = selectedLocation.entity_id;
      query = getTrendingBarAssetQuery({
        id: selectedLocation.entity_id,
        name: selectedLocation.entity_name,
      }, params.entity_type);
    } else if (isTopic) {
      params.entity_type = slugType === DCSC_SECTORS ? topic.type : entityType[slugType];
      params.entity_id = topic?.id;
      query = getTrendingBarAssetQuery(topic, params.entity_type);

      if (chatbotWapiCallParams) {
        params.entity_type = chatbotWapiCallParams.entity_type;
        params.id = chatbotWapiCallParams.id;

        query = getTrendingBarAssetQuery({
          name: chatbotWapiCallParams.name,
          id: chatbotWapiCallParams.id,
        }, chatbotWapiCallParams.entity_type);
      }
    } else if (isFullLocations) {
      params.entity_type = 'Continent';
    } else {
      return this.setState({
        isChartHidden: true,
        isChartLoading: false,
        isAvgLoading: false,
      });
    }

    let data = null;
    if (dataWebhook && firstRender) {
      data = { ...dataWebhook };
    } else {
      data = await this.callTopicAPI(params);
    }
    const newData = generateTopicChartData(data, {
      ...periodTypeObj,
      hasConfidence: true,
    });
    const {
      chartColor, chartGraph, chartGraphArr,
    } = generateChartData(newData);

    this.setState({
      query,
      chartColor,
      chartGraph,
      chartGraphArr,
      isChartHidden: !newData.x.length,
      lastPointData: newData.lastPointData,
      isChartLoading: false,
      isAvgLoading: false,
      avgPoint: newData.avgPoint,
      averageConfidence: Math.round((data.average_confidence || 0) * 100),
      confidenceY: newData.newConfidence,
    });
  }

  handlePeriodChange(type) {
    this.handleTimePeriodChange(type);
  }

  handleTimePeriodChange(type) {
    this.setState({
      chartPeriod: type,
      chartDateFormat: dateTooltipFormat(type),
    }, () => {
      const { isWatchlistPage, isTopic, isFullLocations } = this.props;
      if (isWatchlistPage) {
        this.generateWLData(false);
      } else if (isTopic || isFullLocations) {
        this.generateTopicData(false);
      }
    });
  }

  toggleChartExpandClick() {
    const { isChartExpanded } = this.state;
    this.setState({
      isChartExpanded: !isChartExpanded,
    });
  }

  handleCloseModal() {
    this.setState({ isChartExpanded: false });
  }

  render() {
    const {
      isChartExpanded, chartPeriod, chartGraph, avgPoint, isChartLoading, isChartHidden,
      lastPointData, chartDateFormat, isAvgLoading, query, chartGraphArr, chartColor,
      averageConfidence, confidenceY,
    } = this.state;
    const {
      isFullWidth, isWatchlistPage, isTabView, isTopic, isFullLocations, allowWithoutQuery,
      isLocationPage, activeWatchlistTopics, width, topic, chatbot, priceTileChatbot,
      chatbotWidgetType, scrollToBottomOnWidgetRendered,
    } = this.props;
    const sentimentAnalysisClass = cx('sentiment_analysis',
      {
        full_width: isFullWidth,
        watchlist: isWatchlistPage,
        tab_view: isTabView,
        location_page: isLocationPage,
      });

    if (isWatchlistPage && !activeWatchlistTopics.length) return null;

    const sentimentChartProps = {
      isWatchlistPage,
      isTabView,
      isChartHidden,
      isChartLoading,
      chartGraph,
      chartGraphArr,
      chartPeriod,
      chartDateFormat,
      lastPointData,
      toggleChartExpandClick: this.toggleChartExpandClick,
      handleTimePeriodChange: this.handlePeriodChange,
      isAvgLoading,
      avgPoint,
      averageConfidence,
      chartColor,
    };

    const sentimentChartModalProps = {
      isWatchlistPage,
      isTopic: isTopic || isFullLocations,
      query,
      isChartLoading,
      chartGraph,
      chartGraphArr,
      lastPointData,
      isChartExpanded,
      chartPeriod,
      toggleChartExpandClick: this.toggleChartExpandClick,
      handleTimePeriodChange: this.handlePeriodChange,
      handleCloseModal: this.handleCloseModal,
      allowWithoutQuery,
      chartColor,
      avgPoint,
      averageConfidence,
      confidenceY,
      chatbotWidgetType,
      scrollToBottomOnWidgetRendered,
    };

    if (chatbot) {
      // web-7271 check that all data are valid
      // and the length of arrays is the same (values and labels)
      const filtered = chartGraph.x.filter((value) => (value !== null) && (value !== 'Invalid date') && (value !== undefined));
      chartGraph.x = filtered;
      if (chartGraph.y.length > chartGraph.x.length) chartGraph.y.length = chartGraph.x.length;

      return (
        <SentimentChartModal
          chatbot
          {...sentimentChartModalProps}
        />
      );
    }

    if (priceTileChatbot) {
      return (
        <SentimentChart
          priceTileChatbot
          {...sentimentChartProps}
        />
      );
    }

    return (
      <div className={sentimentAnalysisClass}>
        <div className={Styles.average_sentiment}>
          <div className={Styles.average_sentiment_tags}>
            <div className={Styles.tag_new}>New</div>
          </div>
          <div className={cx('average_title--container', { desktop: width >= 1024 })}>
            <div className={Styles.average_title}>
              Sentiment
              <PlayVideoComponent
                videoLink="https://cityfalcon.wistia.com/medias/362922bfs9"
                videoLinkMobile="https://cityfalcon.wistia.com/medias/362922bfs9"
                additionalStyles={cx('sentiment_play_btn')}
                tooltipAdditionalContentStyle={cx('sentiment_play_btn_tooltip', {
                  wl_page: isWatchlistPage,
                  topics: (!isWatchlistPage && topic?.title !== 'Stocks'),
                  stocks: (!isWatchlistPage && topic?.title === 'Stocks'),
                })}
                isSentiment
              />
            </div>
            <div className={cx('chart_time_filter', { desktop: width >= 1024 })}>
              <ChartTimeFilters
                isSentiment
                options={width <= 768 ? timeFilterMobile : timeFilter}
                activeFilter={chartPeriod}
                setTimeFilter={this.handlePeriodChange}
              />
            </div>
          </div>
        </div>
        <SentimentChart
          {...sentimentChartProps}
        />
        <SentimentChartModal
          {...sentimentChartModalProps}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ watchlistReducer, common, chatbot }) => ({
  activeWatchlist: watchlistReducer.activeWatchlist,
  activeWatchlistTopics: watchlistReducer.activeWatchlistTopics,
  activateFilingsPreloader: watchlistReducer.activateFilingsPreloader,
  width: common.width,
  showChatbot: chatbot.isChatbotOpened,
});

export default withComponentName(connect(mapStateToProps, null)(SentimentAnalysis));
