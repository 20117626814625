import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import Preloader from '../../../../../Preloader';
import Tooltip from '../../../../../Tooltip';
import CFLink from '../../../../../Shared/CFLink';
import SummarisationList from '../../../../../Summarisation/SummarisationList';

import { GOLD } from '../../../../../../data/permissions';
import { CF_URL_FOR_FINPROMPT } from '../../../../../../data/environment';

import useFinprompt from '../../../../../../providers/FinpromptProvider/useFinprompt';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const ModalSummaries = ({
  summaryData, isSentimentMainLocations, hasBigScreenDesign, className = '', isInsideModal,
}) => {
  const isGold = useSelector(({ subscriptions }) => (
    subscriptions.permissions.access_levels.includes(GOLD)
  ));
  const {
    loading,
    summaries,
    notAllowed,
  } = summaryData;
  const { isFinpromptPages } = useFinprompt();

  return (
    <div
      className={cx('summarisation', className, {
        sentiment_locations: isSentimentMainLocations,
        big_screen: hasBigScreenDesign,
        inside_modal: isInsideModal,
      })}
    >
      {!loading ? (
        <SummarisationList
          summaries={summaries}
          notAllowed={notAllowed}
          blurred={!isGold}
          hasBigScreenDesign={hasBigScreenDesign}
        >
          {!isGold && (
            <div className={Styles['summarisation-premium']}>
              <Tooltip
                icon={(
                  <span className={Styles['summarisation-premium-badge']}>
                    GOLD
                  </span>
                )}
                className={cx('summarisation-premium-tooltip', 'gold-tooltip', {
                  'summarisation-premium-tooltip-no-stories': !summaries.length,
                })}
                hasBigScreenDesign={hasBigScreenDesign}
                contentStyle={{ left: 0 }}
                dynamicPosition
              >
                <CFLink
                  to={isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}/pricing` : '/pricing'}
                  external={isFinpromptPages}
                >
                  Upgrade to Gold
                </CFLink>
                &nbsp;to see the full summary
              </Tooltip>
              <div className={Styles['summarisation-premium-message']}>
                <span className={Styles['summarisation-premium-message-first']}>
                  Gold users can see full summaries.&nbsp;
                </span>
                <CFLink
                  to={isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}/pricing` : '/pricing'}
                  external={isFinpromptPages}
                >
                  Upgrade
                </CFLink>
                <span className={Styles['no-wrap']}>&nbsp;for access.</span>
                <Tooltip
                  className={cx('summarisation-premium-tooltip')}
                  hasBigScreenDesign={hasBigScreenDesign}
                  contentStyle={{ left: 0 }}
                  dynamicPosition
                >
                  Gold subscriptions enable more content.
                  <br />
                  <CFLink
                    to={isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}/pricing` : '/pricing'}
                    external={isFinpromptPages}
                  >
                    See plans
                  </CFLink>
                  &nbsp;for details.
                </Tooltip>
              </div>
            </div>
          )}
        </SummarisationList>
      ) : (
        <Preloader loading static />
      )}
    </div>
  );
};

export default ModalSummaries;
