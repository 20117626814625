import axios from 'axios';
import { companiesInstance as CompaniesAPI } from './topicsApi';

import { copy } from '../../helpers/commonHelpers';
import { getAPICachingTime } from '../../helpers/helpers';

import { cachingMedium } from '../../data/webPageData';
import { RetryAPICall } from '../../helpers/apiHelpers';

const caches = {
  natureArr: {},
  fundraising: {},
  fundraisingCurrent: {},
  fundraisingGraphs: {},
  investors: {},
  investorsCurrent: {},
  market: {},
  marketCurrent: {},
  roi: {},
  roiCurrent: {},
  graphsShares: {},
  capital: {},
  shares: {},
  singleInvestor: {},
  coverageArr: {},
  investorHolders: {},
  companiesSearch: {},
};

export class InsiderSvc {
  static async getCompanyInsiderNature() {
    try {
      const key = 'default';
      if (
        caches.natureArr[key]
        && caches.natureArr[key].expDate > Date.now()
      ) {
        return copy(caches.natureArr[key].response);
      }

      let { data } = await RetryAPICall(CompaniesAPI, 'insights/nature');
      if (!Array.isArray(data)) {
        data = [];
      }

      caches.natureArr[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderFundraising(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.fundraising[key] && caches.fundraising[key].expDate > Date.now()) {
        return copy(caches.fundraising[key].response);
      }

      let { data } = await RetryAPICall(CompaniesAPI, 'insights/fundraising', {
        params,
      });

      if (!Array.isArray(data)) {
        data = [];
      }

      caches.fundraising[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return data;
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderFundraisingCurrent(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.fundraisingCurrent[key] && caches.fundraisingCurrent[key].expDate > Date.now()) {
        return copy(caches.fundraisingCurrent[key].response);
      }

      const { data } = await RetryAPICall(CompaniesAPI, 'insights/fundraising/current', {
        params,
      });

      caches.fundraisingCurrent[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return data;
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderFundraisingGraphs(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.fundraisingGraphs[key] && caches.fundraisingGraphs[key].expDate > Date.now()) {
        return copy(caches.fundraisingGraphs[key].response);
      }

      let { data } = await RetryAPICall(CompaniesAPI, 'insights/graphs/fundraising', {
        params,
      });
      data = data.data || [];
      if (!Array.isArray(data)) {
        data = [];
      }

      caches.fundraisingGraphs[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderInvestors(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.investors[key] && caches.investors[key].expDate > Date.now()) {
        return copy(caches.investors[key].response);
      }

      let { data } = await RetryAPICall(CompaniesAPI, 'insights/investors', {
        params,
      });
      if (!Array.isArray(data)) {
        data = [];
      }

      caches.investors[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderInvestorsCurrent(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.investorsCurrent[key] && caches.investorsCurrent[key].expDate > Date.now()) {
        return copy(caches.investorsCurrent[key].response);
      }

      const { data } = await RetryAPICall(CompaniesAPI, 'insights/investors/current', {
        params,
      });

      caches.investorsCurrent[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderMarket(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.market[key] && caches.market[key].expDate > Date.now()) {
        return copy(caches.market[key].response);
      }

      let { data } = await RetryAPICall(CompaniesAPI, 'insights/market', {
        params,
      });

      if (!Array.isArray(data)) {
        data = [];
      }

      caches.market[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderMarketCurrent(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.marketCurrent[key] && caches.marketCurrent[key].expDate > Date.now()) {
        return copy(caches.marketCurrent[key].response);
      }

      let { data } = await RetryAPICall(CompaniesAPI, 'insights/market/current', {
        params,
      });
      if (!Array.isArray(data)) {
        data = [];
      }

      caches.marketCurrent[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderRoi(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.roi[key] && caches.roi[key].expDate > Date.now()) {
        return copy(caches.roi[key].response);
      }

      let { data } = await RetryAPICall(CompaniesAPI, 'insights/roi', {
        params,
      });

      if (!Array.isArray(data)) {
        data = [];
      }

      caches.roi[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderRoiCurrent(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.roiCurrent[key] && caches.roiCurrent[key].expDate > Date.now()) {
        return copy(caches.roiCurrent[key].response);
      }

      const { data } = await RetryAPICall(CompaniesAPI, 'insights/roi/current', {
        params,
      });

      caches.roiCurrent[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getCompanyGraphsShares(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.graphsShares[key] && caches.graphsShares[key].expDate > Date.now()) {
        return copy(caches.graphsShares[key].response);
      }

      let { data } = await RetryAPICall(CompaniesAPI, 'insights/graphs/shares', {
        params,
      });
      data = data.data || [];
      if (!Array.isArray(data)) {
        data = [];
      }

      caches.graphsShares[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderCapital(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.capital[key] && caches.capital[key].expDate > Date.now()) {
        return copy(caches.capital[key].response);
      }

      let { data } = await RetryAPICall(CompaniesAPI, 'insights/capital', {
        params,
      });

      data = data.data || [];

      caches.capital[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return data;
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderShares(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.shares[key] && caches.shares[key].expDate > Date.now()) {
        return copy(caches.shares[key].response);
      }

      let { data } = await RetryAPICall(CompaniesAPI, 'insights/shares', {
        params,
      });

      data = data.data || {};

      caches.shares[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return data;
    } catch (e) {
      return { };
    }
  }

  static async getCompanySingleInvestor(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.singleInvestor[key] && caches.singleInvestor[key].expDate > Date.now()) {
        return copy(caches.singleInvestor[key].response);
      }
      let { data } = await RetryAPICall(CompaniesAPI, 'insights/investor', {
        params,
      });

      data = data.data || {};

      caches.singleInvestor[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return { };
    }
  }

  static async getCompanyInsiderCoverage() {
    try {
      const key = 'default';
      if (
        caches.coverageArr[key]
        && caches.coverageArr[key].expDate > Date.now()
      ) {
        return copy(caches.coverageArr[key].response);
      }

      const { data } = await RetryAPICall(CompaniesAPI, 'insights/coverage');

      caches.coverageArr[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return data;
    } catch (e) {
      return {};
    }
  }

  static async getCompanyInsiderInvestorHolders(params = {}) {
    try {
      const key = JSON.stringify(params);
      if (caches.investorHolders[key] && caches.investorHolders[key].expDate > Date.now()) {
        return copy(caches.investorHolders[key].response);
      }

      const { data } = await RetryAPICall(CompaniesAPI, 'insights/company', {
        params,
      });

      caches.investorHolders[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return data;
    } catch (e) {
      return {
        currentInvestors: [],
        historicalInvestors: [],
      };
    }
  }

  static async getCompanySearch(extraParams = {}) {
    try {
      const { cancelToken, ...params } = extraParams;
      const key = JSON.stringify(params);
      if (caches.companiesSearch[key] && caches.companiesSearch[key].expDate > Date.now()) {
        return copy(caches.companiesSearch[key].response);
      }

      const { data } = await RetryAPICall(CompaniesAPI, 'search', {
        params, cancelToken,
      });

      caches.companiesSearch[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return data;
    } catch (e) {
      return {
        error: true,
        cancelled: axios.isCancel(e),
      };
    }
  }
}

export default InsiderSvc;
