import DCSCSectorsSvc from '../services/dbServices/DCSCSectorsSvc';

export const DCSC_RELEVENT_COMPANIES_CSV_START = 'DCSC_RELEVENT_COMPANIES_CSV_START';
export const DCSC_RELEVENT_COMPANIES_CSV_END = 'DCSC_RELEVENT_COMPANIES_CSV_END';
export const DCSC_RELEVENT_COMPANIES_CSV_CANCEL = 'DCSC_RELEVENT_COMPANIES_CSV_CANCEL';
export const DCSC_RELEVENT_COMPANIES_CSV_ERROR = 'DCSC_RELEVENT_COMPANIES_CSV_ERROR';

const csvProcessObj = {};

export const cancelRelevantCompaniesCSV = (csvKey) => (dispatch) => {
  if (csvProcessObj[csvKey]) {
    if (csvProcessObj[csvKey].controllerSignal) {
      csvProcessObj[csvKey].controllerSignal.abort();
    }
    csvProcessObj[csvKey].cancleCSV = true;
    dispatch({
      type: DCSC_RELEVENT_COMPANIES_CSV_CANCEL,
    });
  }
};

const handleCSVExtraction = (csvKey, blobResponse) => {
  if (csvProcessObj[csvKey]?.cancleCSV) return;

  const blob = new Blob([blobResponse], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${csvKey}.csv`;
  a.click();
  window.URL.revokeObjectURL(url);
  csvProcessObj[csvKey].cancleCSV = false;
  csvProcessObj[csvKey].processCSV = false;
};

export const generateRelevantCompaniesCSV = (params, cancelToken) => async (dispatch) => {
  const setProps = {
    cancleCSV: false,
    processCSV: true,
  };
  const csvKey = 'SmartWatchlist';
  csvProcessObj[csvKey] = setProps;

  dispatch({
    type: DCSC_RELEVENT_COMPANIES_CSV_START,
  });

  const result = await DCSCSectorsSvc.getRelevantCompaniesCSV(params, cancelToken);

  if (result) {
    handleCSVExtraction(csvKey, result);
    dispatch({
      type: DCSC_RELEVENT_COMPANIES_CSV_END,
    });
  } else {
    const csvErrorMessage = 'An error occurred. Please try again later or contact us.';
    handleCSVExtraction(csvKey, new Blob([csvErrorMessage]));
    dispatch({
      type: DCSC_RELEVENT_COMPANIES_CSV_ERROR,
    });
  }
};
