import { TEXT, EMAIL, LOCATION, PASSWORD, SEGMENTS } from './dataTypes';
import {
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  SEGMENTS_FIELD,
  EMAIL_FIELD,
  LOCATION_FIELD,
  PASSWORD_FIELD,
  CURRENT_PASSWORD_FIELD,
  NEW_PASSWORD_FIELD,
} from './fieldTypes';

export const accountData = [
  {
    type: TEXT,
    field: FIRST_NAME_FIELD,
    displayField: 'First Name',
    value: null,
  },
  {
    type: TEXT,
    field: LAST_NAME_FIELD,
    displayField: 'Last Name',
    value: null,
  },
  {
    type: SEGMENTS,
    field: SEGMENTS_FIELD,
    displayField: 'Segment',
    value: null,
  },
  {
    type: EMAIL,
    field: EMAIL_FIELD,
    displayField: 'Email',
    value: null,
  },
  {
    type: LOCATION,
    field: LOCATION_FIELD,
    displayField: 'Location',
    value: null,
  },
  {
    type: PASSWORD,
    field: PASSWORD_FIELD,
    displayField: 'Password',
    value: null,
  },
  {
    type: PASSWORD,
    field: CURRENT_PASSWORD_FIELD,
    displayField: 'Password',
    value: null,
  },
  {
    type: PASSWORD,
    field: NEW_PASSWORD_FIELD,
    displayField: '',
    value: null,
  },
];
