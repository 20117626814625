import classNames from 'classnames/bind';

import withComponentName from '../../../../decorators/withComponentName';
import { timeFilterTooltip } from '../../../../data/sentimentAnalysis';

import Tooltip from '../../../Tooltip';

import Styles from '../styles.module.scss';

const cx = classNames.bind(Styles);

const ChartTimeFilters = ({
  className = '', options, activeFilter, setTimeFilter, isSentiment, isTransparent, hasBigScreenDesign, mobileChatbot,
}) => {
  const timeFiltersClass = cx('time-filters', {
    [className]: true,
    sentiment: isSentiment,
    is_transparent: isTransparent,
    big_screen: hasBigScreenDesign,
    mobile_chatbot: mobileChatbot,

  });

  return (
    <ul className={timeFiltersClass}>
      {options.map((filter) => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li
          key={filter}
          className={cx('filter', { active: activeFilter === filter })}
          onClick={() => setTimeFilter(filter)}
        >
          {isSentiment ? (
            <Tooltip
              icon={timeFilterTooltip[filter].text}
              className={Styles['time-filters-tooltip']}
              disableOnMobile
              hasBigScreenDesign
            >
              {timeFilterTooltip[filter].tooltip}
            </Tooltip>
          ) : (
            <>
              {filter}
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

export default withComponentName(ChartTimeFilters);
