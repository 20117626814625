import {
  FETCH_STORIES_BEGIN,
  FETCH_STORIES_SUCCESS,
  FETCH_STORIES_FAILURE,
  HIDE_STORY,
  FREEZE_STORIES,
  CHANGE_TOPIC,
  HIDE_SHOW_MORE,
  ACTIVATE_TRENDING_PRELOADER, // main preloader activation
  DISACTIVATE_TRENDING_PRELOADER, // disable main preloader
  TOGGLE_SIMILAR_STORIES_MODAL,
  SET_STORY_IMAGE_OPENED,
  RESET_STORIES_REDUX_DATA,
  UPDATE_STORIES_INFO,
  UPDATE_STORIES_BOOKMARK,
} from '../actions/stories.actions';

const initialState = {
  cards: [],
  loading: false,
  error: null,
  freeze_stories: false,
  id: null,
  show_more: false,
  activatePreloader: false,
  similarStoriesModalOpened: false,
  storyImageOpened: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_TRENDING_PRELOADER:
      return {
        ...state,
        activatePreloader: true,
      };
    case DISACTIVATE_TRENDING_PRELOADER:
      return {
        ...state,
        activatePreloader: false,
      };
    case FETCH_STORIES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_STORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        cards: action.payload.stories,
        activatePreloader: false,
      };

    case FETCH_STORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        cards: [],
      };

    case RESET_STORIES_REDUX_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        cards: [],
      };

    case HIDE_STORY:
      return {
        ...state,
        cards: state.cards.filter((item) => item.id !== action.payload),
      };

    case FREEZE_STORIES:
      return {
        ...state,
        freeze_stories: action.payload,
      };

    case CHANGE_TOPIC:
      return {
        ...state,
        id: action.payload,
      };

    case HIDE_SHOW_MORE:
      return {
        ...state,
        show_more: action.payload,
      };

    case TOGGLE_SIMILAR_STORIES_MODAL:
      return {
        ...state,
        similarStoriesModalOpened: action.payload,
      };

    case SET_STORY_IMAGE_OPENED:
      return {
        ...state,
        storyImageOpened: action.payload,
      };
    case UPDATE_STORIES_INFO:
      return {
        ...state,
        cards: action.payload,
      };
    case UPDATE_STORIES_BOOKMARK:
      return {
        ...state,
        cards: action.payload,
      };
    default:
      return state;
  }
};
