import classNames from 'classnames/bind';
import { isMobile } from 'react-device-detect';

import Tooltip from '../../Tooltip';

import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const UpgradeTooltipSmall = ({
  planBadgeText,
  cssClass,
  mobileDirection,
  isGold,
  isSilver,
  premiumContentFilter,
  hasBigScreenDesign,

}) => {
  const plan = planBadgeText.toLowerCase();

  const getTooltipText = () => {
    if (((!isSilver && !isGold && !premiumContentFilter && plan !== 'silver') || (isGold && plan === 'silver'))) {
      return (
        <>
          Data and analytics beyond
          The Basics.
        </>
      );
    }
    if (((isSilver && plan === 'silver'))) {
      return (
        <>
          { isMobile ? (
            <a href="/pricing" className={cx('plan-url')}>
              This is available only to Premium subscribers
            </a>
          ) : 'This is available only to Premium subscribers'}
        </>
      );
    }
    if (((isGold && premiumContentFilter && plan === 'gold'))) {
      return (
        <>
          { isMobile ? (
            <a href="/pricing" className={cx('plan-url')}>
              This is available only to Gold subscribers
            </a>
          ) : 'This is available only to Gold subscribers'}
        </>
      );
    }
    if (!isGold && premiumContentFilter) {
      return (
        <>
          { isMobile ? (
            <a href="/pricing" className={cx('plan-url')}>
              Upgrade  your plan to Gold
              &nbsp;to use this feature
            </a>
          ) : (
            <span>
              <a href="/pricing" className={cx('plan-url')}>
                Upgrade
              </a>
              &nbsp;your plan to Gold
              &nbsp;to use this feature
            </span>
          )}
        </>
      );
    }
    if (!isSilver && !isGold && (plan === 'silver')) {
      return (
        <>
          { isMobile ? (
            <a href="/pricing" className={cx('plan-url')}>
              Upgrade your plan to Silver
              &nbsp;to use this feature
            </a>
          ) : 'Upgrade your plan to Silver to use this feature'}
        </>
      );
    }
  };

  return (
    <Tooltip
      className={cx('plan-suggestion', { user: isGold && premiumContentFilter }, [cssClass], [mobileDirection], { bigScreen: hasBigScreenDesign })}
      icon={(
        <a
          onClick={(e) => isMobile && e.preventDefault()}
          href="/pricing"
          className={cx('plan-badge', `${plan}`)}
        >
          {planBadgeText}
        </a>
    )}
      dynamicPosition
    >
      {getTooltipText()}
    </Tooltip>
  );
};

export default withComponentName(UpgradeTooltipSmall);
