import { useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquare } from '@fortawesome/pro-light-svg-icons/faExternalLinkSquare';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';

import Slider from '../../../Shared/ReactSlick';

import { returnCDNFoxyImageUrl } from '../../../../helpers/helpers';

import Styles from '../styles.module.scss';

const cx = classNames.bind(Styles);

const CompactViewImageLoader = (props) => {
  const {
    isCompactExpandView, story, imageLoadingError, isDesktop, imageHeight,
    imgURL, openTwitterImagesModal, onImageLoad, onImageError,
    openCurrShownImageInNewTab,
  } = props;
  const [selectedImg, setSelectedImg] = useState(imgURL);

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  if (!story.imageUrls || !isCompactExpandView) return null;

  if (!isDesktop && story.imageUrls.length > 1) {
    return (
      <div className={cx('Card__content_slider--img')}>
        <ImageSection
          imageHeight={imageHeight}
          openTwitterImagesModal={openTwitterImagesModal}
          imgURL={selectedImg}
          onImageLoad={onImageLoad}
          onImageError={onImageError}
          openCurrShownImageInNewTab={openCurrShownImageInNewTab}
          story={story}
        />
        <Slider {...settings}>
          {story.imageUrls.map((imgUrl, i) => (
            <InnerImageSection
              // eslint-disable-next-line react/no-array-index-key
              key={`key_${i}`}
              imgUrl={imgUrl}
              setSelectedImg={setSelectedImg}
            />
          ))}
        </Slider>
      </div>
    );
  }

  if (isCompactExpandView && !imageLoadingError) {
    return (
      <ImageSection
        showCount
        imageHeight={imageHeight}
        openTwitterImagesModal={openTwitterImagesModal}
        imgURL={imgURL}
        onImageLoad={onImageLoad}
        onImageError={onImageError}
        openCurrShownImageInNewTab={openCurrShownImageInNewTab}
        story={story}
      />
    );
  }

  return null;
};

const ImageSection = ({
  imageHeight, openTwitterImagesModal, imgURL,
  onImageLoad, onImageError, openCurrShownImageInNewTab,
  story, showCount,
}) => (
  <div className={cx('Card__content--img')}>
    <div
      className="details-header__statistics--twitter-img"
      style={{ height: imageHeight }}
      onClick={openTwitterImagesModal}
    >
      <img
        src={imgURL}
        onLoad={onImageLoad}
        loading="lazy"
        onError={onImageError}
        alt="Story"
      />
    </div>
    <div className={cx('open-btn')} onClick={openCurrShownImageInNewTab}>
      <FontAwesomeIcon icon={faExternalLinkSquare} />
    </div>
    {(story.imageUrls.length > 1) && showCount && (
      <span className={cx('details-header__info--images')}>
        +
        {story.imageUrls.length}
      </span>
    )}
  </div>
);

const NextArrow = ({ onClick }) => (
  <div
    className={cx('right_arrow')}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faChevronRight} />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div
    className={cx('left_arrow')}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faChevronLeft} />
  </div>
);

const InnerImageSection = ({ imgUrl, setSelectedImg }) => {
  const [isFoxy, setIsFoxy] = useState(true);
  const handleImageError = () => {
    if (isFoxy) {
      setIsFoxy(false);
    }
  };

  const handleClick = () => {
    setSelectedImg(imgUrl);
  };

  const imageURL = returnCDNFoxyImageUrl({
    isFoxy, imageURL: imgUrl,
  });

  return (
    <div
      className={cx('slide-img-holder')}
      onClick={handleClick}
    >
      <img
        src={imageURL}
        alt=""
        onError={handleImageError}
      />
    </div>
  );
};

export default CompactViewImageLoader;
