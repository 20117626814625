import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const CFLink = ({
  external,
  to,
  children,
  ...props
}) => (
  (!to || external) ? (
    <a
      href={to || undefined}
      target="_blank"
      rel="noreferrer noopener"
      {...props}
    >
      {children}
    </a>
  ) : (
    <Link to={to} {...props}>{children}</Link>
  )
);

CFLink.propTypes = {
  to: PropTypes.string,
  external: PropTypes.bool,
  children: PropTypes.node,
};

CFLink.defaultProps = {
  to: '',
  external: false,
  children: null,
};

export default CFLink;
