import { memo, useEffect, useState } from 'react';

import NewsCard from '../../HomePage/TopNews/NewsCard';
import Scrollable from '../../Shared/Scrollable';
import BookmarkStoryIcon from '../../BookmarksComponent/BookmarkStoryIcon';
import ShowButton from './ShowButton';

import windowWidthDecorator from '../../../decorators/windowWidthDecorator';

import { CF_URL_FOR_FINPROMPT } from '../../../data/environment';

import useFinprompt from '../../../providers/FinpromptProvider/useFinprompt';

const BookmarkStories = (props) => {
  const {
    payload, windowWidth, handleShowMoreButton, isChatbotOpened, scrollToBottomOnWidgetRendered,
    expanded,
  } = props;
  const components = payload?.components || [];
  const messageDate = payload.date;

  const { isFinpromptPages } = useFinprompt();

  const [stories, setStories] = useState([]);

  const toggleRemoveBookmark = (e, story) => {
    if (e) e.stopPropagation();

    const filteredStories = stories.filter((item) => item.id !== story.id);
    setStories([...filteredStories]);
  };

  const handleClick = () => {
    const redirectPath = isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}/bookmarks` : '/bookmarks';
    if (handleShowMoreButton) handleShowMoreButton(redirectPath);
  };

  useEffect(() => {
    components.forEach((item) => {
      if (item.component_name.trim() === 'bookmarked_stories') {
        setStories([...item.component_data.stories]);
      }
    });
  }, [components]);

  useEffect(() => {
    if (!isChatbotOpened) return;
    if (scrollToBottomOnWidgetRendered) scrollToBottomOnWidgetRendered();
  }, [stories, isChatbotOpened]);

  if (!stories?.length) return null;

  return (
    <div
      data-date={messageDate}
      className="react-chatbot-kit__widget__wrapper bookmarks show-more"
    >
      <Scrollable
        wrapperClassName="chatbot-widget__scrollable"
        arrowClass="top-news-stories-arrow"
        hideArrows={windowWidth < 1024}
        chatbotWidget
        isAllowFull
        arrowAndContentAlignment

      >
        {stories?.map((story) => (
          <div key={story?.id} style={{ position: 'relative' }}>
            <BookmarkStoryIcon
              width={windowWidth}
              toggleRemovedBookmark={toggleRemoveBookmark}
              bookmark={story}
              chatbot
            />
            <NewsCard
              key={story.id}
              story={story}
            />
          </div>
        ))}
      </Scrollable>
      <ShowButton
        handleClick={handleClick}
        isChatbotOpened={isChatbotOpened}
        expanded={expanded}
      />
    </div>
  );
};

export default memo(windowWidthDecorator(BookmarkStories));
