import API, { APIWithoutToken } from './api';

import StorageSvc from '../StorageSvc';
import ExternalSharingStore from '../../store/ExternalSharingStore';

import { SILVER } from '../../data/permissions';

import { cachingVeryHigh } from '../../data/webPageData';
import { getAPICachingTime, returnAPICachingHeader } from '../../helpers/helpers';
import { RetryAPICall } from '../../helpers/apiHelpers';

const caches = {

};

// helper class for story interactions
export default class StoriesApiDbService {
  static async isBasicUser() {
    const accessLevels = await ExternalSharingStore.getAccessLevels();
    return Array.isArray(accessLevels) && !accessLevels.includes(SILVER);
  }

  static async getSignleStory(uuid) {
    const key = `single_story_${uuid}`;

    if (caches[key] && caches[key].response && caches[key]?.expDate > Date.now()) {
      return caches[key].response;
    }

    // Using api token instead of group token for curator users story requests
    // To get curator data in response
    const res = await RetryAPICall(ExternalSharingStore.isCurationActive ? API : APIWithoutToken, `stories/${uuid}`, {
      headers: {
        'Cache-control': returnAPICachingHeader(cachingVeryHigh),
      },
      params: {
        token: !(await this.isBasicUser()) && !ExternalSharingStore.isCurationActive
          ? await ExternalSharingStore.getGroupToken()
          : undefined,
      },
    });

    caches[key] = {
      expDate: getAPICachingTime(cachingVeryHigh),
      response: res,
    };

    return res;
  }

  static async getSignleTranslatedStory(uuid, lang) {
    const key = `single_story_${uuid}_${lang}`;

    if (caches[key] && caches[key].response && caches[key]?.expDate > Date.now()) {
      return caches[key].response;
    }

    // Using api token instead of group token for curator users story requests
    // To get curator data in response
    const res = await RetryAPICall(ExternalSharingStore.isCurationActive ? API : APIWithoutToken, `stories/${uuid}`, {
      headers: {
        'Cache-control': returnAPICachingHeader(cachingVeryHigh),
      },
      params: {
        token: !(await this.isBasicUser()) && !ExternalSharingStore.isCurationActive
          ? await ExternalSharingStore.getGroupToken()
          : undefined,
        translate_to: lang,
      },
    });

    caches[key] = {
      expDate: getAPICachingTime(cachingVeryHigh),
      response: res,
    };

    return res;
  }

  static async getBookmarkStories(params) {
    const res = await API.get('stories', {
      params: {
        ...params,
        query: '',
        bookmarked: true,
      },
    });
    return res;
  }

  // like/dislike actions, send type to deffer which you want to run
  static async storyLikeAction(id, type) {
    return API.post(`stories/${id}/${type}`);
  }

  static async addStoryToBookmark(id) {
    return API.post(`stories/${id}/bookmark`);
  }

  static async removeStoryBookmark(id) {
    return API.delete(`stories/${id}/bookmark`);
  }

  static async checkIsStoryBookmarked(id, uuid) {
    return API.get(`stories/curation_actions?story_ids=${id},${uuid}`);
  }

  static async updateStoryRating(id, type) {
    let activeAssetIds;

    try {
      activeAssetIds = JSON.parse(StorageSvc.getItem('state')).storyFilters.asset_classes || '';
    } catch (e) {
      activeAssetIds = '';
    }

    const data = await API.patch(`stories/${id}/update_rating`, {
      rating_type: type,
      active_asset_ids: activeAssetIds,
      id,
    });

    return data;
  }

  static async shareStory(id, source) {
    return API.post(`stories/${id}/share_on_social_media`, { share_type: source });
  }
}
