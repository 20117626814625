import { memo } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const LinkTag = ({
  withoutReload,
  children,
  href,
  onClick = () => {},
  ...otherProps
}) => (
  withoutReload ? (
    <Link {...otherProps} to={href} onClick={(e) => onClick(e)}>
      {children}
    </Link>
  ) : (
    <a {...otherProps} href={href} onClick={(e) => onClick(e)}>
      {children}
    </a>
  )
);

const ButtonComponent = ({
  value,
  className = '',
  path,
  green,
  gray,
  margin_top: marginTop,
  isBlank,
  checkEvent,
  jqSelector = '',
  clickHandler,
  withoutReload = false,
  align_left: alignLeft,
  align_center: alignCenter,
  all_width: allWidth,
  large,
  longer,
  mobile_long: mobileLong,
  short,
  shortAlways,
  dynamic,
  disabled,
  noborder,
  nomargin,
  borderRadiusMedium,
  useBtn = false,
  purpleBg,
  isFinpromptPages,
  hasBigScreenDesign,
  btnType = 'button',
}) => {
  const ButtonClassNames = cx(className, {
    button_component: true,
    green,
    gray,
    disabled,
    transparent: !green && !gray,
    btn_long: longer,
    btn_mobile_long: mobileLong,
    btn_large: large,
    btn_short: short,
    btn_short_always: shortAlways,
    btn_all_width: allWidth,
    btn_dynamic: dynamic,
    margin_top: marginTop,
    align_center: alignCenter,
    align_left: alignLeft,
    noborder,
    nomargin,
    border_radius_medium: borderRadiusMedium,
    purple: purpleBg,
    finprompt: isFinpromptPages,
    big_screen: hasBigScreenDesign,
  });

  if (useBtn) {
    return (
      <button
        type={btnType}
        className={ButtonClassNames + jqSelector}
        onClick={clickHandler}
      >
        {value}
      </button>
    );
  }

  return (
    <LinkTag
      className={ButtonClassNames + jqSelector}
      href={path}
      target={isBlank ? '_blank' : '_self'}
      onClick={!path ? clickHandler : checkEvent}
      withoutReload={withoutReload}
      tabIndex="0"
    >
      {value}
    </LinkTag>
  );
};

export default withComponentName(memo(ButtonComponent));
