import PropTypes from 'prop-types';

import withComponentName from '../../../../decorators/withComponentName';

import Styles from './styles.module.scss';

export function GrayButton({ className, children, ...otherProps }) {
  return (
    <button type="button" className={`${Styles['preferences-btn-gray']} ${className}`} {...otherProps}>
      {children}
    </button>
  );
}

GrayButton.propTypes = {
  className: PropTypes.string,
};

GrayButton.defaultProps = {
  className: '',
};

export default withComponentName(GrayButton);
