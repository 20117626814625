import {
  FETCH_ESG_BEGIN,
  FETCH_ESG_FAILURE,
  FETCH_ESG_SUCCESS,
  SET_ESG_PAGE_LOADER,
  GET_NEW_ESG_BEGIN,
  GET_NEW_ESG_SUCCESS,
  GET_NEW_ESG_FAILURE,
  SET_ESG_TYPES_FILTER,
  SET_ESG_YEARS_FILTER,
  RESET_ESG_ALL_FILTERS,
  SET_ESG_STORAGE_FILTERS,
} from '../actions/esg.actions';

const initialState = {
  loading: false,
  presentations: [],
  presentationsError: null,
  presentationsPageLoad: false,
  newPresentationsLoading: false,
  newPresentationsError: false,
  isAllTypesSelected: true,
  selectedTypes: [],
  isAllYearsSelected: true,
  fromYear: '',
  toYear: '',
  showUndated: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ESG_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ESG_SUCCESS:
      return {
        ...state,
        loading: false,
        presentationsPageLoad: false,
        presentations: payload.presentations,
      };
    case FETCH_ESG_FAILURE:
      return {
        ...state,
        loading: false,
        presentations: [],
        presentationsPageLoad: false,
        presentationsError: payload.error,
      };
    case SET_ESG_PAGE_LOADER:
      return {
        ...state,
        presentationsPageLoad: payload,
      };
    case GET_NEW_ESG_BEGIN:
      return {
        ...state,
        newPresentationsLoading: true,
        newPresentationsError: false,
      };
    case GET_NEW_ESG_SUCCESS:
      return {
        ...state,
        newPresentationsLoading: false,
        presentations: [...state.presentations, ...payload],
        newPresentationsError: false,
      };
    case GET_NEW_ESG_FAILURE:
      return {
        ...state,
        newPresentationsLoading: false,
        newPresentationsError: true,
      };
    case SET_ESG_STORAGE_FILTERS:
      return {
        ...state,
        ...payload,
      };
    case SET_ESG_TYPES_FILTER:
      return {
        ...state,
        isAllTypesSelected: payload.isAllTypesSelected,
        selectedTypes: payload.selectedTypes,
      };
    case SET_ESG_YEARS_FILTER:
      return {
        ...state,
        isAllYearsSelected: payload.isAllYearsSelected,
        fromYear: payload.fromYear,
        toYear: payload.toYear,
        showUndated: payload.showUndated,
      };
    case RESET_ESG_ALL_FILTERS:
      return {
        ...state,
        isAllTypesSelected: true,
        selectedTypes: [],
        isAllYearsSelected: true,
        fromYear: '',
        toYear: '',
        showUndated: true,
      };
    default:
      return state;
  }
};
