import StorageSvc from '../../../services/StorageSvc';

const updateChatbotMessageById = (message, userId) => {
  const history = JSON.parse(StorageSvc.getItem('chatbotMessages')) || [];
  let currentUserHistory = [];
  let otherUsersHistory = [];

  if (history) {
    currentUserHistory = history?.filter((item) => item.userId === userId);
    otherUsersHistory = history?.filter((item) => item.userId !== userId);
  }
  const messages = (currentUserHistory[0]?.messages || []);
  const index = messages.findIndex((item) => item.message?.date === message.date);

  if (index !== -1) {
    messages[index].payload.clicked = { decision: message.decision };
    StorageSvc.setItem('chatbotMessages', JSON.stringify(
      [...otherUsersHistory,
        { ...currentUserHistory[0], messages: [...messages] },
      ],
    ));
  }
};

export default updateChatbotMessageById;
