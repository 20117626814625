import { companiesInstance as CompaniesAPI } from './topicsApi';

import { copy, removeEmptyProperties } from '../../helpers/commonHelpers';
import { getAPICachingTime } from '../../helpers/helpers';

import {
  cachingMedium,
  cachingVeryHigh,
  cachingVeryVeryHigh,
  cachingVeryVeryLow,
} from '../../data/webPageData';
import { RetryAPICall } from '../../helpers/apiHelpers';

const caches = {
  insiderTransactions: {},
  ownership: {},
  chartTransactions: {},
  chartAggregatedTransactions: {},
  stakeholderTitles: null,
  stakeholderHoldings: null,
};

export class InsiderTransactionsSvc {
  static filterDuplicateTransactions(data) {
    const identifiers = new Set();
    const identifierBuildingKeys = [
      'companyID',
      'currency',
      'numberOfShares',
      'officerID',
      'officerTitle',
      'pricePerShare',
      'sharesHeldDirectly',
      'sharesHeldIndirectly',
      'sharesHeldTotal',
      'transactionDate',
      'transactionType',
      'typeOfSecurity',
      'value',
    ];

    return data.filter((transaction) => {
      let transactionIdentifier = '';

      identifierBuildingKeys.forEach((key) => {
        transactionIdentifier += transaction[key];
      });

      if (identifiers.has(transactionIdentifier)) {
        return false;
      }

      identifiers.add(transactionIdentifier);
      transaction.uid = transactionIdentifier + Math.random().toString(36).slice(2);
      return true;
    });
  }

  static getChartTransactionsStats({
    data,
    type,
  }) {
    return (
      this.filterDuplicateTransactions(
        data.flatMap((item) => (
          item.transactions || []
        )),
      ).map((transaction) => (
        new Proxy({
          ...transaction,
          date: new Date(transaction.transactionDate),
          share: transaction.pricePerShare || 0,
        }, {
          get(target, prop, receiver) {
            switch (prop.toLowerCase()) {
              case 'buy': {
                return target.transactionType === 'BUY' ? target[type] : 0;
              }
              case 'sell': {
                return target.transactionType === 'SELL' ? target[type] : 0;
              }
              case 'other': {
                return !['BUY', 'SELL'].includes(target.transactionType) ? target[type] : 0;
              }
              default: {
                return Reflect.get(target, prop, receiver);
              }
            }
          },
        })
      )).sort((a, b) => (
        a.date.getTime() - b.date.getTime()
      ))
    );
  }

  static getChartTransactionsGroupedByMonth(stats, prices = []) {
    const dateLabels = new Set();

    const sharesCount = {};
    const pricesCount = {};
    const buys = {};
    const sells = {};
    const others = {};
    const shares = {};
    const pricesData = {};

    stats.forEach((stat) => {
      const date = new Date(stat.date);
      // group items by year and month
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);

      const dateString = date.toISOString();
      dateLabels.add(dateString);

      buys[dateString] ??= 0;
      buys[dateString] += stat.buy || 0;

      sells[dateString] ??= 0;
      sells[dateString] += stat.sell || 0;

      others[dateString] ??= 0;
      others[dateString] += stat.other || 0;

      shares[dateString] ??= 0;
      sharesCount[dateString] ??= 0;

      shares[dateString] += stat.share || 0;
      sharesCount[dateString] += 1;
    });

    prices.forEach((price) => {
      const date = new Date(price.updated_at * 1000);
      // group items by year and month
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);

      const dateString = date.toISOString();
      dateLabels.add(dateString);

      pricesData[dateString] ??= 0;
      pricesCount[dateString] ??= 0;

      pricesData[dateString] += price.price || 0;
      pricesCount[dateString] += 1;
    });

    return [...dateLabels]
      .sort((a, b) => (
        new Date(a).getTime() - new Date(b).getTime()
      ))
      .map((dateLabel) => ({
        date: dateLabel,
        buy: buys[dateLabel] ?? null,
        sell: sells[dateLabel] ?? null,
        other: others[dateLabel] ?? null,
        share: sharesCount[dateLabel] > 0 ? (shares[dateLabel] / sharesCount[dateLabel]) : null,
        avgPrice: pricesCount[dateLabel] > 0 ? (
          pricesData[dateLabel] / pricesCount[dateLabel]
        ) : null,
      }));
  }

  static getChartTransactionsForGivenMonth({
    data,
    prices,
    date,
  }) {
    const filterDate = new Date(date);

    const [sharesStats, valuesStats] = [
      this.getChartTransactionsStats({
        data,
        type: 'numberOfShares',
      }),
      this.getChartTransactionsStats({
        data,
        type: 'value',
      }),
    ].map((stats) => (
      this.getChartTransactionsGroupedByMonth(stats, prices).filter((stat) => {
        const transactionDate = new Date(stat.date);

        return (
          filterDate.getFullYear() === transactionDate.getFullYear()
          && filterDate.getMonth() === transactionDate.getMonth()
        );
      })
    ));

    return {
      sharesStats,
      valuesStats,
    };
  }

  static transformChartTransactionsIntoStats({
    data,
    type,
    prices = [],
  }) {
    const stats = this.getChartTransactionsStats({
      data,
      type,
    });

    return this.getChartTransactionsGroupedByMonth(stats, prices);
  }

  static transformChartAggregatedTransactions(data) {
    let maxValue = 0;
    const stats = data.map((currentItem) => {
      const item = { ...currentItem };
      item.buys = {
        shares: 0,
        value: 0,
      };
      item.sells = {
        shares: 0,
        value: 0,
      };
      item.other = {
        shares: 0,
        value: 0,
      };
      item.officerTitle = '';

      if (!Array.isArray(item.transactions)) {
        return item;
      }

      item.transactions.forEach((transaction) => {
        switch (transaction.transactionType) {
          case 'BUY': {
            item.buys.shares += transaction.numberOfShares;
            item.buys.value += transaction.value;
            break;
          }
          case 'SELL': {
            item.sells.shares += transaction.numberOfShares;
            item.sells.value += transaction.value;
            break;
          }
          default: {
            item.other.shares += transaction.numberOfShares;
            item.other.value += transaction.value;
            break;
          }
        }
        if (!item.officerTitle && transaction.officerTitle) {
          item.officerTitle = transaction.officerTitle;
        }
      });

      item.totalValue = item.buys.value + item.sells.value + item.other.value;
      if (maxValue < item.totalValue) {
        maxValue = item.totalValue;
      }

      return item;
    }).sort((a, b) => b.totalValue - a.totalValue);

    return {
      stats,
      maxValue,
    };
  }

  static async getCompanyInsiderTransactions(options = {}) {
    try {
      const {
        text,
        transactionTypes,
        transactionInfoTypes,
        officerTitles,
        officers,
        identifier,
        per,
        page,
        refreshTimestamp,
      } = options;
      let {
        startDate = null,
        endDate = null,
      } = options;

      startDate &&= new Date(startDate);
      endDate &&= new Date(endDate);
      if (startDate && Number.isNaN(startDate.getTime())) {
        startDate = null;
      }
      if (endDate && Number.isNaN(endDate.getTime())) {
        endDate = null;
      }

      const params = {
        text,
        start_date: startDate?.toISOString().slice(0, 10),
        end_date: endDate?.toISOString().slice(0, 10),
        transaction_types: transactionTypes,
        transaction_auto: transactionInfoTypes,
        officer_titles: officerTitles,
        officers,
        identifiers: identifier,
        per,
        page,
        refreshTimestamp,
      };

      removeEmptyProperties(params);

      const key = JSON.stringify(params);
      if (caches.insiderTransactions[key] && caches.insiderTransactions[key].expDate > Date.now()) {
        return copy(caches.insiderTransactions[key].response);
      }

      // Splitting the identifier string into chunks of 120 items
      // to avoid too long URLs
      if (typeof options?.identifier === 'string' && options.identifier.split(',').length > 120) {
        const identifiers = options.identifier.split(',');
        const chunkSize = 120;
        return Promise.all(
          Array.from({ length: Math.ceil(identifiers.length / chunkSize) }).map((_, i) => (
            identifiers.slice(i * chunkSize, i * chunkSize + chunkSize).join(',')
          )).map((chunk) => (
            this.getCompanyInsiderTransactions({ ...options, identifier: chunk })
          )),
        ).then((responses) => (
          responses.flat(1)
        ));
      }

      delete params.refreshTimestamp;

      if (!Object.keys(params).length) return [];

      let { data: { data } } = await RetryAPICall(CompaniesAPI, 'insights/insider-transactions', {
        params,
        timeout: 30000,
      });
      if (!Array.isArray(data)) {
        data = [];
      }

      data = this.filterDuplicateTransactions(data);

      caches.insiderTransactions[key] = {
        expDate: getAPICachingTime(cachingVeryVeryLow),
        response: data,
      };

      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderTransactionsOwnership({
    identifier,
  }) {
    try {
      const params = {
        identifiers: identifier,
      };

      const key = JSON.stringify(params);
      if (caches.ownership[key] && caches.ownership[key].expDate > Date.now()) {
        return copy(caches.ownership[key].response);
      }

      if (!Object.keys(params).length) return [];

      // Follow single response if more than 2 requests was sent immediately
      const request = caches.ownership[key]?.request ?? (
        RetryAPICall(CompaniesAPI, 'insights/insider-transactions/ownership', {
          params,
        })
      );
      caches.ownership[key] = {
        expDate: null,
        response: null,
        request,
      };

      let { data: { data = [] } } = await request;
      if (!Array.isArray(data)) {
        data = [];
      }

      caches.ownership[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderTransactionsChartTransactions({
    identifier,
    timePeriod,
  }) {
    try {
      const params = {
        identifiers: identifier,
        time_period: timePeriod,
      };

      if (!Object.keys(params).length) {
        return {
          transactions: [],
          isError: false,
        };
      }

      let { data: { data = [] } } = await CompaniesAPI.get('insights/insider-transactions/charts/transactions', {
        params,
      });
      if (!Array.isArray(data)) {
        data = [];
      }

      return {
        transactions: copy(data),
        isError: false,
      };
    } catch (e) {
      return {
        transactions: [],
        isError: true,
      };
    }
  }

  static async getCompanyInsiderTransactionsChartAggregatedTransactions({
    identifier,
    timePeriod,
  }) {
    try {
      const params = {
        identifiers: identifier,
        time_period: timePeriod,
      };

      const key = JSON.stringify(params);
      if (
        caches.chartAggregatedTransactions[key]
        && caches.chartAggregatedTransactions[key].expDate > Date.now()
      ) {
        return copy(caches.chartAggregatedTransactions[key].response);
      }

      if (!Object.keys(params).length) return [];

      let { data: { data = [] } } = await RetryAPICall(CompaniesAPI, 'insights/insider-transactions/charts/aggregate-transactions', {
        params,
      });
      if (!Array.isArray(data)) {
        data = [];
      }

      caches.chartAggregatedTransactions[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getCompanyInsiderTransactionsStakeholderTitles() {
    try {
      if (caches.stakeholderTitles && caches.stakeholderTitles.expDate > Date.now()) {
        return copy(caches.stakeholderTitles.response);
      }

      const { data } = await RetryAPICall(CompaniesAPI, 'insights/insider-transactions/stakeholder-titles');

      caches.stakeholderTitles = {
        expDate: getAPICachingTime(cachingVeryVeryHigh),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }

  static async getStakeholderHoldings(stakeholderId) {
    try {
      if (caches.stakeholderHoldings && caches.stakeholderHoldings.expDate > Date.now()) {
        return copy(caches.stakeholderHoldings.response);
      }

      const { data: { data } } = await CompaniesAPI.get(`insights/insider-transactions/stakeholders/${stakeholderId}`);

      caches.stakeholderHoldings = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: data,
      };
      return copy(data);
    } catch (e) {
      return [];
    }
  }
}

export default InsiderTransactionsSvc;
