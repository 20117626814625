import { APIWithoutToken } from './api';
import { getAPICachingTime } from '../../helpers/helpers';
import { cachingVeryVeryHigh } from '../../data/webPageData';

const caches = {
  permalinks: new Map(),
};

export class EntitiesSvc {
  static async getEntityPermalink({
    entityType,
    entityId,
  }, lastItem = false, actions) {
    try {
      const CACHE_KEY = `${entityType}_${entityId}`;

      const cachedItem = caches.permalinks.get(CACHE_KEY);
      if (cachedItem && cachedItem.expDate > Date.now()) {
        return cachedItem.response;
      }

      if (lastItem) actions?.togglePreloaderEntity(true);

      const { data } = await APIWithoutToken.get('entities/permalink', {
        params: {
          entity_type: entityType,
          entity_id: entityId,
        },
      });

      caches.permalinks.set(CACHE_KEY, {
        expDate: getAPICachingTime(cachingVeryVeryHigh),
        response: data,
      });

      return data;
    } catch {
      return {
        permalink: undefined,
      };
    } finally {
      if (lastItem) actions?.togglePreloaderEntity(false);
    }
  }
}

export default EntitiesSvc;
