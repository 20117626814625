import { PureComponent } from 'react';
import autoBind from 'react-autobind';

import { ONE_DAY, PRICE_TIME_FILTERS } from '../../../data/directory/topic_classes/ChartFilters';
import { PRICE_DATA_TIMER } from '../../../data/directory/constants';
import {
  CRYPTOCURRENCIES,
  COMMODITIES,
  INDICES, FOREIGN_EXCHANGE,
  SLUG_TO_TYPE_MAPPING,
} from '../../../data/directory/topic_classes/topicClassTypes';

// services
import StockExchangesSvc from '../../../services/dbServices/StockExchangesSvc';

// decorators
import withComponentName from '../../../decorators/withComponentName';
import withFinprompt from '../../../decorators/withFinprompt';

import {
  getActivePriorityTicker,
  fetchTopicFullDetails,
  convertFullDetails,
  fetchTopicChartData,
} from '../../../helpers/topicsApiHelpers';
import loadable from '../../../utils/loadable';
import isMarketTime from '../../../helpers/isMarketTime';
import copy from '../../../helpers/copy';

// env
import { CF_URL_FOR_FINPROMPT } from '../../../data/environment';

const TopicPricingPanel = loadable(() => import('../../SEO_pages/Topic/TopicHeader/TopicPricingPanel'));

export class MarketCapPriceItem extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      selectedPeriod: PRICE_TIME_FILTERS[0].value,
      topicTickerArr: [],
      currentFullDetailsLoading: true,
      currentFullDetails: null,
      stats: [],
      loading: true,
      assetType: null,
    };
    this.interval = null;
    this.mounted = true;
  }

  componentDidMount() {
    const { topic } = this.props;
    this.extractAssetTypeFromWapiCall();

    const topicTickerArr = getActivePriorityTicker(topic?.tickers)
      .map((obj, i) => ({
        ...obj,
        active: i === 0,
      }));

    this.setState({
      topicTickerArr,
    }, () => {
      if (!topicTickerArr.length) {
        this.setState({
          currentFullDetailsLoading: false,
          currentFullDetails: null,
        });
        return;
      }
      this.prepareData();
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.mounted = false;
  }

  extractAssetTypeFromWapiCall() {
    const { wapiCall } = this.props;
    // eslint-disable-next-line no-new-wrappers
    const modified = new String(wapiCall.market_cap).toString().substring(6);

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of SLUG_TO_TYPE_MAPPING) {
      if (modified.includes(key)) {
        this.setState({ assetType: value });
        return;
      }
      if (modified.includes('companies_public') && key === 'stocks') {
        this.setState({ assetType: value });
        return;
      }
    }
  }

  async prepareData() {
    this.fetchTopicFullDetails();
    await this.fetchPriceInfo();

    // for updating chart data
    this.interval = setInterval(this.fetchPriceInfo, PRICE_DATA_TIMER);
  }

  async fetchTopicFullDetails() {
    const { topicTickerArr, assetType } = this.state;

    const selectors = topicTickerArr.find((f) => f.active) || topicTickerArr[0];

    this.setState({
      currentFullDetailsLoading: true,
    });

    const params = {
      page_limit: 1,
      page: 1,
    };

    if (assetType === COMMODITIES || assetType === INDICES || assetType === FOREIGN_EXCHANGE) {
      params.identifiers = `TR:${selectors.name}`;
    } else if (assetType === CRYPTOCURRENCIES) {
      params.symbol = selectors.name;
    } else {
      params.symbol = selectors.full_name;
    }

    const stockExchangeMarketHours = selectors?.stock_exchange_id ? (
      await StockExchangesSvc.getStockExchangeMarketHours(
        selectors.stock_exchange_id,
      )
    ) : null;
    if (stockExchangeMarketHours) {
      params.market_hours = isMarketTime(stockExchangeMarketHours);
    }

    const { fullDetails, error } = await fetchTopicFullDetails(assetType, params);

    this.setState({
      currentFullDetailsLoading: false,
      currentFullDetails: fullDetails && !error ? convertFullDetails(fullDetails) : null,
    });
  }

  async fetchPriceInfo() {
    const { minigraph } = this.props;
    const { selectedPeriod, topicTickerArr, assetType } = this.state;

    this.setState({
      loading: true,
    });

    const selectors = topicTickerArr.find((f) => f.active) || topicTickerArr[0];

    if (!selectors) {
      this.setState({
        stats: [],
        loading: false,
      });
    }

    const params = {
      graph: selectedPeriod,
      minigraph,
    };

    if (assetType === COMMODITIES || assetType === INDICES || assetType === FOREIGN_EXCHANGE) {
      params.identifiers = `TR:${selectors.name}`;
    } else if (assetType === CRYPTOCURRENCIES) {
      params.selectors = [selectors.name];
    } else {
      params.selectors = [selectors.full_name];
    }

    const stockExchangeMarketHours = selectors?.stock_exchange_id ? (
      await StockExchangesSvc.getStockExchangeMarketHours(
        selectors.stock_exchange_id,
      )
    ) : null;
    if (stockExchangeMarketHours) {
      params.market_hours = isMarketTime(stockExchangeMarketHours);
    }

    this.fetchPricePercentageInfo(params);
    const { stats, error } = await fetchTopicChartData(assetType, params);

    if (!this.mounted) {
      return;
    }

    const { stats: oldData } = this.state;
    const newData = error ? oldData : stats.map((v) => ({
      xData: new Date(v.last_updated),
      yData: v.price_usd,
    }));

    this.setState({
      stats: newData,
      loading: false,
    });

    return newData;
  }

  async fetchPricePercentageInfo(params) {
    const {
      selectedPeriod, currentFullDetailsLoading,
      currentFullDetails, assetType,
    } = this.state;

    if (selectedPeriod === ONE_DAY) {
      if (!currentFullDetailsLoading) {
        this.setState({
          percentChange: currentFullDetails?.percentChange,
        });
      }
      return;
    }

    const { percentChange } = await fetchTopicChartData(assetType, params);

    if (!this.mounted) {
      return;
    }

    this.setState({
      percentChange,
    });
  }

  changeTicker(id) {
    const { topicTickerArr } = this.state;
    const updatedArr = topicTickerArr.map((obj) => ({
      ...obj,
      active: obj.id === id,
    }));
    this.setState({
      topicTickerArr: copy(updatedArr),
    }, () => {
      this.fetchTopicFullDetails();
      this.fetchPriceInfo();
    });
  }

  changePeriodFilter(selectedPeriod) {
    this.setState({ selectedPeriod }, this.fetchPriceInfo);
  }

  handleChartClick() {
    const { history, topic, isFinpromptPages } = this.props;

    if (isFinpromptPages) {
      window.open(`${CF_URL_FOR_FINPROMPT}/news/directory/${topic.asset_class?.slug}/${topic.slug}/charts`, '_blank')?.focus?.();
    } else {
      history.push(`/news/directory/${topic.asset_class?.slug}/${topic.slug}/charts`);
    }
  }

  render() {
    const {
      mode,
      width,
      expanded,
      isChatbotOpened,
      scrollToBottomOnWidgetRendered,
    } = this.props;
    const {
      stats,
      selectedPeriod,
      topicTickerArr,
      loading,
      currentFullDetailsLoading,
      currentFullDetails,
      percentChange,
      assetType,
    } = this.state;

    return (
      <TopicPricingPanel
        currentFullDetails={currentFullDetails}
        currentFullDetailsLoading={currentFullDetailsLoading}
        loading={loading}
        assetType={assetType}
        stats={stats}
        mode={mode}
        selectedPeriod={selectedPeriod}
        changePeriodFilter={this.changePeriodFilter}
        width={width}
        topicTickerArr={topicTickerArr}
        changeTicker={this.changeTicker}
        handleChartClick={this.handleChartClick}
        percentChange={percentChange}
        chatbot
        expanded={expanded}
        isChatbotOpened={isChatbotOpened}
        scrollToBottomOnWidgetRendered={scrollToBottomOnWidgetRendered}
      />

    );
  }
}

export default withComponentName(withFinprompt(MarketCapPriceItem));
