import { PureComponent, createRef } from 'react';

import { loadScript, uuid } from '../../../utils';

export class TradingViewWidget extends PureComponent {
  constructor(props) {
    super(props);

    this.widget = createRef();
    this.id = `id_${uuid()}`;
  }

  componentDidMount() {
    if (!('TradingView' in window)) {
      window.TradingView = null;
      const widgetScript = loadScript('//s3.tradingview.com/tv.js', true);
      document.body.appendChild(widgetScript);
    }

    if (window.TradingView) {
      this.createWidget();
    } else {
      this.onWidgetLoad();
    }
  }

  componentDidUpdate() {
    this.widget.current.innerHTML = '';
    this.createWidget();
  }

  onWidgetLoad() {
    Object.defineProperty(window, 'TradingView', {
      set: (v) => {
        Object.defineProperty(window, 'TradingView', {
          value: v,
        });
        this.createWidget();
      },
      configurable: true,
    });
  }

  createWidget() {
    // eslint-disable-next-line new-cap,no-new
    new window.TradingView.widget({
      ...this.props,
      container_id: this.id,
    });
  }

  render() {
    const { tickerName } = this.props;
    const url = `https://www.tradingview.com/symbols/${tickerName}/`;
    return (
      <>
        <div id={this.id} ref={this.widget} />
        <div className="tradingview-widget-copyright">
          <a href={url} rel="noopener noreferrer" target="_blank">
            <span className="blue-text">
              {tickerName}
              {' '}
              Chart
            </span>
          </a>
          {' '}
          by TradingView
        </div>
      </>
    );
  }
}

export default TradingViewWidget;
