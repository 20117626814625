import StoriesApiDbService from '../services/dbServices/StoriesApiService';

export const FETCH_BOOKMARKS_RESOLVED = 'FETCH_BOOKMARKS_RESOLVED';
export const FETCH_BOOKMARKS_PENDING = 'FETCH_BOOKMARKS_PENDING';
export const FETCH_BOOKMARKS_REJECTED = 'FETCH_BOOKMARKS_REJECTED';
export const FETCH_BOOKMARKS_NEXT_PAGE_RESOLVED = 'FETCH_BOOKMARKS_NEXT_PAGE_RESOLVED';

export const fetchBookmarksResolved = (data) => ({
  type: FETCH_BOOKMARKS_RESOLVED,
  payload: {
    bookmarks: data.bookmarkedStories,
    nextPageToken: data.nextPageToken,
  },
});

export const fetchBookmarksPending = () => ({
  type: FETCH_BOOKMARKS_PENDING,
});

export const fetchBookmarksRejected = (error) => ({
  type: FETCH_BOOKMARKS_REJECTED,
  payload: error,
});

export const fetchBookmarksNextPageResolved = (bookmarks, nextPageToken) => ({
  type: FETCH_BOOKMARKS_NEXT_PAGE_RESOLVED,
  payload: {
    bookmarks,
    nextPageToken,
  },
});

export const getUserBookmarks = (filterParams) => async (dispatch) => {
  try {
    dispatch(fetchBookmarksPending());
    const { data } = await StoriesApiDbService.getBookmarkStories(filterParams);
    const { next_page_token: nextPageToken } = data;
    const bookmarkedStories = data.stories
      .map((story, index) => ({ ...story, bookmarked: true, index }));
    dispatch(fetchBookmarksResolved({ bookmarkedStories, nextPageToken }));
  } catch (error) {
    dispatch(fetchBookmarksRejected(error.message));
  }
};

export const getNextBookmarksPage = (filterParams, nextPageToken) => async (dispatch) => {
  try {
    dispatch(fetchBookmarksPending());
    const { data } = await StoriesApiDbService
      .getBookmarkStories({ ...filterParams, page_token: nextPageToken });
    const bookmarkedStories = data.stories
      .map((story, index) => ({ ...story, bookmarked: true, index }));
    const { next_page_token: newNextPageToken } = data;
    dispatch(fetchBookmarksNextPageResolved(bookmarkedStories, newNextPageToken));
  } catch (error) {
    dispatch(fetchBookmarksRejected(error.message));
  }
};
