import { createContext } from 'react';

export const FinpromptContext = createContext({
  isFinpromptPages: false,
});

const FinpromptProvider = ({ children }) => (
  <FinpromptContext.Provider value={{ isFinpromptPages: true }}>
    {children}
  </FinpromptContext.Provider>
);

export default FinpromptProvider;
