import axios from 'axios';
import API from './api';
import TopicsSvc from '../TopicsSvc';
import SectorsSvc from '../SectorsSvc';

// get events for trending filters
export const getEvents = (pageNum, eventNumId, perPage) => {
  const params = {
    page: pageNum,
    asset_class_ids: eventNumId,
    per_page: perPage, // num events per page, max 200
  };
  return TopicsSvc.getAssets(params);
};

// get sectors for trending filters
export const getSectors = () => SectorsSvc.getSectors();

export const saveFilters = (data) => new Promise((resolve, reject) => {
  API.patch('users/preference', { ...data })
    .then((res) => {
      resolve(res.data.preference);
    }).catch((err) => {
      if (!axios.isCancel(err)) throw err;
      reject();
    });
});
