import { forwardRef, memo, useMemo } from 'react';
import moment from 'moment/moment';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';

import { roundTo } from '../../../../helpers/helpers';
import { checkAndRoun } from '../../../../helpers/topicsHelpers';

import TransactionChartTable from '../TransactionChartTable';

// hooks
import useSizes from '../../../../hooks/sizes';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const TransactionChartTableDesktop = ({
  x,
  y,
  toggleExpandedView,
  sharesData,
  valuesData,
  transactionsChartData,
  chatbot,
}, ref) => {
  const { width } = useSizes();

  const style = useMemo(() => ({
    top: `${y}px`,
    ...(width > 1024 && {
      left: `${Math.min(x, width - 870 * (width >= 2800 ? 2 : 1))}px`,
    }),
  }), [x, y, width]);

  return (
    <div
      ref={ref}
      className={cx('transaction-chart-table', { chatbot })}
      style={style}
    >
      <div className={Styles.header}>
        <div className={Styles.date}>
          {moment(sharesData.date).format('MMM YYYY')}
        </div>
        <div className={Styles.details}>
          <div className={cx('info-wrapper')}>
            <div className={cx('info-wrapper-label')}>
              Avg Stock Price:
            </div>
            <div className={cx('info-wrapper-value')}>
              {sharesData.avgStockPrice !== null ? (
                `$${roundTo(sharesData.avgStockPrice)}`
              ) : '-'}
            </div>
          </div>
          <div className={cx('info-wrapper')}>
            <div className={cx('info-wrapper-label')}>
              Purchased:
            </div>
            <div className={cx('info-wrapper-value')}>
              {checkAndRoun(sharesData.buy, 2, true, false)}
              &nbsp;/ $
              {checkAndRoun(valuesData.buy, 2, true, false)}
            </div>
          </div>
          <div className={cx('info-wrapper')}>
            <div className={cx('info-wrapper-label')}>
              Sold:
            </div>
            <div className={cx('info-wrapper-value')}>
              {checkAndRoun(sharesData.sell, 2, true, false)}
              &nbsp;/ $
              {checkAndRoun(valuesData.sell, 2, true, false)}
            </div>
          </div>
          <div className={cx('info-wrapper')}>
            <div className={cx('info-wrapper-label')}>
              Others:
            </div>
            <div className={cx('info-wrapper-value')}>
              {checkAndRoun(sharesData.other, 2, true, false)}
              &nbsp;/ $
              {checkAndRoun(valuesData.other, 2, true, false)}
            </div>
          </div>
        </div>
        <FontAwesomeIcon icon={faXmark} className={cx('close')} onClick={toggleExpandedView} />
      </div>
      <div className={cx('body')}>
        <div className={cx('title')}>
          TOP TRANSACTIONS
        </div>
        <div>
          <TransactionChartTable
            transactionsChartData={transactionsChartData}
            filterDate={sharesData.date}
            chatbot={chatbot}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(forwardRef(TransactionChartTableDesktop));
