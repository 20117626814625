import { memo } from 'react';

import withComponentName from '../../../decorators/withComponentName';

import happyBot from '../../../assets/chatbot/happy-bot.png';

const Exit = (props) => {
  const { payload } = props;

  const messageDate = payload.date;

  return (
    <div
      data-date={messageDate}
      className="react-chatbot-kit__widget__wrapper chatbot-icon"
    >
      <img className="chatbot" src={happyBot} alt="chatbot-icon" />
    </div>
  );
};

export default memo(withComponentName(Exit));
