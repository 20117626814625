import { useEffect, useRef, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { togglePasswordModal } from '../../actions/account.actions';

import { getParamValueFromUrl } from '../../helpers/commonHelpers';
import loadable from '../../utils/loadable';

const PasswordChangeModal = loadable(() => import('../Modals/PasswordChangeModal'));

export const ResetPassword = (props) => {
  const { isFinpromptPages } = props;

  const passwordsOpened = useSelector(({ account }) => (
    account.passwordsOpened
  ));
  const dispatch = useDispatch();
  const { current: formProps } = useRef({
    action: '/users/password',
    acceptCharset: 'UTF-8',
    method: 'post',
  });
  const { current: formFields } = useRef({
    utf8: '✓',
    _method: 'put',
    'user[reset_password_token]': null,
  });

  useEffect(() => {
    if (getParamValueFromUrl('reset_pass') === 't') {
      const token = getParamValueFromUrl('token');

      if (token) {
        formFields['user[reset_password_token]'] = token;
        dispatch(togglePasswordModal());
      }
    }
  }, [dispatch]);

  return passwordsOpened && (
    <PasswordChangeModal
      {...props}
      useForm={!isFinpromptPages}
      formProps={formProps}
      formFields={formFields}
    />
  );
};

export default memo(ResetPassword, () => true);
