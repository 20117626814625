import isEqual from 'lodash-es/isEqual';
import { APIWithoutToken } from './api';
import { getAPICachingTime, returnAPICachingHeader } from '../../helpers/helpers';
import { cachingHigh, cachingMedium } from '../../data/webPageData';

const caches = {
  trendindTopics: [],
};

export const loadTrendingBarInfo = async (period = 'hour1', trendingClass = '') => {
  const params = {
    trending_period: period,
    asset_class_ids: trendingClass,
  };
  const cachedItems = caches.trendindTopics.find((cache) => isEqual(cache.params, params));
  if (cachedItems && cachedItems.expDate > Date.now()) {
    return cachedItems.response;
  }

  const cacheType = period === 'minutes15' || period === 'hour1' ? cachingMedium : cachingHigh;

  try {
    const res = await APIWithoutToken.get('trending_topics', {
      params,
      headers: {
        'Cache-control': returnAPICachingHeader(cacheType),
      },
    });

    caches.trendindTopics.unshift({
      params,
      expDate: getAPICachingTime(cacheType),
      response: res,
    });

    return res;
  } catch {
    return {
      data: {
        trending_topics: [],
      },
    };
  }
};

export default loadTrendingBarInfo;
