import { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames/bind';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';

import * as watchlistActions from '../../../actions/watchlistActions';

import withComponentName from '../../../decorators/withComponentName';
import withRouter from '../../../decorators/withRouter';

import Styles from './styles.module.scss';

class RemoveAllWatchlistsModal extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.removeModalRef = null;
  }

  removeWatchlist(id, token) {
    const { actions, piwikEnabled, handleCloseModal } = this.props;

    actions.deleteWatchlist(id, token);
    if (piwikEnabled) _paq.push(['trackEvent', 'WL page', 'Delete WL']);
    handleCloseModal();
  }

  removeAllWatchlists() {
    const { actions, handleCloseModal, token } = this.props;
    actions.deleteAllWatchlists(token);
    actions.updateWatchlistPageBegin();
    handleCloseModal();
  }

  removeHandler() {
    const { removeOnlyOneWatchlist, id, token } = this.props;

    if (removeOnlyOneWatchlist) {
      this.removeWatchlist(id, token);
    } else {
      this.removeAllWatchlists();
    }
  }

  render() {
    const cx = classNames.bind(Styles);
    const {
      openModal, handleCloseModal, removeOnlyOneWatchlist, watchlistName, assetNames,
      manageWatchlistsPage, history, hasBigScreenDesign,
    } = this.props;
    const manageWatchlists = manageWatchlistsPage || (history.location.pathname === '/watchlists/manage');

    const isAssetsNames = assetNames?.length;
    const removeAllText = manageWatchlists ? (
      <p>
        This action will delete
        {' '}
        <span className={cx('--weight-bold')}>all</span>
        {' '}
        your selected Watchlists.
        Do you want to proceed?
      </p>
    ) : (
      <>
        <p>This will delete all your created watchlists.</p>
        <p>Are you sure you want to continue?</p>
      </>
    );

    return (
      <Modal
        show={openModal}
        dialogClassName={cx('remove-all-watchlists-modal',
          { big_screen: hasBigScreenDesign },
          { 'manage-wl-page': manageWatchlists })}
        ref={(ref) => { this.removeModalRef = ref; }}
        onHide={handleCloseModal}
        className={cx('dialog-modal-wrapper')}
        centered
      >
        <div className={cx('remove-all-watchlists-close')} onClick={handleCloseModal}>
          <FontAwesomeIcon icon={faXmark} />
        </div>
        <div className={cx('remove-all-text',
          { '--remove-one': removeOnlyOneWatchlist },
          { '--text-align-center': manageWatchlists })}
        >
          <div>
            <FontAwesomeIcon icon={faTrashAlt} />
          </div>
          {
            removeOnlyOneWatchlist ? (
              <>
                <p>Are you sure you want to delete</p>
                <p>
                  <span
                    className={cx('remove-one__name-wl')}
                  >
                    {watchlistName}
                  </span>
                  &nbsp;
                  {manageWatchlists && isAssetsNames && (
                    <>
                      <span>{' ('}</span>
                      <span
                        className={cx('remove-one__name-assets')}
                      >
                        {assetNames?.join(', ')}
                      </span>
                      <span>{') '}</span>
                    </>
                  )}
                  ?
                </p>
              </>
            ) : (removeAllText)
          }
        </div>
        <div className={cx('remove-watchlists-modal-footer')}>
          <button
            type="button"
            onClick={(e) => handleCloseModal(e)}
            className={Styles.remove_cancel_button}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={this.removeHandler}
            className={Styles.remove_confirm_button}
          >
            Delete
          </button>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...watchlistActions }, dispatch),
});

const mapStateToProps = (state) => ({
  token: state.watchlistReducer.userToken,
});

export default withComponentName(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    withRouter(RemoveAllWatchlistsModal),
  ),
);
