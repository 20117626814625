import {
  TOGGLE_SEARCH_STATE,
  ADD_SEARCH_ITEM,
  REMOVE_SEARCH_ITEM,
  SET_ACTIVE_TAB,
  ADD_SINGLE_SEARCH_ITEM,
  BACKSPACE_REMOVE_SEARCH_ITEM,
  INIT_TABS,
  LOAD_TAB_INFO,
  RESET_TABS_DETAILS,
  TOGGLE_HEADER_SEARCHBAR_STATE,
  REMOVE_SEARCH_RESULTS,
  ADD_KEYWORD,
  LOAD_STORIES,
  START_LOAD_TAB_TOPICS,
  SUCCESS_LOAD_TAB_TOPICS,
  RESET_ALL,
  SET_QUERY,
  CHANGE_SEARCH_STATUS,
  CHANGE_INTERNAL_SEARCH_STATUS,
  ADD_NEW_WATCHLIST_ASSETS,
  REMOVE_WL_ASSET,
  START_ON_USER_QUERY_LOAD,
  END_ON_USER_QUERY_LOAD,
  TOGGLE_ONBOARDING_MODAL,
  FOCUS_SEARCHBAR,
  BLUR_SEARCHBAR,
  HIDE_STORY,
  TOGGLE_SEARCHBAR_STATE,
  SELECT_USER_SEARCH_QUERY,
  SET_RBI_INTERNAL,
  FALSE_SELECTED_SEARCHBAR_STATE,
  SET_SEARCH_SUMMARIES_REQUEST_METHOD,
  SET_SEARCHBAR_TYPE,
  RESTORE_SEARCHBAR_ITEMS,
  UPDATE_STORIES_INFO,
  UPDATE_STORIES_BOOKMARK,
  TOOGLE_MANAGE_WATCHLISTS_CREATE_SEARCHBAR,
  TOOGLE_MANAGE_WATCHLISTS_HEADER_SEARCHBAR,
  TOOGLE_PRELOADER_ENTITY,
  SET_FIRST_SEARCH_RESULT,
  SEARCH_RESULT_NEW_PRIVATE_COMPANIES_START,
  SEARCH_RESULT_NEW_PRIVATE_COMPANIES_END,
  SEARCH_RESULT_NEW_INVESTORS_START,
  SEARCH_RESULT_NEW_INVESTORS_END,
} from '../actions/searchActions';
import {
  ADVANCED_SEARCH,
  BASIC_SEARCH,
  searchTypeListMapping,
  searchTypeListWatchlistMapping,
} from '../data/searchbar/types';

const initialState = {
  activeSearchType: BASIC_SEARCH,
  advancedQuerySearchItems: [],
  searchItems: [],
  activeTab: 'Top Results',
  defaultActiveTab: 'Top Results',
  tabs: ['Top Results', 'Topic Classes'],
  tabsInfo: [],
  userQuery: '',
  selectedUserQueries: [],
  nextPageToken: null,
  stories: [],
  newWatchlistTopics: [],
  advancedNewWatchlistTopics: [],
  loadTabTopics: false,
  query: '',
  searchInProgress: false,
  internalSearchInProgress: false,
  userQuerySearch: false,
  openOnboardingWlModal: false,
  searchBarActive: false,
  headerSearchbarActive: false,
  focus: false,
  blur: false,
  internalStories: [],
  initialStoriesLoaded: false,
  searched: false,
  openedManageWatchlistsCreateSearchbar: false,
  openedManageWatchlistsHeaderSearchbar: false,
  openedSearchbarType: [
    {
      type: 'headerSearchbar',
      opened: false,
    },
    {
      type: 'homeSearchbar',
      opened: false,
    },
    {
      type: 'notificationsSearchbar',
      opened: false,
    },
    {
      type: 'createWatchlistsSearchbar',
      opened: false,
    },
    {
      type: 'editWatchlistsSearchbar',
      opened: false,
    },
    {
      type: 'searchPageSearchbar',
      opened: false,
    },
    {
      type: 'locationSearchbar',
      opened: false,
    },
    {
      type: 'manageWatchlistsSearchbar',
      opened: false,
    },
  ],
  summaryRequestMethod: null,
  preloaderEntity: false,
  firstSearchResult: null,
  newPrivateCompanySearch: true,
  newPrivateCompanySearchArr: [],
  newInvestorsSearch: true,
  newInvestorsSearchArr: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOOGLE_MANAGE_WATCHLISTS_HEADER_SEARCHBAR:
      return {
        ...state,
        openedManageWatchlistsHeaderSearchbar: action.payload,
      };
    case TOOGLE_MANAGE_WATCHLISTS_CREATE_SEARCHBAR:
      return {
        ...state,
        openedManageWatchlistsCreateSearchbar: action.payload,
      };
    case TOGGLE_ONBOARDING_MODAL:
      return {
        ...state,
        openOnboardingWlModal: !state.openOnboardingWlModal,
      };
    case TOGGLE_SEARCHBAR_STATE:
      return {
        ...state,
        searchBarActive: !state.searchBarActive,
      };
    case TOGGLE_HEADER_SEARCHBAR_STATE: {
      const activeSearchbar = state.openedSearchbarType.map((searchbar) => {
        if (searchbar.type === action.payload) {
          return {
            ...searchbar,
            opened: !searchbar.opened,
          };
        }
        return searchbar;
      });

      return {
        ...state,
        openedSearchbarType: activeSearchbar,
        searchBarActive: activeSearchbar.some((searchbarItem) => searchbarItem.opened),
      };
    }
    case FALSE_SELECTED_SEARCHBAR_STATE: {
      const activeSearchbar = state.openedSearchbarType.map((searchbar) => {
        if (searchbar.type === action.payload) {
          return {
            ...searchbar,
            opened: false,
          };
        }
        return searchbar;
      });

      return {
        ...state,
        openedSearchbarType: activeSearchbar,
        searchBarActive: activeSearchbar.some((searchbarItem) => searchbarItem.opened),
      };
    }
    case FOCUS_SEARCHBAR:
      return {
        ...state,
        focus: action.payload,
        searchBarActive: action.payload,
      };
    case BLUR_SEARCHBAR:
      return {
        ...state,
        blur: action.payload,
      };
    case START_ON_USER_QUERY_LOAD:
      return {
        ...state,
        userQuerySearch: true,
      };
    case END_ON_USER_QUERY_LOAD:
      return {
        ...state,
        userQuerySearch: false,
      };
    case ADD_NEW_WATCHLIST_ASSETS: {
      const key = searchTypeListWatchlistMapping[state.activeSearchType];

      let newWlItemIndex = [...state[key]].reverse().findIndex((item) => (
        item.id === action.payload.id && item.name === action.payload.name
      ));
      if (newWlItemIndex > -1) {
        newWlItemIndex = state[key].length - 1 - newWlItemIndex;
      }
      // if item not found or it found and not last item of advanced search add it in list
      // otherwise remove it
      if (
        newWlItemIndex === -1
        || (
          state.activeSearchType === ADVANCED_SEARCH
          && newWlItemIndex !== state[key].length - 1
        )
      ) {
        return {
          ...state,
          [key]: [...state[key], action.payload],
        };
      }

      return {
        ...state,
        [key]: state.activeSearchType === BASIC_SEARCH ? (
          state[key].filter((item) => (
            !(item.id === action.payload.id && item.name === action.payload.name)
          ))
        ) : (
          state[key].slice(0, state[key].length - 1)
        ),
      };
    }
    case START_LOAD_TAB_TOPICS:
      return {
        ...state,
        loadTabTopics: true,
      };
    case SUCCESS_LOAD_TAB_TOPICS:
      return {
        ...state,
        loadTabTopics: false,
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case ADD_SEARCH_ITEM: {
      const key = searchTypeListMapping[state.activeSearchType];

      let newItemIndex = [...state[key]].reverse().findIndex((item) => (
        item.id === action.payload.id && item.name === action.payload.name
      ));
      if (newItemIndex > -1) {
        newItemIndex = state[key].length - 1 - newItemIndex;
      }
      // if item not found or it found and not last item of advanced search add it in list
      // otherwise remove it
      if (
        newItemIndex === -1
        || (
          state.activeSearchType === ADVANCED_SEARCH
          && newItemIndex !== state[key].length - 1
        )
      ) {
        return {
          ...state,
          [key]: [...state[key], action.payload],
        };
      }

      return {
        ...state,
        [key]: state.activeSearchType === BASIC_SEARCH ? (
          state[key].filter((item) => (
            !(item.id === action.payload.id && item.name === action.payload.name)
          ))
        ) : (
          state[key].slice(0, state[key].length - 1)
        ),
      };
    }
    case ADD_SINGLE_SEARCH_ITEM: {
      const key = searchTypeListMapping[state.activeSearchType];
      const currentItem = state[key].find((item) => (
        item.id === action.payload.id && item.name === action.payload.name
      ));
      if (!currentItem) {
        return {
          ...state,
          [key]: state.activeSearchType === ADVANCED_SEARCH ? (
            [...state[key], action.payload]
          ) : [action.payload],
        };
      }

      const newItems = state[key].filter((item) => (
        !(item.id === action.payload.id && item.name === action.payload.name)
      ));

      return {
        ...state,
        [key]: [...newItems],
      };
    }
    case REMOVE_SEARCH_ITEM: {
      const key = searchTypeListMapping[state.activeSearchType];
      return {
        ...state,
        [key]: state[key].filter((item, index) => (
          item.id !== action.payload.id
          || index !== action.payload.index
        )),
      };
    }
    case REMOVE_WL_ASSET: {
      const key = searchTypeListWatchlistMapping[state.activeSearchType];

      return {
        ...state,
        [key]: state[key].filter((item, index) => (
          item.id !== action.payload.id
          || index !== action.payload.index
        )),
      };
    }
    case BACKSPACE_REMOVE_SEARCH_ITEM: {
      const key = searchTypeListMapping[state.activeSearchType];
      const wlKey = searchTypeListWatchlistMapping[state.activeSearchType];

      return {
        ...state,
        [key]: state[key].slice(0, state[key].length - 1),
        [wlKey]: state[wlKey].slice(0, state[wlKey].length - 1),
      };
    }
    case INIT_TABS:
      return {
        ...state,
        tabs: ['Top Results', 'Topic Classes', ...action.payload],
      };
    case LOAD_TAB_INFO:
      return {
        ...state,
        tabsInfo: [...action.payload],
      };
    case RESET_TABS_DETAILS:
      return {
        ...state,
        tabs: ['Top Results', 'Topic Classes'],
        tabsInfo: [],
      };
    case REMOVE_SEARCH_RESULTS:
      return {
        ...state,
        searchItems: [],
        advancedQuerySearchItems: [],
        newWatchlistTopics: [],
        advancedNewWatchlistTopics: [],
        firstSearchResult: null,
      };
    case ADD_KEYWORD:
      return {
        ...state,
        userKeywords: String(action.payload),
      };
    case SELECT_USER_SEARCH_QUERY:
      return {
        ...state,
        selectedUserQueries: [...state.selectedUserQueries, action.payload],
      };
    case LOAD_STORIES:
      return {
        ...state,
        stories: [...action.payload.stories],
        nextPageToken: action.payload.nextPageToken,
        initialStoriesLoaded: true,
      };
    case RESET_ALL:
      return {
        ...initialState,
      };
    case SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case CHANGE_SEARCH_STATUS:
      return {
        ...state,
        searchInProgress: action.payload,
      };
    case CHANGE_INTERNAL_SEARCH_STATUS:
      return {
        ...state,
        internalSearchInProgress: action.payload,
      };
    case HIDE_STORY:
      return {
        ...state,
        stories: state.stories.filter((story) => story.id !== action.payload),
      };
    case SET_RBI_INTERNAL:
      return {
        ...state,
        internalStories: action.payload.stories,
      };
    case TOGGLE_SEARCH_STATE:
      return {
        ...state,
        searched: action.payload !== null ? action.payload : !state.searched,
      };
    case SET_SEARCH_SUMMARIES_REQUEST_METHOD:
      return {
        ...state,
        summaryRequestMethod: action.payload || null,
      };
    case SET_SEARCHBAR_TYPE:
      return {
        ...state,
        activeSearchType: action.payload,
      };
    case RESTORE_SEARCHBAR_ITEMS: {
      const key = searchTypeListMapping[action.payload.activeSearchType];

      return {
        ...state,
        [key]: action.payload.items,
        activeSearchType: action.payload.activeSearchType,
        searchBarActive: action.payload.searchBarActive ?? Boolean(action.payload.items?.length),
      };
    }
    case UPDATE_STORIES_INFO:
      return {
        ...state,
        stories: action.payload,
      };
    case UPDATE_STORIES_BOOKMARK:
      return {
        ...state,
        stories: action.payload,
      };
    case TOOGLE_PRELOADER_ENTITY:
      return {
        ...state,
        preloaderEntity: action.payload,
      };
    case SET_FIRST_SEARCH_RESULT:
      return {
        ...state,
        firstSearchResult: action.payload,
      };
    case SEARCH_RESULT_NEW_PRIVATE_COMPANIES_START:
      return {
        ...state,
        newPrivateCompanySearch: true,
      };
    case SEARCH_RESULT_NEW_PRIVATE_COMPANIES_END:
      return {
        ...state,
        newPrivateCompanySearch: false,
        newPrivateCompanySearchArr: action.payload,
      };
    case SEARCH_RESULT_NEW_INVESTORS_START:
      return {
        ...state,
        newInvestorsSearch: true,
      };
    case SEARCH_RESULT_NEW_INVESTORS_END:
      return {
        ...state,
        newInvestorsSearch: false,
        newInvestorsSearchArr: action.payload,
      };
    default:
      return state;
  }
};
