import filter from 'lodash-es/filter';
import axios from 'axios';

import {
  removeWatchlist,
  removeAllWatchlist,
  loadNewWatchlist,
  loadAllWatchlists,
  loadWatchlistStories,
  removeAsset,
  removeQuery,
  editName,
  changeActiveWatchlist,
  addWatchlistSearchQueries,
  getSharedWatchlist,
  addSharedWatchlist,
  updateWatchlistQuery,
} from '../services/dbServices/watchlistDbServices';
import StoriesSvc from '../services/StoriesSvc';
import StorageSvc from '../services/StorageSvc';

import { checkAllAssetsStatus } from '../helpers/helpers';
import { selectAllWatchlistTopics } from '../helpers/watchlistHelperFunctions';
import {
  getGuestUserWatchlists,
  removeGuestUserWatchlist,
  setActiveGuestUserWatchlist,
  renameGuestUserWatchlist,
  addNewQuery,
  deleteGuestWlQuery,
  uncheckGuestWlQuery,
  unselectAllGuestAssets,
  createGuestWatchlistFromShared,
} from '../helpers/guestUsersWatchlists';
import { prepareStoryParams } from '../helpers/storyHelpers';
import { createNewPrivateCompaniesForWatchlist } from '../helpers/topicsApiHelpers';

import { resetAllFilters, loadFilters } from './storyFilters.action';
import { filtersStorageKeys, refreshValuesConversion } from '../data/filters/storyFilters';
import { SILVER, TWEETS } from '../data/permissions';
import { CUSTOM_TIME_FILTER } from '../data/features';
import { ADVANCED_SEARCH } from '../data/searchbar/types';

import { buildAdvancedSearchQuery } from '../helpers/searchbarHelpers';
import createHash from '../helpers/createHash';

export const SET_WL_LOAD_STATUS = 'SET_WL_LOAD_STATUS';
export const ACTIVATE_PRELOADER = 'ACTIVATE_PRELOADER';
export const ACTIVATE_WATCHLIST_LIST_PRELOADER = 'ACTIVATE_WATCHLIST_LIST_PRELOADER';
export const DEACTIVATE_WATCHLIST_LIST_PRELOADER = 'DEACTIVATE_WATCHLIST_LIST_PRELOADER';
export const HIDE_WATCHLIST_STORY = 'HIDE_WATCHLIST_STORY';
export const UPDATE_WATCHLIST_TOPICS = 'UPDATE_WATCHLIST_TOPICS';
export const UPDATE_WATCHLIST_TOPICS_BEGIN = 'UPDATE_WATCHLIST_TOPICS_BEGIN';
export const UPDATE_WATCHLIST_TOPICS_SUCCESS = 'UPDATE_WATCHLIST_TOPICS_SUCCESS';
export const CREATE_WATCHLIST = 'CREATE_WATCHLIST';
export const REMOVE_WATCHLIST = 'REMOVE_WATCHLIST';
export const REMOVE_ALL_WATCHLISTS = 'REMOVE_ALL_WATCHLISTS';
export const UPDATE_WATCHLIST_ASSETS = 'UPDATE_WATCHLIST_ASSETS';
export const LOAD_WATCHLIST_STORIES = 'LOAD_WATCHLIST_STORIES';
export const LOAD_WATCHLIST_INTERNAL_STORIES = 'LOAD_WATCHLIST_INTERNAL_STORIES';
export const LOAD_ACTIVE_WATCHLIST = 'LOAD_ACTIVE_WATCHLIST';
export const FETCH_WL_STORIES_BEGIN = 'FETCH_WL_STORIES_BEGIN';
export const FETCH_INTERNAL_STORIES_BEGIN = 'FETCH_INTERNAL_STORIES_BEGIN';
export const SELECTED_WATCHLIST_ASSETS = 'SELECTED_WATCHLIST_ASSETS';
export const TOGGLE_ACTIVE_WATCHLIST_ASSET = 'TOGGLE_ACTIVE_WATCHLIST_ASSET';
export const SET_ACTIVE_WATCHLIST_ID = 'SET_ACTIVE_WATCHLIST_ID';
export const SET_ACTIVE_WATCHLIST = 'SET_ACTIVE_WATCHLIST';
export const UPDATE_WATCHLIST_PAGE_BEGIN = 'UPDATE_WATCHLIST_PAGE_BEGIN';
export const SET_ALL_WATCHLISTS = 'SET_ALL_WATCHLISTS';
export const FETCH_WATCHLIST_SUCCESS = 'FETCH_WATCHLIST_SUCCESS';
export const NEW_WATCHLIST_DROPDOWN = 'NEW_WATCHLIST_DROPDOWN';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const REMOVE_WATCHLIST_TOPIC = 'REMOVE_WATCHLIST_TOPIC';
export const CHECK_WATCHLIST_TOPIC = 'CHECK_WATCHLIST_TOPIC';
export const NEW_WATCHLIST_CREATION_BEGIN = 'NEW_WATCHLIST_CREATION_BEGIN';
export const NEW_WATCHLIST_CREATION_SUCCESS = 'NEW_WATCHLIST_CREATION_SUCCESS';
export const SHOW_WATCHLIST_STUB = 'SHOW_WATCHLIST_STUB';
export const GUEST_WATCHLIST_REPLACEMENT_BEGIN = 'GUEST_WATCHLIST_REPLACEMENT_BEGIN';
export const GUEST_WATCHLIST_REPLACEMENT_SUCCESS = 'GUEST_WATCHLIST_REPLACEMENT_SUCCESS';
export const CLOSE_NEW_WATCHLIST_DROPDOWN = 'CLOSE_NEW_WATCHLIST_DROPDOWN';
export const ACTIVATE_SHARED_WATCHLIST_STUB = 'ACTIVATE_SHARED_WATCHLIST_STUB';
export const DISACTIVATE_SHARED_WATCHLIST_STUB = 'DISACTIVATE_SHARED_WATCHLIST_STUB';
export const ACTIVATE_FILINGS_PRELOADER = 'ACTIVATE_FILINGS_PRELOADER';
export const DISACTIVATE_FILINGS_PRELOADER = 'DISACTIVATE_FILINGS_PRELOADER';
export const SET_SUMMARIES_REQUEST_METHOD = 'SET_SUMMARIES_REQUEST_METHOD';
export const RESET_WATCHLIST_REDUX_DATA = 'RESET_WATCHLIST_REDUX_DATA';
export const UPDATE_WATCHLIST_STORIES_INFO = 'UPDATE_WATCHLIST_STORIES_INFO';
export const UPDATE_WATCHLIST_STORIES_BOOKMARK = 'UPDATE_WATCHLIST_STORIES_BOOKMARK';
export const SET_WATCHLIST_ASSETS_LOADER = 'SET_WATCHLIST_ASSETS_LOADER';
export const ACTIVATE_MULTIPLE_REQUESTS_PRELOADER = 'ACTIVATE_MULTIPLE_REQUESTS_PRELOADER';
export const DISACTIVATE_MULTIPLE_REQUESTS_PRELOADER = 'DISACTIVATE_MULTIPLE_REQUESTS_PRELOADER';

export const setWatchlistLoadStatus = (status) => ({
  type: SET_WL_LOAD_STATUS,
  payload: status,
});

export const activatePreloader = () => ({
  type: ACTIVATE_PRELOADER,
});

export const disactivateSharedWatchlistStub = () => ({
  type: DISACTIVATE_SHARED_WATCHLIST_STUB,
});

export const activateSharedWatchlistStub = () => ({
  type: ACTIVATE_SHARED_WATCHLIST_STUB,
});

export const hideWatchlistStory = (id) => ({
  type: HIDE_WATCHLIST_STORY,
  payload: id,
});

export const updateActiveWatchlistStoriesInfo = (data) => ({
  type: UPDATE_WATCHLIST_STORIES_INFO,
  payload: data,
});

export const updateWatchlistStoriesInfo = (data) => ((dispatch, getState) => {
  const { id, liked, disliked } = data;
  const {
    watchlistReducer: { activeWatchlistStories },
  } = getState();

  const updatedActiveWatchlistStories = activeWatchlistStories.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        liked,
        disliked,
      };
    }
    return item;
  });
  dispatch(updateActiveWatchlistStoriesInfo(updatedActiveWatchlistStories));
});

export const updateActiveWatchlistStories = (data) => ({
  type: UPDATE_WATCHLIST_STORIES_BOOKMARK,
  payload: data,
});

export const updateWatchlistStoriesBookmark = (data) => ((dispatch, getState) => {
  const { id, bookmarked } = data;
  const {
    watchlistReducer: { activeWatchlistStories },
  } = getState();

  const updatedActiveWatchlistStories = activeWatchlistStories.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        bookmarked,
      };
    }
    return item;
  });
  dispatch(updateActiveWatchlistStories(updatedActiveWatchlistStories));
});

export const guestUserReplacementSuccess = () => ({
  type: GUEST_WATCHLIST_REPLACEMENT_SUCCESS,
});

export const guestUserReplacementBegin = () => ({
  type: GUEST_WATCHLIST_REPLACEMENT_BEGIN,
});

export const checkWatchlistTopic = (topic) => ({
  type: CHECK_WATCHLIST_TOPIC,
  payload: topic,
});

export const updateWatchlistAssetItem = (data) => ({
  type: UPDATE_WATCHLIST_TOPICS,
  payload: data,
});

export const removeWatchlistTopic = (id) => ({
  type: REMOVE_WATCHLIST_TOPIC,
  payload: id,
});

export const removeAllWatchlists = () => ({
  type: REMOVE_ALL_WATCHLISTS,
});

export const setUserToken = (token) => ({
  type: SET_USER_TOKEN,
  payload: token,
});

export const openNewWatchlistDropDown = () => ({
  type: NEW_WATCHLIST_DROPDOWN,
});

export const closeWatchlistDropDown = () => ({
  type: CLOSE_NEW_WATCHLIST_DROPDOWN,
});

export const fetchStoriesSuccess = () => ({
  type: FETCH_WATCHLIST_SUCCESS,
});

export const getWatchlistsList = (watchlists) => ({
  type: SET_ALL_WATCHLISTS,
  payload: watchlists,
});

// used on watchlist page to imitate page update
export const updateWatchlistPageBegin = () => ({
  type: UPDATE_WATCHLIST_PAGE_BEGIN,
});

export const fetchStoriesBegin = () => ({
  type: FETCH_WL_STORIES_BEGIN,
});

export const fetchInternalStoriesBegin = () => ({
  type: FETCH_INTERNAL_STORIES_BEGIN,
});

export const setActiveWatchlist = (watchlist) => ({
  type: SET_ACTIVE_WATCHLIST,
  payload: watchlist,
});

export const setActiveWatchlistId = (id) => ({
  type: SET_ACTIVE_WATCHLIST_ID,
  payload: id,
});

export const selectedWatchlistAssets = (selectedAssets) => ({
  type: SELECTED_WATCHLIST_ASSETS,
  payload: selectedAssets,
});

export const toggleActiveWatchlistAsset = (watchlist) => ({
  type: TOGGLE_ACTIVE_WATCHLIST_ASSET,
  payload: watchlist,
});

export const loadActiveWatchlist = (assets) => ({
  type: LOAD_ACTIVE_WATCHLIST,
  payload: assets,
});

export const watchlistStories = (storiesData) => ({
  type: LOAD_WATCHLIST_STORIES,
  payload: storiesData,
});

export const watchlistInternalStories = (stories) => ({
  type: LOAD_WATCHLIST_INTERNAL_STORIES,
  payload: stories,
});

export const createWatchlist = (watchlistItems) => ({
  type: CREATE_WATCHLIST,
  payload: watchlistItems,
});

export const removeActiveWatchlist = (watchlistId) => ({
  type: REMOVE_WATCHLIST,
  payload: watchlistId,
});

export const updateWatchlistAssets = (newAssets) => ({
  type: UPDATE_WATCHLIST_ASSETS,
  payload: newAssets,
});

export const newWatchlistCreationBegin = () => ({
  type: NEW_WATCHLIST_CREATION_BEGIN,
});

export const newWatchlistCreationSuccess = () => ({
  type: NEW_WATCHLIST_CREATION_SUCCESS,
});

export const updateWatchlistTopicsBegin = () => ({
  type: UPDATE_WATCHLIST_TOPICS_BEGIN,
});

export const updateWatchlistTopicsSuccess = () => ({
  type: UPDATE_WATCHLIST_TOPICS_SUCCESS,
});

export const showWatchlistStubPage = () => ({
  type: SHOW_WATCHLIST_STUB,
});

export const activateFilingsPreloader = () => ({
  type: ACTIVATE_FILINGS_PRELOADER,
});

export const disactivateFilingsPreloader = () => ({
  type: DISACTIVATE_FILINGS_PRELOADER,
});

export const activateWatchlistListFetchPreloader = () => ({
  type: ACTIVATE_WATCHLIST_LIST_PRELOADER,
});

export const deactivateWatchlistListFetchPreloader = () => ({
  type: DEACTIVATE_WATCHLIST_LIST_PRELOADER,
});

export const setSummariesRequestMethod = (method) => ({
  type: SET_SUMMARIES_REQUEST_METHOD,
  payload: method,
});

export const resetWLReduxData = () => ({
  type: RESET_WATCHLIST_REDUX_DATA,
});

export const activateMultipleRequestsPreloader = () => ({
  type: ACTIVATE_MULTIPLE_REQUESTS_PRELOADER,
});

export const disactivateMultipleRequestsPreloader = () => ({
  type: DISACTIVATE_MULTIPLE_REQUESTS_PRELOADER,
});

// edit watchlist name
export const editWatchlistName = (watchlistId, newName, activeWatchlistId) => (dispatch) => {
  editName(watchlistId, newName).then(({ data }) => {
    if (watchlistId === activeWatchlistId) {
      dispatch(setActiveWatchlist(...filter(data.watchlists, ['current', true])));
    }
    loadAllWatchlists().then((watchlists) => {
      dispatch(getWatchlistsList(watchlists));
    });
  });
};

// check all assets action for active wl topic
export const checkAllUncheckedTopics = (assets, token) => (dispatch) => {
  if (token) {
    const data = assets.map((topic) => ({
      ...topic,
      hidden: false,
    }));
    dispatch(checkWatchlistTopic(data));
  } else {
    unselectAllGuestAssets(checkAllAssetsStatus(assets)).then((watchlists) => {
      const [current] = filter(watchlists, ['current', true]);
      const { search_queries: searchQueries } = current;

      dispatch(setActiveWatchlist(current));
      dispatch(updateWatchlistAssets(searchQueries));
      dispatch(getWatchlistsList(watchlists));
    });
  }
};

// uncheck/check all assets/queries
export const checkAll = (watchlistId, assets, token) => (dispatch) => {
  if (token) {
    const data = assets.map((topic) => ({
      ...topic,
      hidden: checkAllAssetsStatus(assets),
    }));
    dispatch(checkWatchlistTopic(data));
  } else {
    unselectAllGuestAssets(checkAllAssetsStatus(assets)).then((watchlists) => {
      const [current] = filter(watchlists, ['current', true]);
      const { search_queries: searchQueries } = current;

      dispatch(setActiveWatchlist(current));
      dispatch(updateWatchlistAssets(searchQueries));
      dispatch(getWatchlistsList(watchlists));
    });
  }
};

const checkOne = (
  query,
  id,
  itemHidden,
  watchlistId,
  token,
  activeWatchlistTopics,
) => (dispatch) => {
  const data = activeWatchlistTopics.map((topic) => ({
    ...topic,
    hidden: topic.id === id ? itemHidden : topic.hidden,
  }));
  dispatch(checkWatchlistTopic(data));
};

// selecting one asset among all in the wl
export const selectOneAsset = (
  topicId, assets, token, guestQueryId, activeWatchlistTopics,
) => (dispatch) => {
  if (token) {
    const data = activeWatchlistTopics.map((topic) => ({
      ...topic,
      hidden: topic.id !== topicId,
    }));
    dispatch(checkWatchlistTopic(data));
  } else {
    unselectAllGuestAssets(assets).then(() => {
      uncheckGuestWlQuery(guestQueryId, false).then((watchlists) => {
        const [current] = filter(watchlists, ['current', true]);
        const { search_queries: searchQueries } = current;

        dispatch(setActiveWatchlist(current));
        dispatch(updateWatchlistAssets(searchQueries));
        dispatch(getWatchlistsList(watchlists));
      });
    });
  }
};

// delete watchlist
export const deleteWatchlist = (watchlistId, token) => (dispatch) => {
  if (token) {
    removeWatchlist(watchlistId, token).then(({ data }) => {
      if (data.watchlists.length >= 1) {
        // select all topics in watchlist by default
        const current = selectAllWatchlistTopics(filter(data.watchlists, ['current', true]));

        const { search_queries: searchQueries, watchlist_assets: watchlistAssets } = current;

        dispatch(setActiveWatchlist(current));
        dispatch(getWatchlistsList(data.watchlists));
        dispatch(updateWatchlistAssets([...searchQueries, ...watchlistAssets]));
        dispatch(fetchStoriesSuccess());
      } else {
        dispatch(updateWatchlistPageBegin());
        dispatch(getWatchlistsList([]));
        dispatch(updateWatchlistAssets([]));
        dispatch(fetchStoriesSuccess());
        dispatch(showWatchlistStubPage());
      }
    });
  } else {
    removeGuestUserWatchlist(watchlistId).then((watchlists) => {
      if (watchlists.length >= 1) {
        const [current] = filter(watchlists, ['current', true]);
        const { search_queries: searchQueries } = current;

        dispatch(setActiveWatchlist(current));
        dispatch(updateWatchlistAssets(searchQueries));
        dispatch(getWatchlistsList(watchlists));
      } else {
        StorageSvc.removeItem('watchlist');
        dispatch(updateWatchlistAssets([]));
        dispatch(getWatchlistsList([]));
        dispatch(showWatchlistStubPage());
      }
    });
  }
};

export const deleteAllWatchlists = (token) => (dispatch) => {
  if (token) {
    removeAllWatchlist(token).then(() => {
      dispatch(removeAllWatchlists());
      dispatch(fetchStoriesSuccess());
    });
  } else {
    StorageSvc.removeItem('watchlist');
  }
  dispatch(getWatchlistsList([]));
  dispatch(showWatchlistStubPage());
};

export const deleteSelectedWatchlists = (selectedWatchlists, token) => async (dispatch) => {
  if (token) {
    await Promise.all(
      selectedWatchlists.map((item) => removeWatchlist(item.id, token)),
    ).then((res) => {
      const { watchlists } = res[res.length - 1].data;
      if (watchlists.length > 0) {
        const current = selectAllWatchlistTopics(filter(watchlists, ['current', true]));
        const { search_queries: searchQueries, watchlist_assets: watchlistAssets } = current;

        dispatch(setActiveWatchlist(current));
        dispatch(getWatchlistsList(watchlists));
        dispatch(updateWatchlistAssets([...searchQueries, ...watchlistAssets]));
        dispatch(fetchStoriesSuccess());
      } else {
        dispatch(updateWatchlistPageBegin());
        dispatch(getWatchlistsList([]));
        dispatch(updateWatchlistAssets([]));
        dispatch(fetchStoriesSuccess());
        dispatch(showWatchlistStubPage());
      }
    });
  } else {
    selectedWatchlists.forEach((item) => {
      removeGuestUserWatchlist(item.id).then((watchlists) => {
        if (watchlists.length >= 1) {
          const [current] = filter(watchlists, ['current', true]);
          const { search_queries: searchQueries } = current;

          dispatch(setActiveWatchlist(current));
          dispatch(updateWatchlistAssets(searchQueries));
          dispatch(getWatchlistsList(watchlists));
        } else {
          StorageSvc.removeItem('watchlist');
          dispatch(updateWatchlistAssets([]));
          dispatch(getWatchlistsList([]));
          dispatch(showWatchlistStubPage());
        }
      });
    });
  }
};

// uncheck asset
export const uncheckTopic = (
  query, id, itemHidden, token, activeWatchlistTopics, watchlistId,
) => (dispatch) => {
  const data = activeWatchlistTopics.map((topic) => {
    if (topic.id === id) updateWatchlistQuery(query, id, itemHidden, watchlistId);

    return {
      ...topic,
      hidden: topic.id === id ? itemHidden : topic.hidden,
    };
  });
  dispatch(checkWatchlistTopic(data));
};

// remove query item from watchlist
export const deleteQuery = (queryId, watchlistId, token) => (dispatch) => {
  removeQuery(queryId, watchlistId, token).then(() => {
    dispatch(removeWatchlistTopic(queryId));
  });
};

// remove asset item from watchlist
export const deleteAsset = (assetId, watchlistId, token) => (dispatch) => {
  removeAsset(assetId, watchlistId, token).then(() => {
    dispatch(removeWatchlistTopic(assetId));
  });
};

// get watchlists (use it on initial component load)
export const getWatchlists = ({ onlyCurrentWatchlist } = {}) => async (dispatch, getState) => {
  try {
    dispatch(activateFilingsPreloader());
    let watchlists = await loadAllWatchlists({
      onlyCurrentWatchlist,
    });

    // to speed up the process of getting watchlists
    // first of all, we're getting only current watchlist
    // after we're getting other watchlists, but withlout topics, only names
    // and after a few seconds, we're getting all watchlists and replacing previous ones
    if (onlyCurrentWatchlist) {
      dispatch(activateWatchlistListFetchPreloader());
      let areFullWatchlistsLoaded = false;
      setTimeout(() => {
        if (areFullWatchlistsLoaded) {
          return;
        }

        loadAllWatchlists({
          onlyWatchlistNames: true,
        }).then((watchlistWithoutNames) => {
          const currentWatchlist = watchlists[0];
          watchlists = watchlistWithoutNames.map((watchlist) => (
            watchlist.id === currentWatchlist?.id
              ? currentWatchlist
              : {
                ...watchlist,
                search_queries: [],
                watchlist_assets: [],
              }
          ));
          dispatch(getWatchlistsList(watchlists));
          dispatch(deactivateWatchlistListFetchPreloader());
        });
      }, 500);

      setTimeout(() => {
        loadAllWatchlists().then((watchlists) => {
          areFullWatchlistsLoaded = true;
          dispatch(getWatchlistsList(watchlists));
          dispatch(deactivateWatchlistListFetchPreloader());
        });
      }, 2000);
    }

    if (watchlists.length >= 1) {
      let activeWatchlist = watchlists.filter((item) => item.current);
      if (activeWatchlist?.length === 0) {
        const lastCreated = watchlists[0];
        lastCreated.current = true;

        activeWatchlist = lastCreated;
      }

      const current = selectAllWatchlistTopics(activeWatchlist);

      const { search_queries: searchQueries, watchlist_assets: watchlistAssets } = current || {};

      dispatch(setActiveWatchlist(current));
      if (!searchQueries?.length && !watchlistAssets?.length) {
        dispatch(watchlistStories({
          stories: [],
          nextPageToken: null,
        }));
      }
      if (searchQueries?.length || watchlistAssets?.length) {
        dispatch(updateWatchlistAssets([...searchQueries, ...watchlistAssets]));
      } else {
        dispatch(fetchStoriesSuccess());
      }
      dispatch(getWatchlistsList(watchlists));
      dispatch(disactivateFilingsPreloader());
      const { pathname } = window.location;
      if (
        pathname.startsWith('/watchlists')
        && !pathname.endsWith('/manage')
        && getState().watchlistReducer.wlPageLoadingStep === 'LOADING_WL'
      ) {
        dispatch(setWatchlistLoadStatus('LOADING_TOPICS_DATA'));
      }
    } else {
      dispatch(showWatchlistStubPage());
      dispatch(disactivateFilingsPreloader());
    }
  } catch (error) {
    console.log(error);
    dispatch(fetchStoriesSuccess());
    dispatch(disactivateFilingsPreloader());
  }
};

// check If User Has Watchlists, add new wl or dont add new wl,
// return array of watchlists
export const getUserWatchlists = () => async (dispatch) => {
  try {
    dispatch(activateFilingsPreloader());
    const watchlists = await loadAllWatchlists();

    if (Array.isArray(watchlists)) {
      dispatch(fetchStoriesSuccess());
      dispatch(disactivateFilingsPreloader());
      return watchlists;
    }
  } catch (error) {
    console.log(error);
    return [];
  } finally {
    dispatch(fetchStoriesSuccess());
    dispatch(disactivateFilingsPreloader());
  }
};

// create watchlist
export const createNewWatchlist = (basicSearchQueries, token, watchlistName, sendRequest = true) => (
  async (dispatch, getState) => {
    const {
      searchReducers: {
        activeSearchType,
        advancedNewWatchlistTopics: tempTopicsList,
        userKeywords,
      },
    } = getState();

    let queries = basicSearchQueries;
    if (activeSearchType === ADVANCED_SEARCH) {
      const advancedNewWatchlistTopics = await createNewPrivateCompaniesForWatchlist(
        tempTopicsList,
      );
      queries = buildAdvancedSearchQuery([
        ...advancedNewWatchlistTopics,
        ...(userKeywords ? [userKeywords] : []),
      ]);
    }
    const { data } = await loadNewWatchlist(token, queries, watchlistName);

    if (!data) return;
    if (sendRequest) dispatch(getWatchlists(token));
    return data.watchlist;
  }
);

// create watchlist from shared identifier
export const createSharedIndentifierWatchlist = (identifier) => async (dispatch, getStore) => {
  let data = {};
  let hasError = false;
  try {
    ({ data } = await addSharedWatchlist(identifier));
  } catch (e) {
    hasError = true;
    console.log(e);
  }
  const { storyFilters, subscriptions } = getStore();

  dispatch(getWatchlists());
  dispatch(newWatchlistCreationSuccess());

  if (hasError || !data || !data.snapshot) return;

  StorageSvc.setItem('filtersTransferred', 'false');
  const { permissions } = subscriptions;
  const { preference } = data.snapshot.preference;
  const filterParam = {
    order_by: preference.order_by || storyFilters.order_by,
    relevancy: preference.relevancy,
    languages: preference.lang || storyFilters.languages,
    all_languages: preference.all_languages,
    min_score: preference.min_score || storyFilters.min_score,
    with_links: preference.with_links,
  };

  if (preference.refresh_rate) {
    if (!permissions.access_levels.includes(SILVER)) {
      if (preference.refresh_rate === 'never' || preference.refresh_rate === 'minutes10') {
        filterParam.refresh_rate = refreshValuesConversion[preference.refresh_rate];
      } else {
        filterParam.refresh_rate = '600000';
      }
    } else {
      filterParam.refresh_rate = refreshValuesConversion[preference.refresh_rate];
    }
  }

  if (
    !permissions.allowed_search_periods.includes(preference.time_filter)
    || (!permissions.features.includes(CUSTOM_TIME_FILTER)
      && (preference.selected_time_filter === 'custom' || preference.selected_time_filter === 'time_period'))
  ) {
    filterParam.selected_time_filter = 'predefined';
    filterParam.time_filter = permissions.search_period;
  }

  if (!permissions.access_levels.includes(TWEETS) && preference.categories.join(',').match(/[cie]/)) {
    const categories = preference.categories.filter((cat) => !['c', 'i', 'e'].includes(cat)).join(',');
    filterParam.categories = categories;
  }

  if (filterParam.user_newsfeed_compactview_web) {
    filterParam.user_newsfeed_view_images_web = false;
    filterParam.user_newsfeed_view_description_web = false;
    filterParam.expand_stories = false;
  }

  dispatch(loadFilters(filterParam));

  return data.watchlist;
};

// add query to watchlist
export const addNewWatchlistQueries = (basicSearchQueries, watchlistId, token) => (
  async (dispatch, getState) => {
    const {
      searchReducers: {
        activeSearchType,
        advancedQuerySearchItems: tempTopicsList,
      },
    } = getState();

    let queries = basicSearchQueries;
    if (activeSearchType === ADVANCED_SEARCH) {
      const advancedQuerySearchItems = await createNewPrivateCompaniesForWatchlist(tempTopicsList);
      queries = buildAdvancedSearchQuery(advancedQuerySearchItems);
    }

    await addWatchlistSearchQueries(watchlistId, queries, token);
    const watchlists = await loadAllWatchlists(token);
    const current = watchlists.find((watchlist) => watchlist.current);
    const { search_queries: searchQueries, watchlist_assets: watchlistAssets } = current;

    dispatch(getWatchlistsList(watchlists));
    dispatch(updateWatchlistAssets([...searchQueries, ...watchlistAssets]));
    dispatch(loadActiveWatchlist(current));
    return watchlists;
  }
);

export const addNewWatchlistAssets = () => async (dispatch) => {
  const watchlists = await loadAllWatchlists();
  const [current] = filter(watchlists, ['current', true]);
  const { search_queries: searchQueries, watchlist_assets: watchlistAssets } = current;

  dispatch(updateWatchlistAssets([...searchQueries, ...watchlistAssets]));
};

export const toggleActiveWatchlist = (
  watchlistId,
  token,
  trendingRedirect,
  redirectPath = '/watchlists',
  isFinpromptPages,
) => (dispatch, getStore) => {
  if (token) {
    const { watchlistsList } = getStore().watchlistReducer;
    changeActiveWatchlist(watchlistId, token).then(({ data }) => {
      // redirect from trending page to watchlists if user choose watchlist in the modal
      if (trendingRedirect) {
        if (isFinpromptPages) {
          setTimeout(() => {
            window.open(redirectPath, '_blank')?.focus?.();
          }, 100);
        } else {
          window.location.href = redirectPath;
        }
        return;
      }

      const foundWatchlist = watchlistsList
        .filter((f) => f.id === data.preference.active_watchlist_id);
      // all topics must be selected by default when user changes watchlist
      const current = selectAllWatchlistTopics(foundWatchlist);

      const {
        search_queries: searchQueries,
        watchlist_assets: watchlistAssets,
      } = current;

      dispatch(setActiveWatchlist(current));
      dispatch(updateWatchlistAssets([...searchQueries, ...watchlistAssets]));

      // TODO: will uncomment when ticket WAPI-668 will be completed
      // getActiveWatchlist(data.preference.active_watchlist_id, token).then(({ data }) => {
      //   // all topics must be selected by default when user changes watchlist
      //   const current = selectAllWatchlistTopics(data.watchlists);

      //   const {
      //     search_queries: searchQueries,
      //     watchlist_assets: watchlistAssets,
      //   } = current;

      //   dispatch(setActiveWatchlist(current));
      //   dispatch(updateWatchlistAssets([...searchQueries, ...watchlistAssets]));
      // });
    })
      .catch((error) => {
        console.log(error);
      });
  } else {
    setActiveGuestUserWatchlist(watchlistId).then((watchlists) => {
      const [current] = filter(watchlists, ['current', true]);
      const { search_queries: searchQueries } = current;

      dispatch(setActiveWatchlist(current));
      dispatch(updateWatchlistAssets(searchQueries));
      if (trendingRedirect) {
        if (isFinpromptPages) {
          setTimeout(() => {
            window.open(redirectPath, '_blank')?.focus?.();
          }, 100);
        } else {
          window.location.href = redirectPath;
        }
      }
    });
  }
  dispatch(closeWatchlistDropDown());
};

// all actions required to work with guest users watchlists
export const loadGuestWatchlistStories = ({
  query,
  params,
  nextPage = false,
  cancelToken,
}) => (
  async (dispatch, getState) => {
    if (query) {
      try {
        const {
          watchlistReducer: { activeWatchlistStories },
          subscriptions: {
            permissions: { earliest_search_date: earliestSearchDate },
          },
        } = getState();
        const storyParams = { ...prepareStoryParams(params), query };

        const extra = { callCurationActions: true, skipSmallList: true };
        if (cancelToken) {
          extra.token = cancelToken;
        }
        const data = await StoriesSvc.getStories(storyParams, earliestSearchDate, extra);
        if (data.cancelled) {
          return { cancelled: true };
        }

        if (!nextPage) {
          dispatch(setSummariesRequestMethod(
            (limit = 2, options = {}) => (
              StoriesSvc.getStories({
                ...storyParams,
                only_summaries: true,
                number_of_summaries: limit,
                ...options,
              }, earliestSearchDate, { callCurationActions: true, skipSmallList: true })
            ),
          ));
        }

        const storiesData = {
          stories: (
            nextPage ? ([
              ...activeWatchlistStories,
              ...data.stories,
            ]) : data.stories
          ),
          nextPageToken: data.nextPageToken,
          error: data.error,
        };

        dispatch(watchlistStories(storiesData));
      } catch (e) {
        console.log(e);
        return {
          cancelled: axios.isCancel(e),
          error: true,
        };
      }
    } else {
      dispatch(watchlistStories({
        stories: [],
        nextPageToken: null,
      }));
    }
  }
);

export const guestUserWatchlists = () => (dispatch) => {
  dispatch(activateFilingsPreloader());
  const watchlists = getGuestUserWatchlists();

  if (watchlists.length >= 1) {
    const [current] = filter(watchlists, ['current', true]);
    const { search_queries: searchQueries } = current;
    dispatch(setActiveWatchlist(current));

    if (searchQueries.length) {
      dispatch(updateWatchlistAssets(searchQueries));
    } else {
      dispatch(fetchStoriesSuccess());
    }
  } else {
    dispatch(fetchStoriesSuccess());
    dispatch(showWatchlistStubPage());
  }
  dispatch(getWatchlistsList(watchlists));
  dispatch(disactivateFilingsPreloader());
};

export const getWatchlistStories = ({
  watchlistId,
  params,
  nextPage = false,
  query,
  cancelToken,
  isRefresh,
}) => (
  async (dispatch, getState) => {
    const {
      watchlistReducer: {
        activeWatchlistStories,
        activeWatchlistTopics,
      },
      subscriptions: {
        permissions: { earliest_search_date: earliestSearchDate },
      },
    } = getState();

    let storyParams;
    let storiesFetchingMethod = async () => ({
      stories: [],
      summaries: [],
      summariesKey: null,
      nextPageToken: 'EOD',
    });
    if (params.include_all_topics) {
      storyParams = {
        ...prepareStoryParams(params),
        // this parameter added as a cache breaker for WL stories
        c: await createHash(
          activeWatchlistTopics
            .map((topic) => (
              topic.id
            ))
            .filter(Boolean)
            .sort((a, b) => a - b)
            .join(','),
        ),
      };
      storiesFetchingMethod = (...args) => (
        loadWatchlistStories(watchlistId, ...args, { cancelToken, isRefresh })
      );
    } else if (query) {
      storyParams = prepareStoryParams({ ...params, query });
      const extra = { skipSmallList: true };
      if (cancelToken) {
        extra.token = cancelToken;
      }
      storiesFetchingMethod = (...args) => StoriesSvc
        .getStories(...args, earliestSearchDate, extra);
    }

    if (!nextPage) {
      dispatch(setSummariesRequestMethod(
        (limit = 2, options = {}) => (
          storiesFetchingMethod({
            ...storyParams,
            only_summaries: true,
            number_of_summaries: limit,
            ...options,
          })
        ),
      ));
    }

    const data = await storiesFetchingMethod(storyParams);

    if (data.cancelled) {
      return { cancelled: true };
    }

    const storiesData = {
      stories: (
        nextPage ? ([
          ...activeWatchlistStories,
          ...data.stories,
        ]) : data.stories
      ),
      nextPageToken: data.nextPageToken,
      error: data.error,
    };

    dispatch(watchlistStories(storiesData));
  }
);

export const updateGuestUserWatchlists = () => (dispatch) => {
  dispatch(getWatchlistsList(getGuestUserWatchlists()));
};

export const editGuestUserWatchlistName = (id, newName) => (dispatch) => {
  renameGuestUserWatchlist(id, newName).then((watchlists) => {
    const [current] = filter(watchlists, ['current', true]);

    dispatch(setActiveWatchlist(current));
    dispatch(getWatchlistsList(watchlists));
  });
};

export const addNewGuestQuery = (watchlistId, topics, userQuery) => (
  async (dispatch, getState) => {
    const {
      searchReducers: {
        activeSearchType,
      },
    } = getState();

    let query = userQuery;
    let activeTopics = topics;
    if (activeSearchType === ADVANCED_SEARCH) {
      query = buildAdvancedSearchQuery(topics);
      activeTopics = [];
    }

    const watchlists = await addNewQuery(watchlistId, activeTopics, query);
    const current = watchlists.find((watchlist) => watchlist.current);
    const { search_queries: searchQueries } = current;

    dispatch(setActiveWatchlist(current));
    dispatch(updateWatchlistAssets(searchQueries));
    dispatch(getWatchlistsList(watchlists));
    dispatch(updateWatchlistTopicsSuccess());
    return watchlists;
  }
);

export const removeGuestQuery = (id) => (dispatch) => {
  deleteGuestWlQuery(id).then((watchlists) => {
    const [current] = filter(watchlists, ['current', true]);
    const { search_queries: searchQueries } = current;

    dispatch(setActiveWatchlist(current));
    dispatch(updateWatchlistAssets(searchQueries));
    dispatch(getWatchlistsList(watchlists));
  });
};

export const uncheckGuestQuery = (id, isHidden) => (dispatch) => {
  uncheckGuestWlQuery(id, isHidden).then((watchlists) => {
    const [current] = filter(watchlists, ['current', true]);
    const { search_queries: searchQueries } = current;

    dispatch(setActiveWatchlist(current));
    dispatch(updateWatchlistAssets(searchQueries));
    dispatch(getWatchlistsList(watchlists));
  });
};

// add shared watchlist to guest user watchlists
export const addSharedWatchlistToGuest = (identifier) => async (dispatch, getStore) => {
  filtersStorageKeys.map((filterName) => StorageSvc.removeItem(filterName));
  const { storyFilters } = getStore();
  let data = {};
  let hasError = false;
  try {
    ({ data } = await getSharedWatchlist(identifier));
  } catch (e) {
    hasError = true;
    console.log(e);
  }

  if (hasError || !data || !data.snapshot) {
    dispatch(disactivateSharedWatchlistStub());
    dispatch(guestUserWatchlists());
    return;
  }

  const { preference } = data.snapshot.preference;

  const filterParam = {
    order_by: preference.order_by || storyFilters.order_by,
    relevancy: preference.relevancy,
    languages: preference.lang || storyFilters.languages,
    lang: preference.lang,
    all_languages: preference.all_languages,
    min_score: preference.min_score || storyFilters.min_score,
    with_links: preference.with_links,
  };

  if (
    preference.refresh_rate
    && (preference.refresh_rate === 'never' || preference.refresh_rate === 'minutes10')
  ) {
    filterParam.refresh_rate = refreshValuesConversion[preference.refresh_rate];
  }

  const allowedSearchPeriods = ['minutes5', 'minutes15', 'hour1', 'hours8', 'day1', 'week1', 'month1'];
  if (preference.time_filter) {
    if (allowedSearchPeriods.includes(preference.time_filter)) {
      filterParam.time_filter = preference.time_filter;
    } else {
      filterParam.time_filter = 'month1';
    }
  }

  if (preference.categories && preference.categories.length) {
    const categories = preference.categories.filter((cat) => !['c', 'i', 'e'].includes(cat)).join(',');
    filterParam.categories = categories;
  }

  dispatch(resetAllFilters(filterParam));

  await createGuestWatchlistFromShared(
    data.snapshot.watchlist.search_queries,
    data.snapshot.watchlist.name,
  );
  dispatch(disactivateSharedWatchlistStub());
  dispatch(guestUserWatchlists());
};
