import Styles from './styles.module.scss';
import ReactNavMenu from '../ReactNavMenu';
import withComponentName from '../../../decorators/withComponentName';

const HeaderLeftSide = ({ navMenuRef, ...props }) => (
  <div className={Styles.left_side}>
    <ReactNavMenu ref={navMenuRef} {...props} />
  </div>
);

export default withComponentName(HeaderLeftSide);
