import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import CFLink from '../../../Shared/CFLink';

import withComponentName from '../../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export function TransparentButton({
  className,
  disabled,
  children,
  noHover = false,
  type = 'button',
  ...otherProps
}) {
  const buttonClass = cx({
    'preferences-btn-transparent': true,
    'no-hover': noHover,
    disabled,
  });

  return type === 'button' ? (
    <button type="button" className={cx(buttonClass, className)} {...otherProps}>
      {children}
    </button>
  ) : (
    <CFLink
      role="button"
      className={cx(buttonClass, className)}
      {...otherProps}
    >
      {children}
    </CFLink>
  );
}

TransparentButton.propTypes = {
  className: PropTypes.string,
};

TransparentButton.defaultProps = {
  className: '',
};

export default withComponentName(TransparentButton);
