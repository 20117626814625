/* eslint-disable no-underscore-dangle */
import { forwardRef, useEffect } from 'react';
import useSizes from '../../hooks/sizes';

import withComponentName from '../../decorators/withComponentName';

const MATCH_URL_WISTIA = /(?:wistia\.(?:com|net)|wi\.st)\/(?:medias|embed)\/(?:iframe\/)?(.*)$/;

let wistiaScript;

export const WistiaPlayer = forwardRef((props, ref) => {
  const { width } = useSizes();

  const {
    url, controls, style, config, playVideo, withoutPopover,
  } = props;

  useEffect(() => {
    if (!url) {
      return;
    }

    if (!wistiaScript) {
      wistiaScript = document.createElement('script');
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }

    const videoId = url ? url.match(MATCH_URL_WISTIA)[1] : null;
    if (videoId && document.getElementById(videoId)) {
      return;
    }

    const urlScript = document.createElement('script');
    if (videoId) {
      urlScript.id = videoId;
    }

    let jsonpUrl = url;
    if (!jsonpUrl.endsWith('.jsonp')) {
      jsonpUrl += '.jsonp';
    }
    if (jsonpUrl.includes('/medias') && !jsonpUrl.includes('/embed/medias')) {
      jsonpUrl = jsonpUrl.replace('/medias', '/embed/medias');
    }

    urlScript.src = jsonpUrl;
    urlScript.async = true;

    wistiaScript.after(urlScript);

    return () => {
      urlScript.remove();
    };
  }, [url]);

  const videoID = url && url.match(MATCH_URL_WISTIA)[1];
  let className = `wistia_embed wistia_async_${videoID}`;

  if (!withoutPopover) {
    if (width > 1024) {
      className += ' popover=true popoverAnimateThumbnail=true popoverPreventScroll=true';
    }

    if (playVideo && width < 1024) {
      className += ' popover=true popoverAnimateThumbnail=true popoverPreventScroll=true';
    }
  }
  const options = {
    controlsVisibleOnLoad: controls,
    fullscreenButton: controls,
    playbar: controls,
    playbackRateControl: controls,
    qualityControl: controls,
    volumeControl: controls,
    settingsControl: controls,
    smallPlayButton: controls,
    ...config.options,
  };

  if (typeof window !== 'undefined') {
    window._wq = window._wq || [];
    window._wq.push({
      id: videoID,
      options,
    });
  }

  return (
    <div
      ref={ref}
      key={videoID}
      className={className}
      style={style}
    />
  );
});

WistiaPlayer.displayName = 'WistiaPlayer';
WistiaPlayer.defaultProps = {
  wrapperProps: {
    style: {
      width: '100%',
      height: '100%',
    },
  },
  playVideo: false,
};

export default withComponentName(WistiaPlayer);
