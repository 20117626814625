import {
  FETCH_RECOMMENDED_STORIES_BEGIN,
  FETCH_RECOMMENDED_STORIES_NEW,
  FETCH_RECOMMENDED_STORIES_SUCCESS,
  RESET_RECOMMENDED_STORIES_DATA,
} from '../actions/recommendedStories.actions';

const initialState = {
  loading: false,
  isNewLoading: false,
  recommendedStories: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_RECOMMENDED_STORIES_BEGIN:
      return {
        ...state,
        loading: true,
        isNewLoading: false,
        recommendedStories: [],
      };
    case FETCH_RECOMMENDED_STORIES_NEW:
      return {
        ...state,
        loading: false,
        isNewLoading: true,
        recommendedStories: payload.recommendedStories,
      };
    case FETCH_RECOMMENDED_STORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        isNewLoading: false,
        recommendedStories: payload.recommendedStories,
      };
    case RESET_RECOMMENDED_STORIES_DATA:
      return {
        ...state,
        loading: false,
        isNewLoading: false,
        recommendedStories: [],
      };
    default:
      return state;
  }
};
