import {
  SET_LOCATIONS_SENTIMENT_MAIN_TIME_PERIOD,
  SET_LOCATIONS_SENTIMENT_SELECTED_ACTIVE_POINT,
  SET_LOCATIONS_SENTIMENT_SELECTED_LOCATION,
} from '../actions/locationsSentiment.actions';
import StorageSvc from '../services/StorageSvc';

const initialState = {
  selectedMainTimePeriod: (JSON.parse(StorageSvc.getItem('locationsSentimentTimePeriod')) || {}).status || '1w',
  activeLocationPoint: null,
  selectedLocation: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOCATIONS_SENTIMENT_MAIN_TIME_PERIOD:
      return {
        ...state,
        selectedMainTimePeriod: payload,
      };
    case SET_LOCATIONS_SENTIMENT_SELECTED_ACTIVE_POINT:
      return {
        ...state,
        activeLocationPoint: payload,
      };
    case SET_LOCATIONS_SENTIMENT_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: payload,
      };
    default:
      return state;
  }
};
