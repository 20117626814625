export const CRYPTO_SET_EXPAND_CHART_DETAILS = 'CRYPTO_SET_EXPAND_CHART_DETAILS';
export const CRYPTO_RESET_EXPAND_CHART_DETAILS = 'CRYPTO_RESET_EXPAND_CHART_DETAILS';

export const setCryptoExpandChartDetails = (payload) => ({
  type: CRYPTO_SET_EXPAND_CHART_DETAILS,
  payload,
});

export const resetCryptoExpandChartDetails = () => ({
  type: CRYPTO_RESET_EXPAND_CHART_DETAILS,
});
