// merge fuzzy and regular search results categories into one
const dbTabsDataParser = ({ data }) => Object.values(data).reduce((acc, curr) => {
  Object.entries(curr).forEach(([k, v]) => {
    if (k in acc) acc[k] = acc[k].concat(v);
    else acc[k] = v;
  });
  return acc;
}, {});

export default dbTabsDataParser;
