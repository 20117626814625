import { useSelector } from 'react-redux';
import { memo, useEffect, useState } from 'react';

import Scrollable from '../../Shared/Scrollable';

const DefaultFallback = (props) => {
  const {
    actionProvider, scrollToBottomOnWidgetRendered, isChatbotOpened, payload,
  } = props;

  const messageDate = payload.date;

  const queries = [
    'List all of my watchlists',
    'Provide me with a summary of my watchlists',
    "Show me tesla's price chart for the last month",
    'Top Rated news for Tesla',
    'Latest news for Tesla',
    'Top Rated news for the Electric Vehicle sector',
    'Latest News for the Electric Vehicle sector',
  ];

  const isTablet = useSelector(({ common }) => (
    !(common.width > 1024)
  ));
  const loading = useSelector(({ chatbot }) => (
    chatbot.loading
  ));

  const [suggestions, setSuggestions] = useState([]);

  const handleClick = (suggestion) => {
    if (!loading) actionProvider.sendClientMessageOnRecommendedOptionClick({ text: suggestion });
  };

  useEffect(() => {
    setSuggestions([...queries]);
  }, []);

  useEffect(() => {
    if (!isChatbotOpened) return;
    if (scrollToBottomOnWidgetRendered) scrollToBottomOnWidgetRendered();
  }, [suggestions, isChatbotOpened]);

  return (
    <div
      data-date={messageDate}
      className="react-chatbot-kit__widget__wrapper queries-suggestions"
    >
      <Scrollable
        wrapperClassName="chatbot-widget__scrollable"
        arrowClass="top-news-stories-arrow"
        disabled={false}
        hideArrows={isTablet}
        isAllowFull={!isTablet}
        chatbotWidget
      >
        {suggestions?.map((suggestion) => (
          <div
            onClick={() => handleClick(suggestion)}
            key={suggestion}
            className="query__item"
          >
            {suggestion}
          </div>
        ))}
      </Scrollable>
    </div>
  );
};

export default memo(DefaultFallback);
