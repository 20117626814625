import { useSelector } from 'react-redux';
import { memo, useEffect, useState } from 'react';

import TopicItem from '../../MainTrending/TrendingTopics/TopicItem';
import Scrollable from '../../Shared/Scrollable';
import WatchlistModal from '../../Modals/WatchlistModal';

const TrendingTopics = (props) => {
  const {
    payload, scrollToBottomOnWidgetRendered, isChatbotOpened,
  } = props;

  const components = payload?.components || [];
  const messageDate = payload.date;

  const [topics, setTopics] = useState([]);

  const isTablet = useSelector(({ common }) => (
    !(common.width > 1024)
  ));
  const user = useSelector(({ userPreferencesReducer }) => (
    userPreferencesReducer.user
  ));

  const [expandedTopic, setExpandedTopic] = useState(0);
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const updateExpandedTopic = (topicID) => {
    setExpandedTopic(topicID);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const showWatchlistModal = (topic) => {
    setSelectedTopic(topic);
    setOpenModal((prevState) => !prevState);
  };

  useEffect(() => {
    components?.forEach((item) => {
      if (item.component_data?.trending_topics) {
        setTopics([...item.component_data?.trending_topics]);
      }
    });
  }, [components]);

  useEffect(() => {
    if (!isChatbotOpened) return;
    if (scrollToBottomOnWidgetRendered) scrollToBottomOnWidgetRendered();
  }, [topics, isChatbotOpened]);

  if (!topics?.length) return null;

  return (
    <div
      data-date={messageDate}
      className="react-chatbot-kit__widget__wrapper trending-topics"
    >
      <Scrollable
        wrapperClassName="chatbot-widget__scrollable"
        arrowClass="top-news-stories-arrow"
        disabled={false}
        hideArrows={isTablet}
        isAllowFull={!isTablet}
        chatbotWidget
        topics
        arrowAndContentAlignment
      >
        {topics.map((topic, index) => (
          <TopicItem
            key={topic.id}
            id={index + 1}
            data={topic}
            chatbot
            user={user}
            expandedTopic={expandedTopic}
            onExpand={updateExpandedTopic}
            onAddToWatchList={showWatchlistModal}
          />
        ))}
      </Scrollable>
      {openModal && (
        <WatchlistModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          topic={selectedTopic}
        />
      )}
    </div>
  );
};

export default memo(TrendingTopics);
