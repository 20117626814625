import { SET_ARTICLES } from '../actions/press.actions';

import { addIds } from '../helpers/helpers';

const initialState = {
  articles: null,
  loading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ARTICLES:
      return {
        articles: {
          latestNews: Array.isArray(payload?.latest_news) ? addIds(payload.latest_news) : [],
          awards: Array.isArray(payload?.awards) ? addIds(payload.awards) : [],
          videos: Array.isArray(payload?.videos) ? addIds(payload.videos) : [],
        },
        loading: false,
      };
    default:
      return state;
  }
};
