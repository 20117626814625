import SubscriptionsSvc from '../services/SubscriptionsSvc';
import { toggleConflictsFlag } from './storyFilters.action';

import { GUEST } from '../data/permissions';

export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_PERMISSIONS_ERROR = 'SET_PERMISSIONS_ERROR';
let subscribersCount = 0;
let permissionsUpdateTimer = null;

export const setPermissions = (permissions) => ({
  type: SET_PERMISSIONS,
  payload: permissions,
});

export const setError = (error) => ({
  type: SET_PERMISSIONS_ERROR,
  payload: error,
});

const clearPermissionsUpdateTimer = () => {
  if (!permissionsUpdateTimer) {
    return;
  }

  clearTimeout(permissionsUpdateTimer);
  permissionsUpdateTimer = null;
};

const getPermissions = () => (
  async (dispatch, getState) => {
    try {
      const { conflictsChecked } = getState().storyFilters;
      const permissions = await SubscriptionsSvc.getPermissions();
      dispatch(setPermissions(permissions));

      // for guest user no need to fetch permissions again and again
      if (permissions?.access_levels?.length === 1 && permissions.access_levels[0] === GUEST) {
        return;
      }

      // after updating permissions list stop all requests for making new checks
      if (conflictsChecked) dispatch(toggleConflictsFlag());

      // skip updating info if there are no subscribers
      if (subscribersCount > 0) {
        clearPermissionsUpdateTimer();
        const validUntil = new Date(permissions.valid_until);
        permissionsUpdateTimer = setTimeout(() => {
          dispatch(getPermissions(false));
        }, validUntil - Date.now());
      }
    } catch (e) {
      dispatch(setError(e.data));
      setTimeout(() => dispatch(getPermissions()), 30000);
    }
  }
);

export const subscribeToPermissions = () => (dispatch) => {
  subscribersCount += 1;

  // if first listener
  if (subscribersCount === 1) {
    dispatch(getPermissions());
  }
};

export const unsubscribeFromPermissions = () => {
  subscribersCount -= 1;
  if (subscribersCount === 0) clearPermissionsUpdateTimer();
};
