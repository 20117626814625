import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const YesNoButton = (props) => {
  const { scrollToBottomOnWidgetRendered, isChatbotOpened, payload } = props;

  const messageDate = payload.date;

  const loading = useSelector(({ chatbot }) => (
    chatbot.loading
  ));
  const [clicked, setClicked] = useState(payload?.clicked);

  const handleClick = (decision = '', data) => {
    const { actionProvider } = props;

    if (!loading) {
      let correctTypeOfDate = payload.date;
      if (correctTypeOfDate instanceof Date) {
        correctTypeOfDate = payload.date?.toISOString?.();
      }
      actionProvider.handleYesNoButton(decision, {
        ...data, date: correctTypeOfDate,
      });
      setClicked({ decision });
    }
  };

  const wasClicked = clicked?.decision;

  useEffect(() => {
    if (!isChatbotOpened) return;
    if (scrollToBottomOnWidgetRendered) scrollToBottomOnWidgetRendered();
  }, [isChatbotOpened]);

  return (
    <div
      data-date={messageDate}
      className={`react-chatbot-kit__widget__wrapper yes-no-button ${wasClicked ? 'disabled' : ''}`}
    >
      <span
        className={`${wasClicked === 'yes' ? 'clicked' : ''}`}
        onClick={() => handleClick('yes')}
      >
        Yes
      </span>
      <span
        className={`${wasClicked === 'no' ? 'clicked' : ''}`}
        onClick={() => handleClick('no')}
      >
        No
      </span>
    </div>
  );
};

export default memo(YesNoButton);
