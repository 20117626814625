import classNames from 'classnames/bind';

import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const SkeletonChatbotPage = ({ cookies, isFinpromptPages }) => (
  <div className={cx('skeleton__wrapper', { cookies }, { finprompt: isFinpromptPages })}>
    <h3 className={cx('skeleton__date')}>
      Today
    </h3>
    <div className={cx('skeleton__message', 'message')}>
      <div className={cx('message__avatar')} />
      <div className={cx('message__content', 'blink')}>
        <div className={cx('message__row', 'row1')} />
        <div className={cx('message__row', 'row2')} />
      </div>
    </div>
    <div className={cx('skeleton__options', 'blink')}>
      <div className={cx('skeleton__option', 'option')}>
        <div className={cx('option__img')} />
        <div className={cx('option__text')} />
      </div>
      <div className={cx('skeleton__option', 'option')}>
        <div className={cx('option__img')} />
        <div className={cx('option__text')} />
      </div>
      <div className={cx('skeleton__option', 'option')}>
        <div className={cx('option__img')} />
        <div className={cx('option__text')} />
      </div>
      <div className={cx('skeleton__option', 'option')}>
        <div className={cx('option__img')} />
        <div className={cx('option__text')} />
      </div>
    </div>
  </div>
);

export default withComponentName(SkeletonChatbotPage);
