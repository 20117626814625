import { Component, createRef } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';
import loadable from '@utils/loadable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';

import TopicsSvc from '../../../../../services/TopicsSvc';
import SectorsSvc from '../../../../../services/SectorsSvc';
import StoriesSvc from '../../../../../services/StoriesSvc';
import EntitiesSvc from '../../../../../services/dbServices/EntitiesSvc';

import * as authentication from '../../../../../actions/authentication.actions';
import * as preference from '../../../../../actions/preferencesActions';

import { getStoryParams } from '../../../../../helpers/storyHelpers';
import { removeDuplicates } from '../../../../../helpers/removeDuplicates';

import { sentimentTagsModalTabs } from '../../../../../data/directory/topics/topicsSentimentAnalysis';
import { CF_URL_FOR_FINPROMPT } from '../../../../../data/environment';
import { GOLD } from '../../../../../data/permissions';
import dummySummaries from '../../../../../data/summaries/dummySummaries';
import { SECTORS_PATH_ITEM } from '../../../../../data/directory/constants';

import Tooltip from '../../../../Tooltip';
import StoriesList from '../../../../SentimentAnalysis/SentimentStoriesModal/StoriesList';
import ModalSummaries from '../../../Charts/StoriesModal/StoriesModalDesktop/ModalSummaries';
import SentimentChart from './SentimentChart';
import WatchlistModal from '../../../../Modals/WatchlistModal';
import CFLink from '../../../../Shared/CFLink';

import withFinprompt from '../../../../../decorators/withFinprompt';

import Styles from './styles.module.scss';
import convertPermalinkToPath from '../../../../../helpers/urlHelpers';

const SentimentMiniChart = loadable(() => import('../../SentimentMiniChart'));

const cx = classNames.bind(Styles);

class SentimentModal extends Component {
  constructor() {
    super();
    this.state = {
      isTopicLoading: true,
      topic: null,
      topicURL: '',
      activeTab: sentimentTagsModalTabs[0].type,
      storiesData: {
        loading: true,
        isNewLoading: false,
        stories: [],
      },
      summaryData: {
        loading: true,
        summaries: [],
        notAllowed: false,
      },
      watchlistOpened: false,
      chartColor: '',
    };

    autoBind(this);
    this.mounted = true;
    this.summariesFetchingInstance = null;
    this.sentimentModalRef = createRef();
    this.sentimentStoryModalRef = createRef();
  }

  componentDidMount() {
    this.getTopicData();
    this.storiesDataCall();
    const { modalTabActive } = this.props;
    if (modalTabActive === 'trends') {
      this.setState({
        activeTab: 'trends',
      });
    }
    document.addEventListener('click', this.closeOnOutsideClick);
  }

  componentDidUpdate(prevProps) {
    const { topicId, modalTabActive, activePeriod } = this.props;
    const { activeTab } = this.state;
    if (topicId && topicId !== prevProps.topicId) {
      this.getTopicData();
      if (activeTab === 'stories') {
        this.storiesDataCall();
      } else if (activeTab === 'summary') {
        this.summaryDataCall();
      }
    }
    if (activePeriod !== prevProps.activePeriod) {
      if (activeTab === 'stories') {
        this.storiesDataCall();
      } else if (activeTab === 'summary') {
        this.summaryDataCall();
      }
    }
    if (
      modalTabActive && modalTabActive !== prevProps.modalTabActive && modalTabActive === 'trends'
      && activeTab !== 'trends'
    ) {
      this.setState({
        activeTab: 'trends',
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener('click', this.closeOnOutsideClick);
  }

  async getTopicData() {
    this.setState({
      isTopicLoading: true,
    });

    const {
      topicId, isFullLocations, isTopicClass, topicClassSlug, slugType, isInfoLabel,
      topic: fullTopic,
    } = this.props;

    if (isFullLocations) {
      this.setState({
        isTopicLoading: false,
        topic: null,
        topicURL: '/directory/sentiment/locations',
      });
      return;
    }
    if (isTopicClass) {
      this.setState({
        isTopicLoading: false,
        topic: null,
        topicURL: `/news/directory/${topicClassSlug}`,
      });
      return;
    }

    if (fullTopic?.permalink) {
      this.setState({
        isTopicLoading: false,
        topic: fullTopic,
        topicURL: convertPermalinkToPath(fullTopic.permalink),
      });
      return;
    }

    let topic;
    let topicURL = '';

    if (slugType === 'StartupCategory') {
      topic = await SectorsSvc.getCategory(topicId);
      topicURL = `/news/directory/${SECTORS_PATH_ITEM}/${topic.subindustry.industry.sector.slug}/${topic.subindustry.industry.slug}/${topic.subindustry.slug}/${topic.slug}`;
    } else if (isInfoLabel) {
      topicURL = await EntitiesSvc.getEntityPermalink({
        entityId: topicId,
        entityType: slugType,
      }).then(({ permalink }) => {
        if (permalink) {
          return convertPermalinkToPath(permalink);
        }
      });
    } else {
      topic = await TopicsSvc.getTopicById(topicId);

      const assetClassSlug = topic?.asset_class?.slug;
      const topicSlug = topic?.slug;
      topicURL = `/news/directory/${assetClassSlug}/${topicSlug}`;
    }

    this.setState({
      isTopicLoading: false,
      topic,
      topicURL,
    });
  }

  getTimeFilter() {
    const { activePeriod } = this.props;
    if (activePeriod === '1d') {
      return 'day1';
    }
    if (activePeriod === '1w') {
      return 'week1';
    }
    return 'month1';
  }

  getStoriesParam() {
    const {
      storyFilters, query,
    } = this.props;

    const params = getStoryParams({
      filters: {
        ...storyFilters,
        limit: 20,
        selected_time_filter: 'predefined',
        time_filter: this.getTimeFilter(),
        order_by: 'top',
        time_period: undefined,
        start_datetime: undefined,
        end_datetime: undefined,
        sentiment_gte: undefined,
        sentiment_lte: undefined,
        query,
        all_languages: !storyFilters.languages ? true : storyFilters.all_languages,
      },
    });

    return params;
  }

  setChartColor(chartColor) {
    this.setState({
      chartColor,
    });
  }

  async mainAPICall(params, oldStories = [], isLast, count = 1) {
    const { earliestSearchDate } = this.props;
    const { stories, nextPageToken } = await StoriesSvc.getStories(params, earliestSearchDate);
    const newData = removeDuplicates(
      [
        ...oldStories || [],
        ...stories,
      ],
      'id',
    ).slice(0, 5);

    const makeNewCall = newData.length < 5 && count < 5 && (nextPageToken !== 'EOD'
      || (nextPageToken === 'EOD' && !params.all_languages));
    const isNewLoading = !(newData.length >= 5) || (isLast ? makeNewCall : true);
    if (this.mounted) {
      this.setState({
        storiesData: {
          loading: false,
          isNewLoading,
          stories: newData,
        },
      });
    }

    if (this.mounted && makeNewCall) {
      return this.mainAPICall({
        ...params,
        ...(nextPageToken === 'EOD' && !params.all_languages ? {
          all_languages: true, languages: undefined,
        } : {}),
        ...(nextPageToken !== 'EOD' ? { page_token: nextPageToken } : {}),
      }, newData, isLast, (count || 1) + 1);
    }
    return newData;
  }

  async storiesDataCall() {
    const params = this.getStoriesParam();

    this.setState({
      storiesData: {
        loading: true,
        isNewLoading: false,
        stories: [],
      },
    });

    const stories = await this.mainAPICall(params);

    if (this.mounted) {
      this.setState({
        storiesData: {
          loading: false,
          isNewLoading: false,
          stories: stories.slice(0, 5),
        },
      });
    }
  }

  async summaryDataCall() {
    const { isGold, earliestSearchDate, maxSummarySize } = this.props;

    if (!isGold) {
      this.setState({
        summaryData: {
          loading: false,
          summaries: dummySummaries,
          notAllowed: false,
        },
      });
      return;
    }

    const params = this.getStoriesParam();

    this.setState({
      summaryData: {
        loading: true,
        summaries: [],
        notAllowed: false,
      },
    });

    const { notAllowed, summariesKey } = await StoriesSvc.getStories(
      {
        ...params,
        only_summaries: true,
        number_of_summaries: maxSummarySize,
        summarization_coverage: (params.query || '').split(':').slice(1, 2).join('#'),
        limit: 500,
        fold_similar_stories: false,
        languages: 'en',
        all_languages: false,
      },
      earliestSearchDate,
    );

    if (notAllowed) {
      this.setState({
        summaryData: {
          loading: false,
          summaries: [],
          notAllowed,
        },
      });
      return;
    }

    if (summariesKey) {
      this.summariesFetchingInstance = StoriesSvc.waitSummaries(summariesKey);
      const summaries = await this.summariesFetchingInstance.promise;
      this.setState({
        summaryData: {
          loading: false,
          summaries,
          notAllowed: false,
        },
      });
    } else {
      this.setState({
        summaryData: {
          loading: false,
          summaries: [],
          notAllowed: false,
        },
      });
    }
  }

  closeOnOutsideClick(e) {
    const {
      handleModalClose, sentimentContentRef, storyImageOpened,
    } = this.props;
    const { watchlistOpened } = this.state;

    // make sure the click was on the tag-item web-7335
    const isTagItemClicked = [
      'sentiment-tags-item',
      'sentiment-tags-item__active',
      'sentiment-tags-item__entity-name',
      'sentiment-tags-item__sentiment-value',
    ].some((className) => Array.from(e.target?.classList).some((name) => name.includes(className)));

    if (
      !this.sentimentModalRef?.current?.contains(e.target)
      && !this.sentimentStoryModalRef?.current?.contains(e.target)
      && ((sentimentContentRef?.current?.contains(e.target) && !isTagItemClicked)
      || !sentimentContentRef?.current?.contains(e.target))
      && !storyImageOpened && !watchlistOpened
    ) {
      handleModalClose();
    }
  }

  handleTabChange(value) {
    const { handleModalTab } = this.props;
    this.setState({
      activeTab: value,
    });
    if (handleModalTab) {
      handleModalTab(value);
    }
    if (value === 'stories') {
      this.storiesDataCall();
    } else if (value === 'summary') {
      this.summaryDataCall();
    }
  }

  handleCloseModal() {
    this.setState({
      watchlistOpened: false,
    });
  }

  addToWL() {
    const { userToken } = this.props;
    if (userToken) {
      return this.setState(({ watchlistOpened }) => ({
        watchlistOpened: !watchlistOpened,
      }));
    }
    this.openAuthModal();
  }

  addPreferences() {
    const {
      userToken, subscriptionTopics, actions, query,
      isFinpromptPages,
    } = this.props;
    if (userToken) {
      actions.addTopicNotification(null, query, null, subscriptionTopics);

      const path = isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}/preferences/notifications` : '/preferences/notifications';
      window.open(path, '_blank')?.focus?.();
      return;
    }
    this.openAuthModal();
  }

  openAuthModal() {
    const { actions } = this.props;
    actions.setAuthModalOpened(true);
  }

  storyModalRef(value) {
    if (!value) return;
    this.sentimentStoryModalRef.current = value;
  }

  getCorrectLinkTo(topicURL) {
    const { isFinpromptPages } = this.props;

    return (isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}${topicURL}` : topicURL);
  }

  render() {
    const {
      isTopicLoading, topic, activeTab, watchlistOpened, storiesData, summaryData,
      topicURL, chartColor,
    } = this.state;
    const {
      slugType, topicId, topicName, activePeriod, width,
      isTopic, isFullLocations, isMainBox, query,
      y, x, arrowLeft, handleModalClose, parentSentiment, height, overflowProperty,
      chatbot, arrowBottom, isFinpromptPages,
    } = this.props;
    const xOffsetDesktop = (x === 20 && width < 2800 && width > 1023) ? '50px' : `${x}px`;
    const xOffsetBigScreen = (x === 40 && width >= 2800) ? '100px' : `${x}px`;
    const arrowLeftOffsetDesktop = (x === 20 && width < 2800 && width > 1023)
      ? `${(parseInt(arrowLeft.substring(0, arrowLeft.length - 2), 10) - 30)}px` : arrowLeft;
    const arrowLeftOffsetBigScreen = (x === 40 && width >= 2800)
      ? `${(parseInt(arrowLeft.substring(0, arrowLeft.length - 2), 10) - 50)}px` : arrowLeft;

    const xOffset = width >= 2800 ? xOffsetBigScreen : xOffsetDesktop;
    const arrowLeftOffset = width >= 2800 ? arrowLeftOffsetBigScreen : arrowLeftOffsetDesktop;

    const cfMainWrapper = chatbot ? document.querySelector('.react-chatbot-kit-chat-message-container') : document.querySelector('.cf-main-wrapper');
    const styles = {
      top: `${y}px`,
      left: xOffset,
      height: `${height}px`,
      overflow: overflowProperty,
    };

    return (
      createPortal(
        <div style={styles} className={cx('sentiment-modal', { chatbot })} ref={this.sentimentModalRef}>
          <div
            className={cx('sentiment-modal-arrow', { 'arrow--bottom': arrowBottom })}
            style={{
              left: arrowLeftOffset,
            }}
          />
          <div className={Styles['sentiment-modal-close']} onClick={handleModalClose}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
          <div className={Styles['sentiment-modal-header']}>
            <div>
              <SentimentMiniChart
                isSingleLine
                isInsideModal
                isTopic={isTopic}
                isFullLocations={isFullLocations}
                isMainBox={isMainBox}
                topic={isFullLocations ? null : {
                  id: topicId,
                  name: topicName,
                }}
                slugType={slugType}
                inView
                activePeriod={activePeriod}
                onChartClick={() => this.handleTabChange('trends')}
                tooltipHidden={activeTab === 'trends'}
                parentSentiment={parentSentiment}
              />
            </div>
            <div className={Styles['sentiment-modal-header-add']}>
              {!isTopicLoading && topic && (
                <>
                  <Tooltip
                    hasBigScreenDesign
                    className={Styles.tooltip_add_wl}
                    icon={(
                      <div
                        className={cx('add-to-wl')}
                        onClick={this.addToWL}
                        onTouchStart={this.addToWL}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    )}
                  >
                    {width >= 1024 ? 'Add topic to your watchlist' : ''}
                  </Tooltip>
                  { width >= 1024 ? (
                    <Tooltip
                      hasBigScreenDesign
                      className={Styles.tooltip_add_notification}
                      icon={(
                        <div
                          className={cx('add-notifications')}
                          onClick={this.addPreferences}
                        >
                          <FontAwesomeIcon icon={faBell} />
                        </div>
                    )}
                    >
                      Add topic to your notifications
                    </Tooltip>
                  ) : (
                    <Tooltip
                      hasBigScreenDesign
                      className={Styles.tooltip_add_notification}
                      icon={(
                        <div
                          className={cx('add-notifications')}
                          onTouchStart={this.addPreferences}
                        >
                          <FontAwesomeIcon icon={faBell} />
                        </div>
                      )}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div className={Styles['sentiment-modal-tabs']}>
            {sentimentTagsModalTabs.map(({ id, title, type }) => (
              <div
                key={id}
                onClick={() => this.handleTabChange(type)}
                className={cx('sentiment-modal-tab', { active: type === activeTab })}
              >
                {title}
              </div>
            ))}
          </div>

          <div className={Styles['sentiment-modal-body']}>

            {activeTab === 'stories' && (
              <StoriesList
                hasBigScreenDesign
                isLoading={storiesData.loading}
                isNewLoading={storiesData.isNewLoading}
                storiesArr={storiesData.stories}
                topicURL={topicURL}
              />
            )}

            {activeTab === 'summary' && (
              <ModalSummaries
                summaryData={summaryData}
                isInsideModal
                hasBigScreenDesign
                className={Styles['sentiment-modal-summary']}
              />
            )}

            {activeTab === 'trends' && (
              <SentimentChart
                topic={{
                  id: topicId,
                  name: topicName,
                }}
                slugType={slugType}
                activePeriod={activePeriod}
                query={query}
                isFullLocations={isFullLocations}
                sentimentStoryModalRef={this.storyModalRef}
                topicURL={topicURL}
                setChartColor={this.setChartColor}
                chatbot={chatbot}
              />
            )}

          </div>
          <div className={Styles['sentiment-modal-see-more']}>
            {activeTab === 'trends' && (
              <div className={Styles.chart_legend}>
                <div className={Styles.chart_legend_list}>
                  <div
                    className={Styles.chart_legend_list_line}
                    style={{
                      borderColor: chartColor,
                    }}
                  />
                  <div className={Styles.chart_legend_list_text}>High Confidence</div>
                </div>
                <div className={Styles.chart_legend_list}>
                  <div
                    className={cx('chart_legend_list_line', 'dash_line')}
                    style={{
                      borderTopColor: chartColor,
                    }}
                  />
                  <div className={Styles.chart_legend_list_text}>Low Confidence</div>
                </div>
              </div>
            )}
            {topicURL && (
              <CFLink
                to={this.getCorrectLinkTo(topicURL)}
                className={Styles['sentiment-modal-see-more-btn']}
                external={isFinpromptPages}
              >
                See More
                <FontAwesomeIcon icon={faChevronRight} />
              </CFLink>
            )}
          </div>
          {watchlistOpened && (
            <WatchlistModal
              openModal={watchlistOpened}
              handleCloseModal={this.handleCloseModal}
              topic={topic}
              type="Topic"
            />
          )}
        </div>,
        cfMainWrapper,
      )
    );
  }
}

function mapToStateProps({
  watchlistReducer, common, storyFilters, subscriptions, stories, userPreferencesReducer,
}) {
  return {
    userToken: watchlistReducer.userToken,
    width: common.width,
    storyFilters,
    earliestSearchDate: subscriptions.permissions.earliest_search_date,
    isGold: subscriptions.permissions.access_levels.includes(GOLD),
    maxSummarySize: subscriptions.permissions.max_summary_size,
    storyImageOpened: stories.storyImageOpened,
    subscriptionTopics: userPreferencesReducer.subscriptionTopics,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...authentication,
    ...preference,
  }, dispatch),
});

export default connect(mapToStateProps, mapDispatchToProps)(withFinprompt(SentimentModal));
