import {
  SET_HEADER_HEIGHT,
  SET_SHOW_HP_HEADER_SEARCHBAR,
  SET_SHOW_HP_HEADER_BOX_SHADOW,
  SET_IS_HIDDEN_HEADER_SEARCHBAR,
} from '../actions/header.actions';

const initialState = {
  height: 79,
  showHPHeaderSearchbar: false,
  showHPHeaderBoxShadow: false,
  isHiddenHeaderSearchbar: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_HEADER_HEIGHT:
      return {
        ...state,
        height: payload,
      };
    case SET_SHOW_HP_HEADER_SEARCHBAR:
      return {
        ...state,
        showHPHeaderSearchbar: payload,
      };
    case SET_SHOW_HP_HEADER_BOX_SHADOW:
      return {
        ...state,
        showHPHeaderBoxShadow: payload,
      };
    case SET_IS_HIDDEN_HEADER_SEARCHBAR:
      return {
        ...state,
        isHiddenHeaderSearchbar: payload,
      };
    default:
      return state;
  }
};
