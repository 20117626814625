import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import executeCode from '../helpers/executeCode';
import processCodeAndReturnCodeBodyScript from '../helpers/processCodeAndReturnCodeBodyScript';
import loadChartjsLibrary from '../helpers/loadChartjsLibrary';

import Preloader from '../../Preloader';

import withComponentName from '../../../decorators/withComponentName';

const Chart = (props) => {
  const { scrollToBottomOnWidgetRendered, widgetType, payload } = props;

  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showInFullHeight, setShowInFullHeight] = useState(false);

  const chartRef = useRef();

  const errorHandler = () => {
    setIsError(true);
    setIsLoaded(false);
  };

  const renderChart = useCallback(() => {
    setIsLoaded(true);
    executeCode(processCodeAndReturnCodeBodyScript(payload), errorHandler);
  }, []);

  const scrollToBottomAfterRendered = () => {
    setTimeout(() => {
      if (scrollToBottomOnWidgetRendered) scrollToBottomOnWidgetRendered();
    }, 50);
  };

  const chartHeightCorrection = useCallback(() => {
    setShowInFullHeight(true);

    scrollToBottomAfterRendered();

    setTimeout(() => {
      setShowInFullHeight(false);
    }, 50);
  }, []);

  useEffect(() => {
    if (window.Chart) {
      renderChart();
      scrollToBottomAfterRendered();
    } else {
      loadChartjsLibrary().then(() => {
        renderChart();
        scrollToBottomAfterRendered();
      });
    }
  }, []);

  useEffect(() => {
    chartHeightCorrection();
  }, [widgetType]);

  const messageDate = payload.date;

  if (isError) return null;

  return (
    <div
      data-date={messageDate}
      className={`react-chatbot-kit__widget__wrapper generic-component ${showInFullHeight ? 'full-height' : ''}`}
    >
      {!isLoaded && (
        <Preloader
          loading
          static
          centered
        />
      )}
      <canvas
        ref={chartRef}
        id={messageDate}
      />
    </div>
  );
};

export default withComponentName(Chart);
