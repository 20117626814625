import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const MyCustomUserChatMessage = (props) => {
  const { message: { text, date: dateProps } } = props;

  const currentMessage = useSelector((state) => state.chatbot.messages.filter((item) => item.message.date === dateProps)[0]);
  const memoizedValues = useMemo(() => {
    const options = { day: 'numeric', month: 'long' };

    const messageTime = new Date(dateProps).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    const messageDate = new Date(dateProps).toLocaleDateString('en-GB', options);

    const currentDate = new Date().toLocaleDateString('en-GB', options);

    return {
      messageTime,
      messageDate,
      currentDate,
      showDate: currentMessage?.message?.showDate,
    };
  }, [text, dateProps]);

  const {
    messageTime, messageDate, currentDate, showDate,
  } = memoizedValues;

  return (
    <>
      <div data-date={dateProps} className={`react-chatbot-kit-user-message-date ${showDate ? '' : 'hide'}`}>{currentDate === messageDate ? 'Today' : messageDate}</div>
      <div className="react-chatbot-kit-user-chat-message-time">{messageTime}</div>
      <div className="react-chatbot-kit-user-chat-message">
        <span>{text}</span>
      </div>
    </>
  );
};

export default MyCustomUserChatMessage;
