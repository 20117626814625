import { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';

import SwitchButton from '../../MainTrending/TrendingFilters/TrendingPersonalisationFilter/SwitchButton';
import LoggedInUserDropdown from './LoggedInUserDropdown';
import UserLoginButtonsComponent from './UserLoginButtonsComponent';
import ComingSoon from '../../Shared/ComingSoon';

import { toggleCuration } from '../../../actions/preferencesActions';
import withComponentName from '../../../decorators/withComponentName';

import { SILVER, GOLD } from '../../../data/permissions';
import UserLoginComponentLinks from '../../../data/headerData/UserLoginComponentLinks';

import { handleMethod } from '../../../helpers/railsHelpers';
import sendSignOutRequestFinPromptHomePage from '../../../helpers/sendSignOutRequestFinPromptHomePage';

import StorageSvc from '../../../services/StorageSvc';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

class UserLoginComponent extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isOpened: false,
      isOpenedUserMobile: false,
      items: this.props.items || UserLoginComponentLinks || [],
    };
    this.userBlockRef = createRef();
  }

  componentDidMount() {
    const { toggleCuration, user } = this.props;

    if (user) document.addEventListener('click', this.trackClickOutside, { passive: true });
    if (StorageSvc.getItem('curationMode') === 'true') toggleCuration();
  }

  componentWillUnmount() {
    const { user } = this.props;

    if (user) document.removeEventListener('click', this.trackClickOutside);
  }

  trackClickOutside(e) {
    if (
      this.userBlockRef.current?.contains(e?.target)
    ) return;

    this.closeUserBlock();
  }

  openUserBlock() {
    this.setState({
      isOpened: true,
    });

    this.toggleMobileBlock(true);
  }

  closeUserBlock() {
    setTimeout(() => {
      this.setState({
        isOpened: false,
      });
      this.toggleMobileBlock(false);
    }, 100);
  }

  toggleUserBlock(e) {
    const { isOpened } = this.state;

    if (!isOpened) {
      this.openUserBlock(e);
    } else {
      this.closeUserBlock(e);
    }
  }

  cutUserName(user, textLength) {
    if (!user) return '';
    if (user.length > textLength) {
      return `${user.substring(0, textLength)}...`;
    }
    return user;
  }

  trackUserAccountAction(type) {
    const { piwikEnabled } = this.props;

    if (type === 'signOut') {
      StorageSvc.removeItem('state');
      StorageSvc.removeItem('selectedOrderTab');
      StorageSvc.removeItem('selectedSentimentTab');
    }

    if (piwikEnabled && type === 'manage') _paq.push(['trackEvent', 'Other', 'Manage account']);
    if (piwikEnabled && type === 'signOut') {
      StorageSvc.removeItem('state');
      _paq.push(['trackEvent', 'Other', 'Sign out']);
    }
  }

  toggleCurate(e) {
    const { toggleCuration, curationMode } = this.props;
    e.preventDefault();

    StorageSvc.setItem('curationMode', String(!curationMode));

    toggleCuration();
    e.stopPropagation();
    // Reload the page after curation is toggled
    // To fetch the right data
    window.location.reload();
  }

  toggleMobileBlock(state) {
    this.setState({
      isOpenedUserMobile: state,
    });
  }

  signOut(e) {
    const { isFinpromptPages } = this.props;

    e.preventDefault();

    if (isFinpromptPages) {
      sendSignOutRequestFinPromptHomePage();
      return;
    }

    this.trackUserAccountAction('signOut');
    handleMethod(e.target);
  }

  getUserName() {
    const { user, isFinpromptPages } = this.props;
    const {
      name,
      first_name: firstName,
      last_name: lastName,
    } = user;

    const userName = firstName ? `${firstName}${lastName ? ` ${lastName}` : ''}` : name;
    return this.cutUserName((userName), !isFinpromptPages ? 10 : undefined);
  }

  render() {
    const { isOpened, isOpenedUserMobile } = this.state;
    const {
      hiddenHeaderSearchbar,
      user,
      curationMode,
      width,
      headerType,
      accessLevels,
      isRailsContext,
      piwikEnabled,
      isFinpromptPages,
    } = this.props;

    // show login buttons if user doesn't logged in
    if (!user) {
      return (
        <UserLoginButtonsComponent
          isFinpromptPages={isFinpromptPages}
          piwikEnabled={piwikEnabled}
          hasBigScreenDesign={isFinpromptPages}
        />
      );
    }

    const {
      image_url: imageUrl,
      is_curator: isCurator,
      email,
    } = user;
    const { items } = this.state;

    const userBlockClass = cx('user_login_block', {
      opened: isOpened,
      watchlists: hiddenHeaderSearchbar,
      finprompt: isFinpromptPages,
    });

    return (
      <>
        <div
          className={`${userBlockClass} user-login-block`}
          onClick={this.toggleUserBlock}
          ref={this.userBlockRef}
        >
          {imageUrl ? <img src={imageUrl} alt="Avatar" /> : <DefaultUserAvatarSVG />}
          <span className={`${Styles.user_name} user-name`}>
            {this.getUserName()}
          </span>
          <FontAwesomeIcon icon={faChevronDown} className={cx('chevron-down')} />
          {isOpened && (
            <div className={cx('block_with_links')}>
              <p className={Styles['user-email']}>
                <span>
                  {email}
                </span>
              </p>
              {!isFinpromptPages && headerType === 'homePageHeader' && accessLevels.includes(SILVER) && !accessLevels.includes(GOLD) && (
                <div className={cx('upgrade_button', 'gold')}>
                  <LinkComponent
                    path="/pricing"
                    title="Upgrade to Gold"
                    isRailsContext={isRailsContext}
                  />
                </div>
              )}
              {!isFinpromptPages && headerType === 'homePageHeader' && !accessLevels.includes(SILVER) && (
                <div className={cx('upgrade_button')}>
                  <LinkComponent
                    path="/pricing"
                    title={(
                      <>
                        Start 7-days free trial
                        <span>No credit card required</span>
                      </>
                    )}
                    isRailsContext={isRailsContext}
                  />
                </div>
              )}
              {items.map((menuItem) => {
                const handleClick = () => {
                  if (menuItem.title === 'Account') this.trackUserAccountAction('manage');
                };

                return (
                  <div className={cx('block_with_links-link_wrapper',
                    { finprompt: isFinpromptPages, disabled: menuItem.comingSoon })}
                  >
                    <LinkComponent
                      isRailsContext={isRailsContext}
                      title={menuItem.title}
                      path={menuItem.route}
                      handleClick={handleClick}
                    />
                    {menuItem.comingSoon && <ComingSoon position="left" />}
                  </div>
                );
              })}
              {!isFinpromptPages && isCurator && (
                <span onClick={this.toggleCurate}>
                  Curation Tools
                  <SwitchButton
                    id="curation-switch"
                    className={cx('curation-switch', { 'curation-mode-true': curationMode })}
                    status={curationMode}
                  />
                </span>
              )}
              {isFinpromptPages && (width < 768) && (
                <Link to="/demo">
                  View Demo
                </Link>
              )}
              {isFinpromptPages ? (
                <p
                  onClick={this.signOut}
                  className={Styles['log-out']}
                >
                  Sign Out
                </p>
              ) : (
                <a
                  rel="nofollow"
                  onClick={this.signOut}
                  href="/users/sign_out"
                  data-method="delete"
                  className={Styles['log-out']}
                >
                  Sign Out
                </a>
              )}
            </div>
          )}
        </div>
        {user && width <= 450 && (
          <LoggedInUserDropdown
            isOpenedUserMobile={isOpenedUserMobile}
            user={user}
            trackUserAccountAction={this.trackUserAccountAction}
            headerType={headerType}
            accessLevels={accessLevels}
            isRailsContext={isRailsContext}
            isFinpromptPages={isFinpromptPages}
            getUserName={this.getUserName}
          />
        )}
      </>
    );
  }
}

const DefaultUserAvatarSVG = () => (
  <svg className={cx('default-avatar')} xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 36 35">
    <path d="M18 .063C8.367.063.562 7.867.562 17.5c0 9.633 7.805 17.437 17.438 17.437S35.437 27.133 35.437 17.5C35.437 7.867 27.633.063 18 .063zm9 29.643c-2.524 1.864-5.632 2.981-9 2.981-3.368 0-6.476-1.117-9-2.98v-.957c0-2.482 2.018-4.5 4.5-4.5.78 0 1.934.802 4.5.802 2.573 0 3.712-.802 4.5-.802 2.482 0 4.5 2.018 4.5 4.5v.956zm2.151-1.933C28.673 24.51 25.896 22 22.5 22c-1.441 0-2.138.802-4.5.802-2.363 0-3.052-.802-4.5-.802-3.396 0-6.173 2.51-6.652 5.773-2.496-2.707-4.036-6.307-4.036-10.273C2.812 9.126 9.626 2.313 18 2.313S33.187 9.126 33.187 17.5c0 3.966-1.54 7.566-4.036 10.273zM18 7.938a6.188 6.188 0 1 0 0 12.375 6.188 6.188 0 0 0 0-12.375zm0 10.125a3.94 3.94 0 0 1-3.938-3.938A3.94 3.94 0 0 1 18 10.188a3.94 3.94 0 0 1 3.937 3.937A3.94 3.94 0 0 1 18 18.063z" />
  </svg>
);

const LinkComponent = ({
  isRailsContext,
  title,
  path,
  handleClick = () => { },
  customClassName = '',
}) => {
  if (isRailsContext) {
    return (
      <a
        rel="nofollow"
        onClick={handleClick}
        href={path}
        className={customClassName}
      >
        {title}
      </a>
    );
  }
  return (
    <Link
      rel="nofollow"
      onClick={handleClick}
      to={path}
      className={customClassName}
    >
      {title}
    </Link>
  );
};

const mapToStateProps = ({ userPreferencesReducer, subscriptions }) => ({
  curationMode: userPreferencesReducer.curationMode,
  accessLevels: subscriptions.permissions.access_levels,
  user: userPreferencesReducer.user,
});

const mapToDispatchProps = (dispatch) => ({
  toggleCuration: () => dispatch(toggleCuration()),
});

export default withComponentName(
  connect(mapToStateProps, mapToDispatchProps, null, { forwardRef: true })(UserLoginComponent),
);
