import {
  loadTrendingBarInfo,
} from '../services/dbServices/trendingBarServices';
import StoriesSvc from '../services/StoriesSvc';
import TopicsSvc from '../services/TopicsSvc';
import { removeDuplicates } from '../helpers/removeDuplicates';

export const LOAD_TRENDING_ASSETS = 'LOAD_TRENDING_ASSETS';
export const LOAD_TRENDING_USER_PREFERENCES = 'LOAD_TRENDING_USER_PREFERENCES';
export const CHANGE_PERIOD = 'CHANGE_PERIOD';
export const LOAD_TRENDING_ASSET_CLASSES = 'LOAD_TRENDING_ASSET_CLASSES';
export const SELECT_TRENDING_ASSET_CLASSES = 'SELECT_TRENDING_ASSET_CLASSES';
export const SELECT_ALL_TRENDING_CLASSES = 'SELECT_ALL_TRENDING_CLASSES';
export const UNSELECT_ALL_TRENDING_CLASSES = 'UNSELECT_ALL_TRENDING_CLASSES';
export const TRENDING_STILL_ANIMATING = 'TRENDING_STILL_ANIMATING';
export const DEFAULT_SELECTED_ASSET_CLASSES = 'DEFAULT_SELECTED_ASSET_CLASSES';
export const TOGGLE_TRENDING_GRAPH = 'TOGGLE_TRENDING_GRAPH';
export const TOGGLE_TRENDING_FILTERS = 'TOGGLE_TRENDING_FILTERS';
export const CLOSE_TRENDING_DROPDOWNS = 'CLOSE_TRENDING_DROPDOWNS';
export const LOAD_TRENDING_BAR_STORIES = 'LOAD_TRENDING_BAR_STORIES';
export const LOAD_NEW_TRENDING_BAR_STORIES = 'LOAD_NEW_TRENDING_BAR_STORIES';
export const LOAD_RELOAD_TRENDING_BAR_STORIES = 'LOAD_RELOAD_TRENDING_BAR_STORIES';
export const LOADING_TRENDING_BAR_DROPDOWN_TOPICS = 'LOADING_TRENDING_BAR_DROPDOWN_TOPICS';
export const TOGGLE_TRENDING_BAR_TOPIC_POPOVER = 'TOGGLE_TRENDING_BAR_TOPIC_POPOVER';

export const loadingTrendingBarTopics = () => ({
  type: LOADING_TRENDING_BAR_DROPDOWN_TOPICS,
});

export const closeTrendingDropDowns = () => ({
  type: CLOSE_TRENDING_DROPDOWNS,
});

export const toggleTrendingFilters = () => ({
  type: TOGGLE_TRENDING_FILTERS,
});

export const toggleTrendingGraph = () => ({
  type: TOGGLE_TRENDING_GRAPH,
});

export const trendingBarClosing = () => ({
  type: TRENDING_STILL_ANIMATING,
});

export const selectAllTrendingClasses = () => ({
  type: SELECT_ALL_TRENDING_CLASSES,
});

export const unselectAllTrendingClasses = () => ({
  type: UNSELECT_ALL_TRENDING_CLASSES,
});

export const selectTrendingClasses = (trendingClass) => ({
  type: SELECT_TRENDING_ASSET_CLASSES,
  payload: trendingClass,
});

export const loadTrendingAssets = (trendingAssets) => ({
  type: LOAD_TRENDING_ASSETS,
  payload: trendingAssets,
});

export const loadTrendingAssetClasses = (trendingAssets) => ({
  type: LOAD_TRENDING_ASSET_CLASSES,
  payload: trendingAssets,
});

export const changeTrendingPeriod = (period) => ({
  type: CHANGE_PERIOD,
  payload: period,
});

export const loadTrendingUserPreferences = () => ({
  type: LOAD_TRENDING_USER_PREFERENCES,
});

export const loadDefaultAssets = (assets) => ({
  type: DEFAULT_SELECTED_ASSET_CLASSES,
  payload: assets,
});

// trending bar stories
export const loadTrendingBarStories = (stories) => ({
  type: LOAD_TRENDING_BAR_STORIES,
  payload: stories,
});

export const loadNewTrendingBarStories = () => ({
  type: LOAD_NEW_TRENDING_BAR_STORIES,
});

export const loadAndReloadTrendingBarStories = (stories) => ({
  type: LOAD_RELOAD_TRENDING_BAR_STORIES,
  payload: stories,
});

export const toggleActiveTrendingBarTopic = (status) => ({
  type: TOGGLE_TRENDING_BAR_TOPIC_POPOVER,
  payload: status,
});

export const toggleSelectAll = (allTrendingAssetClasses) => (dispatch) => {
  if (allTrendingAssetClasses) {
    dispatch(unselectAllTrendingClasses());
    loadTrendingBarInfo('hour1', '').then(({ data }) => {
      dispatch(loadTrendingAssets(data.trending_topics));
    });
  } else {
    dispatch(selectAllTrendingClasses());
    loadTrendingBarInfo('hour1', 'all').then(({ data }) => {
      dispatch(loadTrendingAssets(data.trending_topics));
    });
  }
};

export const updateTrendingBarTopics = (period, assetClasses) => (dispatch) => {
  loadTrendingBarInfo(period, assetClasses).then(({ data }) => {
    dispatch(changeTrendingPeriod(period));
    dispatch(loadTrendingAssets(data.trending_topics));
  });
};

export const onLoadAssets = (period = 'hour1', assetClasses = 'all') => (dispatch) => {
  loadTrendingBarInfo(period, assetClasses).then(({ data }) => {
    dispatch(loadTrendingAssets(data.trending_topics));
  });
};

export const loadTrendingAssetsFilters = () => (dispatch) => {
  TopicsSvc.getAssetsClasses().then(({ data }) => {
    dispatch(loadTrendingAssetClasses(data.asset_classes));
    const assetIds = data.asset_classes.map((assetClass) => assetClass);

    dispatch(loadDefaultAssets(assetIds));
  });
};

export const searchTrendingBarStories = (params, extraProps) => (dispatch, getState) => {
  const {
    storyFilters,
    subscriptions: {
      permissions: {
        earliest_search_date: earliestSearchDate,
      },
    },
    trendingBarReducer: {
      loadingNewTrendingBarTopics,
    },
  } = getState();

  const {
    nextPage, storiesCase = 1, currentCount = 1, oldStories = [],
  } = extraProps || {};
  if (nextPage) {
    dispatch(loadNewTrendingBarStories());
  }

  if (!loadingNewTrendingBarTopics) {
    StoriesSvc.getStories(params, earliestSearchDate).then(({
      stories: fetchedStories, nextPageToken,
    }) => {
      let stories;
      if (storyFilters.group_similar_stories && storyFilters.show_similar_stories_in_feed) {
        const storiesWithSimilars = fetchedStories.filter(
          (story) => story.similar_stories.length > 0,
        );
        const storiesWithOutSimilars = fetchedStories.filter(
          (story) => story.similar_stories.length === 0,
        );
        stories = storiesWithSimilars.slice(0, 5);
        if (stories.length < 5) {
          stories.push(
            ...storiesWithOutSimilars.slice(stories.length, 5),
          );
        }
      } else {
        stories = fetchedStories.slice(0, 5);
      }
      stories.sort((a, b) => b.score - a.score);
      stories = removeDuplicates(
        [
          ...oldStories || [],
          ...stories,
        ],
        'id',
      ).slice(0, 5);

      if (stories.length < 5 && nextPageToken !== 'EOD' && storiesCase === 1 && currentCount < 5) {
        dispatch(loadAndReloadTrendingBarStories(stories));
        const extraPassProps = {
          nextPage: true, storiesCase: 1, currentCount: currentCount + 1, oldStories: stories,
        };
        return searchTrendingBarStories({
          ...params,
          page_token: nextPageToken !== 'EOD' ? nextPageToken : '',
        }, extraPassProps)(dispatch, getState);
      }

      const isSecondCase = storiesCase === 1 ? nextPageToken === 'EOD' : storiesCase === 2 && nextPageToken !== 'EOD';
      if (
        stories.length < 5 && !params.all_languages && currentCount < 5 && isSecondCase
      ) {
        dispatch(loadAndReloadTrendingBarStories(stories));
        const extraPassProps = {
          nextPage: true,
          storiesCase: 2,
          currentCount: storiesCase === 1 ? 1 : currentCount + 1,
          oldStories: stories,
        };
        return searchTrendingBarStories({
          ...params,
          page_token: nextPageToken !== 'EOD' ? nextPageToken : '',
          all_languages: true,
          languages: undefined,
        }, extraPassProps)(dispatch, getState);
      }
      dispatch(loadTrendingBarStories(stories));
    });
  }
};
