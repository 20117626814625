import { APIWithoutToken } from './dbServices/api';

import {
  getAPICachingTime, returnAPICachingData,
  returnAPICachingHeader,
} from '../helpers/helpers';
import { copy, removeEmptyProperties } from '../helpers/commonHelpers';
import { SECTORS_PATH } from '../data/directory/constants';
import { cachingHigh, cachingVeryHigh } from '../data/webPageData';

const caches = {
  sectorsList: [],
  industriesList: [],
  subindustriesList: [],
  startupCategories: [],
};

export default class SectorsSvc {
  static async getSectors(params = {}) {
    removeEmptyProperties(params);

    const cacheData = returnAPICachingData(caches, 'sectorsList', params);
    if (cacheData) return cacheData;

    const result = await APIWithoutToken.get('sectors', {
      params,
      headers: {
        'Cache-control': returnAPICachingHeader(cachingVeryHigh),
      },
    });

    const sectors = result && result.data && result.data.sectors;

    if (!Array.isArray(sectors)) {
      throw new Error('Invalid input');
    }

    const dataArr = sectors.map((sector) => ({
      ...sector,
      icon: sector.image,
    }));

    caches.sectorsList.unshift({
      params,
      expDate: getAPICachingTime(cachingVeryHigh),
      response: dataArr,
    });

    return dataArr;
  }

  static async getSector(slug) {
    try {
      const key = `sector_${slug}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return copy(caches[key].response);
      }

      const path = typeof slug !== 'number' ? 'sectors/by_slug' : `sectors/${slug}`;
      const result = await APIWithoutToken.get(path, {
        params: {
          slug,
        },
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        },
      });

      const dataObj = result?.data?.sectors?.[0] || null;
      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: {
          ...dataObj,
          ...(dataObj && { url: `${SECTORS_PATH}/${dataObj.slug}` }),
        },
      };

      return copy(caches[key].response);
    } catch (e) {
      return null;
    }
  }

  static async getIndustries(params = {}) {
    removeEmptyProperties(params);
    if (params && typeof params !== 'object') {
      throw new TypeError('Params must be an object');
    }

    const perPage = params.per_page;

    if (params.id && typeof params.id === 'string') {
      const sector = await this.getSector(params.id);
      params.id = sector.id;
    }

    const cacheData = returnAPICachingData(caches, 'industriesList', params);
    if (cacheData) return cacheData;

    const response = await APIWithoutToken.get('industries', {
      params: {
        ...params,
        sector_id: params.id,
      },
      headers: {
        'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        // This is a stub header added for CORS headers
        // With this we could force browser request access for custom header
        // in preflight OPTIONS request
        total: '*',
      },
    });

    caches.industriesList.unshift({
      params,
      expDate: getAPICachingTime(cachingVeryHigh),
      response: {
        assets: response.data.industries,
        totalPages: Math.ceil(response.headers.total / perPage) || null,
      },
    });

    return {
      assets: response.data.industries,
      totalPages: Math.ceil(response.headers.total / perPage) || null,
    };
  }

  static async getIndustriesAlphabetHeaders({ id = '' }) {
    const key = `industriesHeaders_${id}`;

    if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
      return copy(caches[key].response);
    }

    const response = await APIWithoutToken.get('industries', {
      params: {
        sector_id: id,
        per_page: 1,
        pagination_alphabet_header: true,
      },
      headers: {
        'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        // This is a stub header added for CORS headers
        // With this we could force browser request access for custom header
        // in preflight OPTIONS request
        total: '*',
      },
    });

    caches[key] = {
      expDate: getAPICachingTime(cachingVeryHigh),
      response: {
        alphabetHeaders: (
          (response && response.data && response.data.pagination_alphabet_header) || []
        ),
        totalItems: parseInt(response.headers.total, 10),
      },
    };

    return copy(caches[key].response);
  }

  static async getIndustry(slug) {
    try {
      const key = `industry_${slug}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return copy(caches[key].response);
      }

      const path = typeof slug !== 'number' ? 'industries/by_slug' : `industries/${slug}`;
      const result = await APIWithoutToken.get(path, {
        params: {
          slug,
        },
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        },
      });

      const dataObj = (
        result && result.data && result.data.industries && result.data.industries[0]
      ) || null;

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: {
          ...dataObj,
          ...(dataObj && dataObj.sectore && { url: `${SECTORS_PATH}/${dataObj.sector.slug}/${dataObj.slug}` }),
        },
      };
      return copy(caches[key].response);
    } catch (e) {
      return null;
    }
  }

  static async getSubIndustries(params = {}) {
    removeEmptyProperties(params);
    if (params && typeof params !== 'object') {
      throw new TypeError('Params must be an object');
    }

    const perPage = params.per_page;

    if (params.id && typeof params.id === 'string') {
      const industry = await this.getIndustry(params.id);
      params.id = industry.id;
    }

    const cacheData = returnAPICachingData(caches, 'subindustriesList', params);
    if (cacheData) return cacheData;

    const response = await APIWithoutToken.get('subindustries', {
      params: {
        ...params,
        industry_id: params.id,
      },
      headers: {
        'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        // This is a stub header added for CORS headers
        // With this we could force browser request access for custom header
        // in preflight OPTIONS request
        total: '*',
      },
    });

    caches.subindustriesList.unshift({
      params,
      expDate: getAPICachingTime(cachingVeryHigh),
      response: {
        assets: response.data.subindustries,
        totalPages: Math.ceil(response.headers.total / perPage) || null,
      },
    });

    return {
      assets: response.data.subindustries,
      totalPages: Math.ceil(response.headers.total / perPage) || null,
    };
  }

  static async getSubIndustriesAlphabetHeaders({ id = '' }) {
    const key = `subindustriesHeaders_${id}`;

    if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
      return copy(caches[key].response);
    }

    const response = await APIWithoutToken.get('subindustries', {
      params: {
        industry_id: id,
        per_page: 1,
        pagination_alphabet_header: true,
      },
      headers: {
        'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        // This is a stub header added for CORS headers
        // With this we could force browser request access for custom header
        // in preflight OPTIONS request
        total: '*',
      },
    });

    caches[key] = {
      expDate: getAPICachingTime(cachingVeryHigh),
      response: {
        alphabetHeaders: (
          (response && response.data && response.data.pagination_alphabet_header) || []
        ),
        totalItems: parseInt(response.headers.total, 10),
      },
    };

    return copy(caches[key].response);
  }

  static async getSubIndustry(slug) {
    try {
      const key = `subindustry_${slug}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return copy(caches[key].response);
      }

      const path = typeof slug !== 'number' ? 'subindustries/by_slug' : `subindustries/${slug}`;
      const result = await APIWithoutToken.get(path, {
        params: {
          slug,
        },
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        },
      });

      const dataObj = (
        (result && result.data && result.data.subindustries && result.data.subindustries[0]) || null
      );
      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: {
          ...dataObj,
          ...(dataObj && dataObj.industry && dataObj.industry.sector && {
            url: `${SECTORS_PATH}/${dataObj.industry.sector.slug}/${dataObj.industry.slug}/${dataObj.slug}`,
          }),
        },
      };

      return copy(caches[key].response);
    } catch (e) {
      return null;
    }
  }

  static async getCategories(params = {}) {
    removeEmptyProperties(params);
    if (params && typeof params !== 'object') {
      throw new TypeError('Params must be an object');
    }

    const perPage = params.per_page;

    const newParams = params;
    if (params.id && typeof params.id === 'string') {
      const subIndustry = await this.getSubIndustry(params.id);
      newParams.id = subIndustry.id;
    }
    newParams.subindustry_id = newParams.id;

    const cacheData = returnAPICachingData(caches, 'startupCategories', params);
    if (cacheData) return cacheData;

    const response = await APIWithoutToken.get('startup_categories', {
      params: newParams,
      headers: {
        'Cache-control': returnAPICachingHeader(cachingHigh),
        // This is a stub header added for CORS headers
        // With this we could force browser request access for custom header
        // in preflight OPTIONS request
        total: '*',
      },
    });
    const res = {
      assets: response.data.startup_categories,
      totalPages: Math.ceil(response.headers.total / perPage) || null,
    };

    caches.startupCategories.unshift({
      params,
      expDate: getAPICachingTime(cachingHigh),
      response: res,
    });

    return res;
  }

  static async getCategoriesAlphabetHeaders({ id = '' }) {
    const key = `categoriesHeaders_${id}`;

    if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
      return copy(caches[key].response);
    }

    const response = await APIWithoutToken.get('startup_categories', {
      params: {
        subindustry_id: id,
        per_page: 1,
        pagination_alphabet_header: true,
      },
      headers: {
        'Cache-control': returnAPICachingHeader(cachingHigh),
        // This is a stub header added for CORS headers
        // With this we could force browser request access for custom header
        // in preflight OPTIONS request
        total: '*',
      },
    });

    caches[key] = {
      expDate: getAPICachingTime(cachingHigh),
      response: {
        alphabetHeaders: (
          (response && response.data && response.data.pagination_alphabet_header) || []
        ),
        totalItems: parseInt(response.headers.total, 10),
      },
    };
    return copy(caches[key].response);
  }

  static async getCategory(slug) {
    try {
      const key = `category_${slug}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return copy(caches[key].response);
      }

      const path = typeof slug !== 'number' ? 'startup_categories/by_slug' : `startup_categories/${slug}`;
      const { data } = await APIWithoutToken.get(path, {
        params: {
          slug,
        },
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        },
      });

      const dataObj = (
        (data && data.startup_categories && data.startup_categories[0]) || null
      );
      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: {
          ...dataObj,
          ...(dataObj?.subindustry?.industry?.sector && {
            url: `${SECTORS_PATH}/${dataObj.subindustry.industry.sector.slug}/${dataObj.subindustry.industry.slug}/${dataObj.subindustry.slug}/${dataObj.slug}`,
          }),
        },
      };
      return copy(caches[key].response);
    } catch (e) {
      return null;
    }
  }
}
