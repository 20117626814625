import moment from 'moment';

const validateStartEndDateTimePeriod = (startDate, endDate) => {
  let newStartDate;
  let newEndDate;
  const difference = moment(endDate).diff(moment(startDate), 'days');
  if (difference > 30) {
    newEndDate = moment().toISOString();
    newStartDate = moment().subtract(30, 'days').toISOString();
    return { newStartDate, newEndDate };
  }
  return { newStartDate: startDate, newEndDate: endDate };
};

export default validateStartEndDateTimePeriod;
