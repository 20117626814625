import {
  memo, useEffect, useState,
} from 'react';

// components
import MarketCapPriceItem from '../components/MarketCapPriceItem';

// decorators
import windowWidthDecorator from '../../../decorators/windowWidthDecorator';
import withRouter from '../../../decorators/withRouter';

// data
import { DESKTOP, MOBILE, TABLET } from '../../../data/screenSizes';

const NumberDataTile = (props) => {
  const {
    payload, scrollToBottomOnWidgetRendered, isChatbotOpened,
    windowWidth, history, expanded,
  } = props;

  const messageDate = payload.date;

  const [priceComponents, setPriceComponents] = useState([]);

  const [mode, setMode] = useState(DESKTOP);

  useEffect(() => {
    payload.components.forEach((component) => {
      if (component.component_name !== 'number_data_tile') return;

      setPriceComponents(
        (prevState) => ([
          ...prevState,
          { ...component },
        ]),
      );
    });
  }, []);

  useEffect(() => {
    let mode;

    switch (true) {
      case windowWidth > 1023:
        mode = DESKTOP;
        break;
      case windowWidth > 767:
        mode = TABLET;
        break;
      default:
        mode = MOBILE;
        break;
    }

    setMode(mode);
  }, [windowWidth]);

  useEffect(() => {
    if (!isChatbotOpened) return;

    setTimeout(() => {
      if (scrollToBottomOnWidgetRendered) scrollToBottomOnWidgetRendered();
    }, 100);
  }, [isChatbotOpened]);

  if (!priceComponents.length || !isChatbotOpened) return null;

  const priceProps = {
    chatbot: true,
    width: windowWidth,
    mode,
    expanded,
    isChatbotOpened,
    history,
  };

  return (
    <div
      data-date={messageDate}
      className="react-chatbot-kit__widget__wrapper number-data-tile"
    >
      {priceComponents.map((item, index) => (
        <MarketCapPriceItem
          key={`marketPrice-${index}`}
          {...priceProps}
          {...item}
          topic={item.component_data.assets_info?.assets[0]}
          wapiCall={item.wapi_call}
          minigraph
          scrollToBottomOnWidgetRendered={scrollToBottomOnWidgetRendered}
        />
      ))}
    </div>
  );
};

export default memo(windowWidthDecorator(withRouter(NumberDataTile)));
