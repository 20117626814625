import {
  Children,
  cloneElement,
  useState,
} from 'react';

const MessageParser = (props) => {
  const { children, actions } = props;

  const [userAvatar] = useState('https://i.pinimg.com/originals/cf/da/fa/cfdafa4dc6aab40eae1c5315c02b9339.jpg');

  const parse = (message) => {
    actions.handleMessage({ text: message });
  };

  return (
    <div>
      {Children.map(children, (child, index) => (
        cloneElement(child, {
          parse,
          actions,
          userAvatar,
          key: `${index}_message-parser`,
        })
      ))}
    </div>
  );
};

export default MessageParser;
