/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import {
  memo, useEffect, useRef, useState,
} from 'react';

import withComponentName from '../../../decorators/withComponentName';

import SentimentAnalysis from '../../SentimentAnalysis';

import Preloader from '../../Preloader';

const Sentiments = (props) => {
  const {
    scrollToBottomOnWidgetRendered, isChatbotOpened,
    widgetType,
  } = props;

  const { payload } = props;
  const { components, date: messageDate } = payload;

  const timeoutStarted = useRef(null);

  const [tiles, setTiles] = useState([]);

  const [chart, setChart] = useState([]);
  const [chartAsset, setChartAsset] = useState([]);

  const [wapiCallParams, setWapiCallParams] = useState([]);

  const [table, setTable] = useState([]);

  const [showPreloader, setShowPreloader] = useState(true);

  const extractEntityTypeFromWapiCall = (url) => {
    const params = {};
    const queryString = url.split('?')[1];
    const paramPairs = queryString.split('&');

    for (let i = 0; i < paramPairs.length; i++) {
      const pair = paramPairs[i].split('=');
      const paramName = decodeURIComponent(pair[0]);
      const paramValue = decodeURIComponent(pair[1]);

      params[paramName] = paramValue;
    }

    return params;
  };

  const getCorrectPeriodFromWapiCallParams = (period) => {
    const allPeriods = {
      hour: '1d',
      day: '1d',
      week: '1w',
      month: '1m',
      year: '1y',
      all: 'all',
    };

    return allPeriods[period];
  };

  const findProperty = (assets) => {
    const possibleProperties = ['areas', 'cities', 'countries', 'places', 'states', 'subcontinents', 'assets', 'continents'];

    let foundProperty = null;
    for (let i = 0; i < possibleProperties.length; i++) {
      const property = possibleProperties[i];
      if (assets.hasOwnProperty(property)) {
        foundProperty = property;
        break;
      }
    }

    if (foundProperty) {
      const value = assets[foundProperty];
      return value[0];
    }
    return null;
  };

  const handleChartsAppears = () => {
    setShowPreloader(false);

    setTimeout(() => {
      scrollToBottomOnWidgetRendered();
    }, 50);
  };

  useEffect(() => {
    components.forEach((item) => {
      if (item.component_name === 'sentiment_tile') {
        setTiles((prev) => [...prev, { ...item.component_data }]);
      }
      if (item.component_name === 'sentiment_chart') {
        setChart((prev) => [...prev, { ...item.component_data?.asset_chart }]);
        if (item.component_data?.assets_info?.assets) {
          setChartAsset((prev) => [...prev, { ...item.component_data?.assets_info?.assets[0] }]);
        } else if (findProperty(item.component_data?.assets_info)) {
          setChartAsset((prev) => [...prev, { ...findProperty(item.component_data?.assets_info) }]);
        }
        if (typeof item.wapi_call === 'string') {
          setWapiCallParams((prev) => [
            ...prev,
            extractEntityTypeFromWapiCall(item.wapi_call.asset_chart),
          ]);
        } else {
          setWapiCallParams((prev) => [
            ...prev, extractEntityTypeFromWapiCall(item.wapi_call.asset_chart)]);
        }
      }
      if (item.component_name === 'tables_generic') {
        setTable((prev) => [...prev, { ...item.component_data }]);
      }
    });
  }, [components]);

  useEffect(() => {
    if (!isChatbotOpened) return;
    if (scrollToBottomOnWidgetRendered) {
      if (timeoutStarted.current) return;

      const timeOut = setTimeout(() => {
        scrollToBottomOnWidgetRendered();
        timeoutStarted.current = true;
      }, 200);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, []);

  useEffect(() => {
    if (!isChatbotOpened) return;
    if (scrollToBottomOnWidgetRendered) scrollToBottomOnWidgetRendered();
  }, [chart, table, tiles, chartAsset, isChatbotOpened]);

  if (!isChatbotOpened) return null;

  if ((!chart?.length || !chartAsset) && !tiles && !table) return null;

  return (
    <>
      {chart.map((item, index) => {
        if (chartAsset && (item.values.some((value) => value !== null && value !== undefined))) {
          return (
            <div
              key={index}
              data-date={messageDate}
              className={`react-chatbot-kit__widget__wrapper sentiments ${showPreloader ? 'preloader_showed' : ''}`}
            >
              <SentimentAnalysis
                key={index}
                topic={chartAsset[index]}
                dataWebhook={item}
                isTopic
                chatbot
                chatbotWapiCallParams={{ ...wapiCallParams[index], ...chartAsset[index] }}
                chatbotChartPeriod={getCorrectPeriodFromWapiCallParams({ ...wapiCallParams[index] }.period)}
                chatbotWidgetType={widgetType}
                scrollToBottomOnWidgetRendered={handleChartsAppears}
              />
              {showPreloader && (
                <Preloader loading centered hasBigScreenDesign />
              )}
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default memo(withComponentName(Sentiments));
