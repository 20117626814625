// helper functions for searchbar

import { getTopicGroup, getAssetClassGroup } from './helpers';
import { transformTopicAssetData } from './topicsApiHelpers';

export const changeSearchbarDataFields = (result) => {
  const topicsData = Object.entries(result).map(([topicGroupName, result], index) => ({
    id: index + 1,
    title: topicGroupName,
    data: result
      .filter((topic) => Boolean(topic.attributes || topic?.groups?.length))
      .map((topic) => ({
        id: topic.topicId || topic.id,
        name: topic.topicName || topic.name || '',
        isActive: false,
        groups: topic.groups,
        topicTickers: [...new Set(topic.topicTickers)],
        attributes: topic.attributes || topic.groups[0].groupType,
        permalink: topic.permalink || '',
        tickers: topic.tickers || [],
        topicLegalEntities: topic.topicLegalEntities || [],
        companyDomains: topic.companyDomains || [],
        recommended: topic.recommended || false,
      })),
  }));
  return topicsData;
};

export const changeSearchbarTopicsDataFields = (result) => {
  const tabInfo = {
    id: Math.floor(Math.random() * 1000),
    title: 'Topic Classes',
  };

  const topicClasses = result.map(transformTopicAssetData);
  tabInfo.data = topicClasses;

  return tabInfo;
};

export const isChecked = (fullItem, currentWlTopics) => {
  if (currentWlTopics) {
    return currentWlTopics.some((item) => (
      item.id === fullItem.id && item.name === fullItem.name
    ));
  }
};

export const isQueryValid = (items) => {
  const searchItems = new Set(['ENTITY', 'AssetClass']);
  let valid = items.every((item, i) => {
    // Boolean operators are AND, OR, AND NOT operators (all expect parenthesis)
    const isBooleanOperator = item.itemType === 'OPERATOR' && !['(', ')'].includes(item.name);
    // if boolean operator is in the start or end then query is invalid
    if (isBooleanOperator && (i === 0 || i === items.length - 1)) {
      return false;
    }

    const [prev = null, next = null] = [items[i - 1], items[i + 1]];
    const isPrevOperator = prev?.itemType === 'OPERATOR';
    const isPrevBooleanOperator = isPrevOperator && !['(', ')'].includes(prev?.name);
    const isNextOperator = next?.itemType === 'OPERATOR';
    const isNextBooleanOperator = isNextOperator && !['(', ')'].includes(next?.name);

    // if 2 topics are near then query is invalid
    if (searchItems.has(item.itemType) && (searchItems.has(prev?.itemType)
        || searchItems.has(next?.itemType))) {
      return false;
    }

    if (item.itemType === 'OPERATOR' && item.name === '(') {
      // after opening parenthesis should be topic or another opening parenthesis
      if (!searchItems.has(next?.itemType) && isNextOperator && next.name !== '(') {
        return false;
      }

      // before opening parenthesis should be
      // another opening parenthesis or empty or boolean operator
      if ((isPrevOperator && prev.name !== '(') && (prev && !isPrevBooleanOperator)) {
        return false;
      }
    }

    if (item.itemType === 'OPERATOR' && item.name === ')') {
      // before closing parenthesis should be topic or another closing parenthesis
      if (!searchItems.has(prev?.itemType) && isPrevOperator && prev.name !== ')') {
        return false;
      }

      // after closing parenthesis should be
      // another closing parenthesis or empty or boolean operator
      if ((isNextOperator && next.name !== ')') && (next && !isNextBooleanOperator)) {
        return false;
      }
    }

    // 2 boolean operators could not be side by side
    if (isBooleanOperator && (isPrevBooleanOperator || isNextBooleanOperator)) {
      return false;
    }

    return true;
  });

  if (!valid) {
    return false;
  }

  const usedOperators = (
    items
      .filter(({ itemType }) => itemType === 'OPERATOR')
      .map(({ name }) => name)
  );

  let notClosedParenthesisCount = 0;
  let usedParenthesis = 0;
  valid = usedOperators.every((operator) => {
    if (operator === '(') {
      notClosedParenthesisCount += 1;
      usedParenthesis += 1;
    } else if (operator === ')') {
      notClosedParenthesisCount -= 1;
      usedParenthesis += 1;
    }

    return notClosedParenthesisCount >= 0;
  });

  if (!valid || notClosedParenthesisCount !== 0) {
    return false;
  }

  // check if we use usedParenthesis
  if (usedParenthesis > 1) {
    const indexOfOpenedParenthesis = items.map((e) => e.name).indexOf('(');
    const indexOfClosedParenthesis = items.map((e) => e.name).indexOf(')');

    // if operator '(' is not in the start
    if (indexOfOpenedParenthesis !== 0) {
      const prevElem = items[indexOfOpenedParenthesis - 1];
      if (prevElem.itemType !== 'OPERATOR') {
        // query is not valid if prevElem is not operator
        return false;
      }
    }

    // if operator '(' is not in the end
    if (indexOfClosedParenthesis !== items.length - 1) {
      // query is not valid if nextElem is not operator
      const nextElem = items[indexOfClosedParenthesis + 1];
      if (nextElem.itemType !== 'OPERATOR') {
        return false;
      }
    }
  }

  return true;
};

export const buildAdvancedSearchQuery = (items) => (
  items.reduce((query, item) => {
    let piece;

    if (item instanceof Object) {
      switch (item.itemType) {
        default:
        case 'ENTITY':
          piece = getTopicGroup(item.groups ? item : item.name);
          break;
        case 'AssetClass':
          piece = getAssetClassGroup(item);
          break;
        case 'OPERATOR':
          piece = ['(', ')'].includes(item.name) ? item.name : ` ${item.name} `;
          break;
      }
    } else {
      piece = getTopicGroup(item);
    }

    return query + piece;
  }, '')
);

export const previewAdvancedSearchQuery = (items) => (
  items.reduce((query, item) => {
    let piece;
    switch (item.itemType) {
      default:
      case 'ENTITY':
        piece = item.name;
        break;
      case 'AssetClass':
        piece = item.name;
        break;
      case 'OPERATOR':
        piece = ['(', ')'].includes(item.name) ? item.name : ` ${item.name} `;
        break;
    }

    return query + piece;
  }, '')
);
