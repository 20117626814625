import { memo } from 'react';

/*
  WEB-6578 This component checks a link. If it not has href attribute,
  than it use a <span> with click handler, else use a <a>.
  Please use this component instead a tag <a> with empty href, because
  empty href attribute it's not good practice for SEO.
*/
const LinkChecker = ({
  linkUrl,
  onClickHandler,
  className,
  children,
  rel,
  target,
  props,
}) => (
  (linkUrl ? (
    <a
      href={linkUrl}
      rel={rel}
      target={target}
      className={className}
      {...props}
    >
      {children}
    </a>
  ) : (
    <span onClick={onClickHandler} className={className}>{children}</span>
  ))
);

export default memo(LinkChecker);
