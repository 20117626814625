import { Component } from 'react';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt as faFileAltLight } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faFileAlt as faFileAltSolid } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faEyeSlash as faEyeSlashLight } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faEyeSlash as faEyeSlashSolid } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import { faShareAlt as faShareAltLight } from '@fortawesome/pro-light-svg-icons/faShareAlt';
import { faShareAlt as faShareAltSolid } from '@fortawesome/pro-solid-svg-icons/faShareAlt';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';

import * as authentication from '../../../../actions/authentication.actions';
import * as storyActions from '../../../../actions/stories.actions';

import ShareModal from './ShareModal';
import DuplicateCount from '../DuplicateCount';
import BlockStorySource from './BlockStorySource';
import StoriesFooterControls from './StoriesFooterControls';
import Tooltip from '../../../Tooltip';

import { getUserToken } from '../../../../helpers/getUserToken';
import loadable from '../../../../utils/loadable';

import { storyFooterActions } from '../../../../data/storyCardData/storyCardFooterData';

import withComponentName from '../../../../decorators/withComponentName';
import withFinprompt from '../../../../decorators/withFinprompt';

import API from '../../../../services/dbServices/api';
import StorageSvc from '../../../../services/StorageSvc';
import StoriesApiDbService from '../../../../services/dbServices/StoriesApiService';

import tagsIcon from '../../../../assets/trending/icons/tag.png';
import idIcon from '../../../../assets/trending/icons/no.png';
import keyIcon from '../../../../assets/trending/icons/key.png';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const CurateModal = loadable(() => import('../../../Modals/CurateModal'));
const MobileStoryControlsModal = loadable(() => import('../../../Modals/MobileStoryControlsModal'));

export class StoryCardFooter extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      ...props.story,
      showCurateModal: false,
      isBlockStoryHidden: true,
    };
  }

  // for like and dislike actions
  storyAction(type) {
    const { id, piwikEnabled, onLikeChanged } = this.props;

    if (!getUserToken()) return this.openSignModal();

    if (piwikEnabled && type === 'like') { _paq.push(['trackEvent', 'Story interaction', 'Like']); }
    if (piwikEnabled && type === 'dislike') { _paq.push(['trackEvent', 'Story interaction', 'Dislike']); }

    API.post(`stories/${id}/${type}`).then(() => {
      const { liked, disliked } = this.state;
      const updateState = {
        liked: false,
        disliked: false,
      };
      if (type === 'like') {
        updateState.liked = !liked;
      } else {
        updateState.disliked = !disliked;
      }
      this.setState(
        {
          ...updateState,
        },
        () => {
          const { liked, disliked } = this.state;
          const data = {
            id,
            liked,
            disliked,
          };
          if (onLikeChanged) onLikeChanged(data);
        },
      );
    });
  }

  bookmarkAction() {
    const {
      piwikEnabled, id, onBookmarkClick,
      bookmarksPage, toggleRemovedBookmark, story,
    } = this.props;
    const { bookmarked } = this.state;

    if (!getUserToken()) return this.openSignModal();

    if (piwikEnabled) { _paq.push(['trackEvent', 'Story interaction', 'Bookmark']); }

    if (bookmarked && bookmarksPage) {
      toggleRemovedBookmark(null, story);
      StoriesApiDbService.removeStoryBookmark(story.id);
    } else {
      const APIRequest = API[bookmarked ? 'delete' : 'post'](
        `stories/${id}/bookmark`,
      );

      APIRequest.then(() => {
        this.setState(
          {
            bookmarked: !bookmarked,
          },
          () => {
            const { bookmarked } = this.state;
            const data = {
              id,
              bookmarked,
            };
            onBookmarkClick(data);
          },
        );
      });
    }
  }

  updateRating(type) {
    const { piwikEnabled, id, onHide } = this.props;

    if (!getUserToken()) return this.openSignModal();

    if (piwikEnabled && type === 'articleIrrelevant') { _paq.push(['trackEvent', 'Story interaction', 'Mark as irrelevant']); }
    if (piwikEnabled && type === 'hideArticle') { _paq.push(['trackEvent', 'Story interaction', 'Hide the story']); }

    let activeAssetIds;

    try {
      activeAssetIds = JSON.parse(StorageSvc.getItem('state')).storyFilters.asset_classes
        || '';
    } catch (e) {
      activeAssetIds = '';
    }

    API.patch(`stories/${id}/update_rating`, {
      rating_type: type,
      active_asset_ids: activeAssetIds,
      id,
    }).then(() => {
      onHide(id);
    });
  }

  openShareModal(story) {
    const { piwikEnabled } = this.props;

    if (!getUserToken()) return this.openSignModal();

    if (piwikEnabled) { _paq.push(['trackEvent', 'Story interaction', 'Share the article']); }

    this.ShareModal.handleShow(story);
  }

  openSignModal() {
    const { actions } = this.props;
    actions.setAuthModalOpened(true);
  }

  closeCurateModal() {
    this.setState({
      showCurateModal: false,
    });
  }

  showCurateModal(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      showCurateModal: true,
    });
  }

  render() {
    const {
      tags,
      type,
      story,
      isDesktop,
      admin,
      id,
      curatorData,
      width,
      sourcesEnabled,
      storyInfoAction,
      hasBigScreenDesign,
      isCompactView,
      token,
      isFinpromptPages,
    } = this.props;
    const { showCurateModal, isBlockStoryHidden } = this.state;

    const cardToolsClass = cx({
      'card__tools--items': true,
      'card__tools--items-tweet': type === 'Tweet',
      'no-duplicates-block': !tags.length && !story.duplicatesCount,
      compact: isCompactView,
    });

    const cardFooterClass = cx({
      card__footer: true,
      'no-duplicates-footer': !tags.length && !story.duplicatesCount,
      big_screen: hasBigScreenDesign,
      compact: isCompactView,
    });

    const tooltipLinkSignInModal = cx({
      'tooltip__link--signin-modal': true,
    });
    const customTooltipText = (
      <>
        Please&nbsp;
        <span
          className={tooltipLinkSignInModal}
          onClick={this.openSignModal}
        >
          log in
        </span>
        &nbsp;to access this feature.
      </>
    );

    return (
      <div className={cardFooterClass} onClick={(e) => e.stopPropagation()}>
        <div className={cx('card__tools')}>
          <div
            className={cx('card__tools--tags-and-count', {
              'hide-mobile': !tags.length && !story.duplicatesCount,
            })}
          >
            <div className={cx('cards_tags')}>
              {Boolean(tags.length) && (
                <img src={tagsIcon} className={cx('tag-icon')} alt="Tag-icon" />
              )}

              <div className={cx('tag-items')}>
                {tags.map((tag) => (
                  <div className={cx('tag-item')} key={tag}>
                    {tag}
                  </div>
                ))}
              </div>
            </div>
            <DuplicateCount showMobile isFooter count={story.duplicatesCount} />
          </div>

          <div className={cardToolsClass}>
            {type !== 'Tweet' && (
              <Tooltip
                tooltipClassName={cx(
                  'tooltip-content',
                  { compact: isCompactView },
                  'first-child',
                  { 'with-token': token },
                )}
                icon={(
                  <StoriesFooterControls
                    actionFunc={(e) => storyInfoAction(e, true, null, false)}
                    active={false}
                    icon={faFileAltLight}
                    iconActive={faFileAltSolid}
                    actionTypeClass="story-info"
                    actionTitle="Story Info"
                    blockClass
                    isFinpromptPages={isFinpromptPages}
                    singleStoryUrl={`/news/stories/${story.uuid_title}`}
                  />
                )}
                firstClickIOSIssue
              >
                Get more info on this story.
              </Tooltip>
            )}

            {storyFooterActions.map(
              ({
                actionId,
                blockClass,
                actionName,
                active,
                icon,
                iconActive,
                actionTypeClass,
                actionTitle,
                params,
                tooltipText,
                lastChild,
              }) => {
                const textForIelevant = (
                  <>
                    {'Mark as irrelevant to teach our algorithms\n'}
                    to refine what you
                    <span>&nbsp;and&nbsp;</span>
                    what others see.
                  </>
                );
                const TTCustomText = actionTitle === 'Irrelevant' ? textForIelevant : tooltipText;
                return (
                  <Tooltip
                    key={actionId}
                    tooltipClassName={cx(
                      'tooltip-content',
                      { compact: isCompactView },
                      { 'last-child': lastChild },
                      { irrelevant: actionTitle === 'Irrelevant' },
                      { bookmark: actionTitle === 'Bookmark' },
                      { 'bookmark-tweet': actionTitle === 'Bookmark' && type === 'Tweet' },
                      { like: actionTitle === 'Like' },
                      { 'like-tweet': actionTitle === 'Like' && type === 'Tweet' },
                      'story-actions',
                      { 'with-token': token },
                    )}
                    className={cx({ 'hide-on-mobile': actionTitle === 'Bookmark' })}
                    icon={(
                      <StoriesFooterControls
                        actionFunc={() => this[actionName](...params)}
                        active={this.state[active]}
                        icon={icon}
                        iconActive={iconActive}
                        actionTypeClass={actionTypeClass}
                        actionTitle={actionTitle}
                        blockClass={blockClass}
                      />
                    )}
                    firstClickIOSIssue
                  >
                    {
                    token ? TTCustomText : customTooltipText
                  }
                  </Tooltip>
                );
              },
            )}

            {sourcesEnabled && !isBlockStoryHidden && (
              <BlockStorySource story={story} width={width} />
            )}

            {token && (
              <Tooltip
                className={cx('hide-on-mobile')}
                tooltipClassName={cx(
                  'tooltip-content',
                  { compact: isCompactView },
                  { 'with-token': token },
                  'hide',
                )}
                icon={(
                  <StoriesFooterControls
                    actionFunc={() => this.updateRating('hideArticle')}
                    active={false}
                    icon={faEyeSlashLight}
                    iconActive={faEyeSlashSolid}
                    actionTypeClass="hide-c"
                    actionTitle="Hide"
                    blockClass
                  />
                )}
                firstClickIOSIssue
              >
                Hide the story from your feed.
              </Tooltip>
            )}

            <Tooltip
              className={cx('hide-on-mobile')}
              tooltipClassName={`${cx(
                'tooltip-content',
                { compact: isCompactView },
                'last-child',
                'share',
                { 'with-token': token },
              )}`}
              icon={(
                <StoriesFooterControls
                  actionFunc={() => this.openShareModal(story)}
                  active={false}
                  icon={faShareAltLight}
                  iconActive={faShareAltSolid}
                  actionTypeClass="share"
                  actionTitle="Share"
                />
              )}
              firstClickIOSIssue
            >
              {
                token ? (
                  'Share the story via email or social media.'
                ) : customTooltipText
              }
            </Tooltip>

            <MobileStoryControlsModal
              openShareModal={() => this.openShareModal(story)}
              hideArticle={() => this.updateRating('hideArticle')}
              likeArticle={() => this.storyAction('like')}
              bookmarkAction={() => this.bookmarkAction()}
              markIrrelevevant={() => this.updateRating('articleIrrelevant')}
              dislikeArticle={() => this.storyAction('dislike')}
              storyInfoAction={(e) => storyInfoAction(e, true)}
              active={false}
              story={story}
              sourcesEnabled={sourcesEnabled}
              token={token}
            />
          </div>
        </div>
        {isDesktop && admin && (
          <>
            <div className={cx('card__admin-tools')}>
              <div className={cx('card__admin-tools--info')}>
                <div className={cx('card__tools--id')}>
                  <img src={idIcon} className={cx('Id-icon')} alt="Id-icon" />
                  {id}
                </div>

                <div className={cx('card__tools--tags-and-count')}>
                  <img
                    src={keyIcon}
                    className={cx('tag-icon')}
                    alt="Tag-icon"
                  />

                  <div className={cx('tag-items')}>
                    {tags.map((tag) => (
                      <div className={cx('tag-item')} key={tag}>
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div
                className={cx('card__tools--item curate')}
                onClick={this.showCurateModal}
              >
                <FontAwesomeIcon className={cx('tool-icon', 'alone')} icon={faCog} />
                <span className={cx('tool-title')}>Curate</span>
              </div>
            </div>
            <CurateModal
              storyId={id}
              show={showCurateModal}
              curatorData={curatorData}
              closeCurateModal={this.closeCurateModal}
              blockSource={this.addSourceToBlocked}
            />
          </>
        )}
        <ShareModal
          ref={(el) => { this.ShareModal = el; }}
          hasBigScreenDesign={hasBigScreenDesign}
        />
      </div>
    );
  }
}

const mapToStateProps = ({ watchlistReducer }) => ({
  token: watchlistReducer.userToken,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...authentication,
      ...storyActions,
    },
    dispatch,
  ),
});

export default connect(
  mapToStateProps,
  mapDispatchToProps,
)(withComponentName(withFinprompt(StoryCardFooter)));
