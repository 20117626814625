import camelcaseKeys from 'camelcase-keys';

import { dcscInstance } from './api';

import ExternalSharingStore from '../../store/ExternalSharingStore';

import { cachingHigh } from '../../data/webPageData';
import { getAPICachingTime } from '../../helpers/helpers';

const caches = {
  level1s: {},
  level2s: {},
  level3s: {},
  level4s: {},
  relevantCompanies: {},
};

export default class DCSCSectorsSvc {
  static async getLevelTopic(slug, level, includeLevels = false) {
    const levelIndex = Number(level?.match(/\d+/)?.[0]) || 1;

    const params = {
      slug,
    };

    if (includeLevels && levelIndex < 4) {
      for (let i = levelIndex + 1; i <= 4; i += 1) {
        params[`include_level${i}s`] = true;
      }
    }

    const { data: { [`${level}s`]: levels } } = await dcscInstance.get(`/${level}s`, {
      params,
    });

    return levels[0];
  }

  static getLevelTopics(level, params = {}) {
    const {
      page,
      per_page: perPage = 200,
      id,
      slug,
      name,
      include_level2s: includeLevel2s,
      include_level3s: includeLevel3s,
      include_level4s: includeLevel4s,
      prefix,
      paginationAlphabetHeader,
    } = params;

    const levelKey = `${level}s`;
    const cacheKey = JSON.stringify(params);

    if (caches[levelKey]?.[cacheKey]?.expDate > Date.now()) {
      return caches[levelKey][cacheKey].response;
    }

    // Do not add an await statement here.
    // It would help to use the same cache if 2+ requests are sent immediately
    const response = dcscInstance.get(`/${levelKey}`, {
      params: {
        page,
        per_page: perPage,
        id,
        slug,
        name,
        include_level2s: includeLevel2s,
        include_level3s: includeLevel3s,
        include_level4s: includeLevel4s,
        prefix,
        pagination_alphabet_header: paginationAlphabetHeader,
      },
    })
      .then(({
        data: { [levelKey]: levels, pagination_alphabet_header: paginationAlphabetHeader },
        headers,
      }) => ({
        levels,
        paginationAlphabetHeader,
        totalPages: Math.ceil(headers.total / perPage) || null,
        totalItems: parseInt(headers.total, 10) || 0,
      }));

    caches[levelKey][cacheKey] = {
      response,
      expDate: getAPICachingTime(cachingHigh),
    };

    return response;
  }

  static async getRelevantCompanies(params = {}) {
    const {
      topicId,
      entityType,
      entityId,
      assetClassId,
      page,
      perPage,
      sortField,
      sortOrder,
      period,
    } = params;

    const cacheKey = JSON.stringify(params);

    if (caches.relevantCompanies?.[cacheKey]?.expDate > Date.now()) {
      return caches.relevantCompanies[cacheKey].response;
    }

    const { data: { relevances }, headers } = await dcscInstance.get('/relevant_companies', {
      params: {
        topic_id: topicId,
        entity_type: entityType,
        entity_id: entityId,
        asset_class_id: assetClassId,
        page,
        per_page: perPage,
        sort_order: sortField === 'relevance' ? sortOrder : undefined,
        sentiment_sort_order: sortField === 'sentiment' ? sortOrder : undefined,
        period,
        token: await ExternalSharingStore.getGroupToken(),
      },
    });

    const response = {
      relevances: camelcaseKeys(relevances, { deep: true }),
      totalPages: Math.ceil(headers.total / perPage) || null,
      totalItems: parseInt(headers.total, 10) || 0,
    };
    caches.relevantCompanies[cacheKey] = {
      response,
      expDate: getAPICachingTime(cachingHigh),
    };

    return response;
  }

  static async getRelevantCompaniesCSV(params = {}, cancelToken) {
    try {
      const {
        topicId,
        entityType,
        entityId,
        assetClassId,
        page,
        perPage,
        sortField,
        sortOrder,
        period,
      } = params;

      const cacheKey = JSON.stringify(params);

      if (caches.relevantCompanies?.[cacheKey]?.expDate > Date.now()) {
        return caches.relevantCompanies[cacheKey].response;
      }

      const { data } = await dcscInstance.get('/relevant_companies', {
        params: {
          topic_id: topicId,
          entity_type: entityType,
          entity_id: entityId,
          asset_class_id: assetClassId,
          page,
          per_page: perPage,
          sort_order: sortField === 'relevance' ? sortOrder : undefined,
          sentiment_sort_order: sortField === 'sentiment' ? sortOrder : undefined,
          period,
          token: await ExternalSharingStore.getGroupToken(),
          format_type: 'csv',
        },
        responseType: 'blob',
        cancelToken,
      });

      return data;
    } catch (e) {
      return null;
    }
  }
}
