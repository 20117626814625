import freePlanImg from '../assets/plan_basic.svg';
import silverPlanImg from '../assets/plan_silver.svg';
import goldPlanImg from '../assets/plan_gold.svg';

export const GUEST_PLAN = 'guest_plan';
export const BASIC_PLAN = 'basic_plan';
export const SILVER_PLAN = 'silver_plan';
export const GOLD_PLAN = 'gold_plan';
export const PLATINUM_PLAN = 'platinum_plan';
export const RBI_PLAN = 'rbi_plan';

const returnWLMessage = (totalWatchList) => {
  if (totalWatchList === 1) {
    return 'You currently have 1 watchlist';
  }
  return `You currently have ${totalWatchList} watchlists`;
};

const retrunTopicMessage = (avgTopic) => {
  if (avgTopic === 1) {
    return 'You have one topic on your only watchlist';
  }
  if (avgTopic === 0) {
    return 'You currently have no topics for any watchlist';
  }
  return `You currently have an average of ${avgTopic} topics per watchlist`;
};

export const basicPlansForAllUsers = {
  title: 'Basic Plan features which are accessible for all users:',
  features: [
    { id: 1, text: '3 watchlists' },
    { id: 2, text: '5 topics per watchlist' },
    { id: 3, text: 'Personalised news, filings, and research' },
    { id: 4, text: 'Search by ticker, name, asset class, topic, sector, location' },
    { id: 5, text: 'Thousands of quality sources in 50+ languages' },
    { id: 6, text: 'Access on the web, on our Android and iOS apps, and on Amazon Echo, Google Home, and Google Assistant' },
    { id: 7, text: '30 days search' },
    { id: 8, text: 'No ads on CityFALCON' },
    {
      id: 9,
      text: 'Similar Story Grouping',
      hasToolTip: true,
      tooltipText: (
        <>
          Machine learning groups similar stories together.
          {' '}
          <a href="https://help.cityfalcon.com/hc/en-us/articles/360007780737" target="_blank" rel="noopener noreferrer">Learn more.</a>
        </>
      ),
    },
    { id: 10, text: 'Basic Sentiment Analysis' },
    { id: 11, text: 'Financial Content Connected to Interactive Price Charts' },
  ],
};

export const additionalSilverPlan = {
  headerText: 'In addition to Basic features, unlock premium features with a Silver Plan:',
  headerImg: silverPlanImg,
  additionalList: [
    { id: 1, title: 'Unlimited Watchlists', desc: 'Organise to any level of granularity' },
    { id: 2, title: 'Up to 100 topics per watchlist', desc: 'Extensive coverage in a single place' },
    { id: 3, title: 'Advanced Sentiment Analysis', desc: 'Access to all CityFALCON sentiment features, including charts' },
  ],
  lastRowText: '+ more coming soon ',
};

export const additionalGoldPlan = {
  headerText: 'In addition to Basic and Silver features, get even more with a Gold Plan:',
  headerImg: goldPlanImg,
  additionalList: [
    {
      id: 1,
      title: 'Access to restricted and paywalled content',
      desc: (
        <>
          Select content from 1000+ publications including
          Washington Post, The Economist, and
          {' '}
          <a
            href="https://help.cityfalcon.com/hc/en-us/articles/360034657073"
            className="many_more"
            target="_blank"
            rel="noopener noreferrer"
          >
            many more
          </a>
        </>
      ),
    },
    { id: 2, title: 'Key Headlines', desc: 'AI-driven summarisation of the most relevant content into bullet points' },
    { id: 3, title: 'Investor Relations Content', desc: 'Official documents like 10-Ks and annual reports from investor relations pages' },
    { id: 4, title: 'ESG Content', desc: 'Corporate sustainability reports and other ESG-related content all gathered in one place' },
    { id: 5, title: 'Automated Summaries', desc: 'AI-driven summarisation of the most relevant content into bullet points' },
    { id: 6, title: 'Translate of all content into English' },
    { id: 7, title: 'Offline reading on mobile and tablet' },
  ],
  lastRowList: [
    { id: 1, text: 'Access select content via API for personal use' },
  ],
};

export const planData = {
  basic_plan: {
    headerImg: freePlanImg,
    headerText: 'Basic',
    featuresText: 'You have access to these Basic features:',
    features: [
      { id: 1, text: 'Personalised news, filings, and research' },
      { id: 2, text: 'Search by ticker, name, asset class, topic, sector, location' },
      { id: 3, text: 'Thousands of quality sources in 50+ languages' },
      {
        id: 4,
        text: (
          <>
            Access on the web, on our
            {' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.cityfalcon"
              target="_blank"
              rel="noreferrer noopener"
              className="feature_link"
            >
              Android
            </a>
            {' '}
            and
            {' '}
            <a
              href="https://itunes.apple.com/us/app/cityfalcon/id1148560018?ls=1&mt=8"
              target="_blank"
              rel="noreferrer noopener"
              className="feature_link"
            >
              iOS
            </a>
            {' '}
            apps, and on
            {' '}
            <a
              href="https://www.amazon.co.uk/dp/B07532KNVM"
              target="_blank"
              rel="noreferrer noopener"
              className="feature_link"
            >
              Amazon Echo
            </a>
            ,
            {' '}
            <a
              href="https://assistant.google.com/services/a/uid/000000f916b51058?hl=en-GB"
              target="_blank"
              rel="noreferrer noopener"
              className="feature_link"
            >
              Google Home
            </a>
            ,
            and Google Assistant
          </>
        ),
      },
      { id: 5, text: '30 days search' },
      { id: 6, text: 'No ads on CityFALCON' },
      {
        id: 7,
        text: 'Similar Story Grouping',
        hasToolTip: true,
        tooltipText: (
          <>
            Machine learning groups similar stories together.
            {' '}
            <a href="https://help.cityfalcon.com/hc/en-us/articles/360007780737" target="_blank" rel="noopener noreferrer">Learn more.</a>
          </>
        ),
      },
      { id: 8, text: 'Basic Sentiment Analysis' },
      { id: 9, text: 'Financial Content Connected to Interactive Price Charts' },
    ],
    activeFeatures: ({ totalWatchList, avgTopic }) => ([
      {
        id: 1,
        title: '3 watchlists',
        desc: returnWLMessage(totalWatchList),
      },
      {
        id: 2,
        title: '5 topics per watchlist',
        desc: retrunTopicMessage(avgTopic),
      },
    ]),
    hasAdditionalSilverPlan: true,
    hasAdditionalGoldPlan: true,
  },
  silver_plan: {
    headerImg: silverPlanImg,
    headerText: 'Silver',
    featuresText: ' Silver ',
    features: [],
    activeFeatures: ({ totalWatchList, avgTopic }) => ([
      {
        id: 1,
        title: 'Unlimited watchlists',
        desc: returnWLMessage(totalWatchList),
      },
      {
        id: 2,
        title: 'Up to 100 topics per watchlist',
        desc: retrunTopicMessage(avgTopic),
      },
      { id: 3, title: 'Advanced Sentiment Analysis', desc: 'Access to all CityFALCON sentiment features, including charts' },
      { id: 4, title: 'Custom push notifications and alerts' },
    ]),
    hasTweetAccess: true,
    comingSoonFeature: [
      { id: 1, text: 'Premium topics and groups, e.g. S&P500 and its constituents' },
      { id: 2, text: 'News insights and advanced analytics' },
    ],
    hasAdditionalGoldPlan: true,
  },
  gold_plan: {
    headerImg: goldPlanImg,
    headerText: 'Gold',
    featuresText: ' Silver and Gold ',
    features: [],
    activeFeatures: ({ totalWatchList, avgTopic }) => ([
      {
        id: 1,
        title: 'Unlimited watchlists',
        desc: returnWLMessage(totalWatchList),
      },
      {
        id: 2,
        title: 'Unlimited topics per watchlist',
        desc: retrunTopicMessage(avgTopic),
      },
      {
        id: 3,
        title: 'Access to restricted and paywalled content',
        desc: (
          <>
            Select content from 1000+ publications including
            Washington Post, The Economist, and
            {' '}
            <a
              href="https://help.cityfalcon.com/hc/en-us/articles/360034657073"
              className="active_feature_more"
            >
              many more
            </a>
          </>
        ),
      },
      { id: 4, title: 'Key Headlines', desc: 'AI-driven summarisation of the most relevant content into bullet points' },
      { id: 5, title: 'Investor Relations Content', desc: 'Official documents like 10-Ks and annual reports from investor relations pages' },
      { id: 6, title: 'ESG Content', desc: 'Corporate sustainability reports and other ESG-related content all gathered in one place' },
      { id: 7, title: 'Automated Summaries', desc: 'AI-driven summarisation of the most relevant content into bullet points' },
      { id: 8, title: 'Custom push notifications and alerts' },
      { id: 9, title: 'Offline reading on mobile and tablet' },
      { id: 10, title: 'Translate all content into English' },
    ]),
    hasTweetAccess: true,
    hasTranslateAccess: true,
    newFeatureHeader: 'We’re delivering new features to the Gold users soon:',
    newFeatureList: [
      { id: 1, text: 'Premium topics and groups, e.g. S&P500 and its constituents' },
      { id: 2, text: 'News insights and advanced analytics' },
      { id: 3, text: 'Access select content via API for personal use' },
    ],
  },
};
