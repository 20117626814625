import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import isEqual from 'lodash-es/isEqual';
import classNames from 'classnames';
import autoBind from 'react-autobind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltUp } from '@fortawesome/pro-regular-svg-icons/faLongArrowAltUp';
import { faLongArrowUp } from '@fortawesome/pro-solid-svg-icons/faLongArrowUp';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle';
import { isIOS } from 'react-device-detect';

import { entityTypeNames } from '../../../../data/entityTypeNames';

import storyExpandImage from '../../../../assets/trending/icons/story_expand_item.png';
import storyExpandImageHover from '../../../../assets/trending/icons/story_expand_item_hover.png';

import withComponentName from '../../../../decorators/withComponentName';
import withFinprompt from '../../../../decorators/withFinprompt';
import windowWidthDecorator from '../../../../decorators/windowWidthDecorator';

import './styles.css';
import { CF_URL_FOR_FINPROMPT } from '../../../../data/environment';

export class TopicItem extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      currentEntityTypeName: this.getCurrentEntityTypeName(),
    };
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (!isEqual(prevProps.data, data)) {
      this.setState({
        currentEntityTypeName: this.getCurrentEntityTypeName(),
      });
    }
  }

  getPercentageBar() {
    const { data, maxScore } = this.props;

    return (data.score / maxScore) * 100;
  }

  getCurrentEntityTypeName() {
    const { data: { topic } } = this.props;

    const curentEntityNameKey = (
      Object.keys(entityTypeNames).find((entityName) => (entityName === topic.asset_class.title))
    );
    return entityTypeNames[curentEntityNameKey];
  }

  expandTopic() {
    const {
      expandedTopic, id, onExpand, chatbot,
      windowWidth,
    } = this.props;
    if (onExpand) {
      if (chatbot && isIOS && (windowWidth < 1024)) {
        const chatbotScrollContainer = document.getElementsByClassName('widget__inner-wrapper')[0];
        if (!chatbotScrollContainer) return;

        if (chatbotScrollContainer?.scrollTop === 0) {
          setTimeout(() => {
            chatbotScrollContainer?.scrollTo({
              top: chatbotScrollContainer?.scrollTop - 1,
              left: 0,
              behavior: 'smooth',
            });
          }, 100);
        } else {
          setTimeout(() => {
            chatbotScrollContainer?.scrollTo({
              top: chatbotScrollContainer?.scrollTop + 1,
              left: 0,
              behavior: 'smooth',
            });
          }, 100);
        }
      }
      if (expandedTopic === id) return onExpand(0);
      return onExpand(id);
    }
  }

  formatScrore(score) {
    return ((score / 100).toFixed(1) + 1);
  }

  addToWatchlist() {
    const { onAddToWatchList, data, piwikEnabled } = this.props;

    if (piwikEnabled) _paq.push(['trackEvent', 'Trending page', 'Add to WL', data.topic.name]);

    if (onAddToWatchList) onAddToWatchList(data.topic);
  }

  openTopicPage(topic) {
    const { piwikEnabled, history, isFinpromptPages } = this.props;

    if (piwikEnabled) _paq.push(['trackEvent', 'Trending page', 'See stories', topic.name]);

    if (isFinpromptPages) {
      const path = `${CF_URL_FOR_FINPROMPT}/news/directory/${topic.asset_class.slug}/${topic.slug}`;
      window.open(path, '_blank')?.focus?.();
      return;
    }
    history.push(`/news/directory/${topic.asset_class.slug}/${topic.slug}`);
  }

  render() {
    const {
      expandedTopic,
      id,
      basicView,
      data,
      chatbot,
    } = this.props;
    const { currentEntityTypeName } = this.state;

    return (
      <div
        className={classNames('TopicItem', {
          expanded: expandedTopic === id,
          basic: basicView,
          chatbot,
        })}
      >
        <div className="TopicItem__content" onClick={this.expandTopic}>
          <div className="TopicItem__titles">
            <span className="TopicItem__titles--head">{data.topic.name}</span>
            <span className="TopicItem__titles--sub-head">{currentEntityTypeName}</span>
          </div>

          <div className="TopicItem__info">
            <FontAwesomeIcon className="TopicItem__info--icon-arrow" icon={chatbot ? faLongArrowUp : faLongArrowAltUp} />

            <span className="TopicItem__info--arrow-value">
              {this.formatScrore(data.score)}
              x
            </span>

            <span className="TopicItem__info--bar">
              <span className="bar-value" style={{ width: `${this.getPercentageBar()}%` }} />
            </span>
            <FontAwesomeIcon
              className="TopicItem__info--icon-dots"
              icon={faEllipsisH}
              onClick={this.expandTopic}
            />
          </div>
        </div>

        <div className="TopicItem__expand-div">
          <div className="TopicItem__expand-div--item" onClick={() => this.openTopicPage(data.topic)}>
            <img src={storyExpandImage} className="default-img" alt="Story Expand" />
            <img src={storyExpandImageHover} className="hover-img" alt="Story Expand Hover" />
            <span>See stories</span>
          </div>
          <div className="TopicItem__expand-div--item" onClick={this.addToWatchlist}>
            <FontAwesomeIcon icon={faPlusCircle} />
            <span>Add to watchlist</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withComponentName(withRouter(withFinprompt(
  windowWidthDecorator(TopicItem),
)));
