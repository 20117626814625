import {
  useState,
  useEffect,
  useCallback,
  memo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import CFLink from '../Shared/CFLink';
import cookie from '../../assets/finprompt/cookie.svg';

import StorageSvc from '../../services/StorageSvc';

import { stringToBool } from '../../helpers/commonHelpers';

import { setCookiesPolicyStatus } from '../../actions/common.actions';

import useFinprompt from '../../providers/FinpromptProvider/useFinprompt';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const PrivacyNoticeBar = () => {
  const location = useLocation();

  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(({ userPreferencesReducer }) => (
    userPreferencesReducer.user
  ));
  const { isFinpromptPages } = useFinprompt();

  useEffect(() => {
    const cookiesPolicyStatus = stringToBool(document.querySelector('.cookies_policy_status')?.value);
    const cookiesPolicyStatusCached = stringToBool(StorageSvc.getItem('cookies_policy_status') || 'false');

    let timeout = null;
    if (
      !window.location.pathname.includes('/privacy')
      && !cookiesPolicyStatus
      && !cookiesPolicyStatusCached
    ) {
      timeout = setTimeout(() => {
        setActive(true);
        dispatch(setCookiesPolicyStatus(true));
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [location?.pathname, isFinpromptPages]);

  const acceptCookies = useCallback(() => {
    StorageSvc.setItem('cookies_policy_status', 'true');
    if (user) {
      axios.patch('/preferences', {
        cookies_status: 'true',
      }).catch(() => {});
    }
    setActive(false);
    dispatch(setCookiesPolicyStatus(false));
  }, [user]);

  const handlePrivacyClick = () => {
    setActive(false);
    dispatch(setCookiesPolicyStatus(false));
  };

  const opacityNo = location.pathname.includes('/chatbot');
  const finpromptHomePage = isFinpromptPages || location.pathname.includes('/finprompt');

  return (
    <div className={cx('privacy-notice-container',
      { active, opacity: !opacityNo, finprompt: finpromptHomePage })}
    >
      <div className={Styles['privacy-notice']}>
        {finpromptHomePage && <img className={cx('privacy-notice__cookie-img')} src={cookie} alt="cookie" />}
        <span className={cx('privacy-notice__text')}>This site uses cookies</span>
        <button
          type="button"
          className={Styles.button}
          onClick={acceptCookies}
        >
          That&apos;s fine
        </button>
        {!finpromptHomePage && (
          <CFLink
            to="/privacy"
            onClick={handlePrivacyClick}
          >
            More info
          </CFLink>
        )}
        {finpromptHomePage && (
          <FontAwesomeIcon
            className={cx('privacy-notice__icons-times')}
            icon={faTimes}
            onClick={handlePrivacyClick}
          />
        )}
      </div>
    </div>
  );
};

export default memo(PrivacyNoticeBar, () => true);
