import {
  useContext,
  useMemo,
  forwardRef,
  useRef,
  Fragment,
} from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';

import PiwikContext from '../../../../../services/piwikContext';
import withComponentName from '../../../../../decorators/withComponentName';

import StoriesList from './StoriesList';
import ModalSummaries from './ModalSummaries';
import NewTag from '../../../../Shared/NewTag';
import StoriesUpgradeSuggestion from '../../../../SubscriptionsComponents/StoriesUpgradeSuggestion';
import Tooltip from '../../../../Tooltip';
import CFLink from '../../../../Shared/CFLink';

import { CHART_MAX_DATE } from '../../../../../data/filters/filterConstants';
import {
  ALL,
  FIVE_YEAR,
  ONE_DAY,
  ONE_YEAR,
  ON_CHART_MODAL_TABS,
} from '../../../../../data/directory/topic_classes/ChartFilters';
import { CRYPTOCURRENCIES } from '../../../../../data/directory/topic_classes/topicClassTypes';

import useFinprompt from '../../../../../providers/FinpromptProvider/useFinprompt';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const StoriesModalDesktop = forwardRef(({
  x,
  y,
  style,
  date,
  price,
  showStories,
  setShowStories,
  storiesData,
  summaryData,
  startDate,
  showToggle,
  tabs,
  setActiveTab,
  timeFilter,
  maxHeight,
  hasBigScreenDesign,
  assetType,
  chatbotExpanded,
}, ref) => {
  const cfMainWrapper = useRef(document.querySelector('.cf-main-wrapper'));
  const { isFinpromptPages } = useFinprompt();

  const styles = useMemo(() => ({
    top: chatbotExpanded ? `${y - ((isFinpromptPages && 350) || 250)}px` : `${y}px`,
    left: `${x}px`,
    ...style,
  }), [x, y, style]);

  const { piwikEnabled } = useContext(PiwikContext);
  const { id: activeTabId } = tabs.find((tab) => tab.active);

  const isStartDateAcceptable = moment(startDate).isAfter(CHART_MAX_DATE);
  const opened = showStories && isStartDateAcceptable;
  const cryptoBigInterval = assetType === CRYPTOCURRENCIES
    && (timeFilter === ONE_YEAR || timeFilter === FIVE_YEAR || timeFilter === ALL);
  const withGMTLabel = timeFilter === ONE_DAY && !cryptoBigInterval;

  const headerDate = () => {
    if (cryptoBigInterval) {
      return moment(date).format('DD MMM YYYY');
    }
    return moment(date).format('DD MMM YYYY HH:mm');
  };

  return (
    createPortal(
      <div
        style={styles}
        className={cx('chart-data-modal', {
          opened,
          big_screen: hasBigScreenDesign,
          chatbot: chatbotExpanded,
          finprompt: isFinpromptPages,
        })}
        ref={ref}
      >
        <div className={Styles['chart-data-modal-header']}>
          <div className={Styles['chart-data-modal-header-info']}>
            <div className={Styles['chart-data-modal-header-date']}>
              {`${headerDate()}${withGMTLabel ? ' GMT' : ''}`}
            </div>
            <div className={Styles['chart-data-modal-header-price']}>
              <span className={Styles['chart-data-modal-header-price-label']}>
                Price:
              </span>
              <span className={Styles['chart-data-modal-header-price-value']}>
                {price}
              </span>
            </div>
          </div>
          {opened && (
            <>
              <div className={Styles.divider} />
              <StoriesUpgradeSuggestion
                classname={cx('chart-data-modal-stories-upgrade')}
                hasBigScreenDesign={hasBigScreenDesign}
              />
              <div className={Styles.divider} />
              <div className={Styles['chart-data-modal-header-tabs-wrapper']}>
                <div className={Styles['chart-data-modal-header-tabs']}>
                  {tabs.map((tab) => (
                    <Fragment key={tab.id}>
                      <div
                        className={cx('chart-data-modal-header-tab', { 'chart-data-modal-header-tab-active': tabs.length > 1 && tab.active })}
                        onClick={() => setActiveTab(tab.id)}
                      >
                        {tab.value}
                      </div>
                      {tab.isSummary && (
                        <Tooltip
                          className={cx('chart-data-modal-summary-info')}
                          hasBigScreenDesign={hasBigScreenDesign}
                        >
                          <>
                            A machine-learning-driven set of key headlines for this feed.
                            Not to be used as the sole basis for investments. &nbsp;
                            <CFLink
                              to="https://www.cityfalcon.ai/features/summarisation"
                              external
                            >
                              Learn more about Key Headlines.
                            </CFLink>
                          </>
                        </Tooltip>
                      )}
                      {tab.beta && (
                        <>
                          <NewTag className={Styles['chart-data-modal-header-tab-beta']} />
                        </>
                      )}
                    </Fragment>
                  ))}
                </div>
                <div className={Styles['chart-data-modal-header-close']} onClick={(e) => setShowStories(false, e)}>
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>
            </>
          )}
        </div>
        {isStartDateAcceptable && (
          showStories ? (
            <div
              className={cx({
                'chart-data-modal-content': true,
                'chart-data-modal-content-stories': activeTabId === ON_CHART_MODAL_TABS.STORIES,
                'chart-data-modal-content-summaries': activeTabId === ON_CHART_MODAL_TABS.SUMMARIES,
              })}
              style={maxHeight ? { maxHeight: `${maxHeight}px` } : {}}
            >
              {activeTabId === ON_CHART_MODAL_TABS.STORIES && (
                <StoriesList
                  storiesData={storiesData}
                  piwikEnabled={piwikEnabled}
                  hasBigScreenDesign={hasBigScreenDesign}
                />
              )}
              {activeTabId === ON_CHART_MODAL_TABS.SUMMARIES && (
                <ModalSummaries
                  summaryData={summaryData}
                  hasBigScreenDesign={hasBigScreenDesign}
                />
              )}
            </div>
          ) : (
            showToggle && (
              <div className={Styles['chart-data-modal-show-more']} onClick={(e) => setShowStories(true, e)}>
                <span>
                  Show Stories
                </span>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            )
          )
        )}
      </div>,
      cfMainWrapper.current,
    )
  );
});

StoriesModalDesktop.defaultProps = {
  style: {},
  date: new Date(1588114029751).toLocaleString(),
  timeFilter: '1m',
  price: 0,
  showStories: true,
};

export default withComponentName(StoriesModalDesktop);
