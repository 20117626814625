import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faCreditCardFront } from '@fortawesome/pro-light-svg-icons/faCreditCardFront';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';

import { isTablet } from 'react-device-detect';
import { faLanguage as faLanguageRegular } from '@fortawesome/pro-regular-svg-icons/faLanguage';
import { faLanguage as faLanguageLight } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { DomainNameTiles, TimeToolTip } from '../CardTooltipComponents';
import { RBI } from '../../../../data/clients';

import Styles from '../styles.module.scss';
import Scrollable from '../../../Shared/Scrollable';
import Tooltip from '../../../Tooltip';

const cx = classNames.bind(Styles);

const TilesView = (props) => {
  const {
    popup, twitterImgLoaded, userNewsfeedViewImages, userNewsfeedViewDescription,
    story, type, expanded, storyImage, width,
    imgURL, imageLoadEvent, imageErrorEvent,
    openTwitterImagesModal, storyInfoAction,
    isTranslationShown, translation,
    openURL, clientName, isMobile,
    showPaywallPopover, togglePopOver, isToolTipTop, stocksLabels,
    otherLabels, showScorePopover, showRegisterPopover, sentimentValue, showSentimentPopover,
    popupEnter, popupLeave, isGoldPlan, isSilverPlan, isPremiumLabelsEnabled,
    onShowPremiumLabelHideModal,
    domainImageURL, domainImageErrorEvent, withoutImages,
    allowMainImage, allowDomainImage, isModal, homepageStories, trendingPageStories,
    resizeGridItem, sentimentContentRef, showTranslation, isTranslationSupported, inHP,
    translationSupportedLanguages, toggleTranslation,
  } = props;

  const [seeMore, setSeeMore] = useState(true);
  const [currentIcon, setCurrentIcon] = useState(faLanguageLight);
  const handleSeeMore = () => {
    setSeeMore(!seeMore);
  };

  const mouseEnterHandler = () => {
    setCurrentIcon(faLanguageRegular);
  };

  const mouseLeaveHandler = () => {
    setCurrentIcon(faLanguageLight);
  };

  useEffect(() => {
    resizeGridItem();
  }, [seeMore]);

  return (
    <div className={cx('Card__content', { popup }, { 'without-images': !twitterImgLoaded || !userNewsfeedViewImages || withoutImages })}>
      {isGoldPlan && isPremiumLabelsEnabled && (
        <div className={cx('tooltip__container')}>
          <div className={cx('goldIcon')}>
            <div className={cx('goldIcon__text')}>
              G
            </div>
            <div className={cx('label__tooltip')}>
              <div className={cx('label__tooltip--container')}>
                <div className={cx('label__tooltip--content')}>
                  <div className={cx('premium-gold-icon-badge')}>
                    GOLD
                  </div>
                  <div className={cx('tooltip-text')}>
                    Exclusive content for Gold users
                  </div>
                </div>
                <div className={cx('tooltip_button')} onClick={onShowPremiumLabelHideModal}>
                  <FontAwesomeIcon icon={faEyeSlash} />
                  <div className={cx('tooltip_button-text')}>Hide Labels</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isSilverPlan && isPremiumLabelsEnabled && (
        <div className={cx('tooltip__container')}>
          <div className={cx('silverIcon')}>
            <div className={cx('silverIcon__text')}>
              S
            </div>
            <div className={cx('label__tooltip')}>
              <div className={cx('label__tooltip--container')}>
                <div className={cx('label__tooltip--content')}>
                  <div className={cx('premium-silver-icon-badge')}>
                    SILVER
                  </div>
                  <div className={cx('tooltip-text')}>
                    Additional content for Premium users
                  </div>
                </div>
                <div className={cx('tooltip_button')} onClick={onShowPremiumLabelHideModal}>
                  <FontAwesomeIcon icon={faEyeSlash} />
                  <div>Hide Labels</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {userNewsfeedViewImages && !withoutImages && (
        <div
          className={cx('Card__content--img')}
        >
          <div
            className="details-header__statistics--twitter-img"
            onClick={openTwitterImagesModal}
          >
            {allowMainImage && !allowDomainImage ? (
              <img
                src={imgURL}
                onLoad={imageLoadEvent}
                onError={imageErrorEvent}
                alt="Story"
                loading="lazy"
              />
            ) : (
              <img
                src={domainImageURL}
                onLoad={imageLoadEvent}
                onError={domainImageErrorEvent}
                alt="Story"
                loading="lazy"
              />
            )}
          </div>
        </div>
      )}
      <div className={cx('Card__content--details')}>
        <div className={cx('details-header')}>
          <span className={cx('details-header__info')}>
            <span
              className={cx('details-header__info--title')}
              onClick={storyInfoAction}
            >
              {isTranslationShown && translation.title ? translation.title : story.title}
            </span>
            {type === 'Story' && (
              <div className={cx(userNewsfeedViewDescription && (story.description || translation.description) ? 'details-description show' : 'details-description')}>
                {isTranslationShown && translation.description
                  ? translation.description : story.description}
              </div>
            )}
          </span>
        </div>
      </div>
      <div className={cx('Card__content--details-bottom')}>
        <div className={cx('Card__content--details-bottom--content')}>
          <span
            className={classNames('details-header__info--about', { tweet: type === 'Tweet', expanded })}
            onClick={(e) => e.stopPropagation()}
          >
            <DomainNameTiles
              id={story.id}
              type={type}
              twitterName={story.author_screen_name}
              openURL={openURL}
              domainName={story.domain_name}
              storyImage={storyImage}
            />
            <TimeToolTip
              id={story.id}
              publishTime={story.publishTime}
              width={width}
            />
            {!([RBI.name].includes(clientName)) && (
            <>
              <span
                className={cx(
                  'details-header__statistics--percentage',
                  { 'show-popover': showScorePopover },
                  { 'show-percentage': true },
                )}
                onClick={(e) => togglePopOver(e, 'showScorePopover')}
                onMouseEnter={(e) => popupEnter(e, 'showScorePopover')}
                onMouseLeave={popupLeave}
              >
                <span className={cx('score-value')}>
                  {Math.round(story.score)}
                  %
                </span>
                <span className={cx('percentage-popover', `percentage-popover-${isToolTipTop ? 'top' : 'bottom'}`)}>
                  <span className={cx('percentage-popover-container')}>
                    CityFALCON Score showing relevance of&nbsp;
                    story to you and your chosen topics.
                    <a href="https://www.cityfalcon.ai/features/cf-score">
                      <span>How does it work?</span>
                    </a>
                  </span>
                </span>
              </span>
              {showRegisterPopover && (
              <span
                className={`registr-icon ${showRegisterPopover && 'show-popover'}`}
                onClick={(e) => togglePopOver(e, 'showRegisterPopover')}
              />
              )}
            </>
            )}
            {showScorePopover && (
            <span className={cx('popover_backdrop')} onClick={(e) => togglePopOver(e, 'showScorePopover')} />
            )}
            {(showTranslation && isTranslationSupported && story.lang !== 'en' && !inHP && width < 768) && (
            <span
              className={cx('details-header__info--translate', { disabled: translation && !translation.title })}
              onMouseEnter={mouseEnterHandler}
              onMouseLeave={mouseLeaveHandler}
            >
              <Tooltip
                className={cx('translate--tooltip')}
                icon={(<FontAwesomeIcon icon={currentIcon} />)}
                hasBigScreenDesign
                isBigScreen
              >
                <span className={cx('title')}>
                  {(() => {
                    if (translation && translation.title) {
                      if (isTranslationShown) {
                        return (
                          <>
                            <div className={cx('translated--from--title')}>
                              {`Translated from ${translationSupportedLanguages
                                ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                            </div>
                            <div
                              className={cx('translate--title')}
                              onClick={toggleTranslation}
                            >
                              Show Original
                            </div>
                          </>
                        );
                      }
                      return (
                        <>
                          <div className={cx('translated--from--title')}>
                            {`Original in ${translationSupportedLanguages
                              ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                          </div>
                          <div
                            className={cx('translate--title')}
                            onClick={toggleTranslation}
                          >
                            Translate to English
                          </div>
                        </>
                      );
                    }
                    return 'Translating to English...';
                  })()}
                </span>
              </Tooltip>
              <span className={cx('title')}>
                {(() => {
                  if (translation && translation.title) {
                    if (isTranslationShown) {
                      return (
                        <>
                          <span className={cx('translated--from--title')}>
                            {`Translated from ${translationSupportedLanguages
                              ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                          </span>
                          <span
                            className={cx('translate--title')}
                            onClick={toggleTranslation}
                          >
                            Show Original
                          </span>
                        </>
                      );
                    }
                    return (
                      <>
                        <span className={cx('translated--from--title')}>
                          {`Original in ${translationSupportedLanguages
                            ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                        </span>
                        <span
                          className={cx('translate--title')}
                          onClick={toggleTranslation}
                        >
                          Translate to English
                        </span>
                      </>
                    );
                  }
                  return 'Translating to English...';
                })()}
              </span>
            </span>
            )}
            {story.paywall && !([RBI.name].includes(clientName)) && !isMobile && (
            <>
              <span
                className={cx('paywall-icon', { 'show-popover': showPaywallPopover })}
                onClick={(e) => togglePopOver(e, 'showPaywallPopover')}
              >
                <FontAwesomeIcon icon={faCreditCardFront} />
                <span
                  className={cx('paywall-popover', `paywall-popover-${isToolTipTop ? 'top' : 'bottom'}`)}
                >
                  <span className={cx('paywall-popover-container')}>
                    This news provider requires payment to view the story.
                  </span>
                </span>
              </span>
              {showPaywallPopover && (
                <span className={cx('popover_backdrop')} onClick={(e) => togglePopOver(e, 'showPaywallPopover')} />
              )}
            </>
            )}
          </span>
          <span className={cx('details-header__statistics')}>
            {sentimentValue && (
            <>
              <span
                className={cx('statistics_sentiment', sentimentValue.type, { 'show-popover': showSentimentPopover })}
                onClick={(e) => togglePopOver(e, 'showSentimentPopover')}
                onMouseEnter={(e) => popupEnter(e, 'showSentimentPopover')}
                onMouseLeave={popupLeave}
              >
                <FontAwesomeIcon icon={sentimentValue.icon} />
                <span className={cx('sentiment-popover', `sentiment-popover-${isToolTipTop ? 'top' : 'bottom'}`)}>
                  <span className={cx('sentiment-popover-container')}>
                    Sentiment:
                    {' '}
                    {story.sentiment}
                    %.
                    <br />
                    Ranges from -100% to +100%.
                    {' '}
                    <a href="https://www.cityfalcon.ai/features/sentiment" target="_blank" rel="noopener noreferrer">
                      <span>Learn more</span>
                    </a>
                  </span>
                </span>
              </span>
              {showSentimentPopover && (
                <span className={cx('popover_backdrop')} onClick={(e) => togglePopOver(e, 'showSentimentPopover')} />
              )}
            </>
            )}
          </span>
        </div>
        <div className={cx('details-header__more')}>
          {(showTranslation && isTranslationSupported && story.lang !== 'en' && !inHP && width >= 768) && (
          <span
            className={cx('details-header__info--translate', { disabled: translation && !translation.title })}
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
          >
            <Tooltip
              className={cx('translate--tooltip')}
              icon={(<FontAwesomeIcon icon={currentIcon} />)}
              hasBigScreenDesign
              isBigScreen
            >
              <span className={cx('title')}>
                {(() => {
                  if (translation && translation.title) {
                    if (isTranslationShown) {
                      return (
                        <>
                          <div className={cx('translated--from--title')}>
                            {`Translated from ${translationSupportedLanguages
                              ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                          </div>
                          <div
                            className={cx('translate--title')}
                            onClick={toggleTranslation}
                          >
                            Show Original
                          </div>
                        </>
                      );
                    }
                    return (
                      <>
                        <div className={cx('translated--from--title')}>
                          {`Original in ${translationSupportedLanguages
                            ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                        </div>
                        <div
                          className={cx('translate--title')}
                          onClick={toggleTranslation}
                        >
                          Translate to English
                        </div>
                      </>
                    );
                  }
                  return 'Translating to English...';
                })()}
              </span>
            </Tooltip>
            <span className={cx('title')}>
              {(() => {
                if (translation && translation.title) {
                  if (isTranslationShown) {
                    return (
                      <>
                        <span className={cx('translated--from--title')}>
                          {`Translated from ${translationSupportedLanguages
                            ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                        </span>
                        <span
                          className={cx('translate--title')}
                          onClick={toggleTranslation}
                        >
                          Show Original
                        </span>
                      </>
                    );
                  }
                  return (
                    <>
                      <span className={cx('translated--from--title')}>
                        {`Original in ${translationSupportedLanguages
                          ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                      </span>
                      <span
                        className={cx('translate--title')}
                        onClick={toggleTranslation}
                      >
                        Translate to English
                      </span>
                    </>
                  );
                }
                return 'Translating to English...';
              })()}
            </span>
          </span>
          )}
        </div>
      </div>
      {(stocksLabels.length > 0 || otherLabels.length > 0)
        && (
        <div className="Card__content--details-infoItemLabels" ref={sentimentContentRef}>
          {(stocksLabels.length !== 0
          && !isModal
          && (!homepageStories && !trendingPageStories)
          && (!isMobile && !isTablet))
          && (
            <div className={cx('infoItemLabels')}>
              {stocksLabels}
              {seeMore && otherLabels.length !== 0 && (
                <div className={cx('see_all_btn')}>
                  <div className={cx('see_all')} onClick={handleSeeMore}>
                    See All Tags
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
              )}
            </div>
          )}
          {(otherLabels.length !== 0
          && !seeMore
          && (!homepageStories && !trendingPageStories)
          && !isModal
          && (!isMobile && !isTablet))
          && (
            <div className={cx('infoItemLabels')}>
              {otherLabels}
              {!seeMore && (
                <div className={cx('see_all_btn')}>
                  <div className={cx('see_all')} onClick={handleSeeMore}>
                    See Less Tags
                    <FontAwesomeIcon icon={faChevronUp} />
                  </div>
                </div>
              )}
            </div>
          )}
          {!isModal && (isMobile || isTablet) && (
          <Scrollable arrowStyle="infoItemLabelsArrow">
            <div className={cx('infoItemLabels')}>
              {stocksLabels.length !== 0 && stocksLabels}
              {otherLabels.length !== 0 && otherLabels}
            </div>
          </Scrollable>
          )}
          {((otherLabels?.length !== 0
          && (homepageStories || trendingPageStories)
          && (!isMobile && !isTablet)
          && seeMore)
          || ((otherLabels?.length !== 0 && stocksLabels.length === 0)
            && (!homepageStories && !trendingPageStories)
            && !isModal
            && (!isMobile && !isTablet)
            && seeMore))
          && (
            <div className={cx('infoItemLabels')}>
              {otherLabels.slice(0, 3)}
              {otherLabels.length > 3 && (
                <div className={cx('see_all_btn')}>
                  <div className={cx('see_all')} onClick={handleSeeMore}>
                    See All Tags
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
              )}
            </div>
          )}
          {(otherLabels?.length !== 0
          && (homepageStories || trendingPageStories)
          && (!isMobile && !isTablet)
          && !seeMore)
          && (
            <div className={cx('infoItemLabels')}>
              {otherLabels}
              <div className={cx('see_all_btn')}>
                <div className={cx('see_all')} onClick={handleSeeMore}>
                  See Less Tags
                  <FontAwesomeIcon icon={faChevronUp} />
                </div>
              </div>
            </div>
          )}
        </div>
        )}
    </div>
  );
};

export default TilesView;
