import moment from 'moment';

import { roundYAxisData } from './topicsHelpers';
import convertToDecimal from './convertToDecimal';

const addPlaceholderCharts = (data, dateLabels) => {
  data.unshift({
    x: dateLabels.map((v, i) => i),
    y: new Array(dateLabels.length).fill(null),
    type: 'bar',
    marker: {
      color: 'rgba(255, 255, 255, 0)',
    },
    hoverinfo: 'skip',
  });
  data.push({
    x: dateLabels.map((v, i) => i),
    y: new Array(dateLabels.length).fill(null),
    type: 'bar',
    marker: {
      color: 'rgba(255, 255, 255, 0)',
    },
    hoverinfo: 'skip',
  });
};

const getRanges = (data) => {
  const yValues = data.flatMap((item) => (
    item.y
  )).sort((a, b) => a - b);
  let [min, max] = [yValues[0], yValues[yValues.length - 1]];
  // calculate 12.5% step between min and max values
  const step = (max - min) / 8;
  min = Math.max(min - step, 0);
  max = Number(max) + step;

  return [min, max];
};

const getYTicks = (data, skipNormalization = false) => {
  const yValues = data.flatMap((item) => (
    item.y
  )).sort((a, b) => a - b);
  let min = Number(yValues[0]);
  const max = Number(yValues[yValues.length - 1]);
  if (!skipNormalization) {
    // calculate 12.5% step between min and max values
    const step = (max - min) / 8;
    min += step;
  }

  const avg = min !== max ? (max + min) / 2 : max;

  return [
    min,
    (avg + min) / 2,
    avg,
    (avg + max) / 2,
    max,
  ].map((value) => (
    convertToDecimal(value)
  ));
};

export const prepareTransactionChart = ({
  stats,
  chartHeight,
  width,
  windowWidth,
}) => {
  const dateLabels = stats.map((stat) => stat.date);

  const isMobile = windowWidth < 768;
  const isTablet = windowWidth <= 1024;

  let itemsCount = !isMobile ? 13 : 6;
  if (itemsCount === 6 && dateLabels.length < 12) {
    itemsCount = Math.floor(dateLabels.length / 2);
  }

  let dateTicks = dateLabels
    .map((v, i) => ({
      value: v,
      index: i,
    }));

  if (dateTicks.length > itemsCount && itemsCount > 1) {
    dateTicks = dateTicks.filter(({ index: i }) => (
      (((i + Math.floor(itemsCount / 2)) % Math.floor(dateLabels.length / itemsCount)) === 0)
    ));
  }

  const buyChart = {
    // set numbers as x values to got linear x-axis
    // and set actual values using tickers below with layout
    x: dateLabels.map((v, i) => i),
    y: stats.map((stat) => stat.buy),
    type: 'bar',
    marker: {
      color: '#5cb96e',
    },
    hoverinfo: 'none',
    name: 'buy',
  };

  const sellChart = {
    x: dateLabels.map((v, i) => i),
    y: stats.map((stat) => stat.sell),
    type: 'bar',
    marker: {
      color: '#e35657',
    },
    hoverinfo: 'none',
    name: 'sell',
  };

  const otherChart = {
    x: dateLabels.map((v, i) => i),
    y: stats.map((stat) => stat.other),
    type: 'bar',
    marker: {
      color: '#666666',
    },
    hoverinfo: 'none',
    name: 'other',
  };

  const shareChart = {
    x: dateLabels.map((v, i) => i),
    y: stats.map((stat) => stat.avgPrice),
    type: 'scatter',
    xaxis: 'x1',
    yaxis: 'y2',
    marker: {
      color: '#3971c1',
    },
    hoverinfo: 'none',
    line: {
      width: 1.5,
      shape: 'spline',
    },
    name: 'share',
  };

  let data = [
    buyChart,
    sellChart,
    otherChart,
    shareChart,
  ];

  let transactionsSuffix = '';
  let transactionsRound = 1;

  let maxTransactionValue = -Infinity;
  data.slice(0, 3).map((item) => {
    const roundedData = roundYAxisData(item.y);
    if (roundedData.round > transactionsRound) {
      transactionsRound = roundedData.round;
      transactionsSuffix = roundedData.suffix;
    }

    return item;
  }).forEach((item) => {
    item.y = item.y.map((elem) => convertToDecimal(elem / transactionsRound));
    const maxValue = Math.max(...item.y);
    if (maxTransactionValue < maxValue) {
      maxTransactionValue = maxValue;
    }
  });
  data = data.filter((chartData) => (
    chartData.y.some((value) => Boolean(Number(value)))
  ));

  const yaxis = {
    rangeselector: {
      visible: false,
    },
    fixedrange: true,
    showgrid: true,
    zeroline: false,
    showline: true,
    mirror: true,
    nticks: 5,
    separatethousands: true,
    linecolor: '#eeeeee',
    type: 'linear',
    tickfont: {
      size: '12',
      family: 'Lato',
      color: 'rgba(41, 41, 41, 1)',
    },
    exponentformat: 'none',
    titlefont: {
      size: '12',
      family: 'Lato',
      color: 'rgba(41, 41, 41, 0.9)',
    },
  };

  let shareExists = true;
  let transactionExists = true;
  if (!data.some((datum) => datum.name === 'share')) {
    shareExists = false;
  } else if (data.length === 1) {
    transactionExists = false;
  }

  const layout = {
    width,
    height: chartHeight,
    showlegend: false,
    fixedrange: true,
    autosize: true,
    dragmode: false,
    margin: {
      l: !isTablet ? 64 : (42 + (Math.trunc(maxTransactionValue) > 9 ? 12 : 0)),
      t: 0,
      b: 17,
      r: !isTablet ? 75 : 50,
    },
    hovermode: 'x',
    xaxis: {
      type: '-',
      showgrid: true,
      fixedrange: true,
      showline: true,
      zeroline: false,
      mirror: true,
      tickmode: 'array',
      tickfont: {
        size: '12',
        family: 'Lato',
        color: '#292929',
      },
      tickvals: dateTicks.map(({ index }) => index),
      ticktext: dateTicks.map(({ value }) => (
        moment(value).format('MMM<br />YYYY')
      )),
      automargin: true,
      tickangle: 0,
      showticklabels: true,
      linecolor: '#eeeeee',
    },
    yaxis: {
      ...yaxis,
      tickvals: transactionExists ? getYTicks(data.slice(0, data.length - 1)) : [],
      ticktext: transactionExists ? getYTicks(data.slice(0, data.length - 1)).map((tick) => (
        `${tick}${transactionsSuffix}${'&nbsp;'.repeat(isTablet ? 1 : 3)}`
      )) : [],
      range: transactionExists ? getRanges(data.slice(0, data.length - 1)) : [0, 0],
      showgrid: transactionExists,
    },
    yaxis2: {
      ...yaxis,
      side: 'right',
      overlaying: 'y',
      showgrid: !transactionExists,
      tickvals: shareExists ? getYTicks(data.slice(-1), true) : [],
      ticktext: shareExists ? getYTicks(data.slice(-1), true).map((tick) => (
        `${'&nbsp;'.repeat(isTablet ? 1 : 3)}${tick}`
      )) : [],
      range: shareExists ? getRanges(data.slice(-1)) : [0, 0],
    },
  };

  // This trick are done to decrease bar chart items width
  if (dateLabels.length < 18 && !isTablet) {
    addPlaceholderCharts(data, dateLabels);
  }

  if (dateLabels.length < 6) {
    for (let i = 0; i < 3; i += 1) {
      addPlaceholderCharts(data, dateLabels);
    }
  }

  return {
    data,
    layout,
    maxTransactionValue,
  };
};

export default prepareTransactionChart;
