import {
  FETCH_FILINGS_BEGIN,
  FETCH_FILINGS_SUCCESS,
  FETCH_FILINGS_FAILURE,
  SET_FILINGS_PAGE_LOADER,
  FETCH_FILINGS_SOURCES_SUCCESS,
  FETCH_FILINGS_SOURCES_FAILURE,
  FETCH_FILINGS_CATEGORIES_SUCCESS,
  FETCH_FILINGS_CATEGORIES_FAILURE,
  GET_NEW_FILINGS_BEGIN,
  GET_NEW_FILINGS_SUCCESS,
  GET_NEW_FILINGS_FAILURE,
  GET_LATEST_FILINGS_BEGIN,
  GET_LATEST_FILINGS_SUCCESS,
  GET_LATEST_FILINGS_FAILURE,
} from '../actions/filings.actions';

const initialState = {
  loading: false,
  filings: [],
  filingsError: null,
  filingsPageLoad: false,
  sources: [],
  categories: [],
  newFilingsLoading: false,
  newFilingsError: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FILINGS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FILINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        filingsPageLoad: false,
        filings: payload.filings,
      };
    case FETCH_FILINGS_FAILURE:
      return {
        ...state,
        loading: false,
        filings: [],
        filingsPageLoad: false,
        filingsError: payload.error,
      };
    case FETCH_FILINGS_SOURCES_SUCCESS:
      return {
        ...state,
        sources: payload.sources,
      };
    case FETCH_FILINGS_SOURCES_FAILURE:
      return {
        ...state,
        sources: [],
      };
    case FETCH_FILINGS_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload.categories,
      };
    case FETCH_FILINGS_CATEGORIES_FAILURE:
      return {
        ...state,
        categories: [],
      };
    case SET_FILINGS_PAGE_LOADER:
      return {
        ...state,
        filingsPageLoad: payload,
      };
    case GET_NEW_FILINGS_BEGIN:
      return {
        ...state,
        newFilingsLoading: true,
        newFilingsError: false,
      };
    case GET_NEW_FILINGS_SUCCESS:
      return {
        ...state,
        newFilingsLoading: false,
        filings: [...state.filings, ...payload],
        newFilingsError: false,
      };
    case GET_NEW_FILINGS_FAILURE:
      return {
        ...state,
        newFilingsLoading: false,
        newFilingsError: true,
      };
    case GET_LATEST_FILINGS_BEGIN:
      return {
        ...state,
        filingsPageLoad: true,
      };
    case GET_LATEST_FILINGS_SUCCESS:
      return {
        ...state,
        filingsPageLoad: false,
        filings: [...payload, ...state.filings],
      };
    case GET_LATEST_FILINGS_FAILURE:
      return {
        ...state,
        filingsPageLoad: false,
      };
    default:
      return state;
  }
};
