import { Component } from 'react';
import classNames from 'classnames/bind';
import autoBind from 'react-autobind';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faClose } from '@fortawesome/pro-light-svg-icons/faClose';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';

import * as watchlistActions from '../../../actions/watchlistActions';

import {
  checkDuplicates, checkOnValidBracketsName, isSpecialCharacters, queryItemWatchlistParser,
} from '../../../helpers/watchlistHelperFunctions';

import withComponentName from '../../../decorators/withComponentName';
import withRouter from '../../../decorators/withRouter';
import withFinprompt from '../../../decorators/withFinprompt';

import RemoveAllWatchlistsModal from '../../Modals/RemoveAllWatchlistsModal';
import RenameWatchlistModal from '../../Modals/RenameWatchlistModal';

import Styles from './styles.module.scss';
import { CF_URL_FOR_FINPROMPT } from '../../../data/environment';

const cx = classNames.bind(Styles);

export class WatchlistNameActions extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      newName: '',
      showInput: false,
      openRemoveWLModal: false,
      openRenameWLModal: false,
      errors: '',
    };
  }

  handleCloseWLRemoveModal() {
    this.setState({
      openRemoveWLModal: false,
    });
    this.props.hideNameActionsIcons();
  }

  handleOpenWLRemoveModal() {
    this.setState({
      openRemoveWLModal: true,
    });
  }

  handleCloseWLRenameModal() {
    this.setState({
      openRenameWLModal: false,
    });
  }

  handleOpenWLRenameModal() {
    this.setState({
      openRenameWLModal: true,
    });
  }

  handleClickWatchlistName(id, token) {
    const {
      actions, manageWlPage, history, isFinpromptPages,
    } = this.props;
    actions.toggleActiveWatchlist(id, token);

    if (isFinpromptPages) {
      window.open(`${CF_URL_FOR_FINPROMPT}/watchlists`, '_blank')?.focus?.();
    } else if (manageWlPage) {
      history.push('/watchlists');
    }
  }

  onChangeWatchlistName(e) {
    const { value: newWatchlistName } = e.target;

    this.setState({ newName: newWatchlistName });

    if (newWatchlistName !== '') {
      this.setState({ errors: '' });
    }
  }

  validateWatchlistName(newName) {
    const isSpecCharacters = isSpecialCharacters(newName);

    if (newName?.length > 50) {
      this.setState({ errors: 'The name is too long. Please use not more than 50 characters.' });
      return true;
    } if (!newName?.trim().length) {
      this.setState({ errors: 'A watchlist name is required' });
      return true;
    } if (isSpecCharacters || checkOnValidBracketsName(newName)) {
      this.setState({ errors: 'Special characters are not allowed ' });
      return true;
    }
    this.setState({ errors: '' });
  }

  toggleInput() {
    const { watchlist: { name } = {} } = this.props;
    const { errors } = this.state;

    this.setState(({ showInput }) => ({
      showInput: !showInput,
      newName: name,
    }));

    if (errors) {
      this.setState({ errors: '' });
    }
  }

  watchlistNameFormSubmit(e, id) {
    e.preventDefault();
    const {
      actions,
      token,
      activeWatchlist,
      watchlist,
      watchlistsArray,
      piwikEnabled,
    } = this.props;
    const { newName } = this.state;

    const errors = this.validateWatchlistName(newName);

    if (errors) return;

    const nameNotChanged = watchlist.name === newName;

    if (nameNotChanged) {
      this.toggleInput();
      return;
    }

    const isHaveDuplicates = checkDuplicates(watchlistsArray, newName);

    if (isHaveDuplicates) {
      this.handleOpenWLRenameModal();
    } else {
      if (token) {
        actions.editWatchlistName(id, newName, activeWatchlist.id);
      } else {
        actions.editGuestUserWatchlistName(id, newName);
      }
      if (piwikEnabled) _paq.push(['trackEvent', 'WL page', 'Rename WL']);

      this.toggleInput();
    }
  }

  render() {
    const {
      token,
      watchlist: {
        id,
        name,
        search_queries: searchQueries,
        watchlist_assets: watchlistAssets = [],
      },
      watchlistsArray,
      activeWatchlist,
      piwikEnabled,
      activeOnHover,
      manageWlPage,
      selected,
      hasBigScreenDesign,
      width,
      chatbot,
      expanded,
    } = this.props;
    const {
      newName, showInput, errors, openRemoveWLModal,
      openRenameWLModal,
    } = this.state;

    const watchlistNameClass = cx({
      wathclists_names_list: true,
      active: showInput,
      hover: activeOnHover,
      manage_wl_page: manageWlPage,
      selected,
      big_screen: hasBigScreenDesign,
      chatbot,
    });

    const assetNames = [
      ...searchQueries,
      ...watchlistAssets,
    ].map((asset) => (asset.query ? queryItemWatchlistParser(asset.query).name : asset.name));

    const getWlName = (name) => {
      if ((manageWlPage || (chatbot && expanded)) && name.length > 50) {
        return `${name.slice(0, 50)}...`;
      } if (manageWlPage || (chatbot && expanded && (width > 480))) {
        return name;
      }
      if (chatbot && !expanded) {
        return (name?.length > 30 ? `${name.slice(0, 30)}...` : name);
      }
      if (width > 767) {
        return (name?.length > 30 ? `${name.slice(0, 30)}...` : name);
      }
      return (name?.length > 20 ? `${name.slice(0, 20)}...` : name);
    };

    return (
      <div className={`wathclists_names_list--static ${watchlistNameClass}`}>
        {showInput ? (
          <form
            onSubmit={(e) => this.watchlistNameFormSubmit(e, id)}
            className={cx('change_name_input', { hasErrors: errors })}
          >
            <input
              type="text"
              value={newName}
              maxLength="50"
              onChange={this.onChangeWatchlistName}
            />
            <button aria-label="Save" type="submit" className={cx('change_name_submit', { hasErrors: errors })}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
            <button aria-label="Delete" type="button" onClick={this.toggleInput} className={cx('change_name_cancel')}>
              <FontAwesomeIcon icon={faClose} />
            </button>
            {errors && <span className={cx('error_message')}>{errors}</span>}
          </form>
        ) : (
          <div className={Styles.name} onClick={() => this.handleClickWatchlistName(id, token)}>
            {getWlName(name)}
          </div>
        )}
        <div className={`watchlist_controls ${cx('watchlist_controls', { disabled: showInput })}`}>
          <div className={Styles.edit} onClick={this.toggleInput}>
            <FontAwesomeIcon icon={faPencil} className="mr-2" />
          </div>
          <div className={Styles.delete} onClick={this.handleOpenWLRemoveModal}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </div>
        </div>
        <RemoveAllWatchlistsModal
          openModal={openRemoveWLModal}
          handleCloseModal={this.handleCloseWLRemoveModal}
          watchlistName={name}
          id={id}
          assetNames={assetNames}
          removeOnlyOneWatchlist
          piwikEnabled={piwikEnabled}
          hasBigScreenDesign={manageWlPage || hasBigScreenDesign}
        />
        <RenameWatchlistModal
          openModal={openRenameWLModal}
          handleCloseModal={this.handleCloseWLRenameModal}
          watchlistsArray={watchlistsArray}
          id={id}
          newName={newName}
          activeWatchlist={activeWatchlist}
          closeInput={this.toggleInput}
          piwikEnabled={piwikEnabled}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...watchlistActions }, dispatch),
});

const mapStateToProps = (state) => ({
  activeWatchlist: state.watchlistReducer.activeWatchlist,
  token: state.watchlistReducer.userToken,
  width: state.common.width,
  expanded: state.chatbot.expanded,
});

export default withComponentName(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(withFinprompt(WatchlistNameActions))),
);
