import {
  SET_AUTH_MODAL_OPENED,
  SET_AUTH_MODAL_OPENED_FROM_CHATBOT,
  SET_FINPROMPT_AUTH_MODAL_OPENED,
  SET_FORGOT_PASSWORD_CUSTOM_MODAL_OPENED,
  SET_USER_HAS_ACCOUNT_MODAL_OPENED,
} from '../actions/authentication.actions';

const initialState = {
  authModalOpened: false,
  authModalOpenedFromChatbot: false,
  finPromptAuthModalOpened: false,
  forgotPasswordCustomModalOpened: false,
  userHasAccountModalOpened: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_AUTH_MODAL_OPENED:
      return {
        ...state,
        authModalOpened: payload,
      };
    case SET_AUTH_MODAL_OPENED_FROM_CHATBOT:
      return {
        ...state,
        authModalOpened: payload,
        authModalOpenedFromChatbot: payload,
      };
    case SET_FINPROMPT_AUTH_MODAL_OPENED:
      return {
        ...state,
        finPromptAuthModalOpened: payload,
      };
    case SET_FORGOT_PASSWORD_CUSTOM_MODAL_OPENED:
      return {
        ...state,
        forgotPasswordCustomModalOpened: payload,
      };
    case SET_USER_HAS_ACCOUNT_MODAL_OPENED:
      return {
        ...state,
        userHasAccountModalOpened: payload,
      };
    default:
      return state;
  }
};
