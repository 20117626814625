import { memo, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/pro-solid-svg-icons/faSquarePlus';
import { faSquareMinus } from '@fortawesome/pro-regular-svg-icons/faSquareMinus';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import classNames from 'classnames/bind';

import TransparentButton from '../../../Buttons/PreferencesButtons/TransparentButton';
import Tooltip from '../../../Tooltip';

import useSizes from '../../../../hooks/sizes';
import useFinprompt from '../../../../providers/FinpromptProvider/useFinprompt';

import { checkAndRoun } from '../../../../helpers/topicsHelpers';
import convertPermalinkToPath from '../../../../helpers/urlHelpers';

import InsiderTransactionsSvc from '../../../../services/dbServices/InsiderTransactionsSvc';

import Styles from './styles.module.scss';

import { CF_URL_FOR_FINPROMPT } from '../../../../data/environment';

const cx = classNames.bind(Styles);

const tooltipContentStyle = {
  transform: 'translate(calc(-50% + 8px), calc(-100% - 10px))',
  zIndex: 100003,
};

const CONTENT_DATA_MAPPING = [
  {
    key: 'filingDate',
    title: 'Filing Date',
  },
  {
    key: 'typeOfSecurity',
    title: 'Type of Security',
  },
  {
    key: 'currency',
    title: 'Currency',
  },
  {
    key: 'pricePerShare',
    title: 'Price per Share',
  },
  {
    key: 'sharesHeldDirectly',
    title: (
      <>
        <span>Shares Held (Directly)</span>
        <Tooltip className={cx('tooltip')} tooltipClassName={cx('tooltip-content')}>
          Number of shares held directly post-transaction.
        </Tooltip>
      </>
    ),
  },
  {
    key: 'sharesHeldIndirectly',
    title: (
      <>
        <span>Shares Held (Indirectly)</span>
        <Tooltip
          className={cx('tooltip')}
          tooltipClassName={cx('tooltip-content')}
          renderOutside
          hideWithOverflow
          dynamicPosition
          contentStyle={tooltipContentStyle}
        >
          Number of shares held through other companies or entities post-transaction.
        </Tooltip>
      </>
    ),
  },
  {
    key: 'sharesHeldTotal',
    title: (
      <>
        <span>Shares Held (Total)</span>
        <Tooltip className={cx('tooltip')} tooltipClassName={cx('tooltip-content')}>
          Number of shares held in total post-transaction.
        </Tooltip>
      </>
    ),
  },
];

export const TransactionChartTable = ({
  transactionsChartData: initialTransactionsChartData,
  filterDate, chatbot,
}) => {
  const [collapsedOfficers, setCollapsedOfficers] = useState(new Set());
  const [collapsedTransactions, setCollapsedTransactions] = useState(new Set());
  const { width } = useSizes();

  const { isFinpromptPages } = useFinprompt();

  const contentDataMapping = useMemo(() => {
    if (width > 767) {
      return CONTENT_DATA_MAPPING;
    }

    return [
      {
        key: 'officerTitle',
        title: 'Insider Title',
      },
      {
        key: 'transactionType',
        title: 'Transaction',
      },
      ...CONTENT_DATA_MAPPING,
    ];
  }, [width > 767]);

  const transactionsChartData = useMemo(() => {
    const date = new Date(filterDate);

    return initialTransactionsChartData.map((data) => {
      const transactions = InsiderTransactionsSvc.filterDuplicateTransactions(
        data.transactions.filter((item) => {
          const transactionDate = new Date(item.transactionDate);

          return (
            date.getFullYear() === transactionDate.getFullYear()
            && date.getMonth() === transactionDate.getMonth()
          );
        }),
      ).sort((a, b) => (
        new Date(a.transactionDate)
        - new Date(b.transactionDate)
      )).map((transaction) => ({
        ...transaction,
        // Capitalize types
        transactionType: (
          transaction.transactionType
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        ),
        filingDate: moment(transaction.filingDate).format('DD MMM YYYY'),
        sharesHeldDirectly: transaction.sharesHeldDirectly
          ? checkAndRoun(transaction.sharesHeldDirectly, 2, true)
          : '-',
        sharesHeldIndirectly: transaction.sharesHeldIndirectly ? checkAndRoun(
          transaction.sharesHeldIndirectly,
          2,
          true,
        ) : '-',
        sharesHeldTotal: transaction.sharesHeldTotal
          ? checkAndRoun(transaction.sharesHeldTotal, 2, true)
          : '-',
        pricePerShare: transaction.pricePerShare
          ? checkAndRoun(transaction.pricePerShare, 2, true)
          : '-',
      }));

      if (!transactions.length) {
        return null;
      }

      const types = [
        ...transactions.reduce((acc, transaction) => {
          acc.add(transaction.transactionType);
          return acc;
        }, new Set()),
      ]
        // Filter others
        .filter((type) => type.trim() && !/other/i.test(type))
        .sort((a, b) => (
          a < b ? -1 : Number(a !== b)
        ));

      const summaryValue = transactions.reduce(
        (acc, transaction) => acc + transaction.value,
        0,
      );
      return {
        ...data,
        officerTitle: data.transactions[0]?.officerTitle || '-',
        minDate: moment(transactions[0].transactionDate).format('DD MMM YYYY'),
        maxDate: moment(transactions[transactions.length - 1].transactionDate).format('DD MMM YYYY'),
        types: types.join(', '),
        numberOfShares: checkAndRoun(transactions.reduce(
          (acc, transaction) => acc + transaction.numberOfShares,
          0,
        ), 2, true),
        value: checkAndRoun(summaryValue, 2, true),
        summaryValue,
        transactions,
      };
    }).filter(Boolean).sort((a, b) => b.summaryValue - a.summaryValue);
  }, [initialTransactionsChartData, filterDate]);

  const toggleOfficerRow = (officerID) => {
    if (collapsedOfficers.has(officerID)) {
      collapsedOfficers.delete(officerID);
    } else {
      collapsedOfficers.add(officerID);
    }

    setCollapsedOfficers(new Set(collapsedOfficers));
  };

  const toggleTransactionRow = (trasnactionUid) => {
    if (collapsedTransactions.has(trasnactionUid)) {
      collapsedTransactions.delete(trasnactionUid);
    } else {
      collapsedTransactions.add(trasnactionUid);
    }

    setCollapsedTransactions(new Set(collapsedTransactions));
  };

  const gettingCorrectLink = (transaction) => {
    if (transaction.filingURL) {
      return (
        isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}${convertPermalinkToPath(transaction.filingURL)}`
          : convertPermalinkToPath(transaction.filingURL)
      );
    }
  };

  if (!transactionsChartData.length) {
    return (
      <div className={Styles['no-data']}>
        No data is available for this month.
      </div>
    );
  }

  return (
    <div className={cx('transaction-chart-table', { chatbot })}>
      <div className={Styles.row}>
        <div className={Styles['row-wrapper']}>
          <div className={cx('column', 'head')}>
            Insider’s Name
          </div>
          <div className={cx('column', 'head')}>
            Insider’s Title
          </div>
          <div className={cx('column', 'head')}>
            Transaction Date
          </div>
          <div className={cx('column', 'head')}>
            Transaction
          </div>
          <div className={cx('column', 'head', 'right')}>
            Number of Shares
          </div>
          <div className={cx('column', 'head', 'right')}>
            Value
          </div>
          <div className={cx('column')} />
        </div>
      </div>
      {transactionsChartData.map((data) => (
        <div key={data.officerID} className={Styles.row}>
          <div className={Styles['row-wrapper']} onClick={() => toggleOfficerRow(data.officerID)}>
            <div className={cx('column')}>
              <FontAwesomeIcon
                className={Styles.icon}
                icon={collapsedOfficers.has(data.officerID) ? faSquareMinus : faSquarePlus}
              />
              <div>{data.officerName}</div>
            </div>
            <div className={cx('column')}>
              {data.officerTitle}
            </div>
            <div className={cx('column')}>
              <div>
                <div>
                  {data.minDate}
                  {data.minDate !== data.maxDate && ' -'}
                </div>
                {data.minDate !== data.maxDate && (
                  <div>{data.maxDate}</div>
                )}
              </div>
            </div>
            <div className={cx('column')}>
              <div className={Styles.types}>
                {data.types}
              </div>
            </div>
            <div className={cx('column', 'right')}>
              {data.numberOfShares}
            </div>
            <div className={cx('column', 'right')}>
              {data.value}
            </div>
            <div className={cx('column')} />
          </div>
          {collapsedOfficers.has(data.officerID) && (
            data.transactions.map((transaction) => (
              <div key={transaction.uid}>
                <div className={cx('row-wrapper', 'transaction')} onClick={() => toggleTransactionRow(transaction.uid)}>
                  <div className={cx('column')}>
                    {moment(transaction.transactionDate).format('DD MMM YYYY')}
                  </div>
                  <div className={cx('column')}>
                    {transaction.transactionType}
                  </div>
                  <div className={cx('column', 'right')}>
                    {checkAndRoun(transaction.numberOfShares, 2, true)}
                  </div>
                  <div className={cx('column', 'right')}>
                    {checkAndRoun(transaction.value, 2, true)}
                  </div>
                  <div className={cx('column', 'right')}>
                    <FontAwesomeIcon
                      className={Styles['icon-transaction-collapse']}
                      icon={(
                        collapsedTransactions.has(transaction.uid) ? faChevronUp : faChevronDown
                      )}
                    />
                  </div>
                </div>
                {collapsedTransactions.has(transaction.uid) && (
                  <div className={cx('row-wrapper', 'transaction-details')}>
                    <div className={cx('row-content')}>
                      <div className={cx('row-content-data')}>
                        {contentDataMapping.map(({ key, title }) => (
                          <div key={key} className={cx('column')}>
                            <div className={cx('title')}>
                              {title}
                            </div>
                            <div className={cx('value')}>
                              {transaction[key]}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className={cx('filling-btn-wrapper')}>
                        <TransparentButton
                          type="anchor"
                          to={gettingCorrectLink(transaction)}
                          external={isFinpromptPages}
                          className={cx('filling-btn')}
                        >
                          See Original Filing
                        </TransparentButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      ))}
    </div>
  );
};

export default memo(TransactionChartTable);
