import { useDispatch, useSelector } from 'react-redux';
import {
  memo, useEffect, useRef, useState,
} from 'react';
import isEqual from 'lodash-es/isEqual';

import WLManageItem from '../../WatchlistComponents/WatchlistManagePageWrapper/WatchlistsManageBody/WLManageItem';
import ShowButton from './ShowButton';

import { getWatchlists } from '../../../actions/watchlistActions';
import { CF_URL_FOR_FINPROMPT } from '../../../data/environment';

const WatchlistsCards = (props) => {
  const {
    payload, handleShowMoreButton, isChatbotOpened, scrollToBottomOnWidgetRendered,
    expanded, isFinpromptPages,
  } = props;
  const components = payload?.components;
  const messageDate = payload.date;

  const dispatch = useDispatch();

  const isExpanded = useSelector(({ chatbot }) => (chatbot.activeWidgetType === 'widgetFullScreen'));
  const watchlistsList = useSelector(({ watchlistReducer }) => (watchlistReducer.watchlistsList));
  const maxWatchlistSize = useSelector(({ subscriptions }) => (subscriptions.permissions.max_watchlist_size));

  const handleShowMore = () => {
    const redirectPath = isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}/watchlists/manage` : '/watchlists/manage';

    if (handleShowMoreButton) handleShowMoreButton(redirectPath);
  };

  const [list, setList] = useState([]);

  const slicedRef = useRef(false);

  useEffect(() => {
    components.forEach((item) => {
      if (item.component_name === 'watchlist_cards') {
        setList([...item.component_data?.watchlists]);
        if (item.component_data?.watchlists.length > 3) {
          slicedRef.current = true;
        } else {
          slicedRef.current = false;
        }
      }
    });
  }, [components]);

  useEffect(() => {
    if (!isEqual(list, watchlistsList)) {
      setList([...watchlistsList]);
      if (watchlistsList.length > 3) {
        slicedRef.current = true;
      } else {
        slicedRef.current = false;
      }
    }
  }, [watchlistsList]);

  useEffect(() => {
    if (!isChatbotOpened) return;

    if (scrollToBottomOnWidgetRendered) {
      setTimeout(() => {
        scrollToBottomOnWidgetRendered();
      }, 200);
    }
  }, [isChatbotOpened]);

  useEffect(() => {
    dispatch(getWatchlists());
  }, []);

  if (!list?.length) return null;

  return (
    <div
      data-date={messageDate}
      className={`react-chatbot-kit__widget__wrapper watchlists ${slicedRef.current ? 'show-more' : ''}`}
    >
      {list.slice(0, 3)?.map((item) => (
        <WLManageItem
          item={item}
          key={item.id}
          watchlistsListNew={list}
          maxWatchlistSize={maxWatchlistSize}
          chatbot
          isExpanded={isExpanded}
        />
      ))}
      {slicedRef.current && (
        <ShowButton
          expanded={expanded}
          handleClick={handleShowMore}
          isChatbotOpened={isChatbotOpened}
        />
      )}
    </div>
  );
};

export default memo(WatchlistsCards);
