import {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import classNames from 'classnames/bind';

import { roundTo } from '../../../../helpers/helpers';
import { checkAndRoun } from '../../../../helpers/topicsHelpers';

import withComponentName from '../../../../decorators/withComponentName';

import TransactionChartTable from '../TransactionChartTable';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const HEADER_HEIGHT = 87;

export const TransactionChartTableMobile = forwardRef(({
  sharesData,
  valuesData,
  transactionsChartData,
}, ref) => {
  const heightRef = useRef(window.innerHeight);
  const [top, setTop] = useState(heightRef.current - HEADER_HEIGHT);
  const initialCursorTop = useRef(0);

  const preventTouchMove = useCallback((event) => {
    if (event.cancelable) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    setTop(heightRef.current - HEADER_HEIGHT);
  }, [sharesData, valuesData]);

  const onTouchStart = (e) => {
    window.addEventListener('touchmove', preventTouchMove, { passive: false });
    const { clientY } = e.changedTouches[0];
    initialCursorTop.current = clientY - top;
    heightRef.current = window.innerHeight;
  };

  const onTouchMove = (e) => {
    const { clientY } = e.changedTouches[0];
    const { current: height } = heightRef;

    let topValue = clientY - initialCursorTop.current;
    if (topValue > height - HEADER_HEIGHT) {
      topValue = height - HEADER_HEIGHT;
    } else if (topValue < height - ref.current.offsetHeight) {
      topValue = Math.max(height - ref.current.offsetHeight, 0);
    }

    setTop(topValue);
  };

  const onTouchEnd = () => {
    window.removeEventListener('touchmove', preventTouchMove);
  };

  return (
    createPortal(
      <div
        className={Styles.wrapper}
        style={{ bottom: `${heightRef.current - top}px` }}
      >
        <div className={Styles['transaction-chart-table-mobile']} ref={ref}>
          <div
            className={Styles.header}
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            <div className={Styles.line} />
            <div className={Styles.date}>
              {moment(sharesData.date).format('MMM YYYY')}
            </div>
            <div className={Styles.details}>
              <div className={cx('info-wrapper')}>
                <div className={cx('info-wrapper-label')}>
                  Avg Stock Price:
                </div>
                <div className={cx('info-wrapper-value')} style={{ color: '#3971c1' }}>
                  {sharesData.avgStockPrice !== null ? (
                    `$${roundTo(sharesData.avgStockPrice)}`
                  ) : '-'}
                </div>
              </div>
              <div className={cx('info-wrapper')}>
                <div className={cx('info-wrapper-label')}>
                  Purchased:
                </div>
                <div className={cx('info-wrapper-value')} style={{ color: '#5cb96e' }}>
                  {checkAndRoun(sharesData.buy, 2, true, false)}
                  &nbsp;/ $
                  {checkAndRoun(valuesData.buy, 2, true, false)}
                </div>
              </div>
              <div className={cx('info-wrapper')}>
                <div className={cx('info-wrapper-label')}>
                  Sold:
                </div>
                <div className={cx('info-wrapper-value')} style={{ color: '#e35657' }}>
                  {checkAndRoun(sharesData.sell, 2, true, false)}
                  &nbsp;/ $
                  {checkAndRoun(valuesData.sell, 2, true, false)}
                </div>
              </div>
              <div className={cx('info-wrapper')}>
                <div className={cx('info-wrapper-label')}>
                  Others:
                </div>
                <div className={cx('info-wrapper-value')} style={{ color: '#666666' }}>
                  {checkAndRoun(sharesData.other, 2, true, false)}
                  &nbsp;/ $
                  {checkAndRoun(valuesData.other, 2, true, false)}
                </div>
              </div>
            </div>
            <div className={Styles.divider} />
            <h3 className={Styles.title}>
              Top Transactions
            </h3>
          </div>
          <div className={Styles.content}>
            <TransactionChartTable
              transactionsChartData={transactionsChartData}
              filterDate={sharesData.date}
            />
          </div>
        </div>
      </div>,
      document.body,
    )
  );
});

export default withComponentName(TransactionChartTableMobile);
