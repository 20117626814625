export const BASIC_SEARCH = 'BASIC_SEARCH';
export const ADVANCED_SEARCH = 'ADVANCED_SEARCH';

export const SEARCH_TYPES_LIST = [
  {
    id: BASIC_SEARCH,
    content: 'Basic Search',
  },
  {
    id: ADVANCED_SEARCH,
    content: 'Advanced Search',
  },
];

export const searchTypeListMapping = {
  [BASIC_SEARCH]: 'searchItems',
  [ADVANCED_SEARCH]: 'advancedQuerySearchItems',
};

export const searchTypeListWatchlistMapping = {
  [BASIC_SEARCH]: 'newWatchlistTopics',
  [ADVANCED_SEARCH]: 'advancedNewWatchlistTopics',
};
