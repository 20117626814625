/* eslint-disable no-new-wrappers */
import { useEffect, useMemo, memo } from 'react';

import StoryCardRow from '../../SEO_pages/CommonComponent/StoryCardLists/StoryCardRow';
import ProviderFilingRow from '../../Filing/ProviderFilings/ProviderFilingsLists/ProviderFilingRow';
import ShowButton from './ShowButton';

import { CF_URL_FOR_FINPROMPT } from '../../../data/environment';

import useFinprompt from '../../../providers/FinpromptProvider/useFinprompt';

const FilingsCards = (props) => {
  const {
    payload, scrollToBottomOnWidgetRendered, isChatbotOpened,
    history, expanded,
  } = props;

  const { components, date: messageDate } = payload;

  const { isFinpromptPages } = useFinprompt();

  const isFilingRowExtractFromWapiCall = (call = '') => {
    const stringFromWapiCall = new String(call).toString();
    return stringFromWapiCall.includes('/webapi/v1/internal?forwarding_path=/v2/filings');
  };

  const handleShowMoreClick = (assetsInfo = {}, tab = '') => {
    if (isFinpromptPages) {
      const path = `${CF_URL_FOR_FINPROMPT}/news/directory/${assetsInfo.asset_class?.slug}/${assetsInfo.slug}/${tab}`;
      window.open(path, '_blank')?.focus?.();
      return;
    }
    history.push(`/news/directory/${assetsInfo.asset_class?.slug}/${assetsInfo.slug}/${tab}`);
  };

  const filingsCards = useMemo(() => components.filter((component) => (
    component.component_name === 'filings_cards'
  )).map((component) => ({
    data: component.component_data.filings_cards,
    isFilingRow: isFilingRowExtractFromWapiCall(component.wapi_call.filings_cards),
    assetsInfo: component.component_data.assets_info?.assets[0],
  })), [components]);

  useEffect(() => {
    if (!isChatbotOpened) return;
    if (scrollToBottomOnWidgetRendered) scrollToBottomOnWidgetRendered();
  }, [isChatbotOpened]);

  if (!filingsCards?.length) return null;

  return (
    <div
      data-date={messageDate}
      className="react-chatbot-kit__widget__wrapper filings-cards"
    >
      {filingsCards.map((filingsCard, index) => {
        const showSeparatingLine = (filingsCards.length >= 2) && (filingsCard.data.length < 3)
          && (index < (filingsCards.length - 1));
        const addSeparatingLineClassname = showSeparatingLine ? 'separating-line' : '';

        if (filingsCard.isFilingRow) {
          return (
            <div
              key={`filing-row_key_wrapper_${index}`}
              className={`filing-row ${addSeparatingLineClassname}`}
            >
              {filingsCard?.data?.slice(0, 3)?.map((filing, index) => (
                <ProviderFilingRow
                  {...filing}
                  key={`filing-row_key_${filing.filingId || index}`}
                  isExpandAll={false}
                  isSource
                  isCompany={false}
                  hasBigScreenDesign
                  chatbot
                  className="filings_row--chatbot"
                  expandedChatbot={expanded}
                />
              ))}
              {filingsCard.data.length >= 3 ? (
                <ShowButton
                  expanded={expanded}
                  isChatbotOpened={isChatbotOpened}
                  handleClick={() => handleShowMoreClick(filingsCard.assetsInfo, 'filings')}
                />
              ) : null}
            </div>
          );
        }
        return (
          <div
            className={`card-row ${addSeparatingLineClassname}`}
            key={`card-row_key__wrapper_${index}`}
          >
            {filingsCard?.data?.slice(0, 3)?.map((filing, index) => (
              <StoryCardRow
                key={`card-row_key_${filing.filingId || index}`}
                {...filing}
                hasBigScreenDesign
                showCompanyTag
                isWatchlists={false}
                isSingleTopic={false}
                cardURL="/directory/investor-relations"
                chatbot
                expandedChatbot={expanded}
              />
            ))}
            {filingsCard.data.length >= 3 ? (
              <ShowButton
                expanded={expanded}
                isChatbotOpened={isChatbotOpened}
                handleClick={() => handleShowMoreClick(filingsCard.assetsInfo, 'investor-relations')}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default memo(FilingsCards);
