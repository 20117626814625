import {
  TOGGLE_EDIT_MODE,
  SET_INFO,
  TOGGLE_LOADING,
  SET_FIELD_VALUE,
  TOGGLE_SEGMENTS_LIST,
  TOGGLE_SEGMENT,
  TOGGLE_PASSWORD_MODAL,
  CHANGE_PASSWORD_VALUE,
  SET_PASSWORD_ERROR,
  SET_ACTIVE_FIELD,
  SET_ACCOUNT_DATA_ERROR,
  TOGGLE_RESET_MODAL,
  TOGGLE_PASSWORD_WAS_CHANGED_MODAL,
} from '../actions/account.actions';

const initialState = {
  editMode: false,
  initialDataLoaded: false,
  selectedAccountData: null,
  editableAccountData: null,
  accountDataErrors: {},
  loading: false,
  segments: [],
  segmentsOpened: false,
  passwordsOpened: false,
  resetModalOpened: false,
  passwordWasChangedModalOpened: false,
  passwords: {
    current_password: '',
    new_password: '',
  },
  passwordErrors: {
    current_password: null,
    new_password: null,
  },
  activeField: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_EDIT_MODE:
      return {
        ...state,
        editMode: payload,
        ...(!payload && {
          editableAccountData: { ...state.selectedAccountData },
          accountDataErrors: {},
          activeField: null,
        }),
      };
    case SET_INFO:
      return {
        ...state,
        initialDataLoaded: true,
        selectedAccountData: { ...payload.accountData },
        editableAccountData: { ...payload.accountData },
        segments: payload.segments,
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case TOGGLE_SEGMENTS_LIST:
      return {
        ...state,
        segmentsOpened: !state.segmentsOpened,
      };
    case TOGGLE_SEGMENT:
      return {
        ...state,
        segments: state.segments.map((segment) => ({
          ...segment,
          active: segment.id === payload ? !segment.active : segment.active,
        })),
      };
    case TOGGLE_PASSWORD_MODAL:
      return {
        ...state,
        passwordsOpened: !state.passwordsOpened,
        ...(state.passwordsOpened && {
          passwords: {
            current_password: '',
            new_password: '',
          },
          passwordErrors: {
            current_password: null,
            new_password: null,
          },
        }),
      };
    case CHANGE_PASSWORD_VALUE:
      return {
        ...state,
        passwords: {
          ...state.passwords,
          [payload.field]: payload.value,
        },
      };
    case SET_FIELD_VALUE:
      return {
        ...state,
        editableAccountData: {
          ...state.editableAccountData,
          [payload.field]: payload.value,
        },
      };
    case SET_PASSWORD_ERROR:
      return {
        ...state,
        passwordErrors: {
          current_password: payload.current_password || null,
          new_password: payload.new_password || null,
        },
      };
    case SET_ACTIVE_FIELD:
      return {
        ...state,
        activeField: state.activeField !== payload ? payload : null,
        editableAccountData: { ...state.selectedAccountData },
        accountDataErrors: {},
      };
    case SET_ACCOUNT_DATA_ERROR:
      return {
        ...state,
        accountDataErrors: payload,
      };
    case TOGGLE_RESET_MODAL:
      return {
        ...state,
        resetModalOpened: !state.resetModalOpened,
      };
    case TOGGLE_PASSWORD_WAS_CHANGED_MODAL:
      return {
        ...state,
        passwordWasChangedModalOpened: !state.passwordWasChangedModalOpened,
      };
    default:
      return state;
  }
};
