import { memo, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { useSelector } from 'react-redux';
import InsiderTransactionsSvc from '../../../services/dbServices/InsiderTransactionsSvc';
import PublicCompaniesSvc from '../../../services/dbServices/PublicCompaniesSvc';

import withComponentName from '../../../decorators/withComponentName';

import transactionChartTypes from '../../../data/directory/insiderTransactions/transactionChartTypes';
import timeFilter from '../../../data/directory/insiderTransactions/timeFilter';
import getInsiderTransactionTickers from '../../../helpers/getInsiderTransactionTickers';

import PeriodFilter from '../../Shared/PeriodFilter';
import TransactionChart from './TransactionChart';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const TransactionsChart = (props) => {
  const {
    identifier, chatbot, activeTimeFilter, chatbotWidgetType,
    toggleModalInsideChatbot, topic,
  } = props;

  const screenWidth = useSelector(({ common }) => (common.width));
  const expandedChatbot = useSelector(({ chatbot }) => (chatbot.expanded));
  const isChatbotOpened = useSelector(({ chatbot }) => (chatbot.isChatbotOpened));

  const [type, setType] = useState(transactionChartTypes[0].value);
  const [timePeriod, setTimePeriod] = useState(
    timeFilter.find(({ active }) => active).value,
  );
  const [
    {
      transactionsChartData,
      transactionsPricesChartData,
      loading,
      initialized,
      isError,
    },
    setTransactionsData,
  ] = useState({
    transactionsChartData: [],
    transactionsPricesChartData: [],
    loading: true,
    initialized: false,
    isError: false,
  });

  useEffect(() => {
    let mounted = true;

    if (!identifier) {
      return;
    }

    setTransactionsData((current) => ({
      transactionsChartData: current.transactionsChartData,
      transactionsPricesChartData: current.transactionsPricesChartData,
      loading: true,
      initialized: current.initialized,
      isError: false,
    }));
    const itTickers = getInsiderTransactionTickers(topic);

    Promise.all([
      InsiderTransactionsSvc.getCompanyInsiderTransactionsChartTransactions({
        identifier,
        timePeriod,
      }),
      (async () => {
        for (const ticker of itTickers) {
          const prices = await PublicCompaniesSvc.getPublicChartsSymbolData(ticker.full_name, {
            graph: timePeriod,
          });

          if (prices.length) {
            return prices;
          }
        }

        return [];
      })(),
    ]).then(([{ transactions: data, isError }, prices]) => {
      if (!mounted) {
        return;
      }

      if (isError) {
        setTransactionsData({
          transactionsChartData: [],
          transactionsPricesChartData: [],
          loading: false,
          initialized: true,
          isError,
        });
      } else if (Array.isArray(data)) {
        setTransactionsData({
          transactionsChartData: data,
          transactionsPricesChartData: Array.isArray(prices) ? prices : [],
          loading: false,
          initialized: true,
          isError: false,
        });
      }
    });

    return () => {
      mounted = false;
    };
  }, [identifier, timePeriod, chatbotWidgetType, isChatbotOpened]);

  return (
    <section className={cx('transactions-chart', { chatbot }, { 'chatbot--mobile': (isChatbotOpened && (!expandedChatbot || (screenWidth < 768))) })}>
      {!chatbot && (
        <div className={cx('transactions-chart-title-wrapper')}>
          <h2 className={cx('transactions-chart-title')}>
            Transactions and Stock Price
          </h2>
          <div className={cx('period-filter')}>
            <span className={cx('period-filter-label')}>
              Period:
            </span>
            <PeriodFilter
              initialFilters={activeTimeFilter || timeFilter}
              withoutTooltip
              onStoryFilterChange={setTimePeriod}
              optionClassName={cx('period-filter-option')}
            />
          </div>
        </div>
      )}
      <div className={cx('transactions-chart-wrapper')}>
        <div className={cx('transactions-chart-header', { 'no-data': !transactionsChartData.length })}>
          <div className={cx('transactions-chart-filters')}>
            <ul className={cx('transactions-chart-types')}>
              {transactionChartTypes.map((transactionChartType) => (
                <li
                  key={transactionChartType.value}
                  className={cx('transactions-chart-type', { active: transactionChartType.value === type })}
                  onClick={() => setType(transactionChartType.value)}
                >
                  {transactionChartType.label}
                </li>
              ))}
            </ul>
          </div>
          {chatbot && (
            <div className={cx('transactions-chart-title-wrapper')}>
              <h2 className={cx('transactions-chart-title')}>
                Transactions and Stock Price
              </h2>
              <div className={cx('period-filter')}>
                <span className={cx('period-filter-label')}>
                  Period:
                </span>
                <PeriodFilter
                  initialFilters={activeTimeFilter || timeFilter}
                  withoutTooltip
                  onStoryFilterChange={setTimePeriod}
                  optionClassName={cx('period-filter-option')}
                  periodFilterClassName={cx({ transactions_chatbot: chatbot })}
                />
              </div>
            </div>
          )}
          <div
            className={cx('transactions-chart-labels')}
          >
            {Boolean(transactionsChartData.length) && (
              <>
                <div className={cx('transactions-chart-label')}>
                  <span className={cx('point', 'box', 'green')} />
                  Buy
                </div>
                <div className={cx('transactions-chart-label')}>
                  <span className={cx('point', 'box', 'red')} />
                  Sell
                </div>
                <div className={cx('transactions-chart-label')}>
                  <span className={cx('point', 'box', 'gray')} />
                  Others
                </div>
              </>
            )}
            {Boolean(transactionsPricesChartData?.length) && (
              <div className={cx('transactions-chart-label')}>
                <span className={cx('point', 'line-point')} />
                Avg. Stock Price
              </div>
            )}
            {!chatbot && !!(transactionsChartData.length || transactionsPricesChartData?.length)
              && (
                <div className={cx('transactions-chart-label')}>
                  Currency:&nbsp;
                  <span className="font-weight-bold">USD</span>
                </div>
              )}
          </div>
        </div>
        <TransactionChart
          type={transactionChartTypes.find(({ value }) => value === type)}
          transactionsChartData={transactionsChartData}
          transactionsPricesChartData={transactionsPricesChartData}
          loading={loading}
          initialized={initialized}
          chatbot={chatbot}
          isError={isError}
          toggleModalInsideChatbot={toggleModalInsideChatbot}
        />
      </div>
    </section>
  );
};

export default memo(withComponentName(TransactionsChart));
