// helper functions for work with guest user watchlists
import uniq from 'lodash-es/uniq';

import Alert from '../components/Shared/ReactAlert';

import { parseSearchQuery, getTrendingBarAssetQuery } from './watchlistHelperFunctions';
import { buildAdvancedSearchQuery } from './searchbarHelpers';

import StorageSvc from '../services/StorageSvc';
import SearchSvc from '../services/SearchSvc';

import { BASIC_SEARCH, ADVANCED_SEARCH } from '../data/searchbar/types';

export const setLocalStorageWatchlists = (data) => {
  StorageSvc.setItem('watchlist', JSON.stringify(data));
};

export const getGuestUserWatchlists = () => {
  try {
    const localItems = StorageSvc.getItem('watchlist');
    if (localItems) {
      return JSON.parse(localItems);
    }
    return [];
  } catch (err) {
    return undefined;
  }
};

export const removeGuestUserWatchlist = (id) => new Promise((res) => {
  const watchlists = getGuestUserWatchlists();
  const newWatchlists = watchlists.filter((item) => item.id !== id).map((watchlist, i, arr) => {
    if (arr.length - 1 === i) {
      return {
        ...watchlist,
        current: true,
      };
    }
    return watchlist;
  });
  setLocalStorageWatchlists(newWatchlists);
  res(newWatchlists);
});

export const setActiveGuestUserWatchlist = (watchlistId) => new Promise((res) => {
  const watchlists = getGuestUserWatchlists();
  const newWatchlists = watchlists.map((item) => {
    if (item.id === watchlistId) {
      return { ...item, current: true };
    }
    return { ...item, current: false };
  });
  setLocalStorageWatchlists(newWatchlists);
  res(newWatchlists);
});

export const renameGuestUserWatchlist = (id, newName) => new Promise((res) => {
  const watchlists = getGuestUserWatchlists();
  const newWatchlists = [];
  watchlists.forEach((item) => {
    if (item.id === id) {
      const b = {
        ...item,
        name: newName,
      };
      newWatchlists.push(b);
    } else {
      newWatchlists.push(item);
    }
  });
  setLocalStorageWatchlists(newWatchlists);
  res(newWatchlists);
});

export const setFalse = (data) => data.map((item) => {
  if (item.current) return { ...item, current: !item.current };
  return { ...item };
});

export const getQueryType = (item) => {
  if (item?.asset_class) return (item.asset_class.title || item.asset_class);
  if (item?.attributes?.assetClassTitle) return item.attributes.assetClassTitle;
  if (item?.groups?.[0]?.groupType) return item.groups[0].groupType;
  return 'Query';
};

export const addNewQuery = (watchlistId, data, userQuery) => new Promise(async (res) => {
  const watchlists = getGuestUserWatchlists();
  const newWatchlist = await Promise.all(
    watchlists.map(async (watchlist) => {
      if (watchlistId === watchlist.id) {
        const newTopics = await Promise.all(
          data.map(async (topic) => {
            watchlist.search_queries.forEach((query, i) => {
              if (query.id === topic.id) watchlist.search_queries.splice(i, 1);
            });
            const tickers = (topic.tickers || [])
              .map(({
                tickerName, tickerCountry, defaultTicker,
                tickerPriority,
              }) => {
                if (!tickerName) return {};
                return {
                  default: defaultTicker,
                  full_name: `${tickerName}${tickerCountry ? `_${tickerCountry}` : ''}`,
                  name: tickerName,
                  priority: tickerPriority,
                };
              });
            const domains = (topic.companyDomains || [])
              .map(({ companyDomainId, companyDomainUrl }) => ({
                id: companyDomainId,
                url: companyDomainUrl,
              }));
            const slug = (topic.permalink || '').split('/').slice(-1)[0];

            const query = getTrendingBarAssetQuery(
              topic, topic?.groups?.[0]?.groupType || getQueryType(topic),
            );
            return {
              id: topic.id,
              query: topic.name,
              logical_structure: await SearchSvc.parseSearchQuery(query, [topic]),
              hidden: false,
              entity_type: getQueryType(topic),
              groups: topic.groups,
              tickers,
              legal_entities: topic.topicLegalEntities,
              company_domains: domains,
              slug,
            };
          }),
        );
        if (userQuery) {
          watchlist.search_queries.forEach((query, i) => {
            if (query.name === userQuery) watchlist.search_queries.splice(i, 1);
          });
          newTopics.push({
            id: Math.floor(Math.random() * 1_000_000),
            query: userQuery,
            logical_structure: await SearchSvc.parseSearchQuery(userQuery),
            hidden: false,
            entity_type: 'Query',
          });
        }
        return {
          ...watchlist,
          search_queries: uniq([...watchlist.search_queries, ...newTopics]),
        };
      }
      return watchlist;
    }),
  );
  setLocalStorageWatchlists(newWatchlist);
  res(newWatchlist);
});

export const deleteGuestWlQuery = (id) => new Promise((res) => {
  const watchlists = getGuestUserWatchlists();
  const newWatchlist = watchlists.map((watchlist) => {
    if (watchlist.current) {
      return {
        ...watchlist,
        search_queries: [...watchlist.search_queries.filter((wlQuery) => wlQuery.id !== id)],
      };
    }
    return watchlist;
  });
  setLocalStorageWatchlists(newWatchlist);
  res(newWatchlist);
});

export const uncheckGuestWlQuery = (id, status) => new Promise((res) => {
  const watchlists = getGuestUserWatchlists();
  const newWatchlist = watchlists.map((watchlist) => {
    if (watchlist.current) {
      const queries = watchlist.search_queries.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            hidden: status,
          };
        }
        return item;
      });
      return {
        ...watchlist,
        hiddenAll: status,
        search_queries: [...queries],
      };
    }
    return watchlist;
  });
  setLocalStorageWatchlists(newWatchlist);
  res(newWatchlist);
});

export const createGuestWatchlistFromShared = (topics, watchlistName) => new Promise((res) => {
  let watchlist;

  const newTopics = topics.map((topic) => ({
    ...parseSearchQuery(topic.query),
    hidden: false,
    entity_type: topic.entity_type,
    tickers: topic.tickers || [],
    legal_entities: topic.legal_entities || [],
    company_domains: topic.company_domains || [],
    slug: topic.slug,
  }));

  if (StorageSvc.getItem('watchlist')) {
    const localItems = StorageSvc.getItem('watchlist');
    const prevState = setFalse(JSON.parse(localItems));

    if (prevState.length <= 4) {
      watchlist = {
        id: Math.floor(Math.random() * 1000),
        current: true,
        hiddenAll: false,
        name: watchlistName || `Watchlist ${prevState.length + 1}`,
        search_queries: [...newTopics],
        watchlist_assets: [],
      };
      prevState.push(watchlist);
      StorageSvc.setItem('watchlist', JSON.stringify(prevState));
      res(watchlist);
    }
  } else {
    watchlist = [{
      id: Math.floor(Math.random() * 1000),
      name: watchlistName || 'Watchlist1',
      current: true,
      hiddenAll: false,
      search_queries: [...newTopics],
      watchlist_assets: [],
    }];
    StorageSvc.setItem('watchlist', JSON.stringify(watchlist));
    res(watchlist);
  }
});

// method for pushing guest user wathclists to localstorage
export const createGuestWatchlist = ({
  topics,
  userQuery,
  watchlistName = '',
  activeSearchType = BASIC_SEARCH,
}) => new Promise(async (res, reject) => {
  let watchlist;

  // watchlist topics creation
  let newTopics;
  switch (activeSearchType) {
    case BASIC_SEARCH: {
      newTopics = await Promise.all(
        topics.map(async (topic) => {
          const tickers = (topic.tickers || [])
            .map(({
              tickerName,
              tickerCountry,
              defaultTicker,
              tickerPriority,
            }) => {
              if (!tickerName) return {};
              return {
                default: defaultTicker,
                full_name: `${tickerName}${tickerCountry ? `_${tickerCountry}` : ''}`,
                name: tickerName,
                priority: tickerPriority,
              };
            });
          const domains = (topic.companyDomains || [])
            .map(({ companyDomainId, companyDomainUrl }) => ({
              id: companyDomainId,
              url: companyDomainUrl,
            }));
          const slug = (topic.permalink || '').split('/').slice(-1)[0];

          const query = getTrendingBarAssetQuery(
            topic, topic?.groups?.[0]?.groupType || getQueryType(topic),
          );
          return {
            id: topic.id,
            query: topic.name,
            logical_structure: await SearchSvc.parseSearchQuery(query, [topic]),
            hidden: false,
            entity_type: getQueryType(topic),
            groups: topic.groups,
            tickers,
            legal_entities: topic.topicLegalEntities,
            company_domains: domains,
            slug,
          };
        }),
      );

      if (userQuery) {
        newTopics.push({
          id: Math.floor(Math.random() * 1_000_000),
          query: userQuery,
          logical_structure: await SearchSvc.parseSearchQuery(userQuery),
          hidden: false,
          entity_type: 'Query',
        });
      }
      break;
    }
    case ADVANCED_SEARCH: {
      const query = buildAdvancedSearchQuery([
        ...topics,
        ...(userQuery ? [userQuery] : []),
      ]);

      const advancedSuperType = async () => {
        const logicalStructure = await SearchSvc.parseSearchQuery(query, topics);
        if (logicalStructure?.type === 'STRING') {
          return 'advanced_text_search';
        }
        return 'advanced_groups_search';
      };

      newTopics = [
        {
          id: Math.floor(Math.random() * 1_000_000),
          query,
          logical_structure: await SearchSvc.parseSearchQuery(query, topics),
          hidden: false,
          entity_type: 'Query',
          query_supertype: await advancedSuperType(),
        },
      ];
      break;
    }
    default:
      reject(new Error('Unknown searchbar type'));
  }

  // checking if we have watchlist or it will be the first one
  const localItems = StorageSvc.getItem('watchlist');
  if (localItems) {
    const prevState = setFalse(JSON.parse(localItems));
    const prevWatchlists = JSON.parse(localItems);

    // check if there is wl with the same name
    const duplicateWl = prevWatchlists.find(({ name }) => watchlistName === name);

    // if new wl name is duplicate of current one, we add query to old one
    if (duplicateWl) {
      addNewQuery(duplicateWl.id, topics, userQuery);
      StorageSvc.setItem('watchlist', JSON.stringify(prevWatchlists));
      res(prevWatchlists);
      return;
    }

    if (prevState.length <= 2) {
      const watchlistNumbers = prevState.map((watchlist) => parseInt(watchlist.name.slice(-1), 10));
      let newWlNumber;

      if (watchlistNumbers.includes(NaN)) {
        const hasOneNumber = watchlistNumbers.some((wlNumber) => typeof wlNumber === 'number');
        if (hasOneNumber) {
          for (let i = 3; i >= 1; i -= 1) {
            if (!watchlistNumbers.includes(i)) {
              newWlNumber = i;
            }
          }
        } else {
          newWlNumber = prevState.length + 1;
        }
      } else {
        for (let i = 3; i >= 1; i -= 1) {
          if (!watchlistNumbers.includes(i)) {
            newWlNumber = i;
          }
        }
      }

      watchlist = {
        id: Math.floor(Math.random() * 1000),
        current: true,
        hiddenAll: false,
        name: watchlistName || `Watchlist${newWlNumber}`,
        search_queries: [...newTopics],
        watchlist_assets: [],
      };
      prevState.push(watchlist);
      StorageSvc.setItem('watchlist', JSON.stringify(prevState));
      res(watchlist);
    } else {
      reject(new Error('Please register to create more than 3 watchlists'));
      return Alert.info('Please register to create more than 3 watchlists', {
        position: 'top',
        effect: 'stackslide',
        timeout: 3000,
      });
    }
  } else {
    watchlist = [{
      id: Math.floor(Math.random() * 1000),
      name: watchlistName || 'Watchlist1',
      current: true,
      hiddenAll: false,
      search_queries: [...newTopics],
      watchlist_assets: [],
    }];
    StorageSvc.setItem('watchlist', JSON.stringify(watchlist));
    res(watchlist);
  }
});

export const unselectAllGuestAssets = (value) => new Promise((res) => {
  const watchlists = getGuestUserWatchlists();

  const newWatchlist = watchlists.map((watchlist) => {
    if (watchlist.current) {
      watchlist.hiddenAll = value;
      const queries = watchlist.search_queries.map((item) => ({
        ...item,
        hidden: value,
      }));
      return {
        ...watchlist,
        search_queries: [...queries],
      };
    }
    return watchlist;
  });
  setLocalStorageWatchlists(newWatchlist);
  res(newWatchlist);
});
