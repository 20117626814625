// custom `withRouter` HOC that will forward ref
import { forwardRef } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

export default (Component) => {
  const WithRouter = forwardRef((props, ref) => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();

    return (
      <Component
        ref={ref}
        history={history}
        location={location}
        match={match}
        {...props}
      />
    );
  });

  WithRouter.displayName = Component.name || Component.displayName;

  return WithRouter;
};
