import PressSvc from '../services/dbServices/PressSvc';

export const SET_ARTICLES = 'SET_ARTICLES';

export const setArticles = (articles) => ({
  type: SET_ARTICLES,
  payload: articles,
});

export const getPressArticles = (options = {}) => (
  async (dispatch) => {
    const articles = await PressSvc.getPressArticles(options);
    dispatch(setArticles(articles));
  }
);
