import { memo, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import withComponentName from '../../../decorators/withComponentName';

import Tooltip from '../../Tooltip';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const PeriodFilter = ({
  onStoryFilterChange,
  label,
  initialFilters = null,
  withoutTooltip = false,
  optionClassName = '',
  periodFilterClassName = '',
}) => {
  const [storyPeriodFilters, setStoryPeriodFilters] = useState(initialFilters);

  useEffect(() => {
    onStoryFilterChange(storyPeriodFilters.find(({ active }) => active)?.value);
  }, []);

  const setStoriesActiveFilter = (value) => {
    setStoryPeriodFilters((currentFilters) => (
      currentFilters.map((filter) => ({
        ...filter,
        active: filter.value === value,
      }))
    ));
    onStoryFilterChange(value);
  };

  return (
    <div className={cx('period-filter', periodFilterClassName)}>
      {label && (
        <span className={cx('period-filter-label')}>
          {label}
        </span>
      )}
      <ul className={cx('period-filter-options')}>
        {storyPeriodFilters.map((filter) => (
          <li
            key={filter.value}
            className={cx('period-filter-option', optionClassName, { active: filter.active })}
            onClick={() => setStoriesActiveFilter(filter.value)}
          >
            <Tooltip
              icon={filter.text}
              className={cx('period-filter-tooltip')}
              disableOnMobile
              disabled={withoutTooltip}
            >
              {filter.tooltip}
            </Tooltip>
          </li>
        ))}
      </ul>
    </div>
  );
};

PeriodFilter.propTypes = {
  onStoryFilterChange: PropTypes.func,
  label: PropTypes.string,
};

PeriodFilter.defaultProps = {
  onStoryFilterChange() {},
  label: null,
};

export default memo(withComponentName(PeriodFilter));
