export const TRANSLATIONS = 'translations';
export const NO_ADVERTISING = 'no_advertising';
export const OFFLINE_READING = 'offline_reading';
export const PUSH_NOTIFICATIONS = 'push_notifications';
export const SUMMARISATION = 'summarization';
export const IR = 'ir';
export const REFRESH_FILTER = 'refresh_filter';
export const CUSTOM_TIME_FILTER = 'custom_time_filter';
export const CONTENT_SOURCE_FILTER = 'content_source_filter';
export const LOCATION_FILTER = 'location_filter';
export const SENTIMENTS_FILTER = 'sentiments_filter';
export const ESG = 'esg';
export const INSIDER_TX_VALUE_SORTING = 'insidertx_value_sorting';
export const UK_INSIGHTS = 'uk-insights';
export const UK_INSIGHTS_TRIAL = 'uk-insights-trial';
export const MANAGE_SMART_WATCHLIST = 'manage_smart_watchlists';
export const CSV_EXPORT = 'csv-export';
