import { createChatBotMessage } from 'react-chatbot-kit';

// custom components
import UserAvatar from '../CustomComponents/UserAvatar';
import BotAvatar from '../CustomComponents/BotAvatar';
import MyCustomChatMessage from '../CustomComponents/MyCustomChatMessage';
import MyCustomUserChatMessage from '../CustomComponents/MyCustomUserChatMessage';

// custom messages
import Loader from '../CustomMessages/Loader';

// widgets
import Stories from '../widgets/Stories';
import Options from '../widgets/Options';
import WatchlistsCards from '../widgets/WatchlistsCards';
import BookmarkStories from '../widgets/BookmarkStories';
import TrendingTopics from '../widgets/TrendingTopics';
import PriceChart from '../widgets/PriceChart';
import DefaultFallback from '../widgets/DefaultFallback';
import YesNoButton from '../widgets/YesNoButton';
import NumberDataTile from '../widgets/NumberDataTile';
import FilingsCards from '../widgets/FilingsCards';
import Sentiments from '../widgets/Sentiments';
import KeyHeadlines from '../widgets/KeyHeadlines';
import Exit from '../widgets/Exit';
import GenericComponent from '../widgets/GenericComponent';

// decorator
import ErrorBoundaryClass from '../chatbot/ErrorBoundary';

const config = {
  initialMessages: [
    createChatBotMessage({
      text: 'Ask me to do ANYTHING on our site. Here are some suggestions, or make your own request.',
      date: new Date(),
      showDate: true,
      initialMessage: true,
    },
    {
      widget: 'options',
      payload: { date: new Date() },
    }),
  ],
  customMessages: {
    loader: (props) => <Loader {...props} />,
  },
  customComponents: {
    botAvatar: (props) => <BotAvatar {...props} />,
    botChatMessage: (props) => <MyCustomChatMessage {...props} />,
    userAvatar: (props) => <UserAvatar {...props} />,
    userChatMessage: (props) => <MyCustomUserChatMessage {...props} />,
  },
  widgets: [
    {
      widgetName: 'options',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <Options
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'action_default_fallback',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <DefaultFallback
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'watchlistlist',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <WatchlistsCards
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'bookmarkread',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <BookmarkStories
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'newsforwatchlist',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <Stories
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'topicsbysentiment',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <Stories
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'watchlisttopicslist',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <Stories
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'infoitemsearch',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <Stories
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'trendingassetsfind',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <TrendingTopics
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getprice',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <PriceChart
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getpricechange',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <PriceChart
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getpricechart',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <PriceChart
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getinsidertransactions',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <PriceChart
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'watchlistcreate',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <YesNoButton
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'watchlistdelete',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <YesNoButton
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'voicechange',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <YesNoButton
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getcapitalization',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <NumberDataTile
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getfilings',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <FilingsCards
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getpressreleases',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <FilingsCards
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getfinancialstatements',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <FilingsCards
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getmanagementletters',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <FilingsCards
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getquarterlyreports',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <FilingsCards
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getearningscalls&transcripts',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <FilingsCards
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getinvestorrelations',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <FilingsCards
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getannualreports',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <FilingsCards
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getnewssummary',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <KeyHeadlines
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'getsentimenttrend',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <Sentiments
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'exit',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <Exit
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
    {
      widgetName: 'generic_component',
      widgetFunc: (props) => (
        <ErrorBoundaryClass>
          <GenericComponent
            {...props}
          />
        </ErrorBoundaryClass>
      ),
    },
  ],
};

export default config;
