import { SHOW_PROMO, HIDE_PROMO } from '../actions/promoBarActions';

const initialPromo = {
  isShowed: false,
  height: 0,
  promobarType: '',
};

export default (state = initialPromo, { type, payload }) => {
  switch (type) {
    case SHOW_PROMO:
      return {
        isShowed: true,
        height: payload.height,
        promobarType: payload.promobarType,
      };
    case HIDE_PROMO:
      return {
        isShowed: false,
        height: 0,
        promobarType: payload.promobarType,
      };
    default:
      return state;
  }
};
