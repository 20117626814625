import StoriesSvc from '../StoriesSvc';

import { getRecommendedStoriesRequestParams } from './helperMethods';

import { queryItemWatchlistParser } from '../../helpers/watchlistHelperFunctions';

export const loadStoriesWithQuotes = async (
  timeFilter,
  hasTwitterAccess,
  queries,
  translation,
  similarStories,
  sentimentLevels,
) => {
  const query = queries.map((item) => {
    // return name for seo topics
    if (!item.query && !item.name) return item;
    // name for watchlist topic parsed from topic query
    const { name } = item.query ? queryItemWatchlistParser(item.query) : item;

    if (name.match(/\b(?:AND|OR|NOT)\b/g) !== null) return `(${name.replace(/(.*?)(\s+(?:AND|OR|NOT)\s+)(.*)/, '"$1"$2"$3"')})`;

    return `"${name}"`;
  }).join(' OR ');

  const storyParams = {
    ...getRecommendedStoriesRequestParams(
      timeFilter,
      hasTwitterAccess,
      undefined,
      undefined,
      undefined,
      similarStories,
      sentimentLevels,
    ),
    query,
    translate_to: translation || undefined,
    with_sentiments: true,
  };

  const data = await StoriesSvc.getStoriesData(storyParams, { callCurationActions: true });
  return data;
};

// TODO when it will be possible to get asset classes from wl topics
export const loadAssetClassStories = (activeWatchlistTopics, filters) => {
  const assetClasses = [];
};

// TODO when will be asset classes
export const loadOriginalTimePeriodStories = (
  hasTwitterAccess, timePeriodValues, query, similarStories, sentimentLevels,
) => new Promise((resolve) => {
  let timeFilter;

  switch (timePeriodValues) {
    case 'custom':
      timeFilter = {
        start_datetime: timePeriodValues.startDatetime,
        end_datetime: timePeriodValues.endDatetime,
        selected_time_filter: 'custom',
      };
      break;
    case 'time_period':
      timeFilter = {
        selected_time_filter: 'time_period',
        time_period: timePeriodValues.timePeriod,
      };
      break;
    default:
      timeFilter = {
        selected_time_filter: 'predefined',
        time_filter: timePeriodValues.timeFilter,
      };
      break;
  }

  const { name } = query ? queryItemWatchlistParser(query) : {};

  const storyParams = {
    categories: hasTwitterAccess ? 'mp,op,r,c,i,e' : 'mp,op,r',
    order_by: 'latest',
    all_languages: true,
    min_score: 5,
    asset_classes: '',
    ...timeFilter,
    similarStories,
    query: `"${name}"`,
    sentiment_levels: sentimentLevels,
    with_sentiments: true,
  };

  const data = StoriesSvc.getStoriesData(storyParams, { callCurationActions: true });
  return resolve(data);
});

export const loadAllAssetSectorsEventsStories = (
  timeFilter,
  hasTwitterAccess,
  translation,
  similarStories,
  sentimentLevels,
) => new Promise((resolve) => {
  const storyParams = {
    ...getRecommendedStoriesRequestParams(
      timeFilter,
      hasTwitterAccess,
      undefined,
      undefined,
      undefined,
      similarStories,
    ),
    end_datetime: undefined,
    start_datetime: undefined,
    order_by: 'top',
    sectors: '',
    asset_classes: '',
    events: '',
    time_filter: timeFilter,
    translate_to: translation || undefined,
    sentiment_levels: sentimentLevels,
    with_sentiments: true,
  };

  const data = StoriesSvc.getStoriesData(storyParams, { callCurationActions: true });
  return resolve(data);
});

export const loadStoriesWithoutQuotes = (
  timeFilter,
  hasTwitterAccess,
  query,
  translation,
  similarStories,
  sentimentLevels,
) => {
  const convertedQuery = query.replace(/"/g, '');

  return new Promise((resolve) => {
    const storyParams = {
      ...getRecommendedStoriesRequestParams(
        timeFilter,
        hasTwitterAccess,
        undefined,
        undefined,
        undefined,
        similarStories,
        sentimentLevels,
      ),
      query: convertedQuery,
      translate_to: translation || undefined,
      with_sentiments: true,
    };

    const data = StoriesSvc.getStoriesData(storyParams, { callCurationActions: true });
    return resolve(data);
  });
};

export const loadAllPremiumStories = (
  timeFilter,
  hasTwitterAccess,
  translation,
  similarStories,
  sentimentLevels,
  whiteListId,
  isMajorOnly,
) => new Promise((resolve) => {
  const storyParams = {
    ...getRecommendedStoriesRequestParams(
      timeFilter,
      hasTwitterAccess,
      undefined,
      undefined,
      undefined,
      similarStories,
    ),
    end_datetime: undefined,
    start_datetime: undefined,
    order_by: 'top',
    sectors: '',
    asset_classes: '',
    events: '',
    time_filter: timeFilter,
    translate_to: translation || undefined,
    sentiment_levels: sentimentLevels,
    with_sentiments: true,
  };

  if (whiteListId) storyParams.whitelisted_domain_ids = whiteListId;
  if (isMajorOnly) storyParams.categories = storyParams.categories.split(',').filter((category) => category === 'mp').join(',');
  const data = StoriesSvc.getStoriesData(storyParams, { callCurationActions: true });
  return resolve(data);
});
