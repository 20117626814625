/* eslint-disable prefer-destructuring */
/* eslint-disable react/sort-comp */
import { PureComponent, createRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash-es/debounce';
import isEqual from 'lodash-es/isEqual';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { isMobile, isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import ResizeObserver from 'resize-observer-polyfill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompressAlt } from '@fortawesome/pro-regular-svg-icons/faCompressAlt';
import { faExpandAlt } from '@fortawesome/pro-regular-svg-icons/faExpandAlt';
import { faSortUp } from '@fortawesome/pro-solid-svg-icons/faSortUp';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown';

import {
  COMMODITIES, CRYPTOCURRENCIES, FOREIGN_EXCHANGE, INDICES,
} from '../../../data/directory/topic_classes/topicClassTypes';
import { PRICE_DATA_TIMER } from '../../../data/directory/constants';
import {
  ADVANCED,
  BASIC,
  ONE_DAY,
  THREE_MONTH,
  ONE_YEAR,
  ALL,
  GRAPH_TIME_FILTERS,
  GRAPH_TYPE_FILTERS,
  GRAPH_TIME_FILTERS_FULL_TEXT,
  FIVE_YEAR,
} from '../../../data/directory/topic_classes/ChartFilters';
import { hoverChartMarkerData, hoverChartLineData } from '../../../data/sentimentAnalysis';

import {
  preparePriceGraph,
  checkAndRoun,
  getRanges,
  optimizePoints,
  getCorrectMarginRight,
} from '../../../helpers/topicsHelpers';
import { roundTo } from '../../../helpers/helpers';
import { stringToBool } from '../../../helpers/commonHelpers';
import { getChartRangeDates } from '../../../helpers/chartHelpers';
import {
  getActivePriorityTicker,
  fetchTopicFullDetails,
  convertFullDetails,
  fetchTopicChartData,
} from '../../../helpers/topicsApiHelpers';
import isMarketTime from '../../../helpers/isMarketTime';

import withComponentName from '../../../decorators/withComponentName';
import withRouter from '../../../decorators/withRouter';
import withFinprompt from '../../../decorators/withFinprompt';

import { closeChatbotWindow } from '../../../actions/chatbot.actions';

import StorageSvc from '../../../services/StorageSvc';
import StockExchangesSvc from '../../../services/dbServices/StockExchangesSvc';

import NoDataMessage from '../NoDataMessage';
import Preloader from '../../Preloader';
import Plot from '../../Plot';
import StoriesModal from './StoriesModal';
import TradingViewWidget from '../TradingViewWidget';
import ChartTimeFilters from '../ChartFilters/ChartTimeFilters';
import ItemsScrollBar from '../../Shared/ItemsScrollBar';
import PlayVideoComponent from '../../PlayVideoComponent';

import Styles from './styles.module.scss';
import { CF_URL_FOR_FINPROMPT } from '../../../data/environment';

const cx = classNames.bind(Styles);

const CHART_STORIES_EXPANDED_KEY = 'chartStoriesExpanded';
const chartConfig = { responsive: true };

export class Charts extends PureComponent {
  constructor(props) {
    super(props);
    autoBind(this);

    const {
      onChartStories, slug, chatbot,
      activeTimeFilterChatbot,
    } = props;
    this.state = {
      stats: null,
      loading: true,
      firstLoading: true,
      layout: null,
      data: null,
      priceTopPosition: 0,
      topicTickerArr: getActivePriorityTicker(slug?.tickers)
        .map((obj, i) => ({
          ...obj,
          active: i === 0,
        })),
      tradingTickersArr: slug?.trading_view_ticker ? (
        slug.trading_view_ticker.split(',').map((ticker, i) => ({
          id: ticker,
          tradingTicker: ticker,
          content: ticker.split(':').pop().trim(),
          active: i === 0,
        }))
      ) : [],
      selectedPoint: {
        visible: false,
        price: 0,
        date: null,
        x: 0,
        y: 0,
        showStories: !chatbot && onChartStories ? (
          stringToBool(StorageSvc.getItem(CHART_STORIES_EXPANDED_KEY)) ?? true
        ) : false,
      },
      wraperWidth: 0,
      chartFilters: {
        filter: activeTimeFilterChatbot || '1m',
        annotationsEnabled: false,
        type: BASIC,
      },
    };

    this.mainTicker = null;
    this.dataUpdateTimer = null;
    this.plot = createRef();
    this.storiesModal = createRef();
    this.plotWrapper = createRef();
    this.onPageHover = debounce(this.onPageHover, 100);
    this.cfMainWrapper = null;
    this.currentSlug = null;
    this.initialModalOpened = false;
    this.initialModalClosed = false;
    this.hoverEventHandled = false;
    this.onMouseEventAdded = false;
    this.chatbotWrapper = createRef(null);
    this.chartsWraper = createRef(null);
    this.plotWrappGradientRightMargin = createRef(null);
  }

  async componentDidMount() {
    const {
      onDataInit, selector,
      isCryptoSummary, slug,
      webhookData, chatbot,
    } = this.props;

    this.cfMainWrapper = document.querySelector('.cf-main-wrapper');
    if (!webhookData) this.fetchTopicFullDetails();
    const stats = await this.getStats({ firstTime: true });
    this.getActualData();

    if (typeof onDataInit === 'function') onDataInit(stats);
    this.dataUpdateTimer = setInterval(this.getStats, PRICE_DATA_TIMER);

    const { topicTickerArr } = this.state;
    const isTopic = !!slug?.slug;
    if (!isCryptoSummary
      && ((!isTopic && !selector.some(Boolean)) || (isTopic && !topicTickerArr.length))
    ) {
      this.setChartType(ADVANCED);
    }

    setTimeout(() => {
      this.setState({
        wraperWidth: this.chartsWraper?.current?.clientWidth || 0,
      }, () => {
        if (!chatbot) {
          this.getStats({ firstTime: true });
        }
      });
    }, !chatbot ? 800 : 0);
    window.addEventListener('resize', this.onResize, { passive: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      chartFilters: {
        filter: prevTimeFilter,
        type: prevType,
      },
    } = prevState;
    const {
      selector: prevSelector,
      width: prevWidth,
    } = prevProps;

    const {
      chartFilters: {
        filter: currentTimeFilter,
        type: currentType,
      },
    } = this.state;
    const {
      selector: currentSelector,
      width: currentWidth,
      slug, chatbot, isChatbotOpened,
      screenWidth, chatbotWidgetType,
    } = this.props;

    if (!isEqual(prevSelector, currentSelector)) {
      this.mainTicker = null;
      this.onStoriesModalClose();
      this.fetchTopicFullDetails();
      this.getStats({ updateCall: true });
      return;
    }
    if (prevTimeFilter !== currentTimeFilter) {
      this.onStoriesModalClose();
      return this.getStats({ updateCall: true });
    }
    if (prevWidth !== currentWidth
      || prevType !== currentType
      || prevProps.chatbotWidgetType !== chatbotWidgetType) {
      this.setState({
        wraperWidth: this.chartsWraper.current?.clientWidth,
      }, () => {
        this.getActualData();
      });
    }

    if (slug && !isEqual(this.currentSlug, slug)) {
      this.currentSlug = slug;
      this.setState({
        tradingTickersArr: slug.trading_view_ticker
          ? slug.trading_view_ticker.split(',').map((ticker, i) => ({
            id: ticker,
            tradingTicker: ticker,
            content: ticker.split(':').pop().trim(),
            active: i === 0,
          })) : [],
        topicTickerArr: getActivePriorityTicker(slug?.tickers)
          .map((obj, i) => ({
            ...obj,
            active: i === 0,
          })),
      }, () => {
        const { topicTickerArr } = this.state;
        const {
          selector,
          isCryptoSummary, slug,
        } = this.props;
        const isTopic = !!slug?.slug;
        if (!isCryptoSummary
          && ((!isTopic && !selector.some(Boolean)) || (isTopic && !topicTickerArr.length))
        ) {
          this.setChartType(ADVANCED);
        } else {
          this.setChartType(BASIC);
        }
        this.fetchTopicFullDetails();
        this.getStats({ firstTime: chatbot });
      });
    }
    if (prevProps.isChatbotOpened !== isChatbotOpened) {
      if (screenWidth < 1025) return;

      if (this.chartsWraper.current) {
        setTimeout(() => {
          this.setState({
            wraperWidth: this.chartsWraper.current.clientWidth,
          }, () => {
            if (!chatbot) this.getStats({ firstTime: true });
          });
        }, 900);
      }
    }
    if (chatbot && isChatbotOpened) {
      if (!this.chatbotWrapper.current) this.chatbotWrapper.current = document.getElementsByClassName('widget__inner-wrapper')[0];

      if (this.chatbotWrapper.current && !this.onMouseEventAdded) {
        this.chatbotWrapper.current.addEventListener('mouseleave', this.handleMouseEvent);
        this.chatbotWrapper.current.addEventListener('scroll', this.closeStoriesModalOnscroll);
        this.onMouseEventAdded = true;
      }
    }
  }

  componentWillUnmount() {
    const { chatbot } = this.props;
    clearInterval(this.dataUpdateTimer);
    this.onStoriesModalClose(false);
    window.removeEventListener('resize', this.onResize);
    if (this?.cfMainWrapper?.firstElementChild) {
      const yieldContainer = this.cfMainWrapper.firstElementChild;
      yieldContainer.style.minHeight = 'unset';
    }

    if (chatbot) {
      if (this.chatbotWrapper.current) {
        this.chatbotWrapper?.current?.removeEventListener('mouseleave', this.handleMouseEvent);
        this.chatbotWrapper.current.removeEventListener('scroll', this.closeStoriesModalOnscroll);
        this.onMouseEventAdded = false;
      }
    }
  }

  handleChartExpandClick() {
    const { onChartExpandClick } = this.props;
    if (onChartExpandClick) {
      onChartExpandClick();
    }
  }

  handleChartCollapseClick() {
    const { onChartCollapseClick } = this.props;
    if (onChartCollapseClick) {
      onChartCollapseClick();
    }
  }

  handleMouseEvent(e) {
    const { selectedPoint } = this.state;

    if (!this.storiesModal?.current?.contains(e.toElement) && selectedPoint.visible) {
      this.onStoriesModalClose();
    }
  }

  closeStoriesModalOnscroll() {
    const { chatbot, isChatbotOpened } = this.props;
    const { selectedPoint } = this.state;

    if (chatbot && isChatbotOpened && selectedPoint.visible) {
      this.onStoriesModalClose();
    }
  }

  onResize() {
    const {
      stats,
    } = this.state;
    const dataAvailable = stats && stats.length > 1;
    if (this.plot.current && this.plotWrapper.current && dataAvailable) {
      const priceTopPosition = this.calculatePoint();
      this.setState({
        priceTopPosition,
      });
    }
    if (this.chartsWraper?.current) {
      this.setState({ wraperWidth: this.chartsWraper?.current?.clientWidth || 0 });
    }
  }

  onPageHover(e) {
    if (!this.plot.current) return;

    const {
      current: {
        plot: {
          current: plot,
        },
      },
    } = this.plot;
    const { current: storiesModal } = this.storiesModal;
    const { similarStoriesModalOpened, storyImageOpened } = this.props;

    if (similarStoriesModalOpened || storyImageOpened) return;

    if (!plot.contains(e?.target) && storiesModal && !storiesModal.contains(e?.target)) {
      this.onStoriesModalClose();
      this.cfMainWrapper.firstElementChild.style.removeProperty('min-height');
    }
  }

  onChartLoad() {
    const { chartData } = this.state;
    const { chatbot, isChatbotOpened } = this.props;

    if (!chartData?.length || isMobile || chatbot
      || this.initialModalOpened || (!chatbot && isChatbotOpened)) {
      return;
    }

    this.openInitialModal();
    const resizeObserver = new ResizeObserver(() => {
      if (this.initialModalClosed) {
        resizeObserver.disconnect();
        return;
      }
      this.openInitialModal();
    });
    resizeObserver.observe(this.cfMainWrapper);
  }

  onChartHover(e, handleHoverEvent = true) {
    const { toggleModalInsideChatbot } = this.props;
    const fullDetails = e.points[0].data;
    if (fullDetails?.name !== 'main_line' && fullDetails?.name !== 'sub_bar' && handleHoverEvent) return;
    const {
      data,
      selectedPoint,
      stats,
      chartFilters: {
        filter: activeTimeFilter,
      },
    } = this.state;
    const {
      isExpanded, screenWidth, hasBigScreenDesign, chatbot, isChatbotOpened, closeChatbotWindow,
      expandedChatbot, searchBarActive,
    } = this.props;

    if (chatbot && (!expandedChatbot || searchBarActive)) return;
    if (chatbot) toggleModalInsideChatbot({ displayed: true, type: 'priceChart' });

    const { pointIndex } = e.points[0];
    const xPoint = e.points[0].x;
    const yPoint = e.points[0].y;
    const currentDate = stats[pointIndex]?.last_updated;
    const date = moment(new Date(currentDate)).utc().format('YYYY-MM-DD HH:mm');

    const helperParams = {
      currentDate,
      prevDate: (stats[pointIndex - 1] || {}).last_updated,
      nextDate: (stats[pointIndex + 1] || {}).last_updated,
    };
    const { startDate, endDate } = activeTimeFilter === ONE_YEAR
      || activeTimeFilter === FIVE_YEAR || activeTimeFilter === ALL
      ? getChartRangeDates(helperParams) : {};

    const { pageX } = (
      e.event?.changedTouches?.[0]
      || e.event
    );

    let x;
    const isBig = hasBigScreenDesign && screenWidth >= 2800;
    const chartWidth = isBig ? 1390 : 830;
    if (!selectedPoint.showStories) {
      x = pageX + 170 <= screenWidth ? (
        pageX + 10
      ) : (
        screenWidth - 180
      );
    } else if (screenWidth > chartWidth) {
      x = pageX + chartWidth <= screenWidth ? (
        pageX + 10
      ) : (
        screenWidth - chartWidth - 10
      );
    } else {
      x = 10;
    }

    const chartHeight = ((this.plotWrapper.current || {}).clientHeight || 758) / 2;
    const height = isExpanded && screenWidth > 1023 && chartHeight > 280
      ? chartHeight : 280;

    const offset = this.plotWrapper.current?.getBoundingClientRect().top;
    const offsetTop = window.pageYOffset + offset;

    this.setState((state) => ({
      selectedPoint: {
        ...state.selectedPoint,
        visible: true,
        date,
        price: data.y[pointIndex],
        x,
        y: offsetTop + height,
        startDate,
        endDate,
        xPoint,
        yPoint,
        chartPageX: pageX,
        showLine: fullDetails?.name === 'main_line' || !handleHoverEvent,
      },
    }));
    if (!this.hoverEventHandled) {
      document.addEventListener(
        !isMobile || !handleHoverEvent ? 'mousemove' : 'click',
        this.onPageHover,
      );
      this.hoverEventHandled = true;
    }
    if (!chatbot && isChatbotOpened) {
      if (closeChatbotWindow) closeChatbotWindow();
    }
  }

  onStoriesModalClose(updateState = true) {
    const {
      chatbot, expandedChatbot,
      toggleModalInsideChatbot,
    } = this.props;

    if (updateState) {
      this.setState(({ selectedPoint }) => ({
        selectedPoint: {
          ...selectedPoint,
          visible: false,
        },
      }));
    }
    if (this.hoverEventHandled) {
      document.removeEventListener(
        !isMobile ? 'mousemove' : 'click',
        this.onPageHover,
      );
      this.hoverEventHandled = false;
    }
    if (this.initialModalOpened) {
      this.initialModalClosed = true;
    }
    if (chatbot && expandedChatbot) {
      toggleModalInsideChatbot({ displayed: false, type: '' });
    }
  }

  onChartStoriesLoad() {
    if (isMobileOnly) return;

    setTimeout(() => {
      const { current: storiesModal } = this.storiesModal;
      if (!storiesModal) return;

      const { selectedPoint } = this.state;
      const yieldContainer = this.cfMainWrapper.firstElementChild;
      const footer = this.cfMainWrapper.children[1];
      yieldContainer.style.minHeight = `${storiesModal.clientHeight + selectedPoint.y - (footer?.clientHeight)}px`;
    });
  }

  onChartStoriesLoadStart() {
    if (this.cfMainWrapper) {
      this.cfMainWrapper.firstElementChild.style.removeProperty('min-height');
    }
  }

  setActiveTicker(id) {
    const { chartFilters: { type: activeChartType } } = this.state;

    if (activeChartType === 'ADVANCED') {
      this.setState(({ tradingTickersArr }) => ({
        tradingTickersArr: tradingTickersArr.map((ticker) => ({
          ...ticker,
          active: id === ticker.id,
        })),
      }));
    } else {
      this.setState(({ topicTickerArr }) => ({
        topicTickerArr: topicTickerArr.map((ticker) => ({
          ...ticker,
          active: id === ticker.id,
        })),
      }), () => {
        this.getStats({ updateCall: true });
        this.fetchTopicFullDetails();
      });
    }
  }

  setShowStories(status, e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }

    let { selectedPoint: { x: pageX } } = this.state;
    const { selectedPoint: { chartPageX } } = this.state;
    pageX -= 10;
    const { screenWidth } = this.props;

    let x = null;
    if (status) {
      if (screenWidth > 830) {
        x = pageX + 830 <= screenWidth ? (
          pageX + 10
        ) : (
          screenWidth - 820
        );
      } else {
        x = 10;
      }
    } else {
      x = chartPageX + 170 <= screenWidth ? (
        chartPageX + 10
      ) : (
        screenWidth - 180
      );
    }

    this.setState(({ selectedPoint }) => ({
      selectedPoint: {
        ...selectedPoint,
        showStories: status,
        ...(x && { x }),
      },
    }));
    StorageSvc.setItem(CHART_STORIES_EXPANDED_KEY, String(status));
  }

  get MAX_TOP_POSITION() {
    const { isExpanded, hasBigScreenDesign, screenWidth } = this.props;
    const isBig = hasBigScreenDesign && screenWidth >= 2800;
    let chartHeight = screenWidth <= 1024 ? 355 : 380;
    if (isBig) {
      chartHeight = 966;
    }
    if (this.plotWrapper.current && isExpanded) {
      let chartBottomSpace = screenWidth <= 1024 ? 75 : 50;
      if (isBig) {
        chartBottomSpace = 100;
      }
      const divHeight = this.plotWrapper.current.clientHeight;
      return divHeight ? ((divHeight - chartBottomSpace)) : chartHeight;
    }
    return chartHeight;
  }

  getCoords(elem) {
    const box = elem.getBoundingClientRect();

    const { body } = document;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  }

  async getStats(extraProps) {
    const { firstTime, updateCall } = extraProps || {};
    const {
      assetType,
      onLoadingToggle,
      selector,
      minigraph,
      slug,
      isCryptoSummary,
      webhookData,
    } = this.props;
    const {
      topicTickerArr,
      chartFilters: { filter: activeTimeFilter },
    } = this.state;

    const isTopic = !!slug?.slug;
    if (!isCryptoSummary
      && ((!isTopic && !selector.some(Boolean)) || (isTopic && !topicTickerArr.length))
    ) {
      this.setState({
        loading: false,
        firstLoading: false,
      });
      return;
    }
    this.setState({
      loading: !!updateCall,
      firstLoading: !!firstTime,
    });
    onLoadingToggle(true);

    const selectors = topicTickerArr.find((f) => f.active) || topicTickerArr[0];
    let currentSymbol;
    if (topicTickerArr.length) {
      if (assetType === CRYPTOCURRENCIES) {
        currentSymbol = [selectors.name];
      } else {
        currentSymbol = [selectors.full_name];
      }
    } else {
      currentSymbol = this.mainTicker ? [this.mainTicker] : selector;
    }

    const params = {
      graph: activeTimeFilter.toLowerCase(),
      minigraph,
    };

    if (assetType === COMMODITIES || assetType === INDICES || assetType === FOREIGN_EXCHANGE) {
      params.identifiers = `TR:${selectors.name}`;
    } else {
      params.selectors = currentSymbol;
    }

    const stockExchangeMarketHours = selectors?.stock_exchange_id ? (
      await StockExchangesSvc.getStockExchangeMarketHours(
        selectors.stock_exchange_id,
      )
    ) : null;
    if (stockExchangeMarketHours) {
      params.market_hours = isMarketTime(stockExchangeMarketHours);
    }

    const { stats, error, percentChange } = await fetchTopicChartData(assetType, params);

    this.mainTicker = topicTickerArr[0] || selector;

    const { chartFilters: { filter: currentFilter } } = this.state;
    // if filter changed while response comes
    if (activeTimeFilter !== currentFilter) return;

    const dataStats = stats || webhookData || [];

    this.setState({
      stats: dataStats.length > 1000 ? optimizePoints(dataStats, currentFilter) : dataStats,
      loading: false,
      firstLoading: false,
      hasChartError: error,
      percentChange,
    }, () => {
      if (!firstTime) {
        this.getActualData();
      }
    });
    onLoadingToggle(false);

    return stats;
  }

  async fetchTopicFullDetails() {
    const {
      assetType, selector, slug, isCryptoSummary,
    } = this.props;
    const { topicTickerArr } = this.state;

    const selectors = topicTickerArr.find((f) => f.active) || topicTickerArr[0];

    const isTopic = !!slug?.slug;
    if (!isCryptoSummary
      && ((!isTopic && !selector.some(Boolean)) || (isTopic && !topicTickerArr.length))
    ) {
      this.setState({
        currentFullDetails: null,
      });
      return;
    }
    this.setState({
      currentFullDetails: null,
    });

    const params = {
      page_limit: 1,
      page: 1,
    };

    if (assetType === COMMODITIES || assetType === INDICES || assetType === FOREIGN_EXCHANGE) {
      params.identifiers = `TR:${selectors.name}`;
    } else if (assetType === CRYPTOCURRENCIES) {
      params.symbol = topicTickerArr.length ? selectors.name : this.mainTicker || selector;
    } else {
      params.symbol = topicTickerArr.length ? selectors.full_name : this.mainTicker || selector;
    }

    const stockExchangeMarketHours = selectors?.stock_exchange_id ? (
      await StockExchangesSvc.getStockExchangeMarketHours(
        selectors.stock_exchange_id,
      )
    ) : null;
    if (stockExchangeMarketHours) {
      params.market_hours = isMarketTime(stockExchangeMarketHours);
    }

    const { fullDetails, error } = await fetchTopicFullDetails(assetType, params);

    this.setState({
      currentFullDetails: fullDetails && !error ? convertFullDetails(fullDetails) : null,
    });
  }

  getActualData() {
    const {
      type,
      width,
      currentPrice,
      isExpanded,
      hasBigScreenDesign,
      slug,
      assetType,
      chatbot, isChatbotOpened, expandedChatbot,
      screenWidth,
    } = this.props;
    const {
      stats, chartFilters: { filter: activeTimeFilter, annotationsEnabled },
    } = this.state;

    if (!stats || !stats.length) return;

    let title;
    let rangesMethod;

    switch (type) {
      case 'Price':
        title = 'Price';
        rangesMethod = ({ price_usd: price }) => price;
        break;
      case 'MarketCap':
        title = 'Market Cap';
        rangesMethod = ({ market_cap_usd: marketCap }) => marketCap;
        break;
      default:
        title = '';
        break;
    }

    const {
      priceData,
      marketCapData,
      priceLayout,
      marketCapLayout,
      chartTypeColor,
    } = preparePriceGraph({
      stats,
      width,
      title,
      showAnnotations: annotationsEnabled,
      timeFilter: activeTimeFilter,
      isExpanded,
      hasBigScreenDesign,
      showChartLabel: !slug?.slug,
      hasFourDecimal: assetType === FOREIGN_EXCHANGE,
      chatbot,
      isChatbotOpened,
      expandedChatbot,
    });

    this.plotWrappGradientRightMargin.current = marketCapLayout?.margin?.r;

    const state = {
      currentPrice: currentPrice || stats[stats.length - 1].price_usd,
      chartColor: chartTypeColor,
      firstDate: activeTimeFilter === ALL || activeTimeFilter === FIVE_YEAR
        ? `${moment(new Date(stats[0].last_updated)).format('MMM YYYY')} to Today`
        : GRAPH_TIME_FILTERS_FULL_TEXT[activeTimeFilter],
    };

    switch (type) {
      case 'Price':
        Object.assign(state, {
          data: priceData,
          layout: priceLayout,
        });
        break;
      case 'MarketCap':
        Object.assign(state, {
          data: marketCapData,
          layout: marketCapLayout,
        });
        break;
      default:
    }

    const data = stats.map(rangesMethod);
    const { min, max } = getRanges(data, isExpanded);
    state.chartMinValue = min;
    state.chartMaxValue = max;
    state.chartData = data;
    const step = this.MAX_TOP_POSITION / (max - min);
    const lastPoint = data[data.length - 1];

    const { state: stateUpdated } = getCorrectMarginRight({
      chartMaxValue: max, state, screenWidth,
    });

    if (this.plotWrapper.current) {
      state.priceTopPosition = Math.round((lastPoint - min) * step);
    }
    this.setState(stateUpdated);
  }

  openInitialModal() {
    const { chartData, stats, chartFilters: { filter: activeTimeFilter } } = this.state;

    if (!this.plotWrapper.current) {
      return;
    }

    this.initialModalOpened = true;
    const isMoreThanMonth = [THREE_MONTH, ONE_YEAR, FIVE_YEAR, ALL].includes(activeTimeFilter);
    let minValue = Infinity;
    let minIndex = -1;
    stats.forEach((obj, index) => {
      const dateCondition = !isMoreThanMonth || (
        isMoreThanMonth && moment().subtract(1, 'months').isSameOrBefore(moment(new Date(obj.last_updated)))
      );
      if (minValue > obj.price_usd && dateCondition) {
        minValue = obj.price_usd;
        minIndex = index;
      }
    });

    if (minIndex === -1) return;

    this.onChartHover({
      event: {
        pageX: this.getCoords(this.plotWrapper.current)?.left + (
          Math.floor(this.plotWrapper.current.clientWidth / chartData.length) * minIndex
        ),
        pageY: this.getCoords(this.plotWrapper.current)?.top + (
          this.MAX_TOP_POSITION - this.calculatePoint(minIndex)
        ),
      },
      points: [{
        pointIndex: minIndex,
        x: minIndex,
        y: minValue,
      }],
    }, false);
  }

  calculatePoint(index = -1) {
    const { chartMaxValue, chartMinValue, chartData } = this.state;

    if (!chartData?.length) return this.MAX_TOP_POSITION;
    const dataPointIndex = index === -1 ? chartData.length - 1 : index;

    const step = this.MAX_TOP_POSITION / (chartMaxValue - chartMinValue);
    return Math.round((chartData[dataPointIndex] - chartMinValue) * step);
  }

  chartsPlotlyRefSet(refValue) {
    if (!refValue) return;

    this.plotWrapper.current = refValue;
    const priceTopPosition = this.calculatePoint();
    this.setState({
      priceTopPosition,
    });
  }

  renderChartsHeaderRight(extraProps) {
    const { className } = extraProps || {};
    const {
      assetType,
    } = this.props;
    const {
      currentFullDetails,
      chartFilters: { type: activeChartType },
    } = this.state;

    const { currency } = currentFullDetails || {};

    return (
      <div className={cx('charts-header-right', className)}>
        {assetType !== FOREIGN_EXCHANGE && assetType !== CRYPTOCURRENCIES
          && activeChartType !== ADVANCED && (
          <div className={cx('charts-header-currency')}>
            Currency:
            {' '}
            <div className={cx('charts-header-currency-value')}>
              {currency || '---'}
            </div>
          </div>
        )}
        {this.renderExpandLink()}
      </div>
    );
  }

  renderExpandLink() {
    const {
      hideExpand, isExpanded, currentPath, isCryptoSummary, isWatchlist,
      isFinpromptPages,
    } = this.props;
    const { hasChartError } = this.state;

    if (isFinpromptPages) {
      return (
        <>
          {(!hideExpand || hasChartError) && (
            isExpanded ? (
              <a
                href={`${CF_URL_FOR_FINPROMPT}${currentPath}`}
                className={Styles.chart_expand}
                onClick={this.handleChartCollapseClick}
              >
                <FontAwesomeIcon icon={faCompressAlt} />
              </a>
            ) : (
              <a
                href={`${CF_URL_FOR_FINPROMPT}${currentPath}${isCryptoSummary || isWatchlist ? '' : '/expanded'}`}
                className={Styles.chart_expand}
                onClick={this.handleChartExpandClick}
              >
                <FontAwesomeIcon icon={faExpandAlt} />
              </a>
            )
          )}
        </>
      );
    }

    return (
      <>
        {(!hideExpand || hasChartError) && (
          isExpanded ? (
            <Link
              to={`${currentPath}`}
              className={Styles.chart_expand}
              onClick={this.handleChartCollapseClick}
            >
              <FontAwesomeIcon icon={faCompressAlt} />
            </Link>
          ) : (
            <Link
              to={`${currentPath}${isCryptoSummary || isWatchlist ? '' : '/expanded'}`}
              className={Styles.chart_expand}
              onClick={this.handleChartExpandClick}
            >
              <FontAwesomeIcon icon={faExpandAlt} />
            </Link>
          )
        )}
      </>
    );
  }

  setTimeFilter(filter = '') {
    if (!filter) return;

    this.setState((prevState) => ({
      chartFilters: { ...prevState.chartFilters, filter },
    }));
  }

  setChartType(type = '') {
    if (!type) return;

    this.setState((prevState) => ({
      chartFilters: { ...prevState.chartFilters, type },
    }));
  }

  toggleAnnotations() {
    this.setState((prevState) => ({
      chartFilters: {
        ...prevState.chartFilters,
        annotationsEnabled: !prevState.chartFilters.annotationsEnabled,
      },
    }));
  }

  render() {
    const {
      stats,
      loading,
      firstLoading,
      layout,
      data,
      priceTopPosition,
      selectedPoint,
      tradingTickersArr,
      topicTickerArr,
      chartColor,
      currentFullDetails,
      percentChange,
      firstDate,
      currentPrice: stateCurrentPrice,
      wraperWidth,
      chartFilters: {
        filter: activeTimeFilter,
        type: activeChartType,
      },
      chartFilters,
      chartMaxValue,
    } = this.state;
    const {
      type,
      query,
      onChartStories,
      slug,
      isExpanded,
      isCryptoSummary,
      homePage,
      hasBigScreenDesign,
      assetType,
      chatbot,
      expandedChatbot,
      isChatbotOpened,
      screenWidth,
      webhookData,
    } = this.props;

    const dataAvailable = (stats?.length > 1) || (chatbot && webhookData?.length);
    const plots = [];

    if (data) {
      plots.push(data);
    }

    if (layout) {
      layout.xaxis2.title = layout.xaxis.title;
      layout.xaxis2.showticklabels = true;
      if (!chatbot && wraperWidth) {
        layout.width = wraperWidth - (layout.margin.r / 3);
      }
    }
    if (selectedPoint.visible && selectedPoint.showLine) {
      plots.push({
        ...hoverChartMarkerData,
        x: [selectedPoint.xPoint],
        y: [selectedPoint.yPoint],
        xaxis: 'x2',
        yaxis: 'y2',
        marker: {
          ...hoverChartMarkerData.marker,
          color: chartColor,
          line: {
            color: `${chartColor}40`,
            width: 6,
          },
        },
      });
      plots.push({
        ...hoverChartLineData,
        x: [selectedPoint.xPoint, selectedPoint.xPoint],
        y: layout.yaxis2.range,
        xaxis: 'x2',
        yaxis: 'y2',
        line: {
          color: chartColor,
          width: 1,
        },
      });
    }

    const withAdvancedFilter = tradingTickersArr.length && topicTickerArr.length;
    const isTopic = !!slug?.slug;

    const currentTickerArr = activeChartType === 'ADVANCED' ? tradingTickersArr : topicTickerArr;
    const {
      id: tradingTicker, tradingTicker: tradingTickername,
    } = (activeChartType === 'ADVANCED' ? tradingTickersArr : topicTickerArr).find(({ active }) => active) || {};
    const { price, percentChange: propsPercentChange } = currentFullDetails || {};
    const currentPrice = stateCurrentPrice || price;
    const currentPercentChange = activeTimeFilter === ONE_DAY ? propsPercentChange : percentChange;

    const mobileVersionChatbot = (isChatbotOpened && (!expandedChatbot || (screenWidth < 768)));

    return (
      <section
        ref={this.chartsWraper}
        className={cx('charts-wrapper', {
          expanded: isExpanded,
          big_screen: hasBigScreenDesign,
          no_topic: !isTopic,
          no_ticker: currentTickerArr.length <= 1,
          chatbot,
          mobile_view_chatbot: mobileVersionChatbot,
        })}
      >
        <div
          className={cx('charts-wrapper-filter', {
            chatbot,
            mobile_view_chatbot: mobileVersionChatbot,
            'home-page': homePage,
          })}
        >
          <div className={cx('charts-wrapper-filter-title')}>
            Price
          </div>
          <PlayVideoComponent
            videoLink="https://cityfalcon.wistia.com/medias/ss2alfe5pj"
            videoLinkMobile="https://cityfalcon.wistia.com/medias/bg126ct1i0"
            additionalStyles={cx('chart_play_button', {
              advanced_chart: activeChartType === 'ADVANCED' && (!isCryptoSummary && !homePage),
              home_crypto_page: homePage || isCryptoSummary,
              home_page: homePage,
              mobile_view_chatbot: mobileVersionChatbot,
            })}
            tooltipAdditionalContentStyle={cx('chart_play_button_tooltip')}
          />
          {!!withAdvancedFilter && (
            <div className={cx('type-filters-wrapper')}>
              <ul className={cx('type-filters')}>
                {GRAPH_TYPE_FILTERS.map(({ type, value }) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li
                    key={type}
                    className={cx('filter', [`${value}`], { active: activeChartType === type })}
                    onClick={() => this.setChartType(type)}
                  >
                    {value}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {activeChartType !== ADVANCED && (
            <>
              <div className={cx('charts-wrapper-filter-break')} />
              <div className={cx('time-filters-wrapper')}>
                <div className={cx('label')}>
                  Period:
                </div>
                <ChartTimeFilters
                  isTransparent
                  hasBigScreenDesign
                  options={GRAPH_TIME_FILTERS}
                  activeFilter={activeTimeFilter}
                  setTimeFilter={this.setTimeFilter}
                  mobileChatbot={mobileVersionChatbot}
                />
              </div>
              <div className={cx('charts-wrapper-filter-info-outer')}>
                {!firstLoading && (
                  <div className={cx('charts-wrapper-filter-info', { loading })}>
                    {typeof currentPercentChange === 'number' && (
                      <div
                        className={
                          cx('change', {
                            positive: currentPercentChange >= 0,
                            negative: currentPercentChange < 0,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={currentPercentChange >= 0 ? faSortUp : faSortDown} />
                        {Math.abs(currentPercentChange).toFixed(2)}
                        %
                      </div>
                    )}
                    <div className={cx('charts-wrapper-filter-info-time')}>
                      {typeof currentPercentChange === 'number'
                        ? (
                          <>
                            {activeTimeFilter === ALL || activeTimeFilter === FIVE_YEAR
                              ? firstDate : GRAPH_TIME_FILTERS_FULL_TEXT[activeTimeFilter]}
                          </>
                        ) : 'No data for this time period.'}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className={cx('charts-wrapper-filter-break', 'after-play')} />
          {this.renderChartsHeaderRight({ className: 'filter-right' })}
        </div>
        <div className={cx('charts', { 'blur-loading': loading })}>
          <div className={cx('charts-header')}>
            <div className={cx('charts-header-left')}>
              {!!withAdvancedFilter && (
                <div className={cx('type-filters-wrapper')}>
                  <ul className={cx('type-filters')}>
                    {GRAPH_TYPE_FILTERS.map(({ type, value }) => (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                      <li
                        key={type}
                        className={cx('filter', { active: activeChartType === type })}
                        onClick={() => this.setChartType(type)}
                      >
                        {value}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {currentTickerArr.length > 1 && (
                <div className={cx('tickers-filters-wrapper', { has_filters: !!withAdvancedFilter })}>
                  <div className={cx('tickers-filters-wrapper-label')}>
                    Tickers:
                  </div>
                  <ItemsScrollBar
                    data={currentTickerArr}
                    onClick={this.setActiveTicker}
                    className={cx('tickers-filters-wrapper-menu')}
                    itemClassName={cx('tickers-filters-wrapper-menu-item')}
                    arrowClassName={cx('tickers-filters-wrapper-menu-arrow')}
                  />
                </div>
              )}
            </div>
            {this.renderChartsHeaderRight()}
          </div>
          <div className={cx('charts-header-tab', { 'home-page': homePage })}>
            <div className={cx('charts-header-tab-tick')}>
              {currentTickerArr.length > 1 && (
                <div className={cx('tickers-filters-wrapper')}>
                  <div className={cx('tickers-filters-wrapper-label')}>
                    Tickers:
                  </div>
                  <ItemsScrollBar
                    data={currentTickerArr}
                    onClick={this.setActiveTicker}
                    className={cx('tickers-filters-wrapper-menu')}
                    itemClassName={cx('tickers-filters-wrapper-menu-item')}
                    arrowClassName={cx('tickers-filters-wrapper-menu-arrow')}
                    chatbotWindow={chatbot && !expandedChatbot && isChatbotOpened}
                  />
                </div>
              )}
            </div>
            {this.renderChartsHeaderRight()}
          </div>
          {activeChartType === BASIC && (
            <>
              <div
                className={cx('plot-wrapper')}
                ref={this.chartsPlotlyRefSet}
              >
                {dataAvailable && (currentPrice || currentPrice === 0) && (
                  <div style={{ top: `${this.MAX_TOP_POSITION - priceTopPosition}px` }} className={cx('price-wrapper')}>
                    <div className={cx('price')}>
                      {type === 'MarketCap'
                        ? checkAndRoun(currentPrice, 2, true)
                        : Number(roundTo(currentPrice, assetType === FOREIGN_EXCHANGE ? 4 : 2))}
                    </div>
                  </div>
                )}
                <div
                  style={{ right: `${this.plotWrappGradientRightMargin?.current}px` }}
                  className={cx('plot-wrapper-gradient')}
                />
                {firstLoading ? (
                  <Preloader
                    loading
                    transform={chatbot}
                    className={cx('loading')}
                  />
                ) : (
                  <>
                    {dataAvailable ? (
                      <Plot
                        data={plots}
                        layout={layout || {}}
                        config={chartConfig}
                        onHover={!isMobile ? this.onChartHover : null}
                        onClick={this.onChartHover}
                        onLoad={this.onChartLoad}
                        ref={this.plot}
                        chatbot={chatbot}
                      />
                    ) : (
                      <NoDataMessage
                        hasBigScreenDesign={hasBigScreenDesign}
                      />
                    )}
                  </>
                )}
                {selectedPoint.visible && (
                  <StoriesModal
                    y={selectedPoint.y}
                    x={selectedPoint.x}
                    timeFilter={activeTimeFilter}
                    query={query}
                    date={selectedPoint.date}
                    price={selectedPoint.price}
                    showStories={selectedPoint.showStories}
                    rangeStartDate={selectedPoint.startDate}
                    rangeEndDate={selectedPoint.endDate}
                    setShowStories={this.setShowStories}
                    onClose={this.onStoriesModalClose}
                    showToggle={onChartStories}
                    onStoriesLoadStart={this.onChartStoriesLoadStart}
                    onStoriesLoad={this.onChartStoriesLoad}
                    ref={this.storiesModal}
                    homePage={homePage}
                    hasBigScreenDesign={hasBigScreenDesign}
                    assetType={assetType}
                    chatbotExpanded={chatbot && expandedChatbot}
                    chatbot={chatbot}
                  />
                )}
              </div>
            </>
          )}
          {activeChartType === ADVANCED && tradingTicker && (
            <div className={cx('trading-widget')}>
              <TradingViewWidget
                symbol={tradingTicker}
                tickerName={tradingTickername}
                theme="light"
                locale="en"
                width="100%"
                interval="D"
                height={isExpanded ? '100%' : '430px'}
                calendar
                hotlist
                details
              />
            </div>
          )}
        </div>
      </section>
    );
  }
}

Charts.propTypes = {
  width: PropTypes.number,
  type: PropTypes.oneOf(['Price', 'MarketCap']),
  currentPrice: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onLoadingToggle: PropTypes.func,
  onChartStories: PropTypes.bool,
  hideExpand: PropTypes.bool,
};

Charts.defaultProps = {
  width: typeof window === 'object' ? window.innerWidth : 0,
  type: 'Price',
  currentPrice: null,
  onLoadingToggle: () => { },
  onChartStories: true,
  hideExpand: false,
};

function mapStateToProps({
  stories, common, chatbot, searchReducers,
}) {
  return {
    similarStoriesModalOpened: stories.similarStoriesModalOpened,
    storyImageOpened: stories.storyImageOpened,
    screenWidth: common.width,
    expandedChatbot: chatbot.expanded,
    isChatbotOpened: chatbot.isChatbotOpened,
    searchBarActive: searchReducers.searchBarActive,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeChatbotWindow: bindActionCreators(closeChatbotWindow, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withComponentName(
  (withRouter(withFinprompt(Charts))),
));
