export const SET_AUTH_MODAL_OPENED = 'SET_AUTH_MODAL_OPENED';
export const SET_AUTH_MODAL_OPENED_FROM_CHATBOT = 'SET_AUTH_MODAL_OPENED_FROM_CHATBOT';
export const SET_FINPROMPT_AUTH_MODAL_OPENED = 'SET_FINPROMPT_AUTH_MODAL_OPENED';
export const SET_FORGOT_PASSWORD_CUSTOM_MODAL_OPENED = 'SET_FORGOT_PASSWORD_CUSTOM_MODAL_OPENED';
export const SET_USER_HAS_ACCOUNT_MODAL_OPENED = 'SET_USER_HAS_ACCOUNT_MODAL_OPENED';

export const setAuthModalOpened = (opened) => ({
  type: SET_AUTH_MODAL_OPENED,
  payload: opened,
});
export const setAuthModalOpenedFromChatbot = (opened) => ({
  type: SET_AUTH_MODAL_OPENED_FROM_CHATBOT,
  payload: opened,
});

export const setFinPromptAuthModalOpened = (opened) => ({
  type: SET_FINPROMPT_AUTH_MODAL_OPENED,
  payload: opened,
});

export const setForgotPasswordCustomModalOpened = (opened) => ({
  type: SET_FORGOT_PASSWORD_CUSTOM_MODAL_OPENED,
  payload: opened,
});

export const setUserHasAccountModal = (opened) => ({
  type: SET_USER_HAS_ACCOUNT_MODAL_OPENED,
  payload: opened,
});
