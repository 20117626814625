export const SET_LOCATIONS_SENTIMENT_MAIN_TIME_PERIOD = 'SET_LOCATIONS_SENTIMENT_MAIN_TIME_PERIOD';
export const SET_LOCATIONS_SENTIMENT_SELECTED_ACTIVE_POINT = 'SET_LOCATIONS_SENTIMENT_SELECTED_ACTIVE_POINT';
export const SET_LOCATIONS_SENTIMENT_SELECTED_LOCATION = 'SET_LOCATIONS_SENTIMENT_SELECTED_LOCATION';

export const setMainTimePeriod = (period) => ({
  type: SET_LOCATIONS_SENTIMENT_MAIN_TIME_PERIOD,
  payload: period,
});

export const setSelectedActivePoint = (value) => ({
  type: SET_LOCATIONS_SENTIMENT_SELECTED_ACTIVE_POINT,
  payload: value,
});

export const setSelectedLocation = (value) => ({
  type: SET_LOCATIONS_SENTIMENT_SELECTED_LOCATION,
  payload: value,
});
