import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';

import Tooltip from '../../Tooltip';
import CFLink from '../../Shared/CFLink';
import Preloader from '../../Preloader';
import LinkChecker from '../../Shared/LinkChecker';

import useSizes from '../../../hooks/sizes';
import useFinprompt from '../../../providers/FinpromptProvider/useFinprompt';

import { calculateTimeAgo } from '../../../helpers/helpers';

import { CF_URL_FOR_FINPROMPT } from '../../../data/environment';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const SummarisationList = ({
  summaries,
  blurred,
  notAllowed,
  collapsed,
  children,
  loading,
  hasBigScreenDesign,
}) => {
  const { width } = useSizes();
  const { isFinpromptPages } = useFinprompt();

  return (
    summaries.length ? (
      <>
        <ul className={cx('summarisation-points', { blurred, big_screen: hasBigScreenDesign, 'summarisation-blured': blurred })}>
          {summaries
            .slice(0, collapsed ? summaries.length : 2)
            .map(({
              id, text,
              uuid, timestamp, publication_name: publicationName,
            }) => (
              <li key={id} className={cx('summarisation-point', { 'summarisation-point-blurred': blurred })}>
                <FontAwesomeIcon icon={faCircle} />
                <Tooltip
                  icon={(
                    <LinkChecker
                      linkUrl={uuid && `${isFinpromptPages ? CF_URL_FOR_FINPROMPT : ''}/news/summary/${uuid}`}
                      external={!uuid || isFinpromptPages}
                      target={isFinpromptPages ? '_blank' : undefined}
                      className={cx('summarisation-point-url')}
                    >
                      {text}
                      {publicationName && (
                        <>
                          {' '}
                          |
                          <span className={cx('summarisation-point-name')}>
                            {' '}
                            {publicationName}
                          </span>
                        </>
                      )}
                    </LinkChecker>
                  )}
                  className={cx('tooltip')}
                  disableOnMobile
                  delayTooltip={3000}
                  hasBigScreenDesign={hasBigScreenDesign}
                >
                  {!blurred && 'Click to view stories related to this summary item.'}
                </Tooltip>
                {Boolean(timestamp) && (
                  <span className={cx('summarisation-point-timediff')}>
                    {calculateTimeAgo(timestamp, width <= 1500)}
                  </span>
                )}
              </li>
            ))}
        </ul>
        {children}
        {loading && width > 1024 && (
          <div className={cx('summarisation-preloader-card', { big_screen: hasBigScreenDesign })}>
            <Preloader loading static centered className={cx('summarisation-preloader')} />
            <div className={cx('summarisation-loading-text')}>
              Key Headlines are reloading…
            </div>
          </div>
        )}
      </>
    ) : (
      <div className={cx('summarisation-no-data', { big_screen: hasBigScreenDesign })}>
        {notAllowed ? (
          <>
            <CFLink to="/pricing" external={false}>
              Upgrade
            </CFLink>
            &nbsp;to access this content
          </>
        ) : (
          <>
            Key Headlines is currently not available&nbsp;
            for the chosen topic(s). Please try again later.
            <br />
            Some topics may not produce summaries.&nbsp;
            <CFLink
              external={isFinpromptPages}
              to="https://help.cityfalcon.com/hc/en-us/articles/360006073397"
              target={isFinpromptPages ? '_blank' : undefined}
            >
              See how it works.
            </CFLink>
          </>
        )}
      </div>
    )
  );
};

SummarisationList.propType = {
  summaries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      url: PropTypes.string,
      uuid: PropTypes.string,
      timestamp: PropTypes.string,
    }),
  ).isRequired,
  blurred: PropTypes.bool,
  notAllowed: PropTypes.bool,
  collapsed: PropTypes.bool,
  children: PropTypes.node,
};

SummarisationList.defaultProps = {
  blurred: false,
  notAllowed: false,
  collapsed: true,
  children: null,
};

export default SummarisationList;
