import { RetryAPICall } from '../../helpers/apiHelpers';
import API from './api';

export default class AccountSvc {
  static async getAccountInfo() {
    try {
      const { data } = await RetryAPICall(API, 'users/info');
      return data;
    } catch (e) {
      return null;
    }
  }

  static async changeAccountInfo(data) {
    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => formData.append(key, value));
      const { data: updatedUser } = await API.patch('users/info', formData);
      return updatedUser;
    } catch (e) {
      throw e.response.data;
    }
  }

  static async exportUserData(data) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));

    const { data: response } = await API.post('users/info/export', formData);
    const URL = window.URL || window.webkitURL;
    return URL.createObjectURL(new Blob([response]));
  }

  static async resetPassword(email) {
    if (typeof email !== 'string' && !email) throw new TypeError('Invalid argument provided');

    const formData = new FormData();
    formData.append('email', email);

    const { data } = await API.post('users/forgot_password', formData);
    return data;
  }

  static async deleteAccount() {
    try {
      const { data, headers: { status } } = await API.delete('users/account');
      return { data, status };
    } catch (e) {
      return null;
    }
  }
}
