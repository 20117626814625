import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import withComponentName from '../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const InfoTable = ({ className, titles, columns }) => (
  <table className={`${cx('info-table')} ${className}`}>
    <thead>
      <tr>
        {titles.map((title, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <td key={`title_${i}`} className={cx('title')}>
            {title}
          </td>
        ))}
      </tr>
    </thead>
    <tbody>
      <tr>
        {columns.map((item) => (
          <td key={item.id} className={cx('content')}>
            {item.content}
          </td>
        ))}
      </tr>
    </tbody>
  </table>
);

InfoTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.node,
      content: PropTypes.node,
    }),
  ),
};

InfoTable.defaultProps = {
  className: '',
  columns: [],
};

export default withComponentName(InfoTable);
