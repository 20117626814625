import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const Arrow = ({
  direction, arrowStyle, arrowClass, ...props
}) => (
  <div className={`${cx('arrow', arrowStyle, direction, arrowClass)} direction-${direction}`} {...props}>
    <div className={cx('icon-wrapper')}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  </div>
);

export const PrevArrow = (props) => (
  <Arrow direction="left" {...props} />
);

export const NextArrow = (props) => (
  <Arrow direction="right" {...props} />
);
