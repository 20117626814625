import API, { APIWithoutToken } from './api';
import { getAPICachingTime, returnAPICachingHeader } from '../../helpers/helpers';
import { cachingVeryHigh } from '../../data/webPageData';
import { RetryAPICall } from '../../helpers/apiHelpers';

const caches = {
  segments: {},
  userSegments: {},
};

export const getSegments = () => {
  try {
    if (caches.segments.response && caches.segments.expDate > Date.now()) {
      return caches.segments.response;
    }

    const res = APIWithoutToken.get('segments', {
      headers: {
        'Cache-control': returnAPICachingHeader(cachingVeryHigh),
      },
    });
    caches.segments = {
      expDate: getAPICachingTime(cachingVeryHigh),
      response: res,
    };

    return res;
  } catch {
    return {
      data: {
        segments: [],
      },
    };
  }
};

export const getTopics = () => RetryAPICall(API, 'asset_classes');

export const saveUserSegments = (data) => API.post('users/segments', data);

export const saveUserTopics = (data) => API.post('users/asset_classes', data);

export const getUserSegments = () => {
  if (caches.userSegments.response && caches.userSegments.expDate > Date.now()) {
    return caches.userSegments.response;
  }

  const res = RetryAPICall(API, 'users/segments', {
    headers: {
      'Cache-control': returnAPICachingHeader(cachingVeryHigh),
    },
  });
  caches.userSegments = {
    expDate: getAPICachingTime(cachingVeryHigh),
    response: res,
  };

  return res;
};

export const getUserAssetClasses = () => RetryAPICall(API, 'users/asset_classes');

export const removeAllSegments = (data) => API.delete(`users/segments?segments=${encodeURIComponent(data)}`);

export const removeAllAssetClasses = (data) => API.delete(`users/asset_classes?asset_classes=${encodeURIComponent(data)}`);

export const saveSegmentsSuggestion = (data) => API.post('suggestions', data);

export const сreateNewWatchlistOnboarding = (query, token, watchlistName) => API.post(`watchlists?api_key=${token}`, {
  search_queries: query,
  name: watchlistName,
});

export const сreateNewWatchlistOnboardingUser = (query) => API.post('watchlists', {
  search_queries: query,
});
