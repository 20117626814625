import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './styles.module.scss';
import { getAverageSentimentText } from '../../../../helpers/sentimentHelpers';

const cx = classNames.bind(Styles);

const TooltipOutside = (props) => {
  const {
    topPosition, leftPosition, isInfoItemLabel, item,
  } = props;

  const cfMainWrapper = document.querySelector('.cf-main-wrapper');
  const styles = {
    top: `${topPosition}px`,
    left: `${leftPosition}px`,
  };

  const renderType = (item) => {
    if (item?.asset_class) {
      return item?.asset_class;
    }
    if (item?.entity_type.toLowerCase() === 'assetclass') {
      return 'Topic Class';
    }
    if (item?.entity_type.toLowerCase() === 'startupcategory') {
      return 'Sector Category';
    }
    return item?.entity_type;
  };

  if (isInfoItemLabel) {
    const { type, icon } = getAverageSentimentText(typeof (item.sentiment) === 'string' ? item.sentiment.slice(0, item.sentiment.length - 1) : item.sentiment);
    return (
      createPortal(
        <div
          className={cx('infoLabelItems-tooltip-outside-text')}
          style={styles}
        >
          <div className={cx('infoLabelItems--tooltip__header')}>
            {item.entity_name}
            ,
            {' '}
            {renderType(item)}
          </div>
          <div className={cx('infoLabelItems--tooltip__sentiment')}>
            <div className={cx('infoLabelItems--tooltip__sentiment--title')}>Sentiment:</div>
            <div className={cx('infoLabelItems--tooltip__sentiment--content', type)}>
              <FontAwesomeIcon icon={icon} />
              <span>
                {typeof (item?.sentiment) === 'string' ? item?.sentiment : `${item?.sentiment}%`}
              </span>
            </div>
          </div>
        </div>,
        cfMainWrapper,
      )
    );
  }

  return (
    createPortal(
      <div
        className={cx('tooltip-outside-text')}
        style={styles}
      >
        Click to see more info
      </div>,
      cfMainWrapper,
    )
  );
};

export default TooltipOutside;
