import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// services
import InsiderTransactionsSvc from '../../../../services/dbServices/InsiderTransactionsSvc';
import StorageSvc from '../../../../services/StorageSvc';

import prepareTransactionChart from '../../../../helpers/transactionChartHelpers';
import { stringToBool } from '../../../../helpers/commonHelpers';

import withComponentName from '../../../../decorators/withComponentName';

import Plot from '../../../Plot';
import Preloader from '../../../Preloader';
import TransactionHoverModal from '../TransactionHoverModal';

import useSizes from '../../../../hooks/sizes';
import useFinprompt from '../../../../providers/FinpromptProvider/useFinprompt';

import InsiderTransactionsTabOptionsContext
  from '../../Topic/InsiderTransactionsTab/InsiderTransactionsTabOptionsContext';

import { toggleModalOutsideChatbot } from '../../../../actions/chatbot.actions';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const INSIDER_TRANSACTIONS_CHART_HOVER_MODAL_EXPANDED_KEY = 'INSIDER_TRANSACTIONS_CHART_HOVER_MODAL_EXPANDED_KEY';

const initialPlotState = {
  visible: false,
  x: 0,
  y: 0,
  data: {
    sharesData: {
      date: null,
      avgStockPrice: 0,
      buy: 0,
      sell: 0,
      other: 0,
    },
    valuesData: {
      date: null,
      avgStockPrice: 0,
      buy: 0,
      sell: 0,
      other: 0,
    },
  },
};

export const TransactionChart = ({
  type,
  transactionsChartData,
  transactionsPricesChartData,
  loading,
  initialized,
  chatbot,
  isError = false,
  toggleModalInsideChatbot,
}) => {
  const isModalOutsideDisplayed = useSelector((state) => state.chatbot.isModalOutsideDisplayed);
  const dispatch = useDispatch();
  const history = useHistory();
  const [plotProps, setPlotProps] = useState({
    data: [],
    layout: {},
    maxTransactionValue: 0,
  });
  const [hoverDataExpanded, setHoverDataExpanded] = useState(
    (
      !chatbot
      && stringToBool(StorageSvc.getItem(INSIDER_TRANSACTIONS_CHART_HOVER_MODAL_EXPANDED_KEY))
    ) || false,
  );
  const [selectedPoint, setSelectedPoint] = useState(initialPlotState);

  const chartContainerRef = useRef(null);
  const chatMessageContainerRef = useRef(null);
  const plotRef = useRef(null);
  const hoverElementRef = useRef(null);
  const isChatbotOpenedPrevValueRef = useRef(null);

  const { width: widthValue, height } = useSizes();
  const { isFinpromptPages } = useFinprompt();

  const expandedBot = useSelector(({ chatbot }) => chatbot.expanded);
  const isChatbotOpened = useSelector(({ chatbot }) => chatbot.isChatbotOpened);
  const searchBarActive = useSelector(({ searchReducers }) => searchReducers.searchBarActive);

  const [width, setWidth] = useState(0);

  const { isWatchlistPage } = useContext(InsiderTransactionsTabOptionsContext);

  const toggleExpandedView = useCallback((e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setHoverDataExpanded((current) => {
      StorageSvc.setItem(INSIDER_TRANSACTIONS_CHART_HOVER_MODAL_EXPANDED_KEY, String(!current));
      return !current;
    });
  }, []);

  const handleChatMessageScroll = () => {
    setSelectedPoint({
      visible: false,
    });
    if (chatbot) toggleModalInsideChatbot({ displayed: false, type: '' });
    dispatch(toggleModalOutsideChatbot(false));
  };

  const stats = useMemo(() => (
    InsiderTransactionsSvc.transformChartTransactionsIntoStats({
      data: transactionsChartData,
      type: type.value,
      prices: transactionsPricesChartData,
    })
  ), [transactionsChartData, type, transactionsPricesChartData]);
  const chartHeight = width > 767 ? 390 : 330;

  useEffect(() => {
    if (chatbot && isChatbotOpened && !expandedBot) {
      setWidth(400);
    } else {
      setWidth(widthValue);
    }
  }, [width, chatbot, isChatbotOpened, expandedBot]);

  useEffect(() => {
    const onPageHover = (e) => {
      if (!plotRef.current) return;

      const {
        current: {
          plot: {
            current: plot,
          },
        },
      } = plotRef;
      const { current: hoverElement } = hoverElementRef;

      if (!plot.contains(e.target) && hoverElement && !hoverElement.contains(e.target)) {
        setSelectedPoint(initialPlotState);
        if (chatbot) toggleModalInsideChatbot({ displayed: false, type: '' });
        dispatch(toggleModalOutsideChatbot(false));
      }
    };
    const eventName = !isMobile ? 'mousemove' : 'click';

    document.addEventListener(eventName, onPageHover, false);

    return () => {
      document.removeEventListener(eventName, onPageHover);
    };
  }, []);

  useEffect(() => {
    if (!stats.length) {
      return;
    }

    const timeDelay = (
      isChatbotOpenedPrevValueRef?.current === isChatbotOpened
      || isChatbotOpenedPrevValueRef?.current === null
    ) ? 0 : 800;

    setTimeout(() => {
      const {
        data,
        layout,
        maxTransactionValue,
      } = prepareTransactionChart({
        chartHeight,
        width: chartContainerRef.current.clientWidth,
        windowWidth: width,
        stats,
      });

      setPlotProps({
        data,
        layout,
        maxTransactionValue,
      });
      isChatbotOpenedPrevValueRef.current = isChatbotOpened;
    }, timeDelay);
  }, [stats, width, chartHeight, isChatbotOpened]);

  const onChartHover = useCallback((e) => {
    if (chatbot && (!expandedBot || searchBarActive)) return;

    const { pointIndex } = e.points[0];

    e.event?.stopPropagation?.();
    const { pageX, pageY, clientY } = (
      e.event?.changedTouches?.[0]
      || e.event
    );
    const x = pageX + 10;
    let y;

    if (width >= 1700) {
      y = 700 + (isWatchlistPage ? -105 : 0);
    } else if (width > 1024) {
      y = 700 + (isWatchlistPage ? 120 : 0);
    } else if (width > 767) {
      y = 1080 + (isWatchlistPage ? -70 : 0);
    } else {
      y = 1235 + (isWatchlistPage ? -220 : 0);
    }

    if (chatbot) {
      let heightChart = hoverDataExpanded ? 350 : 180;
      let fixedPosition;
      if (widthValue >= 2800) {
        heightChart = hoverDataExpanded ? 750 : 370;
      }
      if (isFinpromptPages) {
        const padding = ((width >= 2800) && 300) || 100;
        fixedPosition = (chartContainerRef?.current?.getBoundingClientRect()?.top || pageY) - padding;
      }
      const paddingFromBottom = isFinpromptPages ? 500
        : ((hoverDataExpanded && 100) || 50);

      y = pageY + 25;

      if (((heightChart + clientY + paddingFromBottom) > height)) {
        y = (isFinpromptPages && fixedPosition)
          || (pageY - ((heightChart + clientY + paddingFromBottom) - height));
      }
    }

    const {
      sharesStats,
      valuesStats,
    } = InsiderTransactionsSvc.getChartTransactionsForGivenMonth({
      data: transactionsChartData,
      prices: transactionsPricesChartData,
      date: stats[pointIndex].date,
    });
    if (chatbot) toggleModalInsideChatbot({ displayed: true, type: 'transactionsChart' });
    dispatch(toggleModalOutsideChatbot(true));
    setSelectedPoint({
      visible: true,
      x,
      y,
      data: {
        sharesData: {
          date: sharesStats[0].date,
          avgStockPrice: sharesStats[0].avgPrice,
          buy: sharesStats[0].buy,
          sell: sharesStats[0].sell,
          other: sharesStats[0].other,
        },
        valuesData: {
          date: valuesStats[0].date,
          avgStockPrice: valuesStats[0].avgPrice,
          buy: valuesStats[0].buy,
          sell: valuesStats[0].sell,
          other: valuesStats[0].other,
        },
      },
    });
  }, [
    transactionsChartData,
    transactionsPricesChartData,
    stats,
    width,
    isWatchlistPage,
    hoverDataExpanded,
    widthValue,
    searchBarActive,
    expandedBot,
  ]);

  useEffect(() => {
    if ((history?.location.pathname !== '/chatbot') && !isFinpromptPages) return;

    // eslint-disable-next-line prefer-destructuring
    if (!chatMessageContainerRef.current) chatMessageContainerRef.current = (document?.getElementsByClassName('widget__inner-wrapper') || [])[0];

    if (chatbot && chatMessageContainerRef?.current) {
      chatMessageContainerRef.current.addEventListener('scroll', handleChatMessageScroll);
    }

    return () => {
      if (chatMessageContainerRef.current) chatMessageContainerRef.current.removeEventListener('scroll', handleChatMessageScroll);
    };
  }, [isChatbotOpened, history?.location.pathname]);

  return (
    <div style={{ minHeight: `${chartHeight}px` }} ref={chartContainerRef} className={Styles['transaction-chart']}>
      {initialized ? (
        <>
          <Preloader
            loading={loading}
            centered
            top={0}
            className={Styles.loader}
          />
          {stats.length ? (
            <>
              <span className={cx('title', 'volume', { 'big-values': plotProps.maxTransactionValue > 9 })}>
                {type.label}
              </span>
              <Plot
                data={plotProps.data}
                layout={plotProps.layout}
                ref={plotRef}
                onHover={!isMobile ? onChartHover : null}
                onClick={onChartHover}
              />
              {Boolean(transactionsPricesChartData?.length) && (
                <span className={cx('title', 'share-price')}>
                  Share Price
                </span>
              )}
              {selectedPoint.visible && createPortal(
                <TransactionHoverModal
                  x={selectedPoint.x}
                  y={selectedPoint.y}
                  ref={hoverElementRef}
                  expandedView={hoverDataExpanded}
                  toggleExpandedView={toggleExpandedView}
                  transactionsChartData={transactionsChartData}
                  chatbot={chatbot && isChatbotOpened}
                  {...selectedPoint.data}
                />,
                document.body,
              )}
            </>
          ) : (
            <div className={cx('no-data')} style={{ height: `${chartHeight}px` }}>
              {isError ? (
                'Something went wrong while fetching the data. Please try again later.'
              ) : (
                'No data is available for this time period.'
              )}
            </div>
          )}
        </>
      ) : (
        <Preloader
          loading
          centered
          static
        />
      )}
    </div>
  );
};

export default memo(withComponentName(TransactionChart));
