import { faLaughBeam as faLaughBeamRegular } from '@fortawesome/pro-regular-svg-icons/faLaughBeam';
import { faSmile as faSmileRegular } from '@fortawesome/pro-regular-svg-icons/faSmile';
import { faMeh as faMehRegular } from '@fortawesome/pro-regular-svg-icons/faMeh';
import { faFrown as faFrownRegular } from '@fortawesome/pro-regular-svg-icons/faFrown';
import { faAngry as faAngryRegular } from '@fortawesome/pro-regular-svg-icons/faAngry';

export const productsAndDevices = [
  {
    id: 1,
    class: 'ios',
    newTab: true,
    name: 'iOS',
    path: 'https://apps.apple.com/us/app/cityfalcon/id1148560018',
  },
  {
    id: 2,
    class: 'android',
    newTab: true,
    name: 'Android',
    path: 'https://play.google.com/store/apps/details?id=com.cityfalcon',
  },
  {
    id: 3,
    class: 'api',
    name: 'API',
    path: '/products/api/financial-news',
  },
];

export const sentimentPeriodFilters = [
  {
    value: '1d',
    text: '1D',
    tooltip: '1 day',
    active: false,
  },
  {
    value: '1w',
    text: '1W',
    tooltip: '1 week',
    active: true,
  },
  {
    value: '1m',
    text: '1M',
    tooltip: '1 month',
    active: false,
  },
  {
    value: '1y',
    text: '1Y',
    tooltip: '1 year',
    active: false,
    mobileViewHidden: true,
  },
  {
    value: 'all',
    text: 'All',
    tooltip: 'All',
    active: false,
    mobileViewHidden: true,
  },
];

export const defaultSentimentPeriod = '1w' || sentimentPeriodFilters[1].value;

export const sentimentValues = [
  {
    id: 1, title: 'Very Positive', value: 'very_positive', icon: faLaughBeamRegular,
  },
  {
    id: 2, title: 'Positive', value: 'positive', icon: faSmileRegular,
  },
  {
    id: 3, title: 'Neutral', value: 'neutral', icon: faMehRegular,
  },
  {
    id: 4, title: 'Negative', value: 'negative', icon: faFrownRegular,
  },
  {
    id: 5, title: 'Very Negative', value: 'very_negative', icon: faAngryRegular,
  },
];

export const defaultMiniChartLayout = {
  dragmode: false,
  margin: {
    t: 1, b: 1, l: 0, r: 0,
  },
  xaxis: {
    fixedrange: true,
    type: 'date',
    showgrid: false,
    zeroline: true,
    showline: false,
    showticklabels: false,
  },
  yaxis: {
    showgrid: false,
    zeroline: true,
    zerolinecolor: '#b2b2b2',
    zerolinewidth: 1,
    showline: false,
    showticklabels: false,
    fixedrange: true,
    range: [-125, 125],
    dtick: 125,
  },
};

export const defaultChartGraphData = {
  type: 'scatter',
  mode: 'lines',
  line: {
    color: '#666666',
    width: 2,
    shape: 'spline',
  },
  connectgaps: true,
  hoverinfo: 'none',
  x: [],
  y: [],
  showlegend: false,
  name: 'sentiment_line',
};

export const sentimentTagsModalTabs = [
  { id: 1, title: 'Top Stories', type: 'stories' },
  { id: 2, title: 'Key Headlines', type: 'summary' },
  { id: 3, title: 'Sentiment Trends', type: 'trends' },
];

export const sentimentViewModalTabs = [
  { id: 1, type: 'popup' },
  { id: 2, type: 'both' },
  { id: 3, type: 'chart' },
];
