import { loadScript } from '../../../utils';

let loadingState = null;

const loadChartjsLibrary = () => {
  if (loadingState === null) {
    loadingState = new Promise((resolve) => {
      if (('Chart' in window)) {
        resolve();
        return;
      }

      window.Chart = null;
      const chartScript = loadScript('https://cdn.jsdelivr.net/npm/chart.js', true);
      document.body.appendChild(chartScript);

      Object.defineProperty(window, 'Chart', {
        set: (v) => {
          Object.defineProperty(window, 'Chart', {
            value: v,
          });
          resolve();
        },
        configurable: true,
      });
    });
  }

  return loadingState;
};

export default loadChartjsLibrary;
