import AD from '../../assets/directory/locations/flags/AD.svg';
import AE from '../../assets/directory/locations/flags/AE.svg';
import AF from '../../assets/directory/locations/flags/AF.svg';
import AG from '../../assets/directory/locations/flags/AG.svg';
import AI from '../../assets/directory/locations/flags/AI.svg';
import AL from '../../assets/directory/locations/flags/AL.svg';
import AM from '../../assets/directory/locations/flags/AM.svg';
import AO from '../../assets/directory/locations/flags/AO.svg';
import AR from '../../assets/directory/locations/flags/AR.svg';
import AS from '../../assets/directory/locations/flags/AS.svg';
import AT from '../../assets/directory/locations/flags/AT.svg';
import AU from '../../assets/directory/locations/flags/AU.svg';
import AW from '../../assets/directory/locations/flags/AW.svg';
import AZ from '../../assets/directory/locations/flags/AZ.svg';
import BA from '../../assets/directory/locations/flags/BA.svg';
import BB from '../../assets/directory/locations/flags/BB.svg';
import BD from '../../assets/directory/locations/flags/BD.svg';
import BE from '../../assets/directory/locations/flags/BE.svg';
import BF from '../../assets/directory/locations/flags/BF.svg';
import BG from '../../assets/directory/locations/flags/BG.svg';
import BH from '../../assets/directory/locations/flags/BH.svg';
import BI from '../../assets/directory/locations/flags/BI.svg';
import BJ from '../../assets/directory/locations/flags/BJ.svg';
import BL from '../../assets/directory/locations/flags/BL.svg';
import BM from '../../assets/directory/locations/flags/BM.svg';
import BN from '../../assets/directory/locations/flags/BN.svg';
import BO from '../../assets/directory/locations/flags/BO.svg';
import BQ from '../../assets/directory/locations/flags/BQ.svg';
import BR from '../../assets/directory/locations/flags/BR.svg';
import BS from '../../assets/directory/locations/flags/BS.svg';
import BT from '../../assets/directory/locations/flags/BT.svg';
import BW from '../../assets/directory/locations/flags/BW.svg';
import BY from '../../assets/directory/locations/flags/BY.svg';
import BZ from '../../assets/directory/locations/flags/BZ.svg';
import CA from '../../assets/directory/locations/flags/CA.svg';
import CD from '../../assets/directory/locations/flags/CD.svg';
import CF from '../../assets/directory/locations/flags/CF.svg';
import CG from '../../assets/directory/locations/flags/CG.svg';
import CH from '../../assets/directory/locations/flags/CH.svg';
import CI from '../../assets/directory/locations/flags/CI.svg';
import CK from '../../assets/directory/locations/flags/CK.svg';
import CL from '../../assets/directory/locations/flags/CL.svg';
import CM from '../../assets/directory/locations/flags/CM.svg';
import CN from '../../assets/directory/locations/flags/CN.svg';
import CO from '../../assets/directory/locations/flags/CO.svg';
import CR from '../../assets/directory/locations/flags/CR.svg';
import CU from '../../assets/directory/locations/flags/CU.svg';
import CV from '../../assets/directory/locations/flags/CV.svg';
import CW from '../../assets/directory/locations/flags/CW.svg';
import CX from '../../assets/directory/locations/flags/CX.svg';
import CY from '../../assets/directory/locations/flags/CY.svg';
import CZ from '../../assets/directory/locations/flags/CZ.svg';
import DE from '../../assets/directory/locations/flags/DE.svg';
import DJ from '../../assets/directory/locations/flags/DJ.svg';
import DK from '../../assets/directory/locations/flags/DK.svg';
import DM from '../../assets/directory/locations/flags/DM.svg';
import DO from '../../assets/directory/locations/flags/DO.svg';
import DZ from '../../assets/directory/locations/flags/DZ.svg';
import EC from '../../assets/directory/locations/flags/EC.svg';
import EE from '../../assets/directory/locations/flags/EE.svg';
import EG from '../../assets/directory/locations/flags/EG.svg';
import EH from '../../assets/directory/locations/flags/EH.svg';
import ENG from '../../assets/directory/locations/flags/ENG.svg';
import ER from '../../assets/directory/locations/flags/ER.svg';
import ES from '../../assets/directory/locations/flags/ES.svg';
import ET from '../../assets/directory/locations/flags/ET.svg';
import EU from '../../assets/directory/locations/flags/EU.svg';
import FI from '../../assets/directory/locations/flags/FI.svg';
import FJ from '../../assets/directory/locations/flags/FJ.svg';
import FK from '../../assets/directory/locations/flags/FK.svg';
import FM from '../../assets/directory/locations/flags/FM.svg';
import FO from '../../assets/directory/locations/flags/FO.svg';
import FR from '../../assets/directory/locations/flags/FR.svg';
import GA from '../../assets/directory/locations/flags/GA.svg';
import GB from '../../assets/directory/locations/flags/GB.svg';
import GD from '../../assets/directory/locations/flags/GD.svg';
import GE from '../../assets/directory/locations/flags/GE.svg';
import GF from '../../assets/directory/locations/flags/GF.svg';
import GG from '../../assets/directory/locations/flags/GG.svg';
import GH from '../../assets/directory/locations/flags/GH.svg';
import GI from '../../assets/directory/locations/flags/GI.svg';
import GL from '../../assets/directory/locations/flags/GL.svg';
import GM from '../../assets/directory/locations/flags/GM.svg';
import GN from '../../assets/directory/locations/flags/GN.svg';
import GP from '../../assets/directory/locations/flags/GP.svg';
import GQ from '../../assets/directory/locations/flags/GQ.svg';
import GR from '../../assets/directory/locations/flags/GR.svg';
import GT from '../../assets/directory/locations/flags/GT.svg';
import GU from '../../assets/directory/locations/flags/GU.svg';
import GW from '../../assets/directory/locations/flags/GW.svg';
import GY from '../../assets/directory/locations/flags/GY.svg';
import HK from '../../assets/directory/locations/flags/HK.svg';
import HN from '../../assets/directory/locations/flags/HN.svg';
import HR from '../../assets/directory/locations/flags/HR.svg';
import HT from '../../assets/directory/locations/flags/HT.svg';
import HU from '../../assets/directory/locations/flags/HU.svg';
import ID from '../../assets/directory/locations/flags/ID.svg';
import IE from '../../assets/directory/locations/flags/IE.svg';
import IL from '../../assets/directory/locations/flags/IL.svg';
import IM from '../../assets/directory/locations/flags/IM.svg';
import IN from '../../assets/directory/locations/flags/IN.svg';
import IO from '../../assets/directory/locations/flags/IO.svg';
import IQ from '../../assets/directory/locations/flags/IQ.svg';
import IR from '../../assets/directory/locations/flags/IR.svg';
import IS from '../../assets/directory/locations/flags/IS.svg';
import IT from '../../assets/directory/locations/flags/IT.svg';
import JE from '../../assets/directory/locations/flags/JE.svg';
import JM from '../../assets/directory/locations/flags/JM.svg';
import JO from '../../assets/directory/locations/flags/JO.svg';
import JP from '../../assets/directory/locations/flags/JP.svg';
import KE from '../../assets/directory/locations/flags/KE.svg';
import KG from '../../assets/directory/locations/flags/KG.svg';
import KH from '../../assets/directory/locations/flags/KH.svg';
import KI from '../../assets/directory/locations/flags/KI.svg';
import KM from '../../assets/directory/locations/flags/KM.svg';
import KN from '../../assets/directory/locations/flags/KN.svg';
import KP from '../../assets/directory/locations/flags/KP.svg';
import KR from '../../assets/directory/locations/flags/KR.svg';
import KW from '../../assets/directory/locations/flags/KW.svg';
import KY from '../../assets/directory/locations/flags/KY.svg';
import KZ from '../../assets/directory/locations/flags/KZ.svg';
import LA from '../../assets/directory/locations/flags/LA.svg';
import LB from '../../assets/directory/locations/flags/LB.svg';
import LC from '../../assets/directory/locations/flags/LC.svg';
import LI from '../../assets/directory/locations/flags/LI.svg';
import LK from '../../assets/directory/locations/flags/LK.svg';
import LR from '../../assets/directory/locations/flags/LR.svg';
import LS from '../../assets/directory/locations/flags/LS.svg';
import LT from '../../assets/directory/locations/flags/LT.svg';
import LU from '../../assets/directory/locations/flags/LU.svg';
import LV from '../../assets/directory/locations/flags/LV.svg';
import LY from '../../assets/directory/locations/flags/LY.svg';
import MA from '../../assets/directory/locations/flags/MA.svg';
import MC from '../../assets/directory/locations/flags/MC.svg';
import MD from '../../assets/directory/locations/flags/MD.svg';
import ME from '../../assets/directory/locations/flags/ME.svg';
import MF from '../../assets/directory/locations/flags/MF.svg';
import MG from '../../assets/directory/locations/flags/MG.svg';
import MH from '../../assets/directory/locations/flags/MH.svg';
import MK from '../../assets/directory/locations/flags/MK.svg';
import ML from '../../assets/directory/locations/flags/ML.svg';
import MM from '../../assets/directory/locations/flags/MM.svg';
import MN from '../../assets/directory/locations/flags/MN.svg';
import MP from '../../assets/directory/locations/flags/MP.svg';
import MQ from '../../assets/directory/locations/flags/MQ.svg';
import MR from '../../assets/directory/locations/flags/MR.svg';
import MS from '../../assets/directory/locations/flags/MS.svg';
import MT from '../../assets/directory/locations/flags/MT.svg';
import MU from '../../assets/directory/locations/flags/MU.svg';
import MV from '../../assets/directory/locations/flags/MV.svg';
import MW from '../../assets/directory/locations/flags/MW.svg';
import MX from '../../assets/directory/locations/flags/MX.svg';
import MY from '../../assets/directory/locations/flags/MY.svg';
import MZ from '../../assets/directory/locations/flags/MZ.svg';
import NA from '../../assets/directory/locations/flags/NA.svg';
import NC from '../../assets/directory/locations/flags/NC.svg';
import NE from '../../assets/directory/locations/flags/NE.svg';
import NF from '../../assets/directory/locations/flags/NF.svg';
import NG from '../../assets/directory/locations/flags/NG.svg';
import NI from '../../assets/directory/locations/flags/NI.svg';
import NIR from '../../assets/directory/locations/flags/NIR.svg';
import NL from '../../assets/directory/locations/flags/NL.svg';
import NO from '../../assets/directory/locations/flags/NO.svg';
import NP from '../../assets/directory/locations/flags/NP.svg';
import NR from '../../assets/directory/locations/flags/NR.svg';
import NU from '../../assets/directory/locations/flags/NU.svg';
import NZ from '../../assets/directory/locations/flags/NZ.svg';
import OM from '../../assets/directory/locations/flags/OM.svg';
import PA from '../../assets/directory/locations/flags/PA.svg';
import PE from '../../assets/directory/locations/flags/PE.svg';
import PF from '../../assets/directory/locations/flags/PF.svg';
import PG from '../../assets/directory/locations/flags/PG.svg';
import PH from '../../assets/directory/locations/flags/PH.svg';
import PK from '../../assets/directory/locations/flags/PK.svg';
import PL from '../../assets/directory/locations/flags/PL.svg';
import PM from '../../assets/directory/locations/flags/PM.svg';
import PN from '../../assets/directory/locations/flags/PN.svg';
import PR from '../../assets/directory/locations/flags/PR.svg';
import PS from '../../assets/directory/locations/flags/PS.svg';
import PT from '../../assets/directory/locations/flags/PT.svg';
import PW from '../../assets/directory/locations/flags/PW.svg';
import PY from '../../assets/directory/locations/flags/PY.svg';
import QA from '../../assets/directory/locations/flags/QA.svg';
import RE from '../../assets/directory/locations/flags/RE.svg';
import RO from '../../assets/directory/locations/flags/RO.svg';
import RS from '../../assets/directory/locations/flags/RS.svg';
import RU from '../../assets/directory/locations/flags/RU.svg';
import RW from '../../assets/directory/locations/flags/RW.svg';
import SA from '../../assets/directory/locations/flags/SA.svg';
import SB from '../../assets/directory/locations/flags/SB.svg';
import SC from '../../assets/directory/locations/flags/SC.svg';
import SCT from '../../assets/directory/locations/flags/SCT.svg';
import SD from '../../assets/directory/locations/flags/SD.svg';
import SE from '../../assets/directory/locations/flags/SE.svg';
import SG from '../../assets/directory/locations/flags/SG.svg';
import SH from '../../assets/directory/locations/flags/SH.svg';
import SI from '../../assets/directory/locations/flags/SI.svg';
import SJ from '../../assets/directory/locations/flags/SJ.svg';
import SK from '../../assets/directory/locations/flags/SK.svg';
import SL from '../../assets/directory/locations/flags/SL.svg';
import SM from '../../assets/directory/locations/flags/SM.svg';
import SN from '../../assets/directory/locations/flags/SN.svg';
import SO from '../../assets/directory/locations/flags/SO.svg';
import SOM from '../../assets/directory/locations/flags/SOM.svg';
import SR from '../../assets/directory/locations/flags/SR.svg';
import SS from '../../assets/directory/locations/flags/SS.svg';
import ST from '../../assets/directory/locations/flags/ST.svg';
import SV from '../../assets/directory/locations/flags/SV.svg';
import SX from '../../assets/directory/locations/flags/SX.svg';
import SY from '../../assets/directory/locations/flags/SY.svg';
import SZ from '../../assets/directory/locations/flags/SZ.svg';
import TC from '../../assets/directory/locations/flags/TC.svg';
import TD from '../../assets/directory/locations/flags/TD.svg';
import TG from '../../assets/directory/locations/flags/TG.svg';
import TH from '../../assets/directory/locations/flags/TH.svg';
import TIB from '../../assets/directory/locations/flags/TIB.svg';
import TJ from '../../assets/directory/locations/flags/TJ.svg';
import TK from '../../assets/directory/locations/flags/TK.svg';
import TL from '../../assets/directory/locations/flags/TL.svg';
import TM from '../../assets/directory/locations/flags/TM.svg';
import TN from '../../assets/directory/locations/flags/TN.svg';
import TO from '../../assets/directory/locations/flags/TO.svg';
import TR from '../../assets/directory/locations/flags/TR.svg';
import TT from '../../assets/directory/locations/flags/TT.svg';
import TV from '../../assets/directory/locations/flags/TV.svg';
import TW from '../../assets/directory/locations/flags/TW.svg';
import TZ from '../../assets/directory/locations/flags/TZ.svg';
import UA from '../../assets/directory/locations/flags/UA.svg';
import UG from '../../assets/directory/locations/flags/UG.svg';
import UN from '../../assets/directory/locations/flags/UN.svg';
import US from '../../assets/directory/locations/flags/US.svg';
import UY from '../../assets/directory/locations/flags/UY.svg';
import UZ from '../../assets/directory/locations/flags/UZ.svg';
import VA from '../../assets/directory/locations/flags/VA.svg';
import VC from '../../assets/directory/locations/flags/VC.svg';
import VE from '../../assets/directory/locations/flags/VE.svg';
import VG from '../../assets/directory/locations/flags/VG.svg';
import VI from '../../assets/directory/locations/flags/VI.svg';
import VN from '../../assets/directory/locations/flags/VN.svg';
import VU from '../../assets/directory/locations/flags/VU.svg';
import WF from '../../assets/directory/locations/flags/WF.svg';
import WLS from '../../assets/directory/locations/flags/WLS.svg';
import WS from '../../assets/directory/locations/flags/WS.svg';
import YE from '../../assets/directory/locations/flags/YE.svg';
import YT from '../../assets/directory/locations/flags/YT.svg';
import ZA from '../../assets/directory/locations/flags/ZA.svg';
import ZM from '../../assets/directory/locations/flags/ZM.svg';
import ZW from '../../assets/directory/locations/flags/ZW.svg';
import abkhazia from '../../assets/directory/locations/flags/abkhazia.svg';
import alandIslands from '../../assets/directory/locations/flags/aland-islands.svg';
import azoresIslands from '../../assets/directory/locations/flags/azores-islands.svg';
import balearicIslands from '../../assets/directory/locations/flags/balearic-islands.svg';
import basqueCountry from '../../assets/directory/locations/flags/basque-country.svg';
import bhutan1 from '../../assets/directory/locations/flags/bhutan-1.svg';
import bonaire from '../../assets/directory/locations/flags/bonaire.svg';
import britishColumbia from '../../assets/directory/locations/flags/british-columbia.svg';
import canaryIslands from '../../assets/directory/locations/flags/canary-islands.svg';
import ceuta from '../../assets/directory/locations/flags/ceuta.svg';
import cocosIsland from '../../assets/directory/locations/flags/cocos-island.svg';
import corsica from '../../assets/directory/locations/flags/corsica.svg';
import galapagosIslands from '../../assets/directory/locations/flags/galapagos-islands.svg';
import hawaii from '../../assets/directory/locations/flags/hawaii.svg';
import kosovo from '../../assets/directory/locations/flags/kosovo.svg';
import macao from '../../assets/directory/locations/flags/macao.svg';
import madeira from '../../assets/directory/locations/flags/madeira.svg';
import melilla from '../../assets/directory/locations/flags/melilla.svg';
import nato from '../../assets/directory/locations/flags/nato.svg';
import northenCyprus from '../../assets/directory/locations/flags/northen-cyprus.svg';
import orkneyIslands from '../../assets/directory/locations/flags/orkney-islands.svg';
import ossetia from '../../assets/directory/locations/flags/ossetia.svg';
import rapaNui from '../../assets/directory/locations/flags/rapa-nui.svg';
import sabaIsland from '../../assets/directory/locations/flags/saba-island.svg';
import sardinia from '../../assets/directory/locations/flags/sardinia.svg';
import sintEustatius from '../../assets/directory/locations/flags/sint-eustatius.svg';
import transnistria from '../../assets/directory/locations/flags/transnistria.svg';
import vaticanCity from '../../assets/directory/locations/flags/vatican-city.svg';

export const flags = {
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  AR,
  AS,
  AT,
  AU,
  AW,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BW,
  BY,
  BZ,
  CA,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ENG,
  ER,
  ES,
  ET,
  EU,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GT,
  GU,
  GW,
  GY,
  HK,
  HN,
  HR,
  HT,
  HU,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MF,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NIR,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SCT,
  SD,
  SE,
  SG,
  SH,
  SI,
  SJ,
  SK,
  SL,
  SM,
  SN,
  SO,
  SOM,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TC,
  TD,
  TG,
  TH,
  TIB,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  UN,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WLS,
  WS,
  YE,
  YT,
  ZA,
  ZM,
  ZW,
  abkhazia,
  'aland islands': alandIslands,
  'azores islands': azoresIslands,
  'balearic islands': balearicIslands,
  'basque country': basqueCountry,
  'bhutan 1': bhutan1,
  bonaire,
  'british columbia': britishColumbia,
  'canary islands': canaryIslands,
  ceuta,
  'cocos island': cocosIsland,
  corsica,
  'galapagos islands': galapagosIslands,
  hawaii,
  kosovo,
  macao,
  madeira,
  melilla,
  nato,
  'northen cyprus': northenCyprus,
  'orkney islands': orkneyIslands,
  ossetia,
  'rapa nui': rapaNui,
  'saba island': sabaIsland,
  sardinia,
  'sint eustatius': sintEustatius,
  transnistria,
  'vatican city': vaticanCity,
};

export default flags;
