import {
  CRYPTO_SET_EXPAND_CHART_DETAILS,
  CRYPTO_RESET_EXPAND_CHART_DETAILS,
} from '../actions/cryptoCurrencies.actions';

const initialState = {
  type: '',
  selectedCryptoTopic: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CRYPTO_SET_EXPAND_CHART_DETAILS:
      return {
        ...state,
        ...payload,
      };
    case CRYPTO_RESET_EXPAND_CHART_DETAILS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
