export const IDENTIFIER_RULES = [
  {
    key: 'companyNumber',
    selector: 'NO',
    dependencies: [],
  },
  {
    key: 'topicSlug',
    selector: 'SL',
    dependencies: [],
  },
  {
    key: 'ticker',
    selector: 'TR',
    dependencies: [],
  },
  {
    key: 'companyName',
    selector: 'CN',
    dependencies: [],
  },
  {
    key: 'countryCode',
    selector: 'CC',
    dependencies: [],
  },
  {
    key: 'stateName',
    selector: 'SN',
    dependencies: ['countryCode'],
  },
  {
    key: 'sourceName',
    selector: 'SN',
    dependencies: [],
  },
];

export const metaDataGroupInfo = {
  tickers: {
    title: 'Ticker',
    isLink: false,
  },
  // TODO open back sectors when they will be available
  // sectors: {
  //   title: 'Sector',
  //   isLink: true,
  // },
  // industries: {
  //   title: 'Industry',
  //   isLink: true,
  // },
  // subindustries: {
  //   title: 'Sub-Industry',
  //   isLink: true,
  // },
  startup_categories: {
    title: 'Category',
    isLink: true,
  },
  website: {
    title: 'Website',
    isLink: true,
  },
  description: {
    title: 'Description',
    isLink: false,
  },
};

export const companyDataGroupInfo = {
  name: {
    title: 'Legal Entity',
    isLink: false,
  },
  companyNumber: {
    title: 'Entity ID',
    isLink: false,
  },
  natureOfBusiness: {
    title: 'SEC CIK No.',
    isLink: false,
  },
  country: {
    title: 'Entity HQ Country',
    isLink: true,
    tooltipText: 'The headquarters of the legal entity. This may be different from the global HQ.',
  },
  officer: {
    title: 'CEO',
    isLink: true,
  },
  incorporation_date: {
    title: 'Incorporation Date',
    isLink: false,
  },
};
