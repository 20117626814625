import { useState } from 'react';
import classNames from 'classnames/bind';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';

import { calculateTimeAgo } from '../../../../helpers/helpers';

import Styles from '../styles.module.scss';

const cx = classNames.bind(Styles);

export const DomainNameTiles = ({
  type, id, twitterName, openURL, domainName, storyImage,
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const setTitleRef = (refValue) => {
    if (!refValue) return;
    setIsHidden(refValue.offsetWidth < refValue.scrollWidth);
  };

  if (type === 'Tweet') {
    return (
      <>
        <FontAwesomeIcon icon={faTwitter} className={cx('twitter-icon')} />
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip
              id={`story_domain_tooltip_${id}`}
              bsPrefix={cx({ hidden: !isHidden })}
            >
              @
              {twitterName}
            </Tooltip>
          )}
        >
          <span
            className={cx('twitter-handle')}
            ref={setTitleRef}
            onClick={(e) => openURL(e, `https://www.twitter.com/intent/user?screen_name=${twitterName}`)}
          >
            @
            {twitterName}
          </span>
        </OverlayTrigger>
      </>
    );
  }

  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip
          id={`story_time_tooltip_${id}`}
          bsPrefix={cx({ hidden: !isHidden })}
        >
          {domainName}
        </Tooltip>
      )}
    >
      <span className={cx('domain-name')} ref={setTitleRef}>
        <img src={storyImage} className={cx('source-image')} alt="" width="16px" height="15px" />
        {domainName}
      </span>
    </OverlayTrigger>
  );
};

export const TimeToolTip = ({ id, publishTime, width }) => {
  const [isHidden, setIsHidden] = useState(false);
  const setTitleRef = (refValue) => {
    if (!refValue) return;
    setIsHidden(refValue.offsetWidth < refValue.scrollWidth);
  };
  const timeText = calculateTimeAgo(publishTime, width <= 1500);

  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip
          id={`story_time_tooltip_${id}`}
          bsPrefix={cx({ hidden: !isHidden })}
        >
          {timeText}
        </Tooltip>
      )}
    >
      <span className={cx('story_time')} ref={setTitleRef}>
        {timeText}
      </span>
    </OverlayTrigger>
  );
};
