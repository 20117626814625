import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createCustomMessage, createClientMessage } from 'react-chatbot-kit';

import { chatbotInstance } from '../../../services/dbServices/api';

import updateChatbotMessageById from '../helpers/updateChatbotMessageById';

import * as chatbotActions from '../../../actions/chatbot.actions';
import * as authActions from '../../../actions/authentication.actions';

const ActionProvider = (props) => {
  const { createChatBotMessage, setState, children } = props;

  const userId = useSelector((state) => (state.userPreferencesReducer?.user?.id || 0));
  const user = useSelector((state) => (state.userPreferencesReducer?.user));

  const dispatch = useDispatch();
  const actions = useMemo(() => (
    bindActionCreators({
      ...chatbotActions,
      ...authActions,
    }, dispatch)
  ), [dispatch]);

  const addMessageToState = (message) => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const removeLastMessageInState = () => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages.slice(0, -1)],
    }));
  };

  const mainHandleLogicMultiIntents = (response, data, date) => {
    response.forEach((component, index) => {
      let intent = null;

      const message = { text: null, date };

      if (Array.isArray(component.components)) {
        intent = component?.intent?.toLowerCase();
        message.text = component?.text?.trim();
      } else if (component.Response) {
        const { Intent } = component;
        intent = Intent?.toLowerCase();
        message.text = component?.text?.trim();
      }
      if (!message.text?.length) message.text = 'Sorry, please try again or <a href="https://www.cityfalcon.ai/contact-us">contact us.</a>';
      let botMessage = null;

      if (component.components?.length) {
        botMessage = createChatBotMessage(message, {
          widget: intent,
          loading: false,
          terminateLoading: false,
          payload: { components: component?.components, date },
        });
      } else if ((intent === 'action_default_fallback')
      || (intent === 'action_none')) {
        botMessage = createChatBotMessage(message, {
          widget: 'action_default_fallback',
          loading: false,
          terminateLoading: false,
          payload: {
            data, component, date,
          },
        });
      } else if (intent === 'help') {
        botMessage = createChatBotMessage(message, {
          widget: 'options',
          loading: false,
          terminateLoading: false,
          payload: {
            data, component, date,
          },
        });
      } else if (intent === 'greet') {
        botMessage = createChatBotMessage(message, {
          widget: 'options',
          loading: false,
          terminateLoading: false,
          payload: {
            data, component, date,
          },
        });
      } else if (intent === 'exit') {
        botMessage = createChatBotMessage(message, {
          widget: 'exit',
          loading: false,
          terminateLoading: false,
          payload: {
            data, component, date,
          },
        });
      } else if (component.generic_component?.length) {
        botMessage = createChatBotMessage(message, {
          widget: 'generic_component',
          loading: false,
          terminateLoading: false,
          payload: {
            data, component, date,
          },
        });
      } else {
        botMessage = createChatBotMessage(message, {
          loading: false,
          terminateLoading: false,
          payload: {
            data, component, date,
          },
        });
      }

      if (index === 0) removeLastMessageInState();

      addMessageToState(botMessage);
      actions.createNewMessage(botMessage, userId);
      if (index === (response.length - 1)) actions.setLoading(false);
    });
  };

  const mainHandleLogicSingleIntent = (response, data, date) => {
    let intent = null;

    const message = { text: null, date };

    if (Array.isArray(response.components)) {
      intent = response?.intent?.toLowerCase();
      message.text = response?.text?.trim();
    } else if (response.Response) {
      const { Intent } = response;
      intent = Intent?.toLowerCase();
      message.text = response?.text?.trim();
    }
    if (!message.text?.length) message.text = 'Something happened and i couldn\'t find the information. Please try again';
    let botMessage = null;

    if (response.components?.length) {
      botMessage = createChatBotMessage(message, {
        widget: intent,
        loading: false,
        terminateLoading: false,
        payload: { components: response?.components, date },
      });
    } else if ((intent === 'action_default_fallback')
      || (intent === 'action_none')) {
      botMessage = createChatBotMessage(message, {
        widget: 'action_default_fallback',
        loading: false,
        terminateLoading: false,
        payload: {
          data, response, date,
        },
      });
    } else if (intent === 'help') {
      botMessage = createChatBotMessage(message, {
        widget: 'options',
        loading: false,
        terminateLoading: false,
        payload: {
          data, response, date,
        },
      });
    } else if (intent === 'greet') {
      botMessage = createChatBotMessage(message, {
        widget: 'options',
        loading: false,
        terminateLoading: false,
        payload: {
          data, response, date,
        },
      });
    } else {
      botMessage = createChatBotMessage(message, {
        loading: false,
        terminateLoading: false,
        payload: {
          data, response, date,
        },
      });
    }

    removeLastMessageInState();

    addMessageToState(botMessage);
    actions.createNewMessage(botMessage, userId);
    actions.setLoading(false);
  };

  const handleMessage = (data, deletePrevMessage = true) => {
    const { text } = data;
    const date = new Date();

    const payload = {
      query: text || 'hello',
    };

    if (deletePrevMessage) removeLastMessageInState();
    const clientMessage = createClientMessage({ text, date });
    addMessageToState(clientMessage);
    actions.createNewMessage({ message: { text, date }, type: 'user', payload }, userId);

    const loader = createCustomMessage('', 'loader');
    addMessageToState(loader);

    actions.setLoading(true);

    chatbotInstance.post('', payload).then((res) => {
      const { data } = res;
      const date = new Date();

      const response = data.data[0].custom;

      if (Array.isArray(response)) {
        mainHandleLogicMultiIntents(response, data, date);
      } else if (response) {
        mainHandleLogicSingleIntent(response, data, date);
      }
    }).catch((e) => {
      console.log(e);
      const botMessage = createChatBotMessage(
        {
          text: 'Sorry, please try again or <a href="https://www.cityfalcon.ai/contact-us">contact us.</a>',
          date: new Date(),
        },
      );
      removeLastMessageInState();
      addMessageToState(botMessage);
      actions.createNewMessage(botMessage, userId);
      actions.setLoading(false);
    });
  };

  const sendClientMessageOnRecommendedOptionClick = (data) => {
    if (!user) {
      actions.setFinPromptAuthModalOpened(true);
      return;
    }
    const clientMessage = createClientMessage(data);
    addMessageToState(clientMessage);
    handleMessage(data);
  };

  const handleYesNoButton = (decision, otherProps) => {
    if (!user) {
      actions.setFinPromptAuthModalOpened(true);
      return;
    }
    updateChatbotMessageById({ ...otherProps, decision }, userId);
    handleMessage({ text: decision, ...otherProps }, false);
  };

  return (
    <div>
      {React.Children.map(children, (child, index) => React.cloneElement(child, {
        actions: {
          handleMessage,
          sendClientMessageOnRecommendedOptionClick,
          handleYesNoButton,
        },
        key: `${index}_action-provider`,
      }))}
    </div>
  );
};
export default ActionProvider;
