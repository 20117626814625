import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { memo } from 'react';

const ShowButton = ({ handleClick, isChatbotOpened, expanded }) => (
  <div
    className={`widget__footer-icons${isChatbotOpened ? ' show' : ''}${expanded ? ' expanded' : ''}`}
    onClick={handleClick}
  >
    <div className="button-show-more">
      <span>See More</span>
      <FontAwesomeIcon
        className="widget__header-icon-arrow-right"
        icon={faChevronRight}
      />
    </div>
  </div>
);

export default memo(ShowButton);
