import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage as faLanguageRegular } from '@fortawesome/pro-regular-svg-icons/faLanguage';
import { faLanguage as faLanguageLight } from '@fortawesome/pro-light-svg-icons/faLanguage';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isMobile, isTablet } from 'react-device-detect';

import { calculateTimeAgo, returnCDNFoxyImageUrl } from '../../../../helpers/helpers';
import { getAverageSentimentStoryText } from '../../../../helpers/sentimentHelpers';

import Tooltip from '../../../Tooltip';
import CFLink from '../../../Shared/CFLink';

import { GOLD_PLAN } from '../../../../data/subscriptionPlans';
import { CF_URL_FOR_FINPROMPT } from '../../../../data/environment';

import useSizes from '../../../../hooks/sizes';
import useFinprompt from '../../../../providers/FinpromptProvider/useFinprompt';

import StoriesSvc from '../../../../services/StoriesSvc';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const NewsCard = (props) => {
  const { story, chatbot } = props;
  const history = useHistory();
  const [isFoxy, setIsFoxy] = useState(true);
  const [imageLoadingError, setImageLoadingError] = useState(false);
  const [domainLoadingError, setDomainLoadingError] = useState(false);
  const sentimentValue = (story.sentiment || story.sentiment === 0)
    && getAverageSentimentStoryText(story.sentiment);
  const [isHidden, setIsHidden] = useState(false);
  const [currentIcon, setCurrentIcon] = useState(faLanguageLight);
  const sourceCancelToken = useRef(axios.CancelToken.source());

  const {
    translate_all: showTranslation,
    translationSupportedLanguages,
  } = useSelector((state) => state.storyFilters);

  const { width } = useSizes();
  const { isFinpromptPages } = useFinprompt();

  const [isTranslationShown, setIsTranslationShow] = useState(showTranslation);
  const [translation, setTranslation] = useState({
    title: story?.translations?.[0]?.title,
    description: story?.translations?.[0]?.description,
  });
  const setTitleRef = (refValue) => {
    if (!refValue) return;
    setIsHidden(refValue.offsetHeight < refValue.scrollHeight);
  };

  const handleImageError = () => {
    if (isFoxy) {
      return setIsFoxy(false);
    }
    setImageLoadingError(true);
  };
  const handleDomainError = () => {
    setDomainLoadingError(true);
  };

  const handleStorieClick = (event) => {
    const isSingleStoryUrl = story.lowest_premium_plan === GOLD_PLAN && story.type !== 'Tweet';
    const url = story.url || story.expanded_url || (story.type === 'Tweet' ? `https://twitter.com/i/web/status/${story.additional_data.tweet_id}` : '');
    const correctPath = isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}/news/stories/${story.uuid_title}` : `/news/stories/${story.uuid_title}`;

    if (((isTablet || isMobile) && event?.target?.classList?.contains('score'))
    || event?.target?.tagName === 'A') {
      return;
    }

    if (isFinpromptPages) {
      window.open(correctPath, '_blank')?.focus?.();
    } else if (isSingleStoryUrl) {
      history.push(correctPath);
    } else {
      window.open(url, '_blank')?.focus?.();
    }
  };

  const imgURL = story.imageUrls?.[0];
  const domainImgUrl = story.domain_cached_large_logo_url || story.author_image_url;
  const imageURL = returnCDNFoxyImageUrl({
    isFoxy, imageURL: imgURL || story.author_image_url,
  });

  const setTranslations = async () => {
    if (!story.translations?.length) {
      const isTranslationSupported = translationSupportedLanguages?.map((el) => el.code).toString().split(',')?.includes(story.lang);
      if (!story?.translations?.length && isTranslationSupported && showTranslation) {
        if (!sourceCancelToken.current) {
          sourceCancelToken.current = axios.CancelToken.source();
        }

        const translatedData = await StoriesSvc.getTranslatedStory(story.uuid, 'en', sourceCancelToken.current.token);
        if (translatedData?.cancelled) return;
        story.translations = translatedData?.data?.article_translations;
        setTranslation({
          title: story?.translations?.[0]?.title,
          description: story?.translations?.[0]?.description,
        });
      }
    }
    if (story.translations !== null && story.translations && story.translations.length) {
      setTranslation({
        title: story?.translations[0]?.title,
        description: story?.translations[0]?.description,
      });
    }
  };

  const mouseEnterHandler = () => {
    setCurrentIcon(faLanguageRegular);
  };

  const mouseLeaveHandler = () => {
    setCurrentIcon(faLanguageLight);
  };

  useEffect(() => () => {
    if (sourceCancelToken.current) {
      sourceCancelToken.current.cancel();
    }
  }, []);

  useEffect(() => {
    const isTranslationSupported = translationSupportedLanguages?.map((el) => el.code).toString().split(',')?.includes(story.lang);
    if (isTranslationSupported && showTranslation) {
      setTranslations();
    } else if (sourceCancelToken.current) {
      sourceCancelToken.current.cancel();
      sourceCancelToken.current = null;
    }
    setIsTranslationShow(showTranslation);
  }, [showTranslation, story.translations]);

  const toggleTranslation = (e) => {
    e.stopPropagation();
    const newState = !isTranslationShown;

    if (translation.title) {
      setIsTranslationShow(newState);
    }
  };

  return (
    <div className={cx('news-card', { chatbot })} onClick={handleStorieClick}>
      <div className={Styles['news-card-img']}>
        {!imageLoadingError && imageURL ? (
          <img
            src={imageURL}
            loading="lazy"
            onError={handleImageError}
            alt=""
          />
        ) : (
          <>
            {domainImgUrl && !domainLoadingError && (
              <img
                src={domainImgUrl}
                loading="lazy"
                onError={handleDomainError}
                alt=""
              />
            )}
          </>
        )}
        <div className={Styles['news-card-img-grid']} />

        {(showTranslation && translationSupportedLanguages?.map((el) => el.code)
          .toString().split(',')?.includes(story.lang) && story.lang !== 'en') && (
          <div className={Styles['news-card-translation']}>
            {width < 768 && (
            <span
              className={cx('details-header__info--translate')}
              onMouseEnter={mouseEnterHandler}
              onMouseLeave={mouseLeaveHandler}
            >
              <Tooltip
                icon={(<FontAwesomeIcon icon={currentIcon} />)}
                hasBigScreenDesign
                isBigScreen
                dynamicPosition
              >
                <span className={cx('title')}>
                  {(() => {
                    if (translation && translation.title) {
                      if (isTranslationShown) {
                        return (
                          <>
                            <div className={cx('translated--from--title')}>
                              {`Translated from ${translationSupportedLanguages
                                ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                            </div>
                            <div
                              className={cx('translate--title')}
                              onClick={toggleTranslation}
                            >
                              Show Original
                            </div>
                          </>
                        );
                      }
                      return (
                        <>
                          <div className={cx('translated--from--title')}>
                            {`Original in ${translationSupportedLanguages
                              ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                          </div>
                          <div
                            className={cx('translate--title')}
                            onClick={toggleTranslation}
                          >
                            Translate to English
                          </div>
                        </>
                      );
                    }
                    return 'Translating to English...';
                  })()}
                </span>
              </Tooltip>
            </span>
            )}
            {width > 767 && (
            <span className={cx('title')}>
              {(() => {
                if (translation && translation.title) {
                  if (isTranslationShown) {
                    return (
                      <>
                        <span className={cx('translated--from--title')}>
                          {`Translated from ${translationSupportedLanguages
                            ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                        </span>
                        <span
                          className={cx('translate--title')}
                          onClick={toggleTranslation}
                        >
                          Show Original
                        </span>
                      </>
                    );
                  }
                  return (
                    <>
                      <span className={cx('translated--from--title')}>
                        {`Original in ${translationSupportedLanguages
                          ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                      </span>
                      <span
                        className={cx('translate--title')}
                        onClick={toggleTranslation}
                      >
                        Translate to English
                      </span>
                    </>
                  );
                }
                return 'Translating to English...';
              })()}
            </span>
            )}
          </div>
        )}
      </div>
      <div className={Styles['news-card-content']}>
        <Tooltip
          className={cx('news-card-content-title-tooltip', { hidden: !isHidden })}
          icon={(
            <div className={Styles['news-card-content-title']} ref={setTitleRef}>
              {(isTranslationShown && translation?.title) ? translation?.title : story.title}
            </div>
          )}
        >
          {(isTranslationShown && translation?.title) ? translation?.title : story.title}
        </Tooltip>
        {sentimentValue && (
          <div className={cx('news-card-content-sentiment', sentimentValue.type)}>
            <FontAwesomeIcon icon={sentimentValue.icon} />
          </div>
        )}
      </div>
      <div className={Styles['news-card-details']}>
        <div className={Styles['news-card-details-auth']}>
          {story.type === 'Tweet' ? (
            <>
              <img
                src={story.author_image_url}
                alt=""
                loading="lazy"
              />
              <div className={Styles['news-card-details-auth-name']}>
                @
                {story.author_screen_name}
              </div>
            </>
          ) : (
            <>
              <img
                src={story.domain_cached_logo_url}
                alt=""
                loading="lazy"
              />
              <div className={Styles['news-card-details-auth-name']}>{story.domain_name}</div>
            </>
          )}
        </div>
        <div className={Styles['news-card-details-time']}>
          {calculateTimeAgo(story.publishTime, true)}
        </div>
        <div
          className={cx('news-card-details-score')}
        >
          <Tooltip
            className={Styles['single-story-icon-tooltip']}
            icon={(
              <span className="score">
                {Math.round(story.score)}
                %
              </span>
            )}
            hasBigScreenDesign
          >
            CityFALCON Score showing relevance&nbsp;
            of story to you and your chosen topics.
            <CFLink
              external={isFinpromptPages}
              to="https://www.cityfalcon.com/features/cf-score"
              rel="noopener noreferrer"
            >
              How does it work?
            </CFLink>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
