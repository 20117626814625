import {
  TOGGLE_SCROLL_UP_STATE,
  SET_PAGE_SIZES,
  SET_COMMON_OTHERS_DEFAULT_PROPS,
  SET_COMMON_COOKIES_POLICY_STATUS,
} from '../actions/common.actions';

const initialState = {
  scrollUpPressed: false,
  width: 0,
  height: 0,
  cookiesPolicyStatus: false,
  serverProps: {
    initialized: false,
    props: {},
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_SCROLL_UP_STATE:
      return {
        ...state,
        scrollUpPressed: payload,
      };
    case SET_PAGE_SIZES:
      return {
        ...state,
        width: payload.width,
        height: payload.height,
      };
    case SET_COMMON_OTHERS_DEFAULT_PROPS:
      return {
        ...state,
        serverProps: {
          initialized: true,
          props: payload,
        },
      };
    case SET_COMMON_COOKIES_POLICY_STATUS:
      return {
        ...state,
        cookiesPolicyStatus: payload,
      };
    default:
      return state;
  }
};
