export const TOGGLE_SCROLL_UP_STATE = 'TOGGLE_SCROLL_UP_STATE';
export const SET_PAGE_SIZES = 'SET_PAGE_SIZES';
export const SET_COMMON_OTHERS_DEFAULT_PROPS = 'SET_COMMON_OTHERS_DEFAULT_PROPS';
export const SET_COMMON_COOKIES_POLICY_STATUS = 'SET_COMMON_COOKIES_POLICY_STATUS';

export const toggleScrollUpState = (payload) => ({
  type: TOGGLE_SCROLL_UP_STATE,
  payload,
});

export const setPageSizes = (payload) => ({
  type: SET_PAGE_SIZES,
  payload,
});

export const setOtherDefaultProps = (payload) => ({
  type: SET_COMMON_OTHERS_DEFAULT_PROPS,
  payload,
});

export const setCookiesPolicyStatus = (payload) => ({
  type: SET_COMMON_COOKIES_POLICY_STATUS,
  payload,
});
