import {
  memo,
  useState,
  useRef,
  useEffect,
} from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft as faChevronLeftLight } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight as faChevronRightLight } from '@fortawesome/pro-light-svg-icons/faChevronRight';

import TabItem from './TabItem';

import withComponentName from '../../../decorators/withComponentName';

import loadable from '../../../utils/loadable';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const ScrollMenu = loadable(() => import('react-horizontal-scrolling-menu'));

const Arrow = memo(({ direction, arrowClassName }) => (
  <div className={`${cx('arrow', direction, arrowClassName)} ${direction}`}>
    <FontAwesomeIcon icon={direction === 'left' ? faChevronLeftLight : faChevronRightLight} />
  </div>
));

export const ItemsScrollBar = memo(({
  data,
  onClick,
  scrollBy,
  className,
  itemClassName,
  arrowClassName,
  withUnderline,
  dragging,
  theme,
  scrollToSelected,
  skipTabGap,
  chatbotWindow,
}) => {
  const scrollMenuRef = useRef();
  const [leftArrowVisible, setLeftArrowVisible] = useState(false);
  const [rightArrowVisible, setRightArrowVisible] = useState(true);
  const [previousLength, setPreviousLength] = useState(0);
  const chatbotIsOpenedPrevValueRef = useRef(null);
  const scrollMenuWrapperRef = useRef(null);

  const isOpenedChatbot = useSelector((state) => state.chatbot.isChatbotOpened);
  const desktop = useSelector((state) => (state.common.width > 1024));

  const chatbotViewWasChanged = isOpenedChatbot && desktop;

  const [delayedTabsData, setDelayedTabsData] = useState([]);

  const handleArrowsVisible = (arrowType) => {
    const delay = chatbotViewWasChanged
    || (chatbotIsOpenedPrevValueRef?.current) ? 1000 : 0;

    setTimeout(() => {
      if (arrowType === 'left') {
        setLeftArrowVisible(scrollMenuRef?.current?.state?.leftArrowVisible);
      } else if (arrowType === 'right') {
        setRightArrowVisible(scrollMenuRef?.current?.state?.rightArrowVisible);
      } else {
        setRightArrowVisible(scrollMenuRef?.current?.state?.rightArrowVisible);
        setLeftArrowVisible(scrollMenuRef?.current?.state?.leftArrowVisible);
      }
    }, delay);
  };

  useEffect(() => {
    const delay = chatbotViewWasChanged
    || (chatbotIsOpenedPrevValueRef?.current) ? 800 : 0;

    const timer = setTimeout(() => {
      const computedTabsData = data.map((tab, index) => (
        <TabItem
          key={tab.id}
          className={cx('item', itemClassName, tab.className, theme, {
            first: skipTabGap && (index === 0),
            last: index === data.length - 1,
            active: tab.active,
          })}
          style={tab.style}
          active={tab.active}
          onClick={() => onClick(tab.id)}
          hasBigScreenDesign
        >
          {tab.content}
        </TabItem>
      ));

      chatbotIsOpenedPrevValueRef.current = chatbotViewWasChanged;

      setDelayedTabsData(computedTabsData);

      if (scrollMenuWrapperRef.current?.clientWidth > scrollMenuRef.current?.allItemsWidth) {
        if (scrollMenuRef.current.menuInner) {
          scrollMenuRef.current.menuInner.menuInner.elem.style.transform = 'translate3d(0px, 0px, 0px)';
        }
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [data, onClick, theme, itemClassName, chatbotViewWasChanged]);

  const selected = data.find((tab) => tab.active);

  useEffect(() => {
    if (!scrollToSelected) {
      return;
    }

    const timer = setTimeout(() => {
      setPreviousLength(delayedTabsData.length);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [delayedTabsData.length, scrollToSelected]);

  return (
    <div ref={scrollMenuWrapperRef} className={cx('items-scroll-bar', className, theme, { 'with-underline': withUnderline })}>
      <ScrollMenu
        hideArrows
        hideSingleArrow
        alignCenter={false}
        arrowDisabledClass="d-none"
        translate={1}
        transition={!scrollToSelected || previousLength === delayedTabsData.length ? 0.4 : 0}
        data={delayedTabsData}
        arrowLeft={<Arrow direction="left" arrowClassName={arrowClassName} />}
        arrowRight={<Arrow direction="right" arrowClassName={arrowClassName} />}
        scrollBy={scrollBy}
        dragging={dragging ?? ((isMobile || chatbotWindow) && (leftArrowVisible || rightArrowVisible))}
        ref={scrollMenuRef}
        onFirstItemVisible={() => {
          if (isMobile || chatbotWindow) return;
          handleArrowsVisible('left');
        }}
        onLastItemVisible={() => {
          if (isMobile || chatbotWindow) return;
          handleArrowsVisible('right');
        }}
        onUpdate={() => {
          if (isMobile || chatbotWindow) return;
          handleArrowsVisible();
        }}
        selected={selected && String(selected.id)}
        scrollToSelected={scrollToSelected}
      />
    </div>
  );
});

ItemsScrollBar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      content: PropTypes.node,
      active: PropTypes.bool,
      className: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func,
  scrollBy: PropTypes.number,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  withUnderline: PropTypes.bool,
  dragging: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'black']),
  scrollToSelected: PropTypes.bool,
  skipTabGap: PropTypes.bool,
};

ItemsScrollBar.defaultProps = {
  onClick: () => {},
  scrollBy: 3,
  className: '',
  itemClassName: '',
  arrowClassName: '',
  withUnderline: false,
  dragging: null,
  theme: 'default',
  scrollToSelected: false,
  skipTabGap: false,
};

export default withComponentName(ItemsScrollBar);
