import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import defaultAvatar from '../../../assets/header/default-user-logo.svg';
import withComponentName from '../../../decorators/withComponentName';
import { SILVER, GOLD } from '../../../data/permissions';

import { handleMethod } from '../../../helpers/railsHelpers';
import sendSignOutRequestFinPromptHomePage from '../../../helpers/sendSignOutRequestFinPromptHomePage';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const LoggedInUserDropdown = ({
  user, isOpenedUserMobile,
  trackUserAccountAction, headerType,
  accessLevels, isRailsContext,
  isFinpromptPages, getUserName,
}) => {
  const signOut = (e) => {
    e.preventDefault();
    if (isFinpromptPages) {
      sendSignOutRequestFinPromptHomePage();
      return;
    }

    trackUserAccountAction('signOut');
    handleMethod(e.target);
  };

  return (
    <div
      className={
        cx('user_block_mobile',
          { opened: isOpenedUserMobile },
          { 'homepage-user-dropdown': headerType === 'homePageHeader' },
          { finprompt: isFinpromptPages })
      }
    >
      <img src={user.image_url || defaultAvatar} alt="Avatar" />
      <span className={Styles.user_name}>{getUserName()}</span>
      <span className={Styles.user_email}>{user.email}</span>
      {!isFinpromptPages && accessLevels.includes(SILVER) && !accessLevels.includes(GOLD) && (
        <div className={cx('upgrade_button', 'gold')}>
          <LinkComponent
            path="/pricing"
            title="Upgrade to Gold"
            isRailsContext={isRailsContext}
          />
        </div>
      )}
      {!isFinpromptPages && !accessLevels.includes(SILVER) && (
        <div className={cx('upgrade_button')}>
          <LinkComponent
            path="/pricing"
            title={(
              <>
                Start 7-days free trial
                <span>No credit card required</span>
              </>
            )}
            isRailsContext={isRailsContext}
          />
        </div>
      )}
      <div className={Styles.mobile_block_with_links}>
        {!isFinpromptPages && (
          <LinkComponent
            isRailsContext={isRailsContext}
            title="Manage Watchlists"
            path="/watchlists/manage"
          />
        )}
        <LinkComponent
          isRailsContext={isRailsContext}
          title="Account"
          path="/preferences/account"
          handleClick={() => trackUserAccountAction('manage')}
          customClassName={isFinpromptPages ? cx('disabled') : ''}
        />
        {isFinpromptPages && (
          <LinkComponent
            isRailsContext={isRailsContext}
            title="View Demo"
            path="/demo"
          />
        )}
        {isFinpromptPages ? (
          <p
            onClick={signOut}
            className={Styles['log-out']}
          >
            Sign Out
          </p>
        ) : (
          <a
            rel="nofollow"
            onClick={signOut}
            href="/users/sign_out"
            data-method="delete"
            className={Styles['log-out']}
          >
            Sign Out
          </a>
        )}
      </div>
    </div>
  );
};

const LinkComponent = ({
  isRailsContext, title, path, handleClick = () => {}, customClassName = '',
}) => {
  if (isRailsContext) {
    return (
      <a
        rel="nofollow"
        onClick={handleClick}
        href={path}
        className={customClassName}
      >
        {title}
      </a>
    );
  }
  return (
    <Link
      rel="nofollow"
      onClick={handleClick}
      to={path}
      className={customClassName}
    >
      {title}
    </Link>
  );
};

export default withComponentName(LoggedInUserDropdown);
