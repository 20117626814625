import { useSelector } from 'react-redux';

import { memo, useEffect } from 'react';
import Scrollable from '../../Shared/Scrollable';

import icon from '../../../assets/chatbot/optionsIcons/Icon.svg';
import icon4 from '../../../assets/chatbot/optionsIcons/happens.svg';
import icon2 from '../../../assets/chatbot/optionsIcons/Icon2.svg';
import icon5 from '../../../assets/chatbot/optionsIcons/Icon5.svg';
import icon3 from '../../../assets/chatbot/optionsIcons/sentiment.svg';

const Options = (props) => {
  const { scrollToBottomOnWidgetRendered, isChatbotOpened, payload } = props;

  const messageDate = payload.date;

  const isTablet = useSelector(({ common }) => (
    !(common.width > 1024)
  ));

  const loading = useSelector(({ chatbot }) => (
    chatbot.loading
  ));

  const handleClick = (story) => {
    const { actionProvider } = props;

    if (!loading) {
      actionProvider.sendClientMessageOnRecommendedOptionClick(
        {
          text: story.description,
        },
      );
    }
  };

  const stories = [
    {
      description: 'What is Trending?',
      img: icon,
      id: 0,
    },
    {
      description: 'What\'s happening in the US today?',
      img: icon4,
      id: 1,
      classname: 'middle',
    },
    {
      description: "Show me Tesla's price chart",
      img: icon5,
      id: 2,
      classname: 'middle',
    },
    {
      description: 'What are Microsoft`s insiders doing?',
      img: icon2,
      id: 3,
      classname: 'bigger',
    },
    {
      description: 'What commodity has the highest sentiment today?',
      img: icon3,
      id: 4,
      classname: 'biggest',
    },
  ];

  useEffect(() => {
    if (!isChatbotOpened) return;
    if (scrollToBottomOnWidgetRendered) scrollToBottomOnWidgetRendered();
  }, [isChatbotOpened]);

  return (
    <div
      data-date={messageDate}
      className="react-chatbot-kit__widget__wrapper options"
    >
      <Scrollable
        wrapperClassName="chatbot-widget__scrollable"
        arrowClass="top-news-stories-arrow"
        disabled={false}
        hideArrows={isTablet}
        isAllowFull={!isTablet}
        widget
        arrowAndContentAlignment
      >
        {stories?.map((item) => (
          <div
            className={`react-chatbot-kit__widget-options__item ${item.classname ? item.classname : ''}`}
            onClick={() => handleClick(item)}
            key={item.id}
          >
            <div className="react-chatbot-kit__widget-options__item-image">
              <img alt="imag" src={item.img} />
            </div>
            <h4>
              {item.description}
            </h4>
          </div>
        ))}
      </Scrollable>
    </div>
  );
};

export default memo(Options);
