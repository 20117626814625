import React from 'react';

class ErrorBoundaryClass extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary__wrapper">
          <p className="error-boundary__content">
            An error has occurred. Please try again later. If the problem persists,
            {' '}
            <a
              href="https://www.cityfalcon.ai/contact-us"
            >
              contact us.
            </a>
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundaryClass;
