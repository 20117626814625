import axios from 'axios';
import { stakeholdersInstance as StakeholdersAPI } from './topicsApi';

import copy from '../../helpers/copy';

import { getAPICachingTime } from '../../helpers/helpers';

import { cachingMedium } from '../../data/webPageData';

const caches = {
  stakeholdersSearch: {},
};

export class StakeholdersSvc {
  static async searchStakeholders(extraParams = {}) {
    try {
      const { cancelToken, ...params } = extraParams;
      const key = JSON.stringify(params);
      if (caches.stakeholdersSearch[key] && caches.stakeholdersSearch[key].expDate > Date.now()) {
        return copy(caches.stakeholdersSearch[key].response);
      }

      const { data } = await StakeholdersAPI.get('search', {
        params,
        cancelToken,
      });

      caches.stakeholdersSearch[key] = {
        expDate: getAPICachingTime(cachingMedium),
        response: data,
      };

      return data;
    } catch (e) {
      return {
        error: true,
        cancelled: axios.isCancel(e),
      };
    }
  }
}

export default StakeholdersSvc;
