import StorageSvc from '../services/StorageSvc';

export const TOOGLE_BOT = 'TOOGLE_BOT';
export const TOOGLE_MODAL = 'TOOGLE_MODAL';
export const TOOGLE_EXPAND = 'TOOGLE_EXPAND';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const TOOGLE_WIDGET_STATE = 'TOOGLE_WIDGET_STATE';
export const SAVE_MESSAGES = 'SAVE_MESSAGES';
export const SAVE_CURRENT_DATE = 'SAVE_CURRENT_DATE';
export const SET_LOADING = 'SET_LOADING';
export const TOOGLE_OUTSIDE_MODAL = 'TOOGLE_OUTSIDE_MODAL';

export const toggleBot = (newState) => ({
  type: TOOGLE_BOT,
  payload: newState,
});
export const toggleModalInsideChatbot = (newState) => ({
  type: TOOGLE_MODAL,
  payload: newState,
});
export const toggleModalOutsideChatbot = (newState) => ({
  type: TOOGLE_OUTSIDE_MODAL,
  payload: newState,
});

export const toggleExpand = (newState) => ({
  type: TOOGLE_EXPAND,
  payload: newState,
});

export const addMessage = (newMessage) => ({
  type: ADD_MESSAGE,
  payload: newMessage,
});

export const saveMessages = (messages) => ({
  type: SAVE_MESSAGES,
  payload: messages,
});

export const toogleWidgetState = (widget) => ({
  type: TOOGLE_WIDGET_STATE,
  payload: widget,
});

export const saveCurrentDate = (date) => ({
  type: SAVE_CURRENT_DATE,
  payload: date,
});

export const setLoading = (newState) => ({
  type: SET_LOADING,
  payload: newState,
});

export const closeChatbotWindow = () => (dispatch) => {
  dispatch(toggleBot(false));
};

export const saveMessageInLocalStorage = (message, userId = 0) => {
  const newMessage = message;
  const history = JSON.parse(StorageSvc.getItem('chatbotMessages')) || [];
  let currentUserHistory = [];
  let otherUsersHistory = [];

  if (history) {
    currentUserHistory = history?.filter((item) => item.userId === userId);
    otherUsersHistory = history?.filter((item) => item.userId !== userId);
  }
  const messages = currentUserHistory[0]?.messages || [];

  // leave only 50 last messages in history web-6873
  if (messages?.length >= 50) {
    messages.splice(0, 1);
  }

  if (!messages.length) {
    StorageSvc.setItem('chatbotMessages', JSON.stringify([...history, { messages: [{ ...newMessage }], userId }]));
  } else {
    StorageSvc.setItem('chatbotMessages', JSON.stringify([...otherUsersHistory, { ...currentUserHistory[0], messages: [...messages, { ...newMessage }] }]));
  }

  return newMessage;
};

export const createNewMessage = (newMessage, userId) => (dispatch) => {
  const messageWithAddedNewProp = saveMessageInLocalStorage(newMessage, userId);

  dispatch(addMessage(messageWithAddedNewProp));
};
