async function createHash(message, algo = 'SHA-1') {
  // Crypto is not working on secure contexts (with HTTP)
  // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  // So to not break local development, we will return the message as is
  if (!crypto.subtle) {
    return message;
  }

  return Array.from(
    new Uint8Array(
      await crypto.subtle.digest(algo, new TextEncoder().encode(message)),
    ),
    (byte) => byte.toString(16).padStart(2, '0'),
  ).join('');
}

export default createHash;
