import moment from 'moment';
import isEqual from 'lodash-es/isEqual';

import { getUserToken } from '../helpers/getUserToken';
import { clearFilingsFiltersStates } from '../services/localStorage';
import { loadPreferencesAction, updateUserPreferences } from './preferencesActions';
import { saveStoryFilters } from './storyFilters.action';
import { saveFilters } from '../services/dbServices/filtersDataService';
import { fetchPreferences, updatePreferences } from '../services/dbServices/userServices';

import StorageSvc from '../services/StorageSvc';

const initialState = {
  filing_period_start_datetime: moment().add(-1, 'y').toDate(),
  filing_period_end_datetime: moment().toDate(),
  filing_source_ids: [],
  filing_category_ids: [],
  filing_type_ids: [],
  expand_filings: false,
  filing_types_all: true,
  filing_categories_all: true,
  filing_sources_all: true,
  filings_time_filter: 'lifetime',
};

export const RESET_FILINGS_FILTERS = 'RESET_FILINGS_FILTERS';
export const TOGGLE_EXPAND_FILINGS = 'TOGGLE_EXPAND_FILINGS';
export const FILINGS_FILTER_BY_DATE = 'FILINGS_FILTER_BY_DATE';
export const FILINGS_FILTER_BY_ALL_DATE = 'FILINGS_FILTER_BY_ALL_DATE';
export const FILINGS_FILTER_BY_SOURCE = 'FILINGS_FILTER_BY_SOURCE';
export const FILINGS_FILTER_BY_CATEGORY = 'FILINGS_FILTER_BY_CATEGORY';
export const FILINGS_FILTER_BY_ALL_CATEGORY = 'FILINGS_FILTER_BY_ALL_CATEGORY';

const getFormattedDataToPreferencesEndpoint = (state) => {
  const param = {
    filing_period_start_datetime: state.filing_period_start_datetime,
    filing_period_end_datetime: state.filing_period_end_datetime,
    expand_filings: state.expand_filings,
    filing_categories_all: state.filing_categories_all,
    filing_types_all: state.filing_types_all,
    filings_time_filter: state.filings_time_filter,
  };

  if (typeof state.filing_source_ids === 'string') {
    param.filing_source_ids = state.filing_source_ids;
  } else {
    param.filing_source_ids = (state.filing_source_ids || []).join(',');
  }
  if (typeof state.filing_category_ids === 'string') {
    param.filing_category_ids = state.filing_category_ids;
  } else {
    param.filing_category_ids = (state.filing_category_ids || []).join(',');
  }
  if (typeof state.filing_type_ids === 'string') {
    param.filing_type_ids = state.filing_type_ids;
  } else {
    param.filing_type_ids = (state.filing_type_ids || []).join(',');
  }

  return param;
};

export const resetAllFilters = (defaultFiltersValue) => ({
  type: RESET_FILINGS_FILTERS,
  payload: defaultFiltersValue,
});

export const loadFilters = (filingFilters) => (dispatch) => {
  // terminate if user not logged in
  if (!getUserToken()) {
    // check for preventing error on server side rendering
    if (
      typeof window === 'object'
      && 'setTimeout' in window
    ) setTimeout(() => dispatch(saveStoryFilters(filingFilters)));
    return;
  }

  // check if user has custom filing filters to load it instead of load the filters from db
  if (
    !isEqual(initialState, filingFilters)
    && !JSON.parse(StorageSvc.getItem('filingsFiltersTransferred'))
  ) {
    // load the custom filing filters user selected from localStorage and save it to db
    const preferenceData = getFormattedDataToPreferencesEndpoint(filingFilters);

    updatePreferences(preferenceData).then(({ data }) => {
      StorageSvc.setItem('filingsFiltersTransferred', 'true');

      // remove all localstorage filters after they transferred to registered user
      clearFilingsFiltersStates();

      dispatch(saveStoryFilters(data));
      dispatch(loadPreferencesAction(data));
    });
  } else {
    // load filing filters saved in db into app
    fetchPreferences().then(({ data }) => {
      const newData = {
        ...data.preference,
        languages: data.preference.lang,
      };
      dispatch(saveStoryFilters(newData));
      dispatch(loadPreferencesAction(newData));
    });
  }
};

export const toggleExpandFilings = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ expand_filings: newState });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: TOGGLE_EXPAND_FILINGS,
    payload: newState,
  });
};

export const filterByAllDate = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({
      filings_time_filter: newState.filings_time_filter,
    });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: FILINGS_FILTER_BY_ALL_DATE,
    payload: newState,
  });
};

export const filterByDate = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({
      filing_period_start_datetime: newState.filing_period_start_datetime,
      filing_period_end_datetime: newState.filing_period_end_datetime,
      filings_time_filter: newState.filings_time_filter,
    });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: FILINGS_FILTER_BY_DATE,
    payload: newState,
  });
};

export const filterBySource = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({
      filing_source_ids: newState.filing_source_ids.join(','),
      filing_sources_all: newState.filing_sources_all,
    });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: FILINGS_FILTER_BY_SOURCE,
    payload: newState,
  });
};

export const filterByCategory = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({
      filing_categories_all: newState.filing_categories_all,
      filing_types_all: newState.filing_types_all,
      filing_category_ids: newState.filing_category_ids.join(','),
      filing_type_ids: newState.filing_type_ids.join(','),
    });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: FILINGS_FILTER_BY_CATEGORY,
    payload: newState,
  });
};

export const filterByAllCategory = (newState, token) => async (dispatch) => {
  const param = {
    filing_categories_all: newState.filing_categories_all,
    filing_types_all: newState.filing_types_all,
    filing_category_ids: '',
    filing_type_ids: '',
  };
  if (token) {
    const preference = await saveFilters(param);
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: FILINGS_FILTER_BY_ALL_CATEGORY,
    payload: param,
  });
};

export const resetAllFiltersAction = (token, defaultFilters) => async (dispatch) => {
  const params = {
    ...initialState,
    ...defaultFilters,
    filing_source_ids: '',
    filing_category_ids: '',
    filing_type_ids: '',
  };

  if (token) {
    const preference = await saveFilters(params);
    dispatch(updateUserPreferences(preference));
    preference.filing_source_ids = '';
    preference.filing_category_ids = '';
    preference.filing_type_ids = '';
    return dispatch(loadFilters(preference));
  }
  dispatch(resetAllFilters(initialState));
};
