import { memo } from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';

import Tooltip from '../../Tooltip';

import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const FilingDate = ({
  date,
  short = false,
  className = '',
  hasBigScreenDesign,
}) => {
  if (!date) {
    return '';
  }

  if (new Date(date) <= new Date()) {
    return moment(date).format('DD MMM YYYY');
  }

  function filingDateHandleClick(e) {
    e.stopPropagation();
  }

  return (
    <div onClick={filingDateHandleClick} className={`${cx('future-date', { short, big_screen: hasBigScreenDesign })} ${className}`}>
      {moment(date).format('hh:mm, DD MMM YYYY')}
      <Tooltip className={Styles['future-date-tooltip']} hasBigScreenDesign={hasBigScreenDesign}>
        Some filings may be future-dated.&nbsp;
        All dates are provided by the managing agency and filing party, including future dates.
      </Tooltip>
    </div>
  );
};

export default memo(withComponentName(FilingDate));
