export const headerProductsMenu = [
  {
    menuTitle: 'Consumer',
    menuKey: 'consumerDropdown',
    opened: false,
    menuSubItems: [],
    defaultTab: 'retailInvestors',
    links: [
      {
        linkItem: 'All Products',
        route: '/products',
        useAnchor: true,
      },
      {
        linkItem: 'CityFALCON.ai',
        route: '/products/webapp',
        useAnchor: true,
      },
      {
        linkItem: 'IOS / Android Apps',
        useAnchor: true,
        route: 'https://www.cityfalcon.ai/products/mobileapp',
      },
      {
        linkItem: 'CityFALCON Voice',
        useAnchor: true,
        route: '/products/voiceassistant',
      },
      {
        linkItem: 'CityFALCON Chat Bot',
        comingSoon: true,
        route: '',
      },
    ],
    tabsData: [
      {
        tabTitle: 'Retail Investors',
        tabKey: 'retailInvestors',
        tabDescriptionText: `Discover more opportunity, capture more upside, manage risk and portfolio knowledge better,
         and streamline due diligence with an array of features, analytics, and financial content tailored to the
          ordinary investor, powered by Big Data and AI.`,
        fullLink: true,
        link: 'https://www.cityfalcon.ai/segments/retail-investors',
      },
      {
        tabTitle: 'Retail Traders',
        tabKey: 'retailTraders',
        tabDescriptionText: `Never miss a trend, gauge outlook before prices move, perform needed due diligence, use
         summaries to reduce reaction time, funnel Big Data to your algorithms, and stay ahead of markets with content,\
          analytics, and insights.`,
        fullLink: true,
        link: 'https://www.cityfalcon.ai/segments/retail-traders',
      },
      {
        tabTitle: 'Daily Business News Reader',
        tabKey: 'dailyBusiness',
        tabDescriptionText: `Stay current on market movements, major announcements, and general market happenings with
         curated content, analytics, select paywall-free articles, trends, and more, driven by Big Data and AI.`,
        fullLink: true,
        link: 'https://www.cityfalcon.ai/segments/daily-business-readers',
      },
      {
        tabTitle: 'Angels, P2P Investors',
        tabKey: 'angelsP2P',
        tabDescriptionText: `Discover potential unicorns and growth opportunity, perform pre-investment due diligence
         with filings and post-investment portfolio management with insights, and stay ahead of market news with
          summaries and curated content, data, and analytics.`,
      },
    ],
  },
  {
    menuTitle: 'Enterprise',
    menuKey: 'entepriseDropdown',
    opened: false,
    menuSubItems: [],
    defaultTab: 'brokers',
    links: [
      {
        linkItem: 'All Solutions',
        route: '/enterprise-products',
        useAnchor: true,
      },
      {
        linkItem: 'Financial News API',
        route: '/products/api/financial-news',
        useAnchor: true,
      },
      {
        linkItem: 'Financial Entities and Events Extraction with NLP',
        route: '/products/api/natural-language-processing',
      },
      {
        linkItem: 'Extract and Structure Insights from Your Own Content',
        route: '/products/extract-and-structure-insights',
        useAnchor: true,
      },
      {
        linkItem: 'Integrations',
        route: '/integrations',
        useAnchor: true,
      },
    ],
    tabsData: [
      {
        tabTitle: 'Brokers',
        tabKey: 'brokers',
        tabDescriptionText: `Acquire, inform, and engage users with real-time relevant financial content and increase 
        your users, ARPU, and revenue.  Integrate company filings, the latest market news, analytics and insights to
         cut to the core of what’s really happening, summarisations and price action explanations, and more, all powered
          by Big Data and machine learning.`,
        link: '/segments/brokers',
      },
      {
        tabTitle: 'Students, Professors, Academia',
        tabKey: 'profesors',
        tabDescriptionText: `Elevate your learning, teaching, or researching with analytics and global, up-to-the-minute
         content. Source concrete evidence, dive deep into Big Data reservoirs, and automate projects.`,
        link: '/segments/academia',
      },
      {
        tabTitle: 'Wealth Managers, Financial Advisors',
        tabKey: 'wealthManagers',
        tabDescriptionText: `Find and research investment opportunities, manage knowledge for portfolios, and deliver
         better client service with Big Data, analytics, and global financial content.  Acquire new and keep existing
          clients informed and engaged.`,
        link: '/segments/wealth-managers-advisors',
        useAnchor: true,
      },
      {
        tabTitle: 'P2P Crowdfunding, VC, PE',
        tabKey: 'p2p',
        tabDescriptionText: `Perform sophisticated due diligence pre-investment and manage assets post-investment with
         current financial content, analytics, private company regulatory filings and insights, and AI-powered market
         summarisations.  Track the macro environment by sectors and/or locations and don’t miss any investment
         opportunities`,
        link: '/segments/private-equity',
        useAnchor: true,
      },
      {
        tabTitle: 'Institutional Investors, Treasury',
        tabKey: 'institutionalInvestors',
        tabDescriptionText: `Streamline your due diligence, manage portfolio knowledge, and manage risk in your
         fiduciary duties with the support of Big Data, analytics, global content coverage, and AI-powered market
          insights.`,
        link: '/segments/institutional',
        useAnchor: true,
      },
      {
        tabTitle: 'Consultancy, Legal, Accounting',
        tabKey: 'consultancy',
        tabDescriptionText: `Manage knowledge internally and for clients regarding topics of concern for macro and 
        company-specific viewpoints with global content coverage, Big Data, analytics, and AI-powered market insights.`,
        link: '/segments/consultancy-legal-accounting',
        useAnchor: true,
      },
      {
        tabTitle: 'Central Banks, Regulatory Agencies',
        tabKey: 'centralBanks',
        tabDescriptionText: `Track performance and consumer reviews for financial institutions in your geography and
         globally. Shape better policy by staying informed with the latest market news, gauge market mindsets with
         analytics, and react to foreign regulatory announcements faster with Big Data and AI-powered insights and
         content.`,
        link: '/segments/central-banks-regulatory',
        useAnchor: true,
      },
      {
        tabTitle: 'Equity, Fixed Income, IB, Micro Research',
        tabKey: 'equity',
        tabDescriptionText: `Perform superior research and publish enhanced reports by empowering your research teams
         with Big Data reservoirs, up-to-the-moment global financial content, company filings, and analytics that cut
          away irrelevancy. Save time, write better.`,
      },
      {
        tabTitle: 'Strategy, Product, Marketing',
        tabKey: 'strategy',
        tabDescriptionText: `Better business development, more focused campaigns, and a stronger market position via
         Big Data and AI-driven content, analytics, time-saving summarisations, and competition monitoring.`,
      },
    ],
  },
  {
    menuTitle: 'Features and Content',
    menuKey: 'keyFeaturesDropdown',
    opened: false,
    defaultTab: '',
    menuSubItems: [
      {
        subItemTitle: 'Features',
        subItemKey: 'keyFeaturesItem',
        subItemLinks: [
          {
            linkItem: 'CityFALCON Score',
            route: '/features/cf-score',
            useAnchor: true,
          },
          {
            linkItem: 'Watchlists',
            route: '/features/watchlists',
            useAnchor: true,
          },
          {
            linkItem: 'Similar Stories Grouping',
            route: '/features/similarity',
            useAnchor: true,
          },
          {
            linkItem: 'News on Charts',
            route: '/features/news-on-charts',
            useAnchor: true,
          },
          {
            linkItem: 'Key Headlines',
            route: '/features/summarisation',
            useAnchor: true,
          },
          {
            linkItem: 'Sentiment',
            route: '/features/sentiment',
            useAnchor: true,
          },
          {
            linkItem: 'Content Translation',
            route: '/features/translation',
            useAnchor: true,
          },
          {
            linkItem: 'Push Notifications',
            route: 'https://www.cityfalcon.ai/features/push-notifications',
            useAnchor: true,
          },
        ],
      },
      {
        subItemTitle: 'Content',
        subItemKey: 'keyContentItem',
        subItemLinks: [
          {
            linkItem: 'News',
            route: 'https://www.cityfalcon.ai/learn-more',
            useAnchor: true,
          },
          {
            linkItem: 'Premium Publications',
            route: 'https://www.cityfalcon.ai/products/premium-publications',
            useAnchor: true,
          },
          {
            linkItem: 'Insider Transactions',
            route: 'https://www.cityfalcon.ai/features/insider-transactions',
            useAnchor: true,
          },
          {
            linkItem: 'Official Company Filings',
            route: '/features/filings',
            useAnchor: true,
          },
          {
            linkItem: 'Investor Relations',
            route: '/features/investor-relations',
            useAnchor: true,
          },
          {
            linkItem: 'UK Private Company Insights',
            route: 'https://www.cityfalcon.ai/content/uk-private-company-insights',
            useAnchor: true,
          },
          {
            linkItem: 'ESG Content',
            route: '/features/esg',
            useAnchor: true,
          },
          {
            linkItem: 'Languages',
            route: '/features/language-coverage',
            useAnchor: true,
          },
        ],
      },
    ],
    links: [
      {
        linkItem: 'Languages',
        route: '/features/language-coverage',
        useAnchor: true,
      },
      {
        linkItem: 'Sentiment',
        route: '/features/sentiment',
        useAnchor: true,
      },
      {
        linkItem: 'Key Headlines',
        route: '/features/summarisation',
        useAnchor: true,
      },
      {
        linkItem: 'ESG Content',
        route: '/features/esg',
        useAnchor: true,
      },
      {
        linkItem: 'Investor Relations',
        route: '/features/investor-relations',
        useAnchor: true,
      },
      {
        linkItem: 'Official Company Filings',
        route: '/features/filings',
        useAnchor: true,
      },
      {
        linkItem: 'News on Charts',
        route: '/features/news-on-charts',
        useAnchor: true,
      },
      {
        linkItem: 'Similar Stories Grouping',
        route: '/features/similarity',
        useAnchor: true,
      },
      {
        linkItem: 'Watchlists',
        route: '/features/watchlists',
        useAnchor: true,
      },
      {
        linkItem: 'CityFALCON Score',
        route: '/features/cf-score',
        useAnchor: true,
      },
      {
        linkItem: 'Premium Publications',
        route: 'https://www.cityfalcon.ai/products/premium-publications',
        useAnchor: true,
      },
      {
        linkItem: 'All Features and Content',
        route: 'https://www.cityfalcon.ai/features',
        useAnchor: true,
      },
    ],
    tabsData: [],
  },
  {
    menuTitle: 'Pricing',
    menuKey: 'pricingDropdown',
    opened: false,
    menuSubItems: [],
    defaultTab: '',
    links: [
      {
        linkItem: 'Subscription Plans',
        route: '/pricing',
      },
      {
        linkItem: 'Real-time API Plans',
        route: '/products/api/financial-news/pricing-api/commercial',
      },
      {
        linkItem: 'Historical Data Sets',
        route: '/products/api/financial-news/pricing-historical',
      },
    ],
    tabsData: [],
  },
];

export default headerProductsMenu;
