import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';

import StoryCard from '../../../../TrendingStories/StoryCard';
import ModalSummaries from '../StoriesModalDesktop/ModalSummaries';
import withComponentName from '../../../../../decorators/withComponentName';

import PiwikContext from '../../../../../services/piwikContext';
import { CRYPTOCURRENCIES } from '../../../../../data/directory/topic_classes/topicClassTypes';
import Preloader from '../../../../Preloader';

import { CHART_MAX_DATE } from '../../../../../data/filters/filterConstants';
import {
  ALL, FIVE_YEAR, ONE_DAY, ONE_MONTH, ONE_YEAR, ON_CHART_MODAL_TABS,
} from '../../../../../data/directory/topic_classes/ChartFilters';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);
const HEADER_HEIGHT = 148;

export const StoriesModalMobile = forwardRef(({
  storiesData,
  showStories,
  setShowStories,
  date,
  price,
  volume,
  startDate,
  endDate,
  timeFilter,
  homePage,
  assetType,
  tabs,
  setActiveTab,
  summaryData,
}, ref) => {
  const heightRef = useRef(window.innerHeight);
  const storiesRef = useRef();
  const [top, setTop] = useState(heightRef.current - (homePage ? 0 : HEADER_HEIGHT));
  const initialCursorTop = useRef(0);
  const { piwikEnabled } = useContext(PiwikContext);
  const isStartDateAcceptable = moment(startDate).isAfter(CHART_MAX_DATE);
  const { id: activeTabId } = tabs.find((tab) => tab.active);

  const preventTouchMove = useCallback((event) => {
    if (event.cancelable) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }, []);

  const onTouchStart = useCallback((e) => {
    document.body.classList.add(Styles.fixed);
    document.documentElement.classList.add(Styles.fixed);
    window.addEventListener('touchmove', preventTouchMove, { passive: false });
    const { clientY } = e.changedTouches[0];
    initialCursorTop.current = clientY - top;
    heightRef.current = window.innerHeight;
    return false;
  }, [top]);

  const onTouchMove = useCallback((e) => {
    const { clientY } = e.changedTouches[0];
    const { current: height } = heightRef;

    let topValue = clientY - initialCursorTop.current;
    if (homePage) {
      if ((height - topValue) <= 0) {
        topValue = height;
      } else if ((height - topValue) >= 500) {
        topValue = top;
      }
    } else if (topValue > height - HEADER_HEIGHT) {
      topValue = height - HEADER_HEIGHT;
      if (showStories) setShowStories(false);
    } else if (topValue < height - storiesRef.current.offsetHeight) {
      topValue = Math.max(height - storiesRef.current.offsetHeight, 0);
      if (!showStories) setShowStories(true);
    } else if (!showStories) {
      setShowStories(true);
    }

    setTop(topValue);
  }, [top]);

  const onTouchEnd = useCallback(() => {
    document.body.classList.remove(Styles.fixed);
    document.documentElement.classList.remove(Styles.fixed);
    window.removeEventListener('touchmove', preventTouchMove, { passive: true });
  }, [showStories, setShowStories, ref]);

  const onOpen = () => {
    const { current: height } = heightRef;
    setTop(Math.max(height - storiesRef.current.offsetHeight, 0));
  };

  const onClose = () => {
    setTop(heightRef.current - (homePage ? 0 : HEADER_HEIGHT));
  };

  useEffect(() => {
    if (!isStartDateAcceptable) {
      onClose();
    }
  }, [isStartDateAcceptable]);

  const cryptoBigInterval = assetType === CRYPTOCURRENCIES
  && (timeFilter === ONE_YEAR || timeFilter === FIVE_YEAR || timeFilter === ALL);
  const withGMTLabel = timeFilter === ONE_DAY && !cryptoBigInterval;

  const headerDate = () => {
    if (cryptoBigInterval) {
      return moment(date).format('DD MMM YYYY');
    }
    return moment(date).format('DD MMM YYYY HH:mm');
  };

  const chartMainWrapper = homePage ? useRef(document.getElementById('home_news_chart')).current : document.body;
  const heightValue = heightRef?.current - top;

  const wrapperStyle = {};
  const innerStyle = {};
  if (homePage) {
    wrapperStyle.bottom = '0';
    wrapperStyle.position = 'absolute';
    wrapperStyle.height = 'fit-content';

    innerStyle.overflow = 'scroll';
    innerStyle.height = `${heightValue}px`;
  }

  useEffect(() => {
    if (!homePage && (heightValue > 0)) {
      document.body.classList.add(Styles['searchbar-overflow-custom']);
    } else if (!homePage && (heightValue <= 0)) {
      document.body.classList.remove(Styles['searchbar-overflow-custom']);
    }

    return () => {
      if (!homePage) document.body.classList.remove(Styles['searchbar-overflow-custom']);
    };
  }, [heightValue]);

  const totalTop = heightRef.current - top;

  return (
    createPortal(
      <div
        className={cx('stories-modal-wrapper', { home: homePage })}
        style={{ top: `calc(100% - ${totalTop}px)`, bottom: 0, ...wrapperStyle }}
        ref={ref}
      >
        {homePage && (
          <ModalHeader
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            headerDate={headerDate}
            price={price}
            volume={volume}
            withGMTLabel={withGMTLabel}
            isStartDateAcceptable={isStartDateAcceptable}
            onOpen={onOpen}
            isShowStoriesBtnHidden={totalTop > (HEADER_HEIGHT + 40)}
          />
        )}
        <div className={Styles['stories-modal']} ref={storiesRef} style={innerStyle}>
          {!homePage && (
            <ModalHeader
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
              headerDate={headerDate}
              price={price}
              volume={volume}
              withGMTLabel={withGMTLabel}
              isStartDateAcceptable={isStartDateAcceptable}
              onOpen={onOpen}
              isShowStoriesBtnHidden={totalTop > (HEADER_HEIGHT + 40)}
            />
          )}
          <div className={Styles['stories-modal-content']}>
            <div className={Styles['stories-modal-row']}>
              <div className={Styles['stories-modal-row-tabs-wrapper']}>
                <div className={Styles['stories-modal-row-tabs']}>
                  {tabs.map((tab) => (
                    <div
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={cx('stories-modal-row-tab', { active: tab.active })}
                    >
                      {tab.value}
                    </div>
                  ))}
                </div>
                <div className={Styles['stories-modal-row-close']} onClick={onClose}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            </div>
            <div>
              {activeTabId === ON_CHART_MODAL_TABS.STORIES && (
                <>
                  {storiesData.loading && <Preloader loading top="calc(50% - 50px)" />}
                  {!storiesData.loading && (
                    storiesData.stories.length ? (
                      storiesData.stories.map((story) => (
                        <StoryCard
                          key={story.id}
                          expandFilter={false}
                          showTranslation={false}
                          type={story.type}
                          story={story}
                          onHide={() => null}
                          onFreezeStories={() => null}
                          piwikEnabled={piwikEnabled}
                          trendingBarCard
                          popup
                          skipSimilarStories
                          trimTitle
                        />
                      ))
                    ) : (
                      <div className={Styles['stories-modal-content-no-stories']}>
                        {storiesData.notAllowed ? (
                          <>
                            <a href="/pricing">
                              Upgrade
                            </a>
                            &nbsp;to access this content
                          </>
                        ) : (
                          'No content was found for this period'
                        )}
                      </div>
                    )
                  )}
                </>
              )}
              {activeTabId === ON_CHART_MODAL_TABS.SUMMARIES && (
                <ModalSummaries
                  summaryData={summaryData}
                />
              )}
            </div>
          </div>
          <div className={Styles['stories-modal-footer']}>
            <div className={Styles['stories-modal-footer-label']}>
              Stories cover the period:
            </div>
            {cryptoBigInterval ? (
              <div className={Styles['stories-modal-footer-period']}>
                {`${moment(startDate).format('DD MMM YYYY')}`}
              &nbsp;-&nbsp;
                {`${moment(endDate).format('DD MMM YYYY')}`}
              </div>
            ) : (
              <div className={Styles['stories-modal-footer-period']}>
                {`${moment(startDate).format('DD MMM YYYY HH:mm')} GMT`}
              &nbsp;-&nbsp;
                {`${moment(endDate).format('DD MMM YYYY HH:mm')} GMT`}
              </div>
            )}
          </div>
        </div>
      </div>,
      chartMainWrapper,
    )
  );
});

const ModalHeader = ({
  onTouchStart, onTouchMove, onTouchEnd, headerDate,
  price, withGMTLabel,
  isShowStoriesBtnHidden, isStartDateAcceptable, onOpen,
}) => {
  const mobileEvent = isStartDateAcceptable
    ? {
      onTouchStart,
      onTouchMove,
      onTouchEnd,
    }
    : {};

  return (
    <div
      className={Styles['stories-modal-header']}
      {...mobileEvent}
    >
      <div className={Styles['stories-modal-header-line']} />
      <div className={Styles['stories-modal-header-title']}>
        Top Stories
      </div>
      <div className={Styles['stories-modal-header-info']}>
        <div className={Styles['stories-modal-header-date']}>
          {`${headerDate()}${withGMTLabel ? ' GMT' : ''}`}
        </div>
        <div className={Styles['stories-modal-header-price']}>
          <span className={Styles['stories-modal-header-price-label']}>
            Price:
          </span>
          <span className={Styles['stories-modal-header-price-value']}>
            {price}
          </span>
        </div>
      </div>
      <div className={cx('stories-modal-header-show', { hide: isShowStoriesBtnHidden })}>
        {isStartDateAcceptable && (
          <div
            className={Styles['stories-modal-header-show-more']}
            onClick={onOpen}
          >
            <span>
              Show Stories
            </span>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withComponentName(StoriesModalMobile);
