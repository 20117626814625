export default [
  {
    id: 1,
    text: 'Apple to announce new iPhone',
    url: '/',
    timestamp: Date.now() - 1000 * 60 * 10,
  },
  {
    id: 2,
    text: 'Facebook inks first deal to buy renewable energy in India',
    url: '/',
    timestamp: Date.now() - 1000 * 60 * 8,
  },
];
