import Alert from '../components/Shared/ReactAlert';

import {
  fetchPreferences,
  updatePreferences,
} from '../services/dbServices/userServices';

import NotificationsDbService from '../services/dbServices/NotificationsDbService';

import StorageSvc from '../services/StorageSvc';

export const LOAD_USER_SUBSCRIPTION_TOPICS = 'LOAD_USER_SUBSCRIPTION_TOPICS';
export const ADD_USER_SUBSCRIPTION_TOPICS = 'ADD_USER_SUBSCRIPTION_TOPICS';
export const REMOVE_SUBSCRIPTION_TOPICS = 'REMOVE_USER_SUBSCRIPTION_TOPICS';
export const TOGGLE_STORY_FILTERS = 'TOGGLE_STORY_FILTERS';

export const LOAD_USER_PREFERENCES = 'LOAD_USER_PREFERENCES';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';

export const TOGGLE_MENU = 'TOGGLE_MENU';

export const TOGGLE_CURATION = 'TOGGLE_CURATION';

export const SET_USER_DATA = 'SET_USER_DATA';

export const LOADING_SEARCH_QUERIES = 'LOADING_SEARCH_QUERIES';

export const LOADING_USER_INFO = 'LOADING_USER_INFO';

export const toggleStoryFilters = (isOpened) => ({
  type: TOGGLE_STORY_FILTERS,
  payload: isOpened,
});

export const loadUserSubscriptionTopics = (topics) => ({
  type: LOAD_USER_SUBSCRIPTION_TOPICS,
  payload: topics,
});

export const addUserSubscriptionTopics = (newSubscriptionTopics) => ({
  type: ADD_USER_SUBSCRIPTION_TOPICS,
  payload: newSubscriptionTopics,
});

export const removeUserSubscriptionTopics = (topics) => ({
  type: REMOVE_SUBSCRIPTION_TOPICS,
  payload: topics,
});

export const loadPreferencesAction = (preferences) => ({
  type: LOAD_USER_PREFERENCES,
  payload: preferences,
});

export const updateUserPreferences = (newPreferences) => ({
  type: UPDATE_USER_PREFERENCES,
  payload: newPreferences,
});

export const toggleMenu = () => ({
  type: TOGGLE_MENU,
});

export const toggleCuration = () => ({
  type: TOGGLE_CURATION,
});

export const setUserData = (user) => ({
  type: SET_USER_DATA,
  payload: user,
});

export const loadingSearchQueries = () => ({
  type: LOADING_SEARCH_QUERIES,
  payload: true,
});

export const loadingUserInfo = (newState) => ({
  type: LOADING_USER_INFO,
  payload: newState,
});

export const loadPreferences = (userToken, fetchPref = true) => async (dispatch) => {
  if (userToken && fetchPref) {
    const { data } = await fetchPreferences();

    dispatch(loadPreferencesAction(data.preference));
  } else {
    const guestPref = StorageSvc.getItem('userPreferences');
    if (guestPref) return dispatch(loadPreferencesAction(JSON.parse(guestPref)));
    StorageSvc.setItem('userPreferences', JSON.stringify({ show_trending_asset_bar: false }));
    dispatch(loadPreferencesAction({ show_trending_asset_bar: false }));
  }
};

export const onUserPreferencesUpdate = (preference, userToken) => (
  async (dispatch) => {
    if (userToken) {
      try {
        const { data } = await updatePreferences(preference);
        dispatch(loadPreferencesAction(data.preference));
      } catch (e) {
        return null;
      }
    } else {
      StorageSvc.setItem('userPreferences', JSON.stringify({ show_trending_asset_bar: preference.show_trending_asset_bar }));
      dispatch(loadPreferencesAction(preference));
    }
  }
);

export const addTopicNotification = (watchlistId, topics, queryId, userNotifications, watchlistTopics = []) => (
  async (dispatch) => {
    if (
      watchlistTopics.length > 50
      || userNotifications.length === 50
      || (watchlistTopics.length + userNotifications.length) > 50
    ) {
      return Alert.info('Currently you can not have more then 50 topics for your Top 50 Stories list', {
        position: 'top',
        effect: 'stackslide',
        timeout: 3000,
      });
    }

    try {
      const { search_queries: searchQueries } = await (
        NotificationsDbService.postNotificationQuery(watchlistId, topics, queryId)
      );
      dispatch(addUserSubscriptionTopics(searchQueries));
    } catch {
      return undefined;
    }
  }
);

export const loadUserNotificationTopics = (isLoading) => (
  async (dispatch) => {
    if (isLoading) {
      dispatch(loadingSearchQueries());
    }
    const { search_queries: searchQueries } = await NotificationsDbService.getNotificationQueries();
    dispatch(loadUserSubscriptionTopics(searchQueries));
  }
);

export const removeUserNotificaionTopics = (queryId, removeAll) => (
  async (dispatch) => {
    try {
      const { search_queries: searchQueries } = await (
        NotificationsDbService.removeNotificationQuery(queryId, removeAll)
      );
      dispatch(removeUserSubscriptionTopics(searchQueries));
    } catch {
      return undefined;
    }
  }
);
