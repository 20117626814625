import classNames from 'classnames/bind';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const ProgressBar = (props) => {
  const {
    bgcolor, bgContainerColor, completed, handleClick, screenWidth,
    textMessage, classname,
  } = props;

  const containerStyles = {
    height: 'auto',
    width: '100%',
    backgroundColor: bgContainerColor,
    textAlign: 'center',
    position: 'relative',
  };

  const fillerStyles = {
    position: 'absolute',
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
  };

  const labelStyles = {
    position: 'relative',
    zIndex: 1,
    display: 'inline-block',
    padding: screenWidth > 2800 ? '20px 30px' : '11px 15px',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: screenWidth > 2800 ? '28px' : '14px',
    lineHeight: '100%',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    color: '#444372',
  };

  return (
    <div className={cx('progress-bar', { [`${classname}`]: true })} style={containerStyles}>
      <div style={fillerStyles} />
      <span onClick={handleClick} style={labelStyles}>{textMessage}</span>
    </div>
  );
};

export default ProgressBar;
