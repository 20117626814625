import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import loadable from '@utils/loadable';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/pro-light-svg-icons/faSignIn';
import { faArrowRightToBracket } from '@fortawesome/pro-regular-svg-icons/faArrowRightToBracket';

import withComponentName from '../../../decorators/withComponentName';

import { setAuthModalOpened, setFinPromptAuthModalOpened } from '../../../actions/authentication.actions';

import Styles from './styles.module.scss';

import ButtonComponent from '../../Buttons/ButtonComponent';

const AuthenticationModal = loadable(() => import('../../Modals/AuthModals/AuthenticationModal'));

const cx = classNames.bind(Styles);

const UserLoginButtonsComponent = ({
  piwikEnabled, auth, isFinpromptPages, hasBigScreenDesign,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const openAuthModal = () => {
    if (isFinpromptPages) {
      dispatch(setFinPromptAuthModalOpened(true));
      return;
    }
    dispatch(setAuthModalOpened(true));
  };
  const onboardingLoginBtn = location.pathname.startsWith('/news/onboarding');
  const signInPageLoginBtn = location.pathname === ('/sign_in');

  return (
    <>
      <div
        className={Styles.sign_in_block}
      >
        <div className={cx('sign_button_big',
          { onboarding: onboardingLoginBtn },
          { finprompt: isFinpromptPages },
          { big_screen: hasBigScreenDesign })}
        >
          <ButtonComponent
            clickHandler={(e) => {
              e.preventDefault();
              openAuthModal();
            }}
            value="Sign in / Register"
            short={!isFinpromptPages}
            useBtn
            path=""
            borderRadiusMedium={signInPageLoginBtn || isFinpromptPages}
            noborder={!signInPageLoginBtn}
            onboarding={onboardingLoginBtn}
            className="button_register"
            purpleBg={isFinpromptPages}
            isFinpromptPages={isFinpromptPages}
            hasBigScreenDesign={hasBigScreenDesign}
          />
        </div>
        <div className={cx('sign_button_small',
          { finprompt: isFinpromptPages })}
        >
          <button
            className={cx('mobile-sign-in__btn')}
            aria-label="mobile-sign-in"
            type="button"
            onClick={openAuthModal}
          >
            {isFinpromptPages ? (
              <FontAwesomeIcon icon={faArrowRightToBracket} />
            ) : (<FontAwesomeIcon icon={faSignIn} />)}
          </button>
        </div>
      </div>
      <AuthenticationModal
        piwikEnabled={piwikEnabled}
        auth={auth}
        isFinpromptPages={isFinpromptPages}
      />
    </>
  );
};

export default withComponentName(UserLoginButtonsComponent);
