import isEmpty from 'lodash-es/isEmpty';
import { isMobileOnly } from 'react-device-detect';
import {
  REFRESH,
  STORY_ORDER,
  TIME_PERIOD,
  CATEGORIES,
  MIN_SCORE,
  LANGUAGE,
  CLASSES,
  EVENTS,
  SECTORS,
  INDUSTIRES,
  SUBINDUSTIRES,
  STARTUP_CATEGORIES,
  SEGMENTS,
  QUERY,
  RESET_STORY_FILTERS,
  RESET_TRENDING_FILTERS,
  RESET_ALL_FILTERS,
  ADVANCED_SETTINGS,
  REQUIRED_SOURCE,
  REFRESH_COUNTER,
  EMPTY_TRENDIG_FILTERS,
  TOGGLE_LANGUAGES_SELECTED,
  TOGGLE_EXPAND_STORIES,
  TOGGLE_USER_NEWSFEED_VIEW_IMAGES,
  TOGGLE_USER_NEWSFEED_VIEW_DESCRITION,
  TOGGLE_GROUP_SIMILAR_STORIES,
  TOGGLE_SHOW_SIMILAR_STORIES,
  TOGGLE_USER_NEWSFEED_VIEW,
  TOGGLE_TILES_VIEW,
  LOAD_PREFRENCES,
  TRANSLATE_STORIES_INTO_ENGLISH,
  LOAD_TRENDING_FILTERS,
  DISABLE_TRENDING_PRELOADER,
  TOGGLE_CONFLICTS_FLAG,
  PREMIUM_CONTENT_ONLY,
  SAVE_STORY_FILTERS,
  TOGGLE_DOMAINS_FILTERING_MODE,
  SET_SUMMARISATION_STATE,
  TOGGLE_USER_SIMPLIFIED_VIEW,
  SET_SENTIMENT_TAB,
  TOGGLE_USER_NEWSFEED_VIEW_COMPACT,
  RESET_USER_SELECTED_FILTERS,
  TOGGLE_PREMIUM_LABELS,
  TOGGLE_ONBOARDING_PROCESS,
  SAVE_TRANSLATION_LANGUAGES,
} from '../actions/storyFilters.action';

import {
  RESET_FILINGS_FILTERS,
  TOGGLE_EXPAND_FILINGS,
  FILINGS_FILTER_BY_DATE,
  FILINGS_FILTER_BY_ALL_DATE,
  FILINGS_FILTER_BY_SOURCE,
  FILINGS_FILTER_BY_CATEGORY,
  FILINGS_FILTER_BY_ALL_CATEGORY,
} from '../actions/filingsFilters.actions';

import { initialState, refreshValuesConversion } from '../data/filters/storyFilters';

export default (state = initialState, action) => {
  let newState = {};
  switch (action.type) {
    case SAVE_STORY_FILTERS:
      return {
        ...state,
        ...action.payload,
        categories: (action.payload.categories || '').toString(),
        filtersInitialized: true,
      };
    case DISABLE_TRENDING_PRELOADER:
      return {
        ...state,
        loading: false,
      };

    case TOGGLE_DOMAINS_FILTERING_MODE:
      return {
        ...state,
        domains_filtering_mode: action.payload,
      };

    case PREMIUM_CONTENT_ONLY:
      return {
        ...state,
        premium_content_only: action.payload,
      };

    case REFRESH:
      return {
        ...state,
        refresh_rate: Object.values(refreshValuesConversion).includes(action.payload) ? (
          action.payload
        ) : (
          'never'
        ),
      };

    case STORY_ORDER:
      return {
        ...state,
        order_by: action.payload,
      };

    case TIME_PERIOD:
      return {
        ...state,
        ...action.payload,
      };

    case CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    case MIN_SCORE:
      return {
        ...state,
        min_score: action.payload,
      };

    case LANGUAGE:
      return {
        ...state,
        languages: action.payload.languages,
        all_languages: action.payload.all_languages,
      };
    case CLASSES:
      return {
        ...state,
        asset_classes: action.payload,
        userStartSelectAssetClasses: true,
      };

    case EVENTS:
      return {
        ...state,
        events: action.payload,
      };

    case SECTORS:
      return {
        ...state,
        sectors: action.payload,
      };

    case INDUSTIRES:
      return {
        ...state,
        industries: action.payload,
      };

    case SUBINDUSTIRES:
      return {
        ...state,
        subindustries: action.payload,
      };

    case STARTUP_CATEGORIES:
      return {
        ...state,
        startup_categories: action.payload,
      };

    case SEGMENTS:
      return {
        ...state,
        segments: action.payload,
      };
    case QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case ADVANCED_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };

    case REQUIRED_SOURCE:
      return {
        ...state,
        required_source: { ...action.payload },
      };

    case RESET_STORY_FILTERS:
      return {
        ...action.payload,
        asset_classes: state.asset_classes,
        events: state.events,
        sectors: state.sectors,
        industries: state.industries,
        subindustries: state.subindustries,
        startup_categories: state.startup_categories,
        query: state.query,
        translate_all: state.translate_all,
        translate_to: state.translate_to,
        conflictsChecked: state.conflictsChecked,
        required_source: {
          registrationRequired: true,
          paywall: true,
        },
      };

    case LOAD_TRENDING_FILTERS:
      return {
        ...state,
        initial_asset_classes: action.payload.classes,
        initial_events: action.payload.events,
        initial_sectors: action.payload.sectors,
      };

    case RESET_TRENDING_FILTERS:
      return {
        ...state,
        asset_classes: initialState.asset_classes,
        events: initialState.events,
        sectors: initialState.sectors,
        industries: initialState.industries,
        subindustries: initialState.subindustries,
        startup_categories: initialState.startup_categories,
        query: initialState.query,
        required_source: {
          registrationRequired: true,
          paywall: true,
        },
      };

    case RESET_ALL_FILTERS:
      return {
        ...state,
        ...action.payload,
        conflictsChecked: state.conflictsChecked,
        required_source: {
          registrationRequired: true,
          paywall: true,
        },
      };

    case RESET_USER_SELECTED_FILTERS:

      if (action.payload.hasOwnProperty('paywall') && action.payload.hasOwnProperty('registrationRequired')) {
        return {
          ...state,
          ...action.payload,
          required_source: {
            paywall: true,
            registrationRequired: true,
          },
        };
      }
      if (action.payload.hasOwnProperty('paywall')) {
        newState = {
          ...state,
          ...action.payload,
          required_source: {
            paywall: action.payload.paywall,
            registrationRequired: state.required_source.registrationRequired,
          },
        };
      }

      if (action.payload.hasOwnProperty('registrationRequired')) {
        newState = {
          ...state,
          ...action.payload,
          required_source: {
            registrationRequired: action.payload.registrationRequired,
            paywall: state.required_source.paywall,
          },
        };
      }

      return isEmpty(newState) ? {
        ...state,
        ...action.payload,
      } : newState;

    case REFRESH_COUNTER:
      return {
        ...state,
        refresh_counter: state.refresh_counter + 1 | 0,
      };

    case EMPTY_TRENDIG_FILTERS:
      return {
        ...state,
        empty_selected_trending_filters: action.payload,
      };

    case TOGGLE_LANGUAGES_SELECTED:
      return {
        ...state,
        all_languages: action.payload.all_languages,
        languages: action.payload.languages,
      };

    case TOGGLE_EXPAND_STORIES:
      return {
        ...state,
        expand_stories: action.payload,
      };

    case TOGGLE_USER_NEWSFEED_VIEW_IMAGES:
      return {
        ...state,
        user_newsfeed_view_images_web: action.payload,
      };

    case TOGGLE_USER_NEWSFEED_VIEW_DESCRITION:
      return {
        ...state,
        user_newsfeed_view_description_web: action.payload,
      };

    case TOGGLE_GROUP_SIMILAR_STORIES:
      return {
        ...state,
        group_similar_stories: action.payload,
      };

    case TOGGLE_SHOW_SIMILAR_STORIES:
      return {
        ...state,
        show_similar_stories_in_feed: action.payload,
      };

    case TOGGLE_USER_NEWSFEED_VIEW:
      return {
        ...state,
        [isMobileOnly ? 'user_newsfeed_view' : 'user_newsfeed_view_web']: action.payload,
        [isMobileOnly ? 'user_newsfeed_compactview' : 'user_newsfeed_compactview_web']: false,
      };
    case TOGGLE_USER_SIMPLIFIED_VIEW:
      return {
        ...state,
        [isMobileOnly ? 'user_newsfeed_advanced_view' : 'user_newsfeed_advanced_view_web']: action.payload,
      };
    case SET_SENTIMENT_TAB:
      return {
        ...state,
        selected_sentiment_tab: action.payload,
      };

    case TOGGLE_TILES_VIEW:
      return {
        ...state,
        tiles_view: action.payload,
      };
    case TOGGLE_USER_NEWSFEED_VIEW_COMPACT:
      return {
        ...state,
        ...action.payload,
      };

    case LOAD_PREFRENCES:
      return {
        ...state,
        refresh_rate: action.payload.refresh_rate || 'never',
        order_by: action.payload.order_by,
        time_filter: action.payload.time_filter,
        categories: action.payload.categories.toString(),
        twitter_following_only: action.payload.twitter_following_only,
        selected_time_filter: action.payload.selected_time_filter,
        with_links: action.payload.with_links,
        min_score: action.payload.min_score,
        languages: action.payload.lang,
        all_languages: action.payload.all_languages,
        expand_stories: action.payload.expand_stories,
        user_newsfeed_view_images_web: action.payload.user_newsfeed_view_images_web,
        translate_to: action.payload.translate_to || null,
        translate_all: action.payload.translate_all,
        premium_content_only: action.payload.premium_content_only,
      };

    case TRANSLATE_STORIES_INTO_ENGLISH:
      return {
        ...state,
        translate_all: action.payload,
        translate_to: action.payload ? 'en' : null,
      };

    case TOGGLE_CONFLICTS_FLAG:
      return {
        ...state,
        conflictsChecked: !state.conflictsChecked,
      };

    case RESET_FILINGS_FILTERS:
      return {
        ...state,
        ...action.payload,
      };

    case TOGGLE_EXPAND_FILINGS:
      return {
        ...state,
        expand_filings: action.payload,
      };
    case FILINGS_FILTER_BY_ALL_DATE:
      return {
        ...state,
        filings_time_filter: action.payload.filings_time_filter,
      };
    case FILINGS_FILTER_BY_DATE:
      return {
        ...state,
        filing_period_start_datetime: action.payload.filing_period_start_datetime,
        filing_period_end_datetime: action.payload.filing_period_end_datetime,
        filings_time_filter: action.payload.filings_time_filter,
      };
    case FILINGS_FILTER_BY_SOURCE:
      return {
        ...state,
        filing_source_ids: action.payload.filing_source_ids,
        filing_sources_all: action.payload.filing_sources_all,
      };
    case FILINGS_FILTER_BY_CATEGORY:
      return {
        ...state,
        filing_categories_all: action.payload.filing_categories_all,
        filing_types_all: action.payload.filing_types_all,
        filing_category_ids: action.payload.filing_category_ids,
        filing_type_ids: action.payload.filing_type_ids,
      };
    case FILINGS_FILTER_BY_ALL_CATEGORY:
      return {
        ...state,
        filing_categories_all: action.payload.filing_categories_all,
        filing_types_all: action.payload.filing_types_all,
        filing_category_ids: action.payload.filing_category_ids,
        filing_type_ids: action.payload.filing_type_ids,
      };
    case SET_SUMMARISATION_STATE:
      return {
        ...state,
        summarization_enabled: action.payload,
      };
    case TOGGLE_PREMIUM_LABELS:
      return {
        ...state,
        isPremiumLabelsEnabled: action.payload,
      };
    case TOGGLE_ONBOARDING_PROCESS:
      return {
        ...state,
        isOnboardingProcess: action.payload,
      };
    case SAVE_TRANSLATION_LANGUAGES:
      return {
        ...state,
        translationSupportedLanguages: action.payload,
      };
    default:
      return state;
  }
};
