import isEqual from 'lodash-es/isEqual';
import { captureMessage } from '@sentry/react';
import API from './topicsApi';
import { getAPICachingTime, returnAPICachingHeader } from '../../helpers/helpers';
import { cachingHigh } from '../../data/webPageData';
import { RetryAPICall } from '../../helpers/apiHelpers';
import { removeEmptyProperties } from '../../helpers/commonHelpers';

const caches = {
  filings: [],
  sources: [],
  categories: [],
};

export default class FilingSvc {
  static async getFillings(props) {
    try {
      const { param: fields = {}, ...restProps } = props;

      removeEmptyProperties(fields);

      const cachedFilings = caches.filings.find((cache) => isEqual(cache.params, fields));
      if (cachedFilings && cachedFilings.expDate > Date.now()) {
        return cachedFilings.response;
      }

      // Splitting the identifier string into chunks of 65 items
      // to avoid too long URLs
      if (typeof fields?.identifier === 'string' && fields.identifier.length > 2000) {
        const identifiers = fields.identifier.split(',');
        const chunkSize = 65;
        return Promise.all(
          Array.from({ length: Math.ceil(identifiers.length / chunkSize) }).map((_, i) => (
            identifiers.slice(i * chunkSize, i * chunkSize + chunkSize).join(',')
          )).map((chunk) => (
            this.getFillings({
              ...props,
              param: {
                ...fields,
                identifier: chunk,
              },
            }).catch(() => ({ data: [] }))
          )),
        ).then((responses) => {
          const data = responses.reduce((acc, { data }) => acc.concat(data), []);
          return { data };
        });
      }

      if (typeof fields?.identifier === 'string') {
        fields.identifier = (
          fields.identifier
            .replaceAll('SR:companieshouse-gb', 'SR:cph-gb')
            .replaceAll('SR:gazette-gb', 'SR:gzt-gb')
        );
      }

      const res = await RetryAPICall(API, 'v2/filings', {
        params: fields,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingHigh),
        },
        ...restProps,
      });

      caches.filings.unshift({
        params: fields,
        expDate: getAPICachingTime(cachingHigh),
        response: res,
      });

      return res;
    } catch {
      return {
        data: [],
      };
    }
  }

  static async getFilling(fields = {}) {
    try {
      const { data } = await this.getFillings(fields);
      const [field] = data;
      return field || null;
    } catch (e) {
      return null;
    }
  }

  static async getFillingsSources(params = {}) {
    const cachedSources = caches.sources.find((cache) => isEqual(cache.params, params));
    if (cachedSources && cachedSources.expDate > Date.now()) {
      return cachedSources.response;
    }

    const res = await RetryAPICall(API, 'v1/filings/sources', {
      params,
      headers: {
        'Cache-control': returnAPICachingHeader(cachingHigh),
      },
    });

    caches.sources.unshift({
      params,
      expDate: getAPICachingTime(cachingHigh),
      response: res,
    });

    return res;
  }

  static async getFillingsCategories(params = {}) {
    try {
      const cachedCategories = caches.categories.find((cache) => isEqual(cache.params, params));
      if (cachedCategories && cachedCategories.expDate > Date.now()) {
        return cachedCategories.response;
      }

      const res = await RetryAPICall(API, 'v1/filings/categories', {
        params,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingHigh),
        },
      });

      if (res?.status === 204) {
        captureMessage('Received 204 status code from /v1/filings/categories endpoint', {
          extra: {
            params,
          },
        });
      }

      caches.categories.unshift({
        params,
        expDate: getAPICachingTime(cachingHigh),
        response: res,
      });

      return res;
    } catch {
      return {
        data: [],
      };
    }
  }
}
