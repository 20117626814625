export const entityTypeNames = {
  'Query': 'Query',
  'Stocks': 'Stock',
  'Commodities': 'Commodity',
  'Foreign Exchange and Currencies': 'Foreign Exchange or Currency',
  'Indices': 'Index',
  'Private Companies and Brands': 'Private Company or Brand',
  'Macro Topics': 'Macro Topic',
  'Exchange Traded Funds (ETFs)': 'ETF',
  'Cryptocurrencies': 'Cryptocurrency',
  'Organisations': 'Organisation',
  'People': 'Person',
  'Corporate Bonds': 'Corporate Bond',
  'Financial Regulation and Related Topics': 'Financial Regulation',
  'Mutual Funds': 'Mutual Fund',
  'Products': 'Product',
  'Property': 'Property',
  'Closed-End Funds': 'Closed-End Fund',
  'Major Business and Related Activities': 'Event',
  'Other Topics': 'Other Topic',
  'Technical Analysis': 'Technical Analysis',
  'Preferred Stocks': 'Preferred Stock',
  'Other Events': 'Other Event',
  'Fundamental Analysis': 'Fundamental Analysis',
  'Sovereign and Government Bonds': 'Sovereign or Government Bond',
  'Ratings and Research Firms': 'Ratings or Research Firm',
  'Financial Topics': 'Financial Topic',
  'Sectors': 'Sector',
  'Industries': 'Industry',
  'Subindustries': 'Subindustry',
  'Startup Categories': 'Startup Category',
  'Events': 'Events'
};
