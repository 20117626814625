import {
  LOAD_TRENDING_ASSETS,
  CHANGE_PERIOD,
  LOAD_TRENDING_ASSET_CLASSES,
  SELECT_TRENDING_ASSET_CLASSES,
  SELECT_ALL_TRENDING_CLASSES,
  UNSELECT_ALL_TRENDING_CLASSES,
  TRENDING_STILL_ANIMATING,
  DEFAULT_SELECTED_ASSET_CLASSES,
  TOGGLE_TRENDING_FILTERS,
  TOGGLE_TRENDING_GRAPH,
  CLOSE_TRENDING_DROPDOWNS,
  LOAD_TRENDING_BAR_STORIES,
  LOAD_NEW_TRENDING_BAR_STORIES,
  LOAD_RELOAD_TRENDING_BAR_STORIES,
  LOADING_TRENDING_BAR_DROPDOWN_TOPICS,
  TOGGLE_TRENDING_BAR_TOPIC_POPOVER,
} from '../actions/trendingBarActions';

const initialState = {
  trendingAssets: [],
  trendingBarFilters: [],
  period: 'hour1',
  selectedTrendingClasses: [],
  assetClasses: [],
  defaultTrendingClass: true,
  trendingStillAnimating: false,
  trendingGraphOpened: false,
  trendingBarFiltersOpened: false,
  loadingTrendingBarTopics: false,
  loadingNewTrendingBarTopics: false,
  trendingBarStories: [],
  trendingBarTopicPopover: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TRENDING_BAR_DROPDOWN_TOPICS:
      return {
        ...state,
        loadingTrendingBarTopics: !state.loadingTrendingBarTopics,
      };
    case CLOSE_TRENDING_DROPDOWNS:
      return {
        ...state,
        trendingGraphOpened: false,
        trendingBarFiltersOpened: false,
      };
    case TOGGLE_TRENDING_GRAPH:
      return {
        ...state,
        trendingGraphOpened: !state.trendingGraphOpened,
        trendingBarFiltersOpened: false,
      };
    case TOGGLE_TRENDING_FILTERS:
      return {
        ...state,
        trendingBarFiltersOpened: !state.trendingBarFiltersOpened,
        trendingGraphOpened: false,
      };
    case TRENDING_STILL_ANIMATING:
      return {
        ...state,
        trendingStillAnimating: !state.trendingStillAnimating,
      };
    case SELECT_TRENDING_ASSET_CLASSES: {
      let newItems = state.selectedTrendingClasses.find((item) => item.id === action.payload.id);
      if (!newItems) {
        return {
          ...state,
          selectedTrendingClasses: [...state.selectedTrendingClasses, action.payload],
          defaultTrendingClass: false,
        };
      }
      newItems = state.selectedTrendingClasses.filter((item) => (
        item.id !== action.payload.id
      ));
      return {
        ...state,
        selectedTrendingClasses: [...newItems],
        defaultTrendingClass: false,
      };
    }
    case UNSELECT_ALL_TRENDING_CLASSES:
      return {
        ...state,
        defaultTrendingClass: false,
        selectedTrendingClasses: [],
      };
    case SELECT_ALL_TRENDING_CLASSES:
      return {
        ...state,
        selectedTrendingClasses: [...state.assetClasses],
        defaultTrendingClass: true,
      };
    case LOAD_TRENDING_ASSETS:
      return {
        ...state,
        trendingAssets: [...action.payload],
      };
    case CHANGE_PERIOD:
      return {
        ...state,
        period: action.payload,
      };
    case LOAD_TRENDING_ASSET_CLASSES:
      return {
        ...state,
        assetClasses: [...action.payload],
      };
    case DEFAULT_SELECTED_ASSET_CLASSES:
      return {
        ...state,
        selectedTrendingClasses: [...action.payload],
      };
    case LOAD_TRENDING_BAR_STORIES:
      return {
        ...state,
        trendingBarStories: [...action.payload],
        loadingTrendingBarTopics: false,
        loadingNewTrendingBarTopics: false,
      };
    case LOAD_NEW_TRENDING_BAR_STORIES:
      return {
        ...state,
        loadingNewTrendingBarTopics: !state.loadingNewTrendingBarTopics,
      };
    case LOAD_RELOAD_TRENDING_BAR_STORIES:
      return {
        ...state,
        trendingBarStories: [...action.payload],
        loadingTrendingBarTopics: false,
        loadingNewTrendingBarTopics: false,
      };
    case TOGGLE_TRENDING_BAR_TOPIC_POPOVER:
      return {
        ...state,
        trendingBarTopicPopover: action.payload,
      };
    default:
      return state;
  }
};
