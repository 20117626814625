import { EMAIL_FIELD } from '../data/preferences/fieldTypes';
import { accountData as accountDataConstants } from '../data/preferences/account';

import { isValidEmail } from '../helpers/commonHelpers';

import AccountSvc from '../services/dbServices/AccountSvc';
import { getSegments } from '../services/dbServices/onboardingDbData';
import { setUserData } from './preferencesActions';

export const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
export const TOGGLE_SEGMENTS_LIST = 'TOGGLE_SEGMENTS_LIST';
export const TOGGLE_SEGMENT = 'TOGGLE_SEGMENT';
export const TOGGLE_PASSWORD_MODAL = 'TOGGLE_PASSWORD_MODAL';
export const TOGGLE_PASSWORD_WAS_CHANGED_MODAL = 'TOGGLE_PASSWORD_WAS_CHANGED_MODAL';
export const SET_INFO = 'SET_INFO';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const SET_FIELD_VALUE = 'SET_FIELD_VALUE';
export const CHANGE_PASSWORD_VALUE = 'CHANGE_PASSWORD_VALUE';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';
export const SET_ACTIVE_FIELD = 'SET_ACTIVE_FIELD';
export const SET_ACCOUNT_DATA_ERROR = 'SET_ACCOUNT_DATA_ERROR';
export const TOGGLE_RESET_MODAL = 'TOGGLE_RESET_MODAL';

export const toggleLoading = (loading) => ({
  type: TOGGLE_LOADING,
  payload: loading,
});

export const toggleSegmentsList = () => ({
  type: TOGGLE_SEGMENTS_LIST,
});

export const toggleSegment = (segmentId) => ({
  type: TOGGLE_SEGMENT,
  payload: segmentId,
});

export const toggleEditMode = (mode) => ({
  type: TOGGLE_EDIT_MODE,
  payload: mode,
});

export const togglePasswordModal = () => ({
  type: TOGGLE_PASSWORD_MODAL,
});

export const togglePasswordWasChangedModal = () => ({
  type: TOGGLE_PASSWORD_WAS_CHANGED_MODAL,
});

export const setInfo = (data) => ({
  type: SET_INFO,
  payload: data,
});

export const setFieldValue = (field, value) => ({
  type: SET_FIELD_VALUE,
  payload: {
    field,
    value,
  },
});

export const changePasswordValue = (field, value) => ({
  type: CHANGE_PASSWORD_VALUE,
  payload: {
    field,
    value,
  },
});

export const setPasswordError = (error) => ({
  type: SET_PASSWORD_ERROR,
  payload: error,
});

export const setActiveField = (field) => ({
  type: SET_ACTIVE_FIELD,
  payload: field,
});

export const setAcccountDataError = (error) => ({
  type: SET_ACCOUNT_DATA_ERROR,
  payload: error,
});

export const toggleResetModal = () => ({
  type: TOGGLE_RESET_MODAL,
});

export const resetPassword = () => (
  async (dispatch, getState) => {
    try {
      dispatch(toggleLoading(true));
      const { selectedAccountData } = getState().account;
      await AccountSvc.resetPassword(selectedAccountData.email);
      dispatch(toggleResetModal());
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(toggleLoading(false));
    }
  }
);

export const changePassword = (current_password, new_password, isFinpromptPages) => (
  async (dispatch) => {
    try {
      await AccountSvc.changeAccountInfo({
        current_password,
        new_password,
      });

      if (!isFinpromptPages) {
        // force user relogin after password change
        window.location.href = '/';
        dispatch(togglePasswordModal());
      }

      dispatch(togglePasswordWasChangedModal());
      dispatch(setUserData(null));
    } catch (e) {
      dispatch(setPasswordError(e));
    }
  }
);

export const loadInitialInfo = () => (
  async (dispatch) => {
    try {
      dispatch(toggleLoading(true));

      const [accountData, segments] = await Promise.all([
        AccountSvc.getAccountInfo(),
        getSegments().then(({ data }) => data.segments),
      ]);
      if (accountData && segments) {
        segments.forEach((segment) => {
          segment.active = accountData.segments.some((seg) => seg.id === segment.id);
        });
        dispatch(setInfo({ accountData, segments }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(toggleLoading(false));
    }
  }
);

export const saveChanges = () => (
  async (dispatch, getState) => {
    try {
      const {
        account: {
          selectedAccountData,
          editableAccountData,
          segments,
        },
        userPreferencesReducer: {
          user,
        },
      } = getState();
      let accountData = { ...editableAccountData };
      dispatch(toggleLoading(true));

      const err = Object.entries(accountData).reduce((error, [field, value]) => {
        if (!String(value).trim() && selectedAccountData[field] !== value) {
          return {
            ...error,
            [field]: `${accountDataConstants.find((data) => data.field === field).displayField} can not be empty`,
          };
        }

        if (field === EMAIL_FIELD && !isValidEmail(value)) {
          return {
            ...error,
            [field]: 'Please enter a valid email address',
          };
        }

        return error;
      }, {});

      if (Object.keys(err).length) return dispatch(setAcccountDataError(err));

      accountData.segment_ids = segments.filter((segment) => segment.active).map((segment) => segment.id).join(',');
      delete accountData.segments;

      accountData = await AccountSvc.changeAccountInfo(accountData);
      dispatch(
        setUserData({
          ...user,
          first_name: accountData.first_name,
          last_name: accountData.last_name,
          email: accountData.email,
        }),
      );

      dispatch(setInfo({ accountData, segments }));
      dispatch(toggleEditMode(false));
    } catch (e) {
      dispatch(setAcccountDataError(e));
    } finally {
      dispatch(toggleLoading(false));
    }
  }
);
