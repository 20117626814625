import { forwardRef } from 'react';

import useFinprompt from '../providers/FinpromptProvider/useFinprompt';

const withFinprompt = (Component) => {
  const NewComponent = forwardRef((props, ref) => {
    const { isFinpromptPages } = useFinprompt();

    return <Component {...props} ref={ref} isFinpromptPages={isFinpromptPages} />;
  });

  NewComponent.displayName = Component.displayName;

  return NewComponent;
};

export default withFinprompt;
