import pluralize from 'pluralize';
import { Link } from 'react-router-dom';

import { IDENTIFIER_RULES } from '../data/directory/companyData';

import InfoTable from '../components/InfoTable';
import Tooltip from '../components/Tooltip';
import { countryList } from '../data/filing';

export const generateColumns = (source, groupInfo) => {
  const titles = [];
  const contents = [];

  Object.entries(groupInfo).forEach(([key, { title, isLink, tooltipText }], i) => {
    if (source[key]) {
      const group = {
        id: i,
      };

      const items = Array.isArray(source[key]) ? source[key] : [source[key]];

      if (items.length) {
        const groupTitle = items.length > 1
          ? pluralize(title)
          : (
            <>
              {title}
              {tooltipText && (
                <Tooltip
                  className="info_custom_tooltip"
                >
                  {tooltipText}
                </Tooltip>
              )}
            </>
          );
        group.content = items.map((item) => (
          isLink && item.url ? (
            <Link
              key={item.id}
              to={item.url}
            >
              {item.name || item}
            </Link>
          ) : (
            <span key={item.id || item.name || item}>
              {item.name || item}
            </span>
          )
        ));

        titles.push(groupTitle);
        contents.push(group);
      }
    }
  });

  return {
    titles, contents,
  };
};

export const generateIdentifier = (options) => {
  const identifiers = [];
  IDENTIFIER_RULES.forEach((rule) => {
    if (
      options[rule.key]
      && rule.dependencies.every((dependency) => options[dependency])
    ) {
      identifiers.push(`${rule.selector}:${options[rule.key]}`);
    }
  });

  return identifiers.join('|');
};

export const generateIdentifiers = (identifiers) => (
  identifiers.map((identifierData) => (
    generateIdentifier(identifierData)
  )).filter(Boolean).join(',')
);

export const generateTables = (source, groupInfo, width) => {
  const tables = [];
  const { titles, contents } = generateColumns(source, groupInfo);

  if (titles) {
    let itemsInRow;

    switch (true) {
      case width > 1023:
        itemsInRow = 7;
        break;
      case width > 767:
        itemsInRow = 2;
        break;
      default:
        itemsInRow = 1;
        break;
    }

    for (let i = 0; i < Math.ceil(titles.length / itemsInRow); i += 1) {
      tables.push(
        <InfoTable
          key={i}
          titles={titles.slice(
            itemsInRow * i,
            itemsInRow * (i + 1),
          )}
          columns={contents.slice(
            itemsInRow * i,
            itemsInRow * (i + 1),
          )}
        />,
      );
    }
  }

  return tables;
};

export function getSourceName(source) {
  if (typeof source === 'string') {
    if (/lse/i.test(source)) return 'London Stock Exchange';
    if (/gazette/i.test(source)) return 'The Gazette, UK';
    if (/companieshouse-gb/i.test(source)) return 'Companies House';
    if (/companieshouse-ca/i.test(source)) return 'Innovation, Science And Economic Development Canada';
    if (/sec-us/i.test(source)) return 'Securities And Exchange Commission';
  }
  return source;
}

export function getCountry(countryCode) {
  return countryList.find(({ ticker }) => ticker.toLowerCase() === countryCode.toLowerCase());
}
