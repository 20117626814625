import {
  memo, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import orderBy from 'lodash-es/orderBy';

import Charts from '../../SEO_pages/Charts';
import TransactionsChart from '../../SEO_pages/TransactionsChart';

import { getFormattedQuery } from '../../../helpers/helpers';
import { getTrendingBarAssetQuery } from '../../../helpers/watchlistHelperFunctions';
import { generateIdentifier } from '../../../helpers/companyHelpers';
import { getActivePriorityTicker } from '../../../helpers/topicsApiHelpers';

import { toggleModalInsideChatbot } from '../../../actions/chatbot.actions';

import { timeFilter } from '../../../data/directory/insiderTransactions/timeFilter';
import { SLUG_TO_TYPE_MAPPING } from '../../../data/directory/topic_classes/topicClassTypes';

import useSizes from '../../../hooks/sizes';

const PriceChart = (props) => {
  const {
    payload, scrollToBottomOnWidgetRendered, isChatbotOpened, widgetType,
  } = props;

  const dispatch = useDispatch();

  const expanded = useSelector(({ chatbot }) => (chatbot.expanded));

  const components = payload?.components;
  const messageDate = payload.date;

  const { width } = useSizes();

  const plotRef = useRef(null);
  const timeoutStarted = useRef(null);

  const [priceTile, setPriceTile] = useState([]);

  const [priceChart, setPriceChart] = useState([]);
  const [priceActiveTimeFilter, setPriceActiveTimeFilter] = useState([]);
  const [priceAsset, setPriceAsset] = useState([]);
  const [selector, setSelector] = useState([]);

  const [infoChart, setInfoChart] = useState([]);
  const [identifier, setIdentifier] = useState([]);
  const [itInfoActiveTimeFilter, setItInfoActiveTimeFilter] = useState([]);
  const [itInfoActiveTimePeriods, setItInfoActiveTimePeriods] = useState([timeFilter]);
  const [itInfoAsset, setItInfoAsset] = useState([]);

  const [tablesInfo, setTablesInfo] = useState([]);

  const widthExpandedCharts = width < 2800 ? 380 : 620;

  const getCorrectActiveTimeFilterPrice = (time) => {
    if (time === '1h') return '1d';
    return time;
  };

  useEffect(() => {
    components?.forEach((item) => {
      const {
        component_name: componentName,
        component_data: componentData, activeTimeFilter,
      } = item;

      if (componentName === 'price_tile') {
        setPriceTile((prevState) => ([...prevState, { ...componentData }]));
      }
      if (componentName === 'price_chart') {
        if (Array.isArray(componentData.company_chart)) {
          setPriceChart((prevState) => ([...prevState, { ...componentData.company_chart }]));
        } else if (Array.isArray(componentData?.company_chart?.cryptocurrency_stats)) {
          setPriceChart((prevState) => ([
            ...prevState,
            { ...componentData?.company_chart?.cryptocurrency_stats }]));
        }
        if (componentData?.assets_info?.assets?.length) {
          setPriceAsset((prevState) => ([
            ...prevState,
            { ...componentData?.assets_info?.assets[0] }]));
        }
        setPriceActiveTimeFilter((prevState) => ([...prevState, activeTimeFilter]));
      }

      if (componentName === 'IT_info_chart') {
        if (Array.isArray(componentData?.company_chart)) {
          setInfoChart((prevState) => ([...prevState, { ...componentData.company_chart }]));
          setItInfoActiveTimeFilter((prevState) => ([...prevState, activeTimeFilter]));
          setItInfoAsset((prevState) => ([
            ...prevState,
            { ...componentData?.assets_info?.assets[0] }]));
        } else if (Array.isArray(componentData.cryptocurrency_stats)) {
          setInfoChart((prevState) => ([...prevState, { ...componentData.cryptocurrency_stats }]));
        }
      }

      if (componentName === 'tables_generic') {
        if (Array.isArray(componentData?.data)) {
          setTablesInfo((prevState) => ([...prevState, { ...componentData.data }]));
        }
      }
    });
  }, []);

  useEffect(() => {
    priceAsset.forEach((item) => {
      const defaultUsTickers = orderBy(item?.tickers?.filter((ticker) => ticker.default), 'priority', 'asc') || [];

      if (defaultUsTickers?.length) {
        setSelector((prevState) => ([...prevState, defaultUsTickers[0].full_name]));
      }
    });
  }, [priceChart, priceAsset]);

  useEffect(() => {
    const idenitifiersData = itInfoAsset.map((item) => {
      const defaultUsTickers = orderBy(item?.tickers?.filter((ticker) => ticker.default), 'priority', 'asc') || [];
      if (defaultUsTickers?.length) {
        return {
          topic: item,
          value: generateIdentifier({ ticker: defaultUsTickers[0].full_name }),
        };
      }

      return null;
    });
    setIdentifier(idenitifiersData);
  }, [infoChart, itInfoAsset]);

  useEffect(() => {
    itInfoActiveTimePeriods.forEach((filters) => {
      setItInfoActiveTimePeriods((prevState) => [
        ...prevState,
        filters.map((item) => ({
          ...item,
          active: item.value === itInfoActiveTimeFilter,
        })),
      ]);
    });
  }, [itInfoActiveTimeFilter]);

  useEffect(() => {
    if (!isChatbotOpened) return;
    if (scrollToBottomOnWidgetRendered) {
      if (timeoutStarted.current) return;

      const timeOut = setTimeout(() => {
        scrollToBottomOnWidgetRendered();
        timeoutStarted.current = true;
      }, 50);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [isChatbotOpened]);

  const handleModalInsideChatbot = (newState) => {
    // fixed issue web-7279
    // Section models getting displayed simultaneously and getting overlapped
    dispatch(toggleModalInsideChatbot(newState));
  };

  if (!isChatbotOpened) return null;

  if (!infoChart?.length && !priceChart?.length && !priceTile && !tablesInfo?.length) return null;

  return (
    <div
      data-date={messageDate}
      ref={plotRef}
      className="react-chatbot-kit__widget__wrapper charts"
    >
      {priceChart?.map((chart, index) => (getActivePriorityTicker(priceAsset[index].tickers).length ? (
        <div
          className="react-chatbot-kit__widget__chart"
          key={`${index}_c`}
        >
          <Charts
            chatbot
            webhookData={chart}
            selector={[selector[index]]}
            activeTimeFilterChatbot={getCorrectActiveTimeFilterPrice(
              priceActiveTimeFilter[index],
            )}
            assetType={SLUG_TO_TYPE_MAPPING.get(
              priceAsset[index].asset_class?.slug,
            )}
            currentPath={`/news/directory/${priceAsset[index].asset_class?.slug}/${priceAsset[index].slug}/charts`}
            slug={priceAsset[index]}
            width={expanded || (widgetType === 'widgetFullScreen') ? plotRef.current?.clientWidth : widthExpandedCharts}
            query={getFormattedQuery(
              getTrendingBarAssetQuery(priceAsset[index], 'Topic'),
            )}
            chatbotWidgetType={widgetType}
            toggleModalInsideChatbot={handleModalInsideChatbot}
          />
        </div>
      ) : null))}
      {infoChart?.map((infoChart, index) => (
        <div key={`${index}_i`} className="react-chatbot-kit__widget__transaction">
          <TransactionsChart
            activeTimeFilter={itInfoActiveTimePeriods[index]}
            chatbot
            identifier={identifier[index]?.value}
            topic={identifier[index]?.topic}
            chatbotWidgetType={widgetType}
            toggleModalInsideChatbot={handleModalInsideChatbot}
          />
        </div>
      ))}
    </div>
  );
};

export default memo(PriceChart);
