import { isMobileOnly } from 'react-device-detect';
import isEqual from 'lodash-es/isEqual';

import { getUserToken } from '../helpers/getUserToken';
import { loadPreferencesAction, updateUserPreferences } from './preferencesActions';

import { initialState, refreshRateConversion, refreshValuesConversion } from '../data/filters/storyFilters';
import { SILVER, TWEETS } from '../data/permissions';

import {
  getEvents,
  getSectors,
  saveFilters,
} from '../services/dbServices/filtersDataService';

import { clearSavedTrendingFiltersStates, clearSavedStoryFiltersStates } from '../services/localStorage';
import { fetchPreferences, getTranslationSupportedLanguages, updatePreferences } from '../services/dbServices/userServices';
import StorageSvc from '../services/StorageSvc';
import TopicsSvc from '../services/TopicsSvc';

export const REFRESH = 'REFRESH';
export const STORY_ORDER = 'STORY_ORDER';
export const TIME_PERIOD = 'TIME_PERIOD';
export const CATEGORIES = 'CATEGORIES';
export const MIN_SCORE = 'MIN_SCORE';
export const LANGUAGE = 'LANGUAGE';
export const CLASSES = 'CLASSES';
export const EVENTS = 'EVENTS';
export const SECTORS = 'SECTORS';
export const INDUSTIRES = 'INDUSTIRES';
export const SUBINDUSTIRES = 'SUBINDUSTIRES';
export const STARTUP_CATEGORIES = 'STARTUP_CATEGORIES';
export const SEGMENTS = 'SEGMENTS';
export const QUERY = 'QUERY';
export const RESET_STORY_FILTERS = 'RESET_STORY_FILTERS';
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';
export const RESET_TRENDING_FILTERS = 'RESET_TRENDING_FILTERS';
export const ADVANCED_SETTINGS = 'ADVANCED_SETTINGS';
export const REQUIRED_SOURCE = 'REQUIRED_SOURCE';
export const REFRESH_COUNTER = 'REFRESH_COUNTER';
export const EMPTY_TRENDIG_FILTERS = 'EMPTY_TRENDIG_FILTERS';
export const TOGGLE_LANGUAGES_SELECTED = 'TOGGLE_LANGUAGES_SELECTED';
export const TOGGLE_EXPAND_STORIES = 'TOGGLE_EXPAND_STORIES';
export const TOGGLE_USER_NEWSFEED_VIEW_IMAGES = 'TOGGLE_USER_NEWSFEED_VIEW_IMAGES';
export const TOGGLE_USER_NEWSFEED_VIEW_DESCRITION = 'TOGGLE_USER_NEWSFEED_VIEW_DESCRITION';
export const TOGGLE_USER_NEWSFEED_VIEW = 'TOGGLE_USER_NEWSFEED_VIEW';
export const TOGGLE_USER_SIMPLIFIED_VIEW = 'TOGGLE_USER_SIMPLIFIED_VIEW';
export const TOGGLE_TILES_VIEW = 'TOGGLE_TILES_VIEW';
export const TOGGLE_GROUP_SIMILAR_STORIES = 'TOGGLE_GROUP_SIMILAR_STORIES';
export const TOGGLE_SHOW_SIMILAR_STORIES = 'TOGGLE_SHOW_SIMILAR_STORIES';
export const LOAD_PREFRENCES = 'LOAD_PREFRENCES';
export const TRANSLATE_STORIES_INTO_ENGLISH = 'TRANSLATE_STORIES_INTO_ENGLISH';
export const FETCH_TRENDING_FILTERS_ITEMS_BEGIN = 'FETCH_TRENDING_FILTERS_ITEMS_BEGIN';
export const FETCH_TRENDING_FILTERS_ITEMS_SUCCESS = 'FETCH_TRENDING_FILTERS_ITEMS_SUCCESS';
export const LOAD_TRENDING_FILTERS = 'LOAD_TRENDING_FILTERS';
export const DISABLE_TRENDING_PRELOADER = 'DISABLE_TRENDING_PRELOADER';
export const TOGGLE_CONFLICTS_FLAG = 'TOGGLE_CONFLICTS_FLAG';
export const PREMIUM_CONTENT_ONLY = 'PREMIUM_CONTENT_ONLY';
export const SAVE_STORY_FILTERS = 'SAVE_STORY_FILTERS';
export const TOGGLE_DOMAINS_FILTERING_MODE = 'TOGGLE_DOMAINS_FILTERING_MODE';
export const SET_SUMMARISATION_STATE = 'SET_SUMMARISATION_STATE';
export const SET_ORDER_TAB = 'SET_ORDER_TAB';
export const SET_SENTIMENT_TAB = 'SET_SENTIMENT_TAB';
export const TOGGLE_USER_NEWSFEED_VIEW_COMPACT = 'TOGGLE_USER_NEWSFEED_VIEW_COMPACT';
export const SENTIMENT_CATEGORIES = 'SENTIMENT_CATEGORIES';
export const RESET_USER_SELECTED_FILTERS = 'RESET_USER_SELECTED_FILTERS';
export const TOGGLE_PREMIUM_LABELS = 'TOGGLE_PREMIUM_LABELS';
export const TOGGLE_ONBOARDING_PROCESS = 'TOGGLE_ONBOARDING_PROCESS';
export const SAVE_TRANSLATION_LANGUAGES = 'SAVE_TRANSLATION_LANGUAGES';

const getFormattedDataToPreferencesEndpoint = ({
  user_newsfeed_compactview_web: compactView, ...state
}) => ({
  refresh_rate: refreshRateConversion[state.refresh_rate],
  order_by: state.order_by,
  time_filter: state.time_filter,
  categories: state.categories,
  twitter_following_only: state.twitter_following_only,
  with_links: state.with_links,
  min_score: state.min_score,
  languages: state.languages,
  all_languages: state.all_languages,
  user_newsfeed_advanced_view_web: state.user_newsfeed_advanced_view_web,
  user_newsfeed_view_web: state.user_newsfeed_view_web,
  tiles_view: state.tiles_view,
  show_similar_stories_in_feed: state.show_similar_stories_in_feed,
  group_similar_stories: state.group_similar_stories,
  selected_time_filter: state.selected_time_filter,
  summarization_enabled: state.summarization_enabled,
  selected_order_tab: state.selected_order_tab,
  selected_sentiment_tab: state.selected_sentiment_tab,
  user_newsfeed_compactview_web: compactView,
  expand_stories: state.expand_stories,
  user_newsfeed_view_images_web: state.user_newsfeed_view_images_web,
  user_newsfeed_view_description_web:
    state.user_newsfeed_view_description_web,
});

export const loadTrendingFilters = (filters) => ({
  type: LOAD_TRENDING_FILTERS,
  payload: filters,
});

export const filterByClasses = (newState) => ({
  type: CLASSES,
  payload: newState,
});

export const filterByEvents = (newState) => ({
  type: EVENTS,
  payload: newState,
});

export const filterBySectors = (newState) => ({
  type: SECTORS,
  payload: newState,
});

export const filterByIndustries = (newState) => ({
  type: INDUSTIRES,
  payload: newState,
});

export const filterBySubindustries = (newState) => ({
  type: SUBINDUSTIRES,
  payload: newState,
});

export const filterByStartupCategories = (newState) => ({
  type: STARTUP_CATEGORIES,
  payload: newState,
});

export const filterBySegments = (newState) => ({
  type: SEGMENTS,
  payload: newState,
});

export const filterByQuery = (newState) => ({
  type: QUERY,
  payload: newState,
});

export const setSummarisationState = (state) => ({
  type: SET_SUMMARISATION_STATE,
  payload: state,
});

export const toggleConflictsFlag = () => ({
  type: TOGGLE_CONFLICTS_FLAG,
});

export const saveStoryFilters = (newState) => ({
  type: SAVE_STORY_FILTERS,
  payload: newState,
});

export const resetAllFilters = (defaultFiltersValue) => ({
  type: RESET_ALL_FILTERS,
  payload: defaultFiltersValue,
});

export const resetUserSelectedFilters = (defaultFiltersValue) => ({
  type: RESET_USER_SELECTED_FILTERS,
  payload: defaultFiltersValue,
});

export const resetTrendingFiltersAction = () => ({
  type: RESET_TRENDING_FILTERS,
});

export const filterByRequiredSources = (state) => ({
  type: REQUIRED_SOURCE,
  payload: state,
});

export const disableStoriesPreloader = () => ({
  type: DISABLE_TRENDING_PRELOADER,
});

export const increaseRefreshCounter = () => ({
  type: REFRESH_COUNTER,
});

export const checkEmptyTrendingFilters = (state) => ({
  type: EMPTY_TRENDIG_FILTERS,
  payload: state,
});

export const loadPreferencesIntoStoryFilters = (preferencs) => ({
  type: LOAD_PREFRENCES,
  payload: preferencs,
});

export const toggleDomainsFilteringMode = (mode) => ({
  type: TOGGLE_DOMAINS_FILTERING_MODE,
  payload: mode,
});

export const fetchTrendingFiltersValues = () => (dispatch) => Promise.all([
  TopicsSvc.getAssetsClasses(),
  getSectors(),
  getEvents(1, 165, 200),
]).then(([classes, sectors, events]) => {
  dispatch(loadTrendingFilters({
    classes: [...classes.data.asset_classes],
    sectors: sectors.map((item) => ({
      ...item,
      select: 'all',
    })),
    events: events.data.assets.map((item) => ({
      ...item,
      select: 'all',
    })),
  }));
});

const getFormattedPreferenceData = (data) => ({
  ...data.preference,
  languages: data.preference.lang,
  refresh_rate: refreshValuesConversion[data.preference.refresh_rate],
  sentiment_gte: data.preference.sentiment_down_limit,
  sentiment_lte: data.preference.sentiment_up_limit,
  sentiment_levels: (data.preference.sentiment_categories || []).join(','),
});

export const saveTranslationSupportedLanguages = (token) => async (dispatch) => {
  if (token) {
    getTranslationSupportedLanguages().then((res) => {
      StorageSvc.setItem('translationSupportedLanguages', JSON.stringify(res.from_languages
        .map((el) => el.code).toString().split(',')));
      dispatch({
        type: SAVE_TRANSLATION_LANGUAGES,
        payload: res.from_languages,
      });
    });
  }
};

export const loadFilters = (storyFilters) => (dispatch) => {
  // terminate if user not logged in
  if (!getUserToken()) {
    // check for preventing error on server side rendering
    if (typeof window === 'object' && 'setTimeout' in window) setTimeout(() => dispatch(saveStoryFilters(storyFilters)));
    return;
  }

  // check if user has custom story filters to load it instead of load the filters from db
  if (!isEqual(initialState, storyFilters) && !JSON.parse(StorageSvc.getItem('filtersTransferred'))) {
    // load the custom story filters user selected from localStorage and save it to db
    const preferenceData = getFormattedDataToPreferencesEndpoint(storyFilters);

    updatePreferences(preferenceData).then(({ data }) => {
      StorageSvc.setItem('filtersTransferred', 'true');

      // remove all localstorage filters after they transferred to registered user
      clearSavedStoryFiltersStates();
      StorageSvc.removeItem('state');
      const formattedData = getFormattedPreferenceData(data);

      dispatch(saveStoryFilters(formattedData));
      dispatch(loadPreferencesAction(formattedData));
    });
  } else {
    // load story filters saved in db into app
    fetchPreferences().then(({ data }) => {
      const formattedData = getFormattedPreferenceData(data);

      dispatch(saveStoryFilters(formattedData));
      dispatch(loadPreferencesIntoStoryFilters(formattedData));
      dispatch(loadPreferencesAction(formattedData));
    });
  }
};

export const refreshEvery = (newState, token) => async (dispatch) => {
  const refreshValues = {
    10000: 'seconds10',
    30000: 'seconds30',
    60000: 'minute1',
    600000: 'minutes10',
    never: 'never',
  };

  if (token) {
    const preference = await saveFilters({ refresh_rate: refreshValues[newState] });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: REFRESH,
    payload: String(newState),
  });
};

export const filterByStoryOrder = (newState, token, shouldLoadFilters = true) => (
  async (dispatch) => {
    if (token) {
      const preference = await saveFilters({ order_by: newState });
      if (shouldLoadFilters) {
        return dispatch(loadFilters(preference));
      }
    }
    dispatch({
      type: STORY_ORDER,
      payload: newState,
    });
  }
);

export const filterByTimePeriod = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({
      time_filter: newState.time_filter,
      selected_time_filter: newState.selected_time_filter,
      start_datetime: newState.start_datetime,
      end_datetime: newState.end_datetime,
      time_period: newState.time_period,
    });

    return dispatch(loadFilters(preference));
  }

  dispatch({
    type: TIME_PERIOD,
    payload: {
      time_filter: newState.time_filter,
      selected_time_filter: newState.selected_time_filter,
    },
  });
};

export const filterByCategories = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ categories: newState });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: CATEGORIES,
    payload: newState,
  });
};

export const filterByMinScore = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ min_score: newState });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: MIN_SCORE,
    payload: newState,
  });
};

export const filterBySelectedSources = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters(newState);
    return dispatch(loadFilters(preference));
  }
};

export const filterByLanguage = (newState, allLanguages, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ languages: newState, all_languages: allLanguages });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: LANGUAGE,
    payload: {
      all_languages: allLanguages,
      languages: newState,
    },
  });
};

export const filterByAdvancedSettings = (filterValues, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ ...filterValues });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: ADVANCED_SETTINGS,
    payload: { ...filterValues },
  });
};

// TODO remove this action anf use resetAllFiltersAction()
export const resetStoryFilters = () => (dispatch, getState) => {
  const data = { ...initialState };
  const {
    access_levels: accessLevels,
    search_period: searchPeriod,
  } = getState().subscriptions.permissions;

  if (!accessLevels.includes(TWEETS)) data.categories = data.categories.split(',').filter((cat) => !['c', 'i', 'e'].includes(cat)).join(',');
  data.time_filter = searchPeriod;

  dispatch({
    type: RESET_STORY_FILTERS,
    payload: data,
  });
};

export const resetTrendingFilters = () => (dispatch) => {
  clearSavedTrendingFiltersStates();
  dispatch(resetTrendingFiltersAction());
};

export const toggleExpandStories = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ expand_stories: newState });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: TOGGLE_EXPAND_STORIES,
    payload: newState,
  });
};

export const toggleUserNewsfeedViewImages = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ user_newsfeed_view_images_web: newState });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: TOGGLE_USER_NEWSFEED_VIEW_IMAGES,
    payload: newState,
  });
};

export const toggleUserNewsfeedViewDescription = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ user_newsfeed_view_description_web: newState });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: TOGGLE_USER_NEWSFEED_VIEW_DESCRITION,
    payload: newState,
  });
};

export const toggleUserNewsfeedView = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({
      [isMobileOnly ? 'user_newsfeed_view' : 'user_newsfeed_view_web']: newState,
      [isMobileOnly ? 'user_newsfeed_compactview' : 'user_newsfeed_compactview_web']: false,
    });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: TOGGLE_USER_NEWSFEED_VIEW,
    payload: newState,
  });
};

export const toggleUserSimplifiedView = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({
      [isMobileOnly ? 'user_newsfeed_advanced_view' : 'user_newsfeed_advanced_view_web']: newState,
    });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: TOGGLE_USER_SIMPLIFIED_VIEW,
    payload: newState,
  });
};

export const toggleTilesView = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({
      tiles_view: newState,
      user_newsfeed_view_web: !newState,
    });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: TOGGLE_TILES_VIEW,
    payload: newState,
  });
};

export const toggleGroupSimilars = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ group_similar_stories: newState });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: TOGGLE_GROUP_SIMILAR_STORIES,
    payload: newState,
  });
};

export const toggleSummarisation = (newState, token) => async (dispatch) => {
  if (token) {
    saveFilters({ summarization_enabled: newState }).then((preference) => {
      dispatch(loadFilters(preference));
    });
  }
  dispatch(setSummarisationState(newState));
};

export const toggleShowSimilars = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ show_similar_stories_in_feed: newState });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: TOGGLE_SHOW_SIMILAR_STORIES,
    payload: newState,
  });
};

export const toggleUserNewsfeedViewCompact = (newState, token) => async (dispatch) => {
  const param = {
    [isMobileOnly ? 'user_newsfeed_compactview' : 'user_newsfeed_compactview_web']: newState,
  };
  if (newState) {
    if (isMobileOnly) {
      param.user_newsfeed_view = 'tiles';
    } else {
      param.user_newsfeed_view_web = 'rows';
    }
  }

  if (token) {
    const preference = await saveFilters(param);
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: TOGGLE_USER_NEWSFEED_VIEW_COMPACT,
    payload: param,
  });
};

// select all languages action
export const toggleAllLanguagesSelected = (
  newState, selectedLanguages, token,
) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ all_languages: newState, languages: selectedLanguages });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: TOGGLE_LANGUAGES_SELECTED,
    payload: {
      all_languages: newState,
      languages: selectedLanguages,
    },
  });
};

export const showTranslation = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ translate_to: newState ? 'en' : null, translate_all: Boolean(newState) });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: TRANSLATE_STORIES_INTO_ENGLISH,
    payload: newState,
  });
};

export const filterByPremiumContent = (newState, token) => async (dispatch) => {
  if (token) {
    const preference = await saveFilters({ premium_content_only: newState });
    return dispatch(loadFilters(preference));
  }
  dispatch({
    type: PREMIUM_CONTENT_ONLY,
    payload: newState,
  });
};

export const toggleSentimentFilterType = (newState) => async (dispatch) => {
  const preference = await saveFilters({ sentiment_filter: newState });
  return dispatch(loadFilters(preference));
};

export const filterBySentimentCategories = (newState) => async (dispatch) => {
  const preference = await saveFilters({ sentiment_categories: newState.join(',') });
  return dispatch(loadFilters(preference));
};

export const filterBySentimentRange = (newState) => async (dispatch) => {
  const preference = await saveFilters({
    sentiment_down_limit: newState.sentiment_down_limit,
    sentiment_up_limit: newState.sentiment_up_limit,
  });
  return dispatch(loadFilters(preference));
};

export const filterByAllSentiments = () => async (dispatch, getState) => {
  const { storyFilters } = getState();
  const param = {};
  if (storyFilters.sentiment_filter === 'categories') {
    param.sentiment_categories = 'very_negative,negative,neutral,positive,very_positive';
  } else {
    param.sentiment_down_limit = -100;
    param.sentiment_up_limit = 100;
  }
  const preference = await saveFilters(param);
  return dispatch(loadFilters(preference));
};

export const resetAllFiltersAction = (token, filtersParams) => async (dispatch, getState) => {
  const data = getFormattedDataToPreferencesEndpoint(initialState);
  const { subscriptions } = getState();
  const { access_levels: accessLevels } = subscriptions.permissions;

  if (!accessLevels.includes(TWEETS)) data.categories = data.categories.split(',').filter((cat) => !['c', 'i', 'e'].includes(cat)).join(',');

  const params = {
    ...data,
    ...filtersParams,
    selected_time_filter: 'predefined',
    translate_to: null,
    translate_all: false,
    refresh_rate: 'minutes10',
    premium_content_only: false,
    user_newsfeed_compactview_web: false,
  };

  if (accessLevels.includes(SILVER)) {
    params.sentiment_categories = 'very_negative,negative,neutral,positive,very_positive';
    params.sentiment_down_limit = -100;
    params.sentiment_up_limit = 100;
  }

  if (token) {
    dispatch(resetAllFilters({
      ...params, refresh_rate: refreshValuesConversion[params.refresh_rate],
    }));
    const preference = await saveFilters(params);
    dispatch(updateUserPreferences({
      ...preference, refresh_rate: refreshValuesConversion[preference.refresh_rate],
    }));
    return dispatch(loadFilters(preference));
  }

  dispatch(resetAllFilters({
    ...params, refresh_rate: refreshValuesConversion[params.refresh_rate],
  }));
};

export const resetSelectedFilters = (token, filterParams) => async (dispatch) => {
  if (token) {
    if (filterParams.hasOwnProperty('paywall') || filterParams.hasOwnProperty('registrationRequired')) {
      dispatch(resetUserSelectedFilters({
        ...filterParams,
      }));
    }
    const preference = await saveFilters(filterParams);
    dispatch(updateUserPreferences({
      ...preference,
    }));
    return dispatch(loadFilters(preference));
  }

  dispatch(resetUserSelectedFilters({
    ...filterParams,
  }));
};

export const setSentimentTab = (newState) => async (dispatch) => {
  dispatch({
    type: SET_SENTIMENT_TAB,
    payload: newState,
  });
};

export const togglePremiumLabels = (newState) => async (dispatch) => {
  dispatch({
    type: TOGGLE_PREMIUM_LABELS,
    payload: newState,
  });
};

export const toggleOnboardingProcess = (newState) => async (dispatch) => {
  dispatch({
    type: TOGGLE_ONBOARDING_PROCESS,
    payload: newState,
  });
};
