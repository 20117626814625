export const UK_PRIVATE_COMPANY_TOP_YEAR_FILTERS = 'UK_PRIVATE_COMPANY_TOP_YEAR_FILTERS';
export const UK_PRIVATE_COMPANY_INVESTORS_FILTERS = 'UK_PRIVATE_COMPANY_INVESTORS_FILTERS';
export const UK_PRIVATE_COMPANY_SINGLE_INVESTORS_FILTERS = 'UK_PRIVATE_COMPANY_SINGLE_INVESTORS_FILTERS';
export const UK_PRIVATE_COMPANY_FUNDRAISING_FILTERS = 'UK_PRIVATE_COMPANY_FUNDRAISING_FILTERS';
export const UK_PRIVATE_COMPANY_SINGLE_FUNDRAISING_FILTERS = 'UK_PRIVATE_COMPANY_SINGLE_FUNDRAISING_FILTERS';
export const UK_PRIVATE_COMPANY_MARKET_FILTERS = 'UK_PRIVATE_COMPANY_MARKET_FILTERS';
export const UK_PRIVATE_COMPANY_ROI_FILTERS = 'UK_PRIVATE_COMPANY_ROI_FILTERS';
export const UK_PRIVATE_COMPANY_HIDE_ABNORMAL = 'UK_PRIVATE_COMPANY_HIDE_ABNORMAL';

export const setUKTopYearFilters = (payload) => ({
  type: UK_PRIVATE_COMPANY_TOP_YEAR_FILTERS,
  payload,
});

export const handleInvestorsFilter = (payload) => ({
  type: UK_PRIVATE_COMPANY_INVESTORS_FILTERS,
  payload,
});

export const handleSingleInvestorsFilter = (payload) => ({
  type: UK_PRIVATE_COMPANY_SINGLE_INVESTORS_FILTERS,
  payload,
});

export const handleFundraisingFilter = (payload) => ({
  type: UK_PRIVATE_COMPANY_FUNDRAISING_FILTERS,
  payload,
});

export const handleSingleFundraisingFilter = (payload) => ({
  type: UK_PRIVATE_COMPANY_SINGLE_FUNDRAISING_FILTERS,
  payload,
});

export const handleMarketFilter = (payload) => ({
  type: UK_PRIVATE_COMPANY_MARKET_FILTERS,
  payload,
});

export const handleRoiFilter = (payload) => ({
  type: UK_PRIVATE_COMPANY_ROI_FILTERS,
  payload,
});

export const setUKPrivateCompanyHideAbnormal = (payload) => ({
  type: UK_PRIVATE_COMPANY_HIDE_ABNORMAL,
  payload,
});
