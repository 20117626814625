import { Component } from 'react';
import classNames from 'classnames/bind';
import autoBind from 'react-autobind';
import loadable from '@utils/loadable';

import withComponentName from '../../../../../../decorators/withComponentName';
import SentimentsSvc from '../../../../../../services/dbServices/SentimentsSvc';
import {
  getPeriodType,
  generateTopicChartData,
  generateChartData,
} from '../../../../../../helpers/sentimentHelpers';
import {
  defaultChartGraphData, defaultChartFillGraphData,
} from '../../../../../../data/sentimentAnalysis';
import { sentimentPeriodFilters } from '../../../../../../data/directory/topics/topicsSentimentAnalysis';

import Styles from './styles.module.scss';

const ChartSection = loadable(() => import('./ChartSection'));

const cx = classNames.bind(Styles);

class SentimentChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChartLoading: true,
      isChartHidden: false,
      chartPeriod: props.activePeriod,
      chartGraph: defaultChartGraphData,
      chartGraphArr: [defaultChartGraphData],
      lastPointData: null,
      avgPoint: {},
      confidenceY: [],
    };
    autoBind(this);
  }

  componentDidMount() {
    this.generateTopicData();
  }

  componentDidUpdate(prevProps) {
    const { topic } = this.props;
    if (topic.id !== prevProps.topic.id) {
      this.generateTopicData();
    }
  }

  async callTopicAPI(params) {
    try {
      const { data } = await SentimentsSvc.getTopicSentiments(params);
      return data;
    } catch (e) {
      console.log(e);
    }
    return { data: [] };
  }

  async generateTopicData() {
    this.setState({
      isChartLoading: true,
    });

    const { chartPeriod } = this.state;
    const {
      slugType, topic, isFullLocations, setChartColor,
    } = this.props;
    const periodTypeObj = getPeriodType(chartPeriod);
    const params = {
      period: periodTypeObj.period,
      duration: 'daily',
    };

    if (isFullLocations) {
      params.entity_type = 'Continent';
    } else {
      params.entity_type = slugType;
      params.entity_id = topic.id;
    }

    const data = await this.callTopicAPI(params);
    const newData = generateTopicChartData(data, {
      ...periodTypeObj,
      hasConfidence: true,
    });
    const {
      chartColor, chartGraph, chartGraphArr,
    } = generateChartData(newData);
    setChartColor(chartColor);

    this.setState({
      chartColor,
      chartGraph,
      chartGraphArr,
      lastPointData: newData.lastPointData,
      avgPoint: newData.avgPoint,
      isChartLoading: false,
      isChartHidden: !newData.x.length || newData.x.length <= 5,
      averageConfidence: Math.round((data.average_confidence || 0) * 100),
      confidenceY: newData.newConfidence,
    });
  }

  handlePeriodChange(type) {
    this.setState({
      chartPeriod: type,
    }, () => {
      this.generateTopicData();
    });
  }

  render() {
    const {
      chartPeriod, chartGraph, isChartLoading, isChartHidden,
      lastPointData, chartGraphArr, chartColor, avgPoint, averageConfidence,
      confidenceY,
    } = this.state;
    const {
      sentimentStoryModalRef, query, isFullLocations, topicURL, chatbot,
    } = this.props;

    return (
      <div className={Styles['sentiment-chart']}>
        <div className={Styles['sentiment-chart-header']}>
          <div className={Styles['sentiment-chart-header-left']}>
            <div className={Styles['sentiment-chart-header-text']}>Period:</div>
            <ul className={Styles['time-filters']}>
              {sentimentPeriodFilters.map((filter) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  key={filter.value}
                  className={cx('filter', { active: chartPeriod === filter.value })}
                  onClick={() => this.handlePeriodChange(filter.value)}
                >
                  {filter.value}
                </li>
              ))}
            </ul>
          </div>
          <div className={Styles.chart_average}>
            <div className={Styles.chart_average_title}>
              Average:
            </div>
            <div className={cx('chart_average_type', [avgPoint.type])}>
              {avgPoint.storyText}
            </div>
          </div>
        </div>
        <ChartSection
          query={query}
          isChartLoading={isChartLoading}
          isChartHidden={isChartHidden}
          chartGraph={chartGraph}
          chartGraphArr={chartGraphArr}
          lastPointData={lastPointData}
          avgPoint={avgPoint}
          chartPeriod={chartPeriod}
          chartColor={chartColor}
          sentimentStoryModalRef={sentimentStoryModalRef}
          isFullLocations={isFullLocations}
          topicURL={topicURL}
          averageConfidence={averageConfidence}
          confidenceY={confidenceY}
          chatbot={chatbot}
        />
      </div>
    );
  }
}

export default withComponentName(SentimentChart);
