import {
  FETCH_BOOKMARKS_RESOLVED,
  FETCH_BOOKMARKS_PENDING,
  FETCH_BOOKMARKS_REJECTED,
  FETCH_BOOKMARKS_NEXT_PAGE_RESOLVED,
} from '../actions/bookmarks.actions';

const initialState = {
  bookmarks: [],
  pending: false,
  error: null,
  nextPageToken: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BOOKMARKS_RESOLVED:
      return {
        ...state,
        bookmarks: action.payload.bookmarks,
        pending: false,
        error: null,
        nextPageToken: action.payload.nextPageToken,
      };
    case FETCH_BOOKMARKS_NEXT_PAGE_RESOLVED:
      return {
        ...state,
        bookmarks: [...state.bookmarks, ...action.payload.bookmarks],
        pending: false,
        error: null,
        nextPageToken: action.payload.nextPageToken,
      };
    case FETCH_BOOKMARKS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case FETCH_BOOKMARKS_REJECTED:
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    default:
      return state;
  }
};
