import { memo } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import withComponentName from '../../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const TabItem = memo((props) => {
  const {
    className,
    active,
    children,
    onClick,
    hasBigScreenDesign,
    style,
  } = props;

  return (
    <button
      type="button"
      className={`${cx('tab-item', { active, big_screen: hasBigScreenDesign })} ${className}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  );
});

TabItem.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

TabItem.defaultProps = {
  className: '',
  onClick: () => {},
  active: false,
};

export default withComponentName(TabItem);
