import { Modal } from 'react-bootstrap';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';

import { isMobile } from 'react-device-detect';
import GrayButton from '../../Buttons/PreferencesButtons/GrayButton';
import TransparentButton from '../../Buttons/PreferencesButtons/TransparentButton';
import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const PremiumLabelHideModal = ({ show, closeModal, onConfirm }) => (
  <Modal
    show={show}
    onHide={closeModal}
    dialogClassName={cx('premium-label-modal')}
    className={cx('premium-label-modal-outer')}
    centered
  >
    <div className={Styles['premium-label-modal-inner']} onClick={(e) => e.stopPropagation()}>
      <FontAwesomeIcon className={cx('close')} icon={faXmark} onClick={closeModal} />
      {isMobile && (
        <>
          <p className={cx('message')}>
            Additional content for Premium users.
          </p>
          <p className={cx('message', 'secondMessage')}>
            Manage labels in Filters or hide now.
          </p>
        </>
      )}
      {!isMobile && (
        <p className={cx('message')}>
          Premium labels will be hidden.
          <br />
          Reshow labels with the Filters menu.
        </p>
      )}
      <div className={cx('control-buttons')}>
        <GrayButton className={cx('cancel')} onClick={closeModal}>
          Close
        </GrayButton>
        <TransparentButton
          className={cx('confirm')}
          onClick={onConfirm}
        >
          Hide Labels
        </TransparentButton>
      </div>
    </div>
  </Modal>
);

PremiumLabelHideModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  onConfirm: PropTypes.func,
};

PremiumLabelHideModal.defaultProps = {
  show: false,
  closeModal: () => null,
  onConfirm: () => null,
};

export default withComponentName(PremiumLabelHideModal);
