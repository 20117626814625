import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';

import {
  GOLD, SILVER, BASIC, GUEST,
} from '../../../../data/permissions';
import { CF_URL_FOR_FINPROMPT } from '../../../../data/environment';

import { setAuthModalOpened } from '../../../../actions/authentication.actions';

import useFinprompt from '../../../../providers/FinpromptProvider/useFinprompt';

import Preloader from '../../../Preloader';
import CFLink from '../../../Shared/CFLink';
import StoryCard from './StoryCard';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const StoriesList = (props) => {
  const {
    isLoading, isNewLoading, storiesArr, topicURL, hasBigScreenDesign,
  } = props;
  const dispatch = useDispatch();
  const { isFinpromptPages } = useFinprompt();

  const { access_levels: accessLevels } = useSelector(({ subscriptions }) => (
    subscriptions.permissions
  ));

  const handleLogin = () => {
    dispatch(setAuthModalOpened(true));
  };

  const isGoldPlan = accessLevels?.includes(GOLD);
  const isSilverPlan = accessLevels?.includes(SILVER) && !isGoldPlan;
  const isBasicPlan = accessLevels?.includes(BASIC) && !isGoldPlan && !isSilverPlan;
  const isGuestPlan = accessLevels?.includes(GUEST) && !isGoldPlan && !isSilverPlan && !isBasicPlan;

  const getCorrectPath = (path) => (isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}${path}` : path);

  return (
    <div className={cx('stories-list', { big_screen: hasBigScreenDesign })}>
      {isLoading ? (
        <Preloader centered loading static />
      ) : (
        <>
          {!storiesArr.length && !isNewLoading && (
            <div className={Styles['stories-list-no-data']}>
              No stories to show.
              {' '}
              {(isBasicPlan || isGuestPlan) && (
                <>
                  Premium content may be hidden.
                  {' '}
                  <div
                    className={Styles['stories-list-no-data-log-in']}
                    onClick={handleLogin}
                  >
                    Log in
                  </div>
                  {' '}
                  or
                  {' '}
                  <CFLink to={getCorrectPath('/pricing')} external={isFinpromptPages}>
                    upgrade
                  </CFLink>
                  {' '}
                  for Premium content.
                </>
              )}
              {isSilverPlan && (
                <>
                  Gold content may be hidden. Visit
                  {' '}
                  {topicURL ? (
                    <CFLink external={isFinpromptPages} to={getCorrectPath(topicURL)}>
                      this topic&apos;s page
                    </CFLink>
                  ) : "this topic's page"}
                  {' '}
                  for more content, including non-news data, or
                  {' '}
                  <CFLink to={getCorrectPath('/pricing')} external={isFinpromptPages}>
                    upgrade
                  </CFLink>
                  {' '}
                  for Gold content.
                </>
              )}
              {isGoldPlan && (
                <>
                  Visit
                  {' '}
                  {topicURL ? (
                    <CFLink external={isFinpromptPages} to={getCorrectPath(topicURL)}>
                      this topic&apos;s page
                    </CFLink>
                  ) : "this topic's page"}
                  {' '}
                  for more content, including non-news data and archived content.
                </>
              )}
            </div>
          )}
          {storiesArr.map((story) => (
            <StoryCard
              key={story.id}
              story={story}
              hasBigScreenDesign={hasBigScreenDesign}
            />
          ))}
          {isNewLoading && (
            <Preloader centered loading static />
          )}
        </>
      )}
    </div>
  );
};

export default StoriesList;
