import { getTrendingBarAssetQuery } from './watchlistHelperFunctions';

export const getCurrentPositionSelectedTopic = (
  {
    pageX, pageY, clientY, width, promoHeight, currentWidth, chatbot, screenHeight,
    parentLeftPosition, isFinpromptPages,
  },
) => {
  let x;
  let y = pageY + 50 - promoHeight;
  let arrowLeft = 'calc(50% - 9px)';
  let arrowBottom = null;

  if (width >= 2800) {
    y = pageY + 100 - promoHeight;
    if (pageX + 920 + currentWidth <= window.innerWidth) {
      if (pageX - 920 + currentWidth > 40) {
        x = pageX - 900 + currentWidth;
      } else {
        x = 40;
        arrowLeft = `${pageX + currentWidth - 58}px`;
      }
    } else {
      x = window.innerWidth - 1840;
      arrowLeft = `${pageX - x - 18 + currentWidth}px`;
    }
  } else if (pageX + 460 + currentWidth <= window.innerWidth) {
    if (pageX - 460 + currentWidth > 20) {
      x = pageX - 450 + currentWidth;
    } else {
      x = 20;
      arrowLeft = `${pageX + currentWidth - 29}px`;
    }
  } else {
    x = window.innerWidth - 920;
    arrowLeft = `${pageX - x - 9 + currentWidth}px`;
  }

  if (chatbot) {
    const isBigScreen = width >= 2800;
    y += promoHeight;

    const heightChart = isBigScreen ? 1040 : 520;
    const bottomSpace = isFinpromptPages ? 200 : 50;

    if (((heightChart + (clientY || pageY) + bottomSpace) > screenHeight)) {
      y = pageY - (heightChart + 15);
      arrowBottom = true;
    }

    x -= parentLeftPosition;
  }

  return {
    x, y, arrowLeft, arrowBottom,
  };
};

// SEO page TopicSentimentAnalysis, Chabot Stories widget
export const handleTopicSelectHelper = (props) => {
  const {
    event, topic, ref,
    handleTopicRedirect, width, promoHeight, chatbot,
    screenHeight, isFinpromptPages,
  } = props;

  if (width <= 1024) {
    handleTopicRedirect(topic);
    return ({
      query: '',
      selectedTopic: null,
    });
  }

  const parent = document.querySelector('.react-chatbot-kit-chat-message-container');
  const itemTopPosition = ref?.current?.getBoundingClientRect()?.top;
  const parentTopPosition = parent?.getBoundingClientRect()?.top;
  const parentLeftPosition = parent?.getBoundingClientRect()?.left;

  const correctPosition = itemTopPosition - parentTopPosition;

  const offsetY = event.currentTarget.getBoundingClientRect().top;
  const pageY = chatbot ? correctPosition : (window.pageYOffset + offsetY);

  const pageX = event.currentTarget.getBoundingClientRect().left;
  const currentWidth = event.currentTarget.clientWidth / 2;

  const { clientY } = (
    event.event?.changedTouches?.[0]
    || event.nativeEvent || {}
  );

  const {
    x, y, arrowLeft, arrowBottom,
  } = getCurrentPositionSelectedTopic({
    pageX,
    clientY,
    pageY,
    promoHeight,
    chatbot,
    screenHeight,
    currentWidth,
    parentLeftPosition,
    isFinpromptPages,
  });

  const query = getTrendingBarAssetQuery({
    id: topic.entity_id,
    name: topic.entity_name,
  }, topic.entity_type);

  return ({
    query,
    selectedTopic: {
      ...topic,
      x,
      y,
      arrowLeft,
      arrowBottom,
    },
  });
};

export default handleTopicSelectHelper;
