import moment from 'moment';

import Diversity from '../assets/directory/esg/diversity.png';
import DiversityBig from '../assets/directory/esg/diversity_single.png';
import General from '../assets/directory/esg/general.png';
import GeneralBig from '../assets/directory/esg/general_single.png';
import Governance from '../assets/directory/esg/governance.png';
import GovernanceBig from '../assets/directory/esg/governance_single.png';
import Social from '../assets/directory/esg/social.png';
import SocialBig from '../assets/directory/esg/social_single.png';
import Sustainability from '../assets/directory/esg/sustainability.png';
import SustainabilityBig from '../assets/directory/esg/sustainability_single.png';

export const getIRParams = (filterProps, props) => {
  const {
    isAllTypesSelected, selectedTypes,
    isAllYearsSelected, fromYear, toYear,
    isAllPeriodsSelected, selectedPeriods,
  } = filterProps;

  const param = {
    ...props,
  };

  if (!isAllTypesSelected && selectedTypes.length) {
    param.type = selectedTypes.join(',');
  }
  if (!isAllYearsSelected) {
    const endIsAllowed = moment(toYear, 'YYYY').endOf('year').isSameOrBefore(moment(fromYear, 'YYYY').endOf('year'));
    param.start_date = moment(fromYear, 'YYYY').unix();
    param.end_date = endIsAllowed ? moment(toYear, 'YYYY').endOf('year').unix() : moment().unix();
  }
  if (!isAllPeriodsSelected && selectedPeriods.length) {
    param.period = selectedPeriods.join(',');
  }

  return param;
};

export const getESGParams = (filterProps, props) => {
  const {
    isAllTypesSelected, selectedTypes,
    isAllYearsSelected, fromYear, toYear, showUndated,
  } = filterProps;

  const param = {
    ...props,
    'show-undated': showUndated,
  };

  if (!isAllTypesSelected && selectedTypes.length) {
    param.type = selectedTypes.join(',');
  }
  if (!isAllYearsSelected) {
    param['year-from'] = fromYear;
    param['year-to'] = toYear;
  }

  return param;
};

export const getESGTypeImg = (type, isBigImg) => {
  switch (type.toLowerCase()) {
    case 'diversity':
      return isBigImg ? DiversityBig : Diversity;
    case 'general':
      return isBigImg ? GeneralBig : General;
    case 'governance':
      return isBigImg ? GovernanceBig : Governance;
    case 'social':
      return isBigImg ? SocialBig : Social;
    case 'sustainability':
      return isBigImg ? SustainabilityBig : Sustainability;
    default:
      return '';
  }
};

export const getESGNewsAssetQuery = ({ name, id }, esgTopic) => {
  try {
    const newsTopic = `group:Topic:${id}:${name.replace(/\((.*?)\)/g, '\\($1\\)')}`;
    return `"${newsTopic}" AND "group:Topic:${esgTopic.id}:${esgTopic.name}"`;
  } catch (err) {
    return undefined;
  }
};

export const getESGWLNewsAssetQuery = (topics, esgTopic) => (
  topics
    .filter((topic) => !topic.hidden && topic.entity_type === 'Stocks')
    .map((item) => (
      `"${(item.query).replace(/"/g, '')}" AND "group:Topic:${esgTopic.id}:${esgTopic.name}"`
    ))
    .join(' OR ')
);
