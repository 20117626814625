export const FETCH_STORIES_BEGIN = 'FETCH_STORIES_BEGIN';
export const FETCH_STORIES_SUCCESS = 'FETCH_STORIES_SUCCESS';
export const FETCH_STORIES_FAILURE = 'FETCH_STORIES_FAILURE';
export const HIDE_STORY = 'HIDE_STORY';
export const FREEZE_STORIES = 'FREEZE_STORIES';
export const CHANGE_TOPIC = 'CHANGE_TOPIC';
export const HIDE_SHOW_MORE = 'HIDE_SHOW_MORE';
export const ACTIVATE_TRENDING_PRELOADER = 'ACTIVATE_TRENDING_PRELOADER';
export const DISACTIVATE_TRENDING_PRELOADER = 'DISACTIVATE_TRENDING_PRELOADER';
export const TOGGLE_SIMILAR_STORIES_MODAL = 'TOGGLE_SIMILAR_STORIES_MODAL';
export const SET_STORY_IMAGE_OPENED = 'SET_STORY_IMAGE_OPENED';
export const RESET_STORIES_REDUX_DATA = 'RESET_STORIES_REDUX_DATA';
export const UPDATE_STORIES_INFO = 'UPDATE_STORIES_INFO';
export const UPDATE_STORIES_BOOKMARK = 'UPDATE_STORIES_BOOKMARK';

export const activateTrendingPreloader = () => ({
  type: ACTIVATE_TRENDING_PRELOADER,
});

export const disactivateTrendingPreloader = () => ({
  type: DISACTIVATE_TRENDING_PRELOADER,
});

export const fetchTrendingStoriesBegin = () => ({
  type: FETCH_STORIES_BEGIN,
});

export const fetchTrendingStoriesSuccess = (stories) => ({
  type: FETCH_STORIES_SUCCESS,
  payload: { stories },
});

export const fetchStoriesError = (error) => ({
  type: FETCH_STORIES_FAILURE,
  payload: { error },
});

export const hideStory = (id) => ({
  type: HIDE_STORY,
  payload: id,
});

export const freezeStories = (newState) => ({
  type: FREEZE_STORIES,
  payload: newState,
});

export const changeTopic = (id) => ({
  type: CHANGE_TOPIC,
  payload: id,
});

export const hideShowMore = (show) => ({
  type: HIDE_SHOW_MORE,
  payload: show,
});

export const toggleSimilarStoriesModal = (state) => ({
  type: TOGGLE_SIMILAR_STORIES_MODAL,
  payload: state,
});

export const setStoryImageOpened = (state) => ({
  type: SET_STORY_IMAGE_OPENED,
  payload: state,
});

export const resetStoriesReduxData = () => ({
  type: RESET_STORIES_REDUX_DATA,
});

export const updateStoriesData = (data) => ({
  type: UPDATE_STORIES_INFO,
  payload: data,
});

export const updateStoriesInfo = (data) => ((dispatch, getState) => {
  const { id, liked, disliked } = data;
  const {
    stories: { cards },
  } = getState();

  const updatedCards = cards.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        liked,
        disliked,
      };
    }
    return item;
  });
  dispatch(updateStoriesData(updatedCards));
});

export const updateStoriesBookmarkData = (data) => ({
  type: UPDATE_STORIES_BOOKMARK,
  payload: data,
});

export const updateStoriesBookmark = (data) => ((dispatch, getState) => {
  const { id, bookmarked } = data;
  const {
    stories: { cards },
  } = getState();

  const updatedCards = cards.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        bookmarked,
      };
    }
    return item;
  });
  dispatch(updateStoriesBookmarkData(updatedCards));
});
