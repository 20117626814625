const sortByPriority = (a, b) => {
  if (a.priority === b.priority) {
    return 0;
  }

  if (a.priority === null) {
    return 1;
  }

  if (b.priority === null) {
    return -1;
  }

  return a.priority - b.priority;
};

export default sortByPriority;
