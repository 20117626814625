const executeCode = (script, errorHandler) => {
  // Execute the code in the script in the body
  if (script) {
    try {
      // eslint-disable-next-line no-new-func
      new Function(script)();
    } catch (error) {
      errorHandler();
      console.error('Error executing code from the script in the body:', error);
    }
  } else {
    errorHandler();
  }
};

export default executeCode;
