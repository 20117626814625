import StoriesSvc from '../services/StoriesSvc';
import StorageSvc from '../services/StorageSvc';

import {
  loadStoriesWithQuotes,
  loadAllAssetSectorsEventsStories,
  loadStoriesWithoutQuotes,
  loadOriginalTimePeriodStories,
  loadAllPremiumStories,
} from '../services/recommendedStories/recommnededStoriesLoaders';
import { removeDuplicates } from '../helpers/removeDuplicates';
import { changeRecommendedTimePeriod } from '../services/recommendedStories/helperMethods';
import { removeEmptyProperties } from '../helpers/commonHelpers';

export const FETCH_RECOMMENDED_STORIES_BEGIN = 'FETCH_RECOMMENDED_STORIES_BEGIN';
export const FETCH_RECOMMENDED_STORIES_NEW = 'FETCH_RECOMMENDED_STORIES_NEW';
export const FETCH_RECOMMENDED_STORIES_SUCCESS = 'FETCH_RECOMMENDED_STORIES_SUCCESS';
export const RESET_RECOMMENDED_STORIES_DATA = 'RESET_RECOMMENDED_STORIES_DATA';

export const fetchRecommendedStoriesBegin = () => ({
  type: FETCH_RECOMMENDED_STORIES_BEGIN,
});

export const fetchRecommendedStoriesNew = (newState) => ({
  type: FETCH_RECOMMENDED_STORIES_NEW,
  payload: newState,
});

export const fetchRecommendedStoriesSuccess = (newState) => ({
  type: FETCH_RECOMMENDED_STORIES_SUCCESS,
  payload: newState,
});

export const resetRecommendedStoriesData = () => ({
  type: RESET_RECOMMENDED_STORIES_DATA,
});

export const loadStoriesWithChangedPeriod = ({
  timeFilterValues,
  hasTwitterAccess,
  query,
  translation,
  events,
  sectors,
  assetClasses,
  similarStories,
  customTimeFilter,
  earliestSearchDate,
  goldUser,
  sentimentLevels,
  whiteListId,
  isMajorOnly,
  oldStories = [],
}) => (dispatch) => new Promise(async (resolve) => {
  if (
    ((customTimeFilter && !goldUser) && timeFilterValues.timeFilter === 'year1')
    || (customTimeFilter && timeFilterValues.timeFilter === 'lifetime')
    || (!customTimeFilter && timeFilterValues.timeFilter === 'month1')
  ) {
    return resolve({ stories: [] });
  }

  const storyParams = {
    ...changeRecommendedTimePeriod({
      timeFilterValues,
      hasTwitterAccess,
      events,
      sectors,
      assetClasses,
      customTimeFilter,
      earliestSearchDate,
    }),
    query,
    fold_similar_stories: similarStories,
    translate_to: translation || undefined,
    with_sentiments: true,
  };
  if (sentimentLevels) storyParams.sentiment_levels = sentimentLevels;
  if (whiteListId) storyParams.whitelisted_domain_ids = whiteListId;
  if (isMajorOnly) storyParams.categories = storyParams.categories.split(',').filter((category) => category === 'mp').join(',');

  removeEmptyProperties(storyParams);
  let { stories } = await StoriesSvc.getStoriesData(storyParams, { callCurationActions: true });

  stories = removeDuplicates([
    ...oldStories,
    ...stories,
  ], 'id');

  if (stories.length < 30) {
    if (storyParams.time_filter === 'week1') {
      dispatch(fetchRecommendedStoriesNew({
        recommendedStories: stories,
      }));

      storyParams.time_filter = 'month1';
      const newData = await StoriesSvc.getStoriesData(storyParams, { callCurationActions: true });
      stories = removeDuplicates([
        ...stories,
        ...newData.stories,
      ], 'id');
    }
    if (
      stories.length < 30
      && timeFilterValues.maximumAllowedTime
      && storyParams.time_filter !== timeFilterValues.maximumAllowedTime
    ) {
      dispatch(fetchRecommendedStoriesNew({
        recommendedStories: stories,
      }));

      storyParams.time_filter = timeFilterValues.maximumAllowedTime;
      const newData = await StoriesSvc.getStoriesData(storyParams, { callCurationActions: true });
      stories = removeDuplicates([
        ...stories,
        ...newData.stories,
      ], 'id');
    }
  }

  dispatch(fetchRecommendedStoriesNew({
    recommendedStories: stories,
  }));
  return resolve({ stories });
});

export const getWatchlistRecommendedStories = ({
  timeFilterValues,
  hasTwitterAccess,
  query,
  activeWatchlistTopics,
  translation,
  similarStories,
  customTimeFilter,
  goldUser,
  earliestSearchDate,
  sentimentLevels,
}) => async (dispatch) => {
  // get stories from first api call with time filter
  let { stories } = await dispatch(loadStoriesWithChangedPeriod({
    timeFilterValues,
    hasTwitterAccess,
    query,
    translation,
    similarStories,
    customTimeFilter,
    earliestSearchDate,
    goldUser,
    sentimentLevels,
  }));
  if (stories.length >= 30) {
    dispatch(fetchRecommendedStoriesSuccess({
      recommendedStories: stories,
    }));
    return stories;
  }

  // if there are less than 30 stories we make 2 api call with topics in quotes
  const data = await loadStoriesWithQuotes(
    timeFilterValues.timeFilter,
    hasTwitterAccess,
    activeWatchlistTopics,
    translation,
    similarStories,
    sentimentLevels,
  );

  stories = removeDuplicates([
    ...stories,
    ...data.stories,
  ], 'id');

  dispatch(fetchRecommendedStoriesSuccess({
    recommendedStories: stories,
  }));
  return stories;
};

export const getLocationRecommendedStories = (options) => async (dispatch) => {
  const {
    timeFilter,
    hasTwitterAccess,
    translation,
    query,
    similarStories,
    customTimeFilter,
    earliestSearchDate,
    sentimentLevels,
  } = options;
  const parents = [query, ...options.parents];

  const result = await parents.reduce(async (collectedStories, parentQuery) => {
    const currentStories = await collectedStories;

    if (currentStories.length >= 30) {
      dispatch(fetchRecommendedStoriesNew({
        recommendedStories: currentStories,
      }));
      return currentStories;
    }

    try {
      const { stories } = await dispatch(loadStoriesWithChangedPeriod({
        timeFilterValues: timeFilter,
        hasTwitterAccess,
        query: parentQuery,
        translation,
        similarStories,
        customTimeFilter,
        earliestSearchDate,
        sentimentLevels,
        oldStories: currentStories,
      }));

      dispatch(fetchRecommendedStoriesNew({
        recommendedStories: removeDuplicates([
          ...currentStories,
          ...stories,
        ], 'id'),
      }));

      const { stories: storiesDefaultPeriod } = await loadOriginalTimePeriodStories(
        hasTwitterAccess,
        timeFilter,
        parentQuery,
        similarStories,
        sentimentLevels,
      );

      dispatch(fetchRecommendedStoriesNew({
        recommendedStories: removeDuplicates([
          ...currentStories,
          ...stories,
          ...storiesDefaultPeriod,
        ], 'id'),
      }));

      return [
        ...currentStories,
        ...stories,
        ...storiesDefaultPeriod,
      ];
    } catch (e) {
      console.log(e);
      dispatch(fetchRecommendedStoriesNew({
        recommendedStories: currentStories,
      }));
      return currentStories;
    }
  }, []);

  const finalStories = removeDuplicates(result, 'id');
  dispatch(fetchRecommendedStoriesSuccess({
    recommendedStories: finalStories,
  }));
  return finalStories;
};

export const getTrendingFiltersIds = (name) => {
  if (StorageSvc.getItem(name) !== null) {
    const topics = JSON.parse(StorageSvc.getItem(name));
    const items = topics.selectedItems.map((item) => item.id);

    return items.join(',');
  }

  return null;
};

export const getTrendingRecommendedStories = ({
  timeFilterValues,
  hasTwitterAccess,
  translation,
  similarStories,
  customTimeFilter,
  earliestSearchDate,
  sentimentLevels,
}) => async (dispatch) => {
  const { stories } = await dispatch(loadStoriesWithChangedPeriod({
    timeFilterValues,
    hasTwitterAccess,
    query: '',
    translation,
    events: hasTwitterAccess ? getTrendingFiltersIds('eventsFilter') : '',
    sectors: getTrendingFiltersIds('sectorsFilter'),
    assetClasses: getTrendingFiltersIds('asset_classesFilter'),
    similarStories,
    customTimeFilter,
    earliestSearchDate,
    sentimentLevels,
  }));

  if (stories.length >= 30) {
    dispatch(fetchRecommendedStoriesSuccess({
      recommendedStories: stories,
    }));
    return stories;
  }

  dispatch(fetchRecommendedStoriesNew({
    recommendedStories: stories,
  }));

  // if there are less than 30 stories we make 2 api call with topics in quotes
  const data = loadAllAssetSectorsEventsStories(
    timeFilterValues.timeFilter,
    hasTwitterAccess,
    translation,
    similarStories,
    sentimentLevels,
  ).then((data) => removeDuplicates([...stories, ...data.stories], 'id'));

  dispatch(fetchRecommendedStoriesSuccess({
    recommendedStories: data,
  }));
  return data;
};

export const getPremiumRecommendedStories = ({
  timeFilterValues,
  hasTwitterAccess,
  translation,
  similarStories,
  customTimeFilter,
  earliestSearchDate,
  sentimentLevels,
  whiteListId,
  isMajorOnly,
}) => async (dispatch) => {
  const { stories } = await dispatch(loadStoriesWithChangedPeriod({
    timeFilterValues,
    hasTwitterAccess,
    query: '',
    translation,
    similarStories,
    customTimeFilter,
    earliestSearchDate,
    sentimentLevels,
    whiteListId,
    isMajorOnly,
  }));

  if (stories.length >= 30) {
    dispatch(fetchRecommendedStoriesSuccess({
      recommendedStories: stories,
    }));
    return stories;
  }

  dispatch(fetchRecommendedStoriesNew({
    recommendedStories: stories,
  }));

  // if there are less than 30 stories we make 2 api call with topics in quotes
  const data = await loadAllPremiumStories(
    timeFilterValues.timeFilter,
    hasTwitterAccess,
    translation,
    similarStories,
    sentimentLevels,
    whiteListId,
    isMajorOnly,
  ).then((data) => removeDuplicates([...stories, ...data.stories], 'id'));

  dispatch(fetchRecommendedStoriesSuccess({
    recommendedStories: data,
  }));
  return data;
};

export const getSearchRecommendedStories = ({
  timeFilterValues,
  hasTwitterAccess,
  query,
  translation,
  similarStories,
  customTimeFilter,
  earliestSearchDate,
  sentimentLevels,
}) => async (dispatch) => {
  // get stories from first api call with time filter
  const { stories } = await dispatch(loadStoriesWithChangedPeriod({
    timeFilterValues,
    hasTwitterAccess,
    query,
    translation,
    similarStories,
    customTimeFilter,
    earliestSearchDate,
    sentimentLevels,
  }));

  // check if query has quotes
  if (stories.length < 30 && /['||"]/.test(query)) {
    const quotesStories = await loadStoriesWithoutQuotes(
      timeFilterValues.timeFilter,
      hasTwitterAccess,
      query,
      translation,
      similarStories,
      sentimentLevels,
    ).then((data) => removeDuplicates([...stories, ...data.stories], 'id'));

    if (quotesStories.length >= 30) {
      dispatch(fetchRecommendedStoriesSuccess({
        recommendedStories: quotesStories,
      }));
      return quotesStories;
    }

    dispatch(fetchRecommendedStoriesNew({
      recommendedStories: quotesStories,
    }));

    const data = await loadAllAssetSectorsEventsStories(
      timeFilterValues.timeFilter,
      hasTwitterAccess,
      translation,
      similarStories,
      sentimentLevels,
    ).then((allAssetsData) => removeDuplicates([...quotesStories, ...allAssetsData.stories], 'id'));

    dispatch(fetchRecommendedStoriesSuccess({
      recommendedStories: data,
    }));
    return data;
  }
  if (stories.length < 30 && !/['||"]/.test(query)) {
    const data = await loadAllAssetSectorsEventsStories(
      timeFilterValues.timeFilter,
      hasTwitterAccess,
      translation,
      similarStories,
      sentimentLevels,
    ).then((data) => removeDuplicates([...stories, ...data.stories], 'id'));

    dispatch(fetchRecommendedStoriesSuccess({
      recommendedStories: data,
    }));
    return data;
  }

  dispatch(fetchRecommendedStoriesSuccess({
    recommendedStories: stories,
  }));
  return stories;
};
