import { forwardRef, memo } from 'react';
import { isMobile } from 'react-device-detect';

import TransactionDetails from '../TransactionDetails';
import TransactionChartTableDesktop from '../TransactionChartTableDesktop';
import TransactionChartTableMobile from '../TransactionChartTableMobile';

export const TransactionHoverModal = ({
  expandedView = false,
  x,
  y,
  toggleExpandedView,
  sharesData,
  valuesData,
  transactionsChartData,
  chatbot,
}, ref) => {
  if (isMobile) {
    return (
      <TransactionChartTableMobile
        ref={ref}
        sharesData={sharesData}
        valuesData={valuesData}
        transactionsChartData={transactionsChartData}
      />
    );
  }

  return (
    !expandedView ? (
      <TransactionDetails
        ref={ref}
        x={x}
        y={y}
        toggleExpandedView={toggleExpandedView}
        sharesData={sharesData}
        valuesData={valuesData}
        chatbot={chatbot}
      />
    ) : (
      <TransactionChartTableDesktop
        ref={ref}
        x={x}
        y={y}
        toggleExpandedView={toggleExpandedView}
        sharesData={sharesData}
        valuesData={valuesData}
        transactionsChartData={transactionsChartData}
        chatbot={chatbot}
      />
    )
  );
};

export default memo(forwardRef(TransactionHoverModal));
