import {
  FETCH_INVESTOR_RELATIONS_BEGIN,
  FETCH_INVESTOR_RELATIONS_FAILURE,
  FETCH_INVESTOR_RELATIONS_SUCCESS,
  SET_INVESTOR_RELATIONS_PAGE_LOADER,
  GET_NEW_INVESTOR_RELATIONS_BEGIN,
  GET_NEW_INVESTOR_RELATIONS_SUCCESS,
  GET_NEW_INVESTOR_RELATIONS_FAILURE,
  SET_INVESTOR_RELATIONS_STORAGE_FILTERS,
  SET_INVESTOR_RELATIONS_TYPES_FILTER,
  SET_INVESTOR_RELATIONS_YEARS_FILTER,
  SET_INVESTOR_RELATIONS_PERIODS_FILTER,
  RESET_INVESTOR_RELATIONS_ALL_FILTERS,
} from '../actions/investorRelations.actions';

const initialState = {
  loading: false,
  presentations: [],
  presentationsError: null,
  presentationsPageLoad: false,
  newPresentationsLoading: false,
  newPresentationsError: false,
  isAllTypesSelected: true,
  selectedTypes: [],
  isAllYearsSelected: true,
  fromYear: '',
  toYear: '',
  isAllPeriodsSelected: true,
  selectedPeriods: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_INVESTOR_RELATIONS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_INVESTOR_RELATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        presentationsPageLoad: false,
        presentations: payload.presentations,
      };
    case FETCH_INVESTOR_RELATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        presentations: [],
        presentationsPageLoad: false,
        presentationsError: payload.error,
      };
    case SET_INVESTOR_RELATIONS_PAGE_LOADER:
      return {
        ...state,
        presentationsPageLoad: payload,
      };
    case GET_NEW_INVESTOR_RELATIONS_BEGIN:
      return {
        ...state,
        newPresentationsLoading: true,
        newPresentationsError: false,
      };
    case GET_NEW_INVESTOR_RELATIONS_SUCCESS:
      return {
        ...state,
        newPresentationsLoading: false,
        presentations: [...state.presentations, ...payload],
        newPresentationsError: false,
      };
    case GET_NEW_INVESTOR_RELATIONS_FAILURE:
      return {
        ...state,
        newPresentationsLoading: false,
        newPresentationsError: true,
      };
    case SET_INVESTOR_RELATIONS_STORAGE_FILTERS:
      return {
        ...state,
        ...payload,
      };
    case SET_INVESTOR_RELATIONS_TYPES_FILTER:
      return {
        ...state,
        isAllTypesSelected: payload.isAllTypesSelected,
        selectedTypes: payload.selectedTypes,
      };
    case SET_INVESTOR_RELATIONS_YEARS_FILTER:
      return {
        ...state,
        isAllYearsSelected: payload.isAllYearsSelected,
        fromYear: payload.fromYear,
        toYear: payload.toYear,
      };
    case SET_INVESTOR_RELATIONS_PERIODS_FILTER:
      return {
        ...state,
        isAllPeriodsSelected: payload.isAllPeriodsSelected,
        selectedPeriods: payload.selectedPeriods,
      };
    case RESET_INVESTOR_RELATIONS_ALL_FILTERS:
      return {
        ...state,
        isAllTypesSelected: true,
        selectedTypes: [],
        isAllYearsSelected: true,
        isAllPeriodsSelected: true,
        selectedPeriods: [],
      };
    default:
      return state;
  }
};
