import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import withComponentName from '../../../../decorators/withComponentName';

import Styles from './styles.module.scss';
import { CF_URL_FOR_FINPROMPT } from '../../../../data/environment';

const cx = classNames.bind(Styles);

const StoriesFooterControls = ({
  actionFunc, active, icon, iconActive, actionTypeClass, actionTitle,
  blockClass, singleStoryUrl, isFinpromptPages,
}) => {
  const mainClass = cx('card__tools--item',
    { 'story-actions-block': blockClass },
    actionTypeClass, { active });

  if (actionTypeClass === 'story-info') {
    return isFinpromptPages ? (
      <a
        href={`${CF_URL_FOR_FINPROMPT}${singleStoryUrl}`}
        className={mainClass}
        onClick={actionFunc}
        target="blank"
      >
        <IconComponent
          icon={icon}
          iconActive={iconActive}
          actionTitle={actionTitle}
        />
      </a>
    ) : (
      <Link to={singleStoryUrl} className={mainClass} onClick={actionFunc}>
        <IconComponent
          icon={icon}
          iconActive={iconActive}
          actionTitle={actionTitle}
        />
      </Link>
    );
  }

  return (
    <div
      className={mainClass}
      onClick={actionFunc}
    >
      <IconComponent
        icon={icon}
        iconActive={iconActive}
        actionTitle={actionTitle}
      />
    </div>
  );
};

const IconComponent = ({ icon, iconActive, actionTitle }) => (
  <>
    <FontAwesomeIcon className={cx('tool-icon')} icon={icon} />
    <FontAwesomeIcon className={cx('tool-icon', 'active')} icon={iconActive} />
    <span className={cx('tool-title')}>{actionTitle}</span>
  </>
);

export default withComponentName(StoriesFooterControls);
