import { Component } from 'react';
import { Link } from 'react-router-dom';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan as faBanLight } from '@fortawesome/pro-light-svg-icons/faBan';
import { faBan as faBanSolid } from '@fortawesome/pro-solid-svg-icons/faBan';

import withComponentName from '../../../../../decorators/withComponentName';

import * as storyFiltersActions from '../../../../../actions/storyFilters.action';
import loadable from '../../../../../utils/loadable';

import Tooltip from '../../../../Tooltip';
import UpgradeTooltipSmall from '../../../../GeneralComponents/UpgradeTooltip/UpgradeTooltipSmall';

import { CONTENT_SOURCE_FILTER } from '../../../../../data/features';

import Styles from '../styles.module.scss';

const cx = classNames.bind(Styles);

const BlockSourceModal = loadable(() => import('../../../../Modals/BlockSourceModal'));

class BlockStorySource extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      showModal: false,
      sourceInfo: {},
    };
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  blockSource(sourceInfo) {
    const { permissions: { features } } = this.props;
    // do not open block modal if user doesn't have content_source_filter feature
    if (!features.includes(CONTENT_SOURCE_FILTER)) return;

    this.setState({ sourceInfo, showModal: true });
  }

  addSourceToBlocked() {
    const { storyFilters, actions: { filterBySelectedSources } } = this.props;
    const { sourceInfo: { curator_data: curatorData } } = this.state;
    const {
      domains_filtering_mode: domainsFilteringMode, whitelisted_domain_ids: whitelistedDomainIds,
    } = storyFilters;

    const domainInWhitelist = whitelistedDomainIds.includes(curatorData.domain_id);

    if (domainsFilteringMode === 'whitelist' && domainInWhitelist) {
      const data = whitelistedDomainIds.filter((item) => item !== curatorData.domain_id);

      filterBySelectedSources({ whitelisted_domain_ids: data.join(',') });
    } else if (domainsFilteringMode === 'all') {
      filterBySelectedSources({
        blacklisted_domain_ids: `${storyFilters.blacklisted_domain_ids.join(',')}, ${curatorData.domain_id}`,
        domains_filtering_mode: 'blacklist',
      });
    } else {
      filterBySelectedSources({
        blacklisted_domain_ids: `${storyFilters.blacklisted_domain_ids.join(',')}, ${curatorData.domain_id}`,
      });
    }
    this.setState({ showModal: false });
  }

  render() {
    const {
      story,
      permissions,
      cssClass,
      width,
    } = this.props;

    const {
      showModal,
      sourceInfo,
    } = this.state;

    const { features } = permissions;
    const domainsSelectionFeature = features.includes(CONTENT_SOURCE_FILTER);
    const blockSourceCss = cx({
      'card__tools--item': true,
      'story-actions-block': true,
      'block-source': true,
      'block-sources__disabled': !domainsSelectionFeature,
    });

    // css classes for mobile block source button
    const blockSourceMobileCss = cx({
      'block-source-mobile': true,
      'card__tools--item': true,
      'block-sources__disabled': !domainsSelectionFeature,
      [cssClass]: true,
    });

    return (
      <>
        {width >= 768 ? (
          <Tooltip
            className={cx(
              'block-sources-tooltip',
              { 'disabled-selection-tooltip': !domainsSelectionFeature },
              { 'single-page-tooltip': cssClass === 'single-story-block-source' },
            )}
            icon={(
              <DesktopBlockSourceIcon
                blockSourceAction={() => this.blockSource(story)}
                blockSourceCss={blockSourceCss}
              />
            )}
          >
            {!domainsSelectionFeature ? (
              <>
                <Link to="/pricing">Upgrade</Link>
                {' '}
                <span>for access</span>
              </>
            ) : (
              <>
                <p>Hide content from this source.</p>
                <p>Manage later with Content Settings filters.</p>
              </>
            )}
          </Tooltip>
        ) : (
          <div
            className={blockSourceMobileCss}
            onClick={() => this.blockSource(story)}
          >
            <div className={Styles['mobile-block-source-icon']}>
              <FontAwesomeIcon icon={faBanLight} className={cx('tool-icon')} />
              <span className={cx('tool-title')}>Block Source</span>
            </div>
            {!domainsSelectionFeature && (
              <div className={Styles['upgrade-block-source']}>
                <UpgradeTooltipSmall
                  width={width}
                  planBadgeText="SILVER"
                  mobileDirection="left"
                />
              </div>
            )}
          </div>
        )}
        {showModal && (
          <BlockSourceModal
            show={showModal}
            sourceName={sourceInfo.domain_name}
            domainId={sourceInfo.curator_data.domain_id}
            closeModal={this.closeModal}
            addSourceToBlocked={this.addSourceToBlocked}
          />
        )}
      </>
    );
  }
}

const DesktopBlockSourceIcon = ({ blockSourceCss, blockSourceAction }) => (
  <div
    className={blockSourceCss}
    onClick={blockSourceAction}
  >
    <div className={Styles['desktop-block-source-icon']}>
      <FontAwesomeIcon icon={faBanLight} className={cx('tool-icon')} />
      <FontAwesomeIcon icon={faBanSolid} className={cx('tool-icon', 'active')} />
      <span className={cx('tool-title')}>Block Source</span>
    </div>
  </div>
);

const mapStateToProps = ({ storyFilters, subscriptions }) => ({
  permissions: subscriptions.permissions,
  storyFilters,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...storyFiltersActions,
  }, dispatch),
});

export default withComponentName(
  connect(mapStateToProps, mapDispatchToProps)(BlockStorySource),
);
