export const SHOW_PROMO = 'SHOW_PROMO';
export const HIDE_PROMO = 'HIDE_PROMO';

export const showPromo = (height, promobarType) => ({
  type: SHOW_PROMO,
  payload: {
    height,
    promobarType,
  },
});

export const hidePromo = (height = 0, promobarType) => ({
  type: HIDE_PROMO,
  payload: {
    height,
    promobarType,
  },
});
