import { useEffect, useState, memo } from 'react';

import withComponentName from '../../../decorators/withComponentName';

import Summarisation from '../../Summarisation';

const KeyHeadlines = (props) => {
  const { payload, scrollToBottomOnWidgetRendered, isChatbotOpened } = props;

  const [keys, setKeys] = useState([]);
  const [keysWapiCall, setKeysWapiCall] = useState(null);

  const messageDate = payload.date;

  useEffect(() => {
    payload.components?.forEach((item) => {
      if (item.component_name === 'key_headlines' && item.component_data) {
        setKeys([...item.component_data?.summaries]);
        setKeysWapiCall(item.wapi_call);
      }
    });
  }, [payload.components]);

  useEffect(() => {
    if (!isChatbotOpened) return;
    if (scrollToBottomOnWidgetRendered) scrollToBottomOnWidgetRendered();
  }, [keys, isChatbotOpened]);

  if (!keys?.length) return null;

  return (
    <div
      data-date={messageDate}
      className="react-chatbot-kit__widget__wrapper key_headlines"
    >
      <Summarisation
        directlyData={keys}
        storiesEndpoint={null}
        isTilesView={false}
        hasBigScreenDesign
        chatbot
      />
    </div>
  );
};

export default memo(withComponentName(KeyHeadlines));
