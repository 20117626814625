import { useSelector } from 'react-redux';

const useSizes = () => {
  const width = useSelector(({ common }) => common.width);
  const height = useSelector(({ common }) => common.height);

  return { width, height };
};

export default useSizes;
