import {
  useRef,
  useState,
  memo,
  useCallback,
  useEffect,
} from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { getAverageSentimentText } from '../../../../../helpers/sentimentHelpers';

import TooltipOutside from '../../TooltipOutside';

import Styles from '../styles.module.scss';

const cx = classNames.bind(Styles);

const SentimentTag = (props) => {
  const {
    item,
    selectedTopic,
    handleTopicSelect,
    width,
    promoHeight,
    isInfoItemLabel,
    hideTooltip,
    hideSentimentScores,
    isDisabled,
    isChatbotExpanded,
    searchBarActive,
    chatbot,
    isActiveTopic,
    indexActiveTagSentimentScrolled,
    resetIndexActiveTagSentimentScrolled,
  } = props;

  const isChatbotOpened = useSelector(({ chatbot }) => chatbot.isChatbotOpened);

  const sentimentTagRef = useRef();
  const currentSelectedTarget = useRef();
  const currentSelectedTopic = useRef();
  const currentModalTopPosition = useRef();
  const isChatbotOpenedRef = useRef(null);

  const [boxChartTooltip, setBoxChartTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    topPosition: '',
    leftPosition: '',
  });

  const showTooltipTimeTimeoutRef = useRef(0);
  const showStoryModalTimoutRef = useRef(0);
  const showStoryModalChangedPositionTimeoutRef = useRef(0);

  const clearAllTimeouts = useCallback(() => {
    clearTimeout(showTooltipTimeTimeoutRef.current);
    clearTimeout(showStoryModalTimoutRef.current);
    clearTimeout(showStoryModalChangedPositionTimeoutRef.current);
  }, []);

  const handleBoxMouseEnter = useCallback(() => {
    clearAllTimeouts();
    if (width <= 1024) {
      return;
    }

    showTooltipTimeTimeoutRef.current = setTimeout(() => {
      const { top: offsetY, left: pageX } = sentimentTagRef.current?.getBoundingClientRect() || {};

      const pageY = window.scrollY + offsetY;
      const currentWidth = sentimentTagRef.current?.clientWidth / 2;

      let x = pageX + (currentWidth - (width >= 2800 ? 148 : 74));
      let y = pageY - promoHeight - (width >= 2800 ? 76 : 38);

      if (isInfoItemLabel) {
        if (x < 65) {
          x = (width >= 2800 ? 90 : 45);
        } else {
          x -= (width >= 2800 ? 40 : 20);
        }
        y -= (width >= 2800 ? 70 : 35);
      }
      setBoxChartTooltip(true);
      setTooltipPosition({
        topPosition: y,
        leftPosition: x,
      });
    }, 500);

    showStoryModalTimoutRef.current = setTimeout(() => {
      if (selectedTopic && item.entity_id === (selectedTopic || {}).entity_id) return;
      sentimentTagRef.current.click();
    }, 3000);
  }, [clearAllTimeouts]);

  useEffect(() => {
    if (!indexActiveTagSentimentScrolled || !isActiveTopic) return;
    clearTimeout(showStoryModalChangedPositionTimeoutRef.current);

    showStoryModalChangedPositionTimeoutRef.current = setTimeout(() => {
      sentimentTagRef.current.click();
      resetIndexActiveTagSentimentScrolled();
    }, 500);
  }, [indexActiveTagSentimentScrolled]);

  const handleBoxMouseLeave = useCallback(() => {
    clearAllTimeouts();
    setBoxChartTooltip(false);
  }, [clearAllTimeouts]);

  const handleClick = useCallback((e) => {
    clearTimeout(showStoryModalTimoutRef.current);

    currentSelectedTarget.current = e.currentTarget;
    currentModalTopPosition.current = e.currentTarget?.getBoundingClientRect()?.top;

    handleTopicSelect(e, item, sentimentTagRef);
  }, [isChatbotExpanded, searchBarActive]);

  const updatedItem = {
    ...item,
    sentiment: typeof (item.sentiment) === 'string' ? item.sentiment : `${item.sentiment}%`,
  };

  useEffect(() => {
    if (selectedTopic?.entity_id !== item?.entity_id) {
      currentSelectedTarget.current = null;
      currentSelectedTopic.current = null;
      currentModalTopPosition.current = null;
    }
    if (selectedTopic && ((selectedTopic.entity_id !== currentSelectedTopic?.current?.entity_id))
    && (selectedTopic?.entity_id === item?.entity_id)) {
      currentSelectedTopic.current = selectedTopic;
    }
  }, [selectedTopic]);

  useEffect(() => {
    if (selectedTopic?.entity_id !== item?.entity_id) return;

    if (!currentSelectedTarget.current || !currentSelectedTopic.current) return;

    if (
      (currentModalTopPosition.current && currentSelectedTarget?.current?.getBoundingClientRect().top)
      && (currentModalTopPosition.current !== currentSelectedTarget?.current?.getBoundingClientRect().top)) {
      currentModalTopPosition.current = currentSelectedTarget?.current?.getBoundingClientRect().top;
      handleTopicSelect({ currentTarget: currentSelectedTarget.current }, currentSelectedTopic.current);
    }

    if ((isChatbotOpenedRef.current !== null) && (isChatbotOpenedRef.current !== isChatbotOpened)) {
      if (!isChatbotOpened) {
        setTimeout(() => {
          currentModalTopPosition.current = currentSelectedTarget?.current?.getBoundingClientRect()?.top;

          if (currentSelectedTopic.current) {
            handleTopicSelect(
              { currentTarget: currentSelectedTarget.current },
              currentSelectedTopic.current,
            );
          }
        }, 1100);
      }
    }

    isChatbotOpenedRef.current = isChatbotOpened;
  }, [selectedTopic, isChatbotOpened, isChatbotExpanded]);

  useEffect(() => (
    () => {
      clearAllTimeouts();
    }
  ), [clearAllTimeouts]);

  return (
    <div
      key={updatedItem.entity_id}
      ref={sentimentTagRef}
      className={cx('sentiment-tags-item',
        { active: updatedItem.entity_id === (selectedTopic || {}).entity_id },
        { infoLabel: isInfoItemLabel },
        { infoLabelStory: hideSentimentScores },
        { disabled: isDisabled },
        { chatbot },
        { chatbot_widget_view: chatbot && !isChatbotExpanded })}
      onClick={handleClick}
      onMouseEnter={handleBoxMouseEnter}
      onMouseLeave={handleBoxMouseLeave}
    >
      <div className={Styles['sentiment-tags-item-active']}>{updatedItem.entity_name}</div>
      <div className={cx('sentiment-tags-item__entity-name')}>{updatedItem.entity_name}</div>
      {updatedItem.sentiment && !hideSentimentScores && (
        <div
          className={cx('sentiment-tags-item__sentiment-value', 'sentiment-value', getAverageSentimentText(
            typeof (updatedItem.sentiment) === 'string' ? updatedItem.sentiment.slice(0, updatedItem.sentiment.length - 1) : updatedItem.sentiment,
          )?.type)}
        >
          {Number(typeof (updatedItem.sentiment) === 'string' ? updatedItem.sentiment.slice(0, updatedItem.sentiment.length - 1) : updatedItem.sentiment) > 0 && '+'}
          {typeof (updatedItem.sentiment) === 'string' ? updatedItem.sentiment : `${updatedItem.sentiment}%`}
        </div>
      )}
      {boxChartTooltip
        && updatedItem.entity_id !== (selectedTopic || {}).entity_id
        && !hideTooltip
        && (
        <TooltipOutside
          topPosition={tooltipPosition.topPosition}
          leftPosition={tooltipPosition.leftPosition}
          isInfoItemLabel={isInfoItemLabel}
          item={updatedItem}
        />
        )}
    </div>
  );
};

export default memo(SentimentTag);
