import { Component } from 'react';
import classNames from 'classnames/bind';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';

import { SILVER, GOLD } from '../../../data/permissions';
import { CF_URL_FOR_FINPROMPT } from '../../../data/environment';

import Tooltip from '../../Tooltip';
import permissionsDecorator from '../../../decorators/permissionsDecorator';
import withComponentName from '../../../decorators/withComponentName';
import withFinprompt from '../../../decorators/withFinprompt';

import StorageSvc from '../../../services/StorageSvc';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export class StoriesUpgradeSuggestion extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      closed: true,
    };
    this.PRICING_PAGE_HREF = '/pricing';
  }

  componentDidMount() {
    let closed;

    try {
      const closingInfo = JSON.parse(StorageSvc.getItem('premiumSuggestionClose'));
      closed = closingInfo.expirationDate > Date.now();
    } catch (e) {
      closed = false;
    }

    this.setState({ closed });
  }

  componentDidUpdate({ activeWatchlistId: prevId }) {
    const { activeWatchlistId, watchlistPageLoaded } = this.props;

    if (watchlistPageLoaded && prevId !== activeWatchlistId) {
      this.setState({
        closed: false,
      });
      StorageSvc.removeItem('premiumSuggestionClose');
    }
  }

  closeAlert() {
    StorageSvc.setItem('premiumSuggestionClose', JSON.stringify({
      expirationDate: Date.now() + 1000 * 60 * 60 * 24 * 7,
    }));
    this.setState({
      closed: true,
    });
  }

  sendUpgradeAction() {
    const { piwikEnabled } = this.props;

    if (piwikEnabled) _paq.push(['trackEvent', 'Premium', 'Upgrade', window.location.href]);
  }

  render() {
    const {
      accessLevels, permissionsInitialized, isWatchlist, classname,
      hasBigScreenDesign, isFinpromptPages,
    } = this.props;
    const { closed } = this.state;

    const suggestionBlockClass = cx('suggestion-block',
      {
        silver: accessLevels.includes(SILVER),
        watchlist: isWatchlist,
        big_screen: hasBigScreenDesign,
      }, classname);

    return permissionsInitialized && !accessLevels.includes(GOLD) && !closed && (
      <div className={suggestionBlockClass}>
        <div className={cx('block-wrapper')}>
          <div className={cx('block-text-web')}>
            Premium&nbsp;
            {accessLevels.includes(SILVER) && 'Gold '}
            Content is hidden.&nbsp;
            <a
              className={cx('pricing-link')}
              href={isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}${this.PRICING_PAGE_HREF}` : this.PRICING_PAGE_HREF}
              target={isFinpromptPages ? '_blank' : undefined}
              rel="noopener noreferrer"
              onClick={this.sendUpgradeAction}
            >
              Upgrade
            </a>
            &nbsp;for access.
          </div>
          <div className={cx('block-text-tab-mobile')}>
            <a
              className={cx('pricing-link')}
              href={isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}${this.PRICING_PAGE_HREF}` : this.PRICING_PAGE_HREF}
              target={isFinpromptPages ? '_blank' : undefined}
              rel="noopener noreferrer"
              onClick={this.sendUpgradeAction}
            >
              Upgrade
            </a>
            &nbsp;to access Premium&nbsp;
            {accessLevels.includes(SILVER) && 'Gold '}
            content.
          </div>
          <Tooltip newIcon className={cx('tooltip')} hasBigScreenDesign={hasBigScreenDesign}>
            {!accessLevels.includes(SILVER) && 'Silver and '}
            Gold subscriptions enable more content.
            <br />
            <a
              href={isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}${this.PRICING_PAGE_HREF}` : this.PRICING_PAGE_HREF}
              target={isFinpromptPages ? '_blank' : undefined}
              rel="noopener noreferrer"
            >
              See plans
            </a>
            &nbsp;for details.
          </Tooltip>
        </div>
        <FontAwesomeIcon icon={faXmark} className={cx('close-icon')} onClick={this.closeAlert} />
      </div>
    );
  }
}

function mapToStateProps({ subscriptions, watchlistReducer }) {
  const { permissions, permissionsInitialized } = subscriptions;
  const { watchlistsList, activeWatchlist } = watchlistReducer;

  return {
    permissionsInitialized,
    accessLevels: permissions.access_levels,
    activeWatchlistId: activeWatchlist.id,
    watchlistPageLoaded: Boolean(watchlistsList.length),
  };
}

export default withComponentName(
  connect(mapToStateProps)(permissionsDecorator(withFinprompt(StoriesUpgradeSuggestion))),
);
