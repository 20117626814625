import axios from 'axios';
import Cookies from 'js-cookie';
import getUserToken from './getUserToken';

const sendSignOutRequestFinPromptHomePage = async () => {
  axios.post(`/webapi/v1/users/sign_out?api_key=${getUserToken()}`)
    .catch((error) => {
      console.log(error.message);
    }).finally(() => {
      Cookies.remove('api_token');
      window.location.reload();
    });
};

export default sendSignOutRequestFinPromptHomePage;
