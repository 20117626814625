import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';

import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const ValuePropositionTabs = ({
  tabsData,
  defaultTab,
  activeTab,
  setActiveTab,
  closeMenu,
}) => (
  <div className={Styles['value-proposition-wrapper']}>
    <h2>Value Proposition by Audience</h2>
    <div className="d-flex">
      <Tab.Container
        id="header-nav-tabs"
        defaultActiveKey={tabsData[0].tabKey}
        onSelect={setActiveTab}
        activeKey={activeTab || defaultTab}
      >
        <div className={Styles['tab-links']}>
          <Nav variant="tabs">
            {tabsData.map((tab) => (
              <Nav.Item key={tab.tabKey}>
                <Nav.Link
                  eventKey={tab.tabKey}
                >
                  {tab.tabTitle}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content>
            {tabsData.map((tab) => (
              <Tab.Pane eventKey={tab.tabKey} key={tab.tabKey}>
                <p>
                  {tab.tabDescriptionText}
                </p>
                {tab.link && (
                  tab.fullLink || tab.useAnchor ? (
                    <a
                      href={tab.link}
                      className={Styles['tab-link']}
                      onClick={closeMenu}
                    >
                      Learn More
                    </a>
                  ) : (
                    <Link
                      to={tab.link}
                      className={Styles['tab-link']}
                      onClick={closeMenu}
                    >
                      Learn More
                    </Link>
                  )
                )}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  </div>
);

export default withComponentName(ValuePropositionTabs);
