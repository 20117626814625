// Determines if the request is a cross domain request.
const isCrossDomain = (url) => {
  const originAnchor = document.createElement('a');
  originAnchor.href = window.location.href;
  const urlAnchor = document.createElement('a');

  try {
    urlAnchor.href = url;

    // If URL protocol is false or is a string containing a single colon
    // *and* host are false, assume it is not a cross-domain request
    // (should only be the case for IE7 and IE compatibility mode).
    // Otherwise, evaluate protocol and host of the URL against the origin
    // protocol and host.
    return !(((!urlAnchor.protocol || urlAnchor.protocol === ':') && !urlAnchor.host)
      || (`${originAnchor.protocol}//${originAnchor.host}`
        === `${urlAnchor.protocol}//${urlAnchor.host}`));
  } catch (e) {
    // If there is an error parsing the URL, assume it is crossDomain.
    return true;
  }
};

// Up-to-date Cross-Site Request Forgery token
export const getCsrfToken = () => (
  document.querySelector('meta[name=csrf-token]')?.getAttribute('content')
);

// URL param that must contain the CSRF token
export const getCsrfParam = () => (
  document.querySelector('meta[name=csrf-param]')?.getAttribute('content')
);

export const handleMethod = (link) => {
  const href = link.getAttribute('href');
  const { method } = link.dataset;
  const target = link.getAttribute('target');
  const csrfToken = getCsrfToken();
  const csrfParam = getCsrfParam();
  const form = document.createElement('form');

  let metadataInput = `<input name="_method" value="${method}" type="hidden" />`;

  if (csrfParam !== undefined && csrfToken !== undefined && !isCrossDomain(href)) {
    metadataInput += `<input name="${csrfParam}" value="${csrfToken}" type="hidden" />`;
  }

  if (target) {
    form.setAttribute('target', target);
  }

  form.setAttribute('method', 'post');
  form.setAttribute('action', href);
  form.hidden = true;
  form.innerHTML = metadataInput;
  document.body.appendChild(form);
  form.submit();
};
