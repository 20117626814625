import axios from 'axios';
import isEqual from 'lodash-es/isEqual';

import API from './api';
import StoriesSvc from '../StoriesSvc';
import { getCurationActions } from './curationSvc';

import { STORIES_LIMIT } from '../../data/filters/storyFilters';
import { filterSourceDomains } from '../../helpers/filtersHelpers';
import { getStoriesCacheType } from '../../helpers/storyHelpers';
import { getAPICachingTime, returnAPICachingData, returnAPICachingHeader } from '../../helpers/helpers';
import { RetryAPICall } from '../../helpers/apiHelpers';

const caches = {
  storiesList: [],
};

// create shared watchlist
export const createSharedWatchlist = (watchlistId) => API.post(`watchlists/${watchlistId}/share`);

// get shared watchlist by it's identifier for guest users
export const getSharedWatchlist = (sharedIdentifier) => RetryAPICall(API, `shared_watchlists/${sharedIdentifier}`);

// add share watchlist to registered user watchlists
export const addSharedWatchlist = (sharedIdentifier) => API.post('users/apply_shared_watchlist', { share_identifier: sharedIdentifier });

// request for watchlist creation
export const loadNewWatchlist = (token, queries, watchlistName) => {
  const params = {
    search_queries: queries,
    name: watchlistName,
  };
  return API.post('watchlists', params);
};

// adding new queries to watchlist
export const addWatchlistSearchQueries = (watchlistId, query) => API.post(`watchlists/${watchlistId}/search_queries`, { query });

// request for load all watchlists
export const loadAllWatchlists = async ({
  onlyCurrentWatchlist,
  onlyWatchlistNames,
} = {}) => {
  const { data } = await RetryAPICall(API, 'watchlists', {
    params: {
      only_current_watchlist: onlyCurrentWatchlist,
      only_watchlist_names: onlyWatchlistNames,
    },
  });

  return data.watchlists;
};

// request for load watchlist stories
export const loadWatchlistStories = async (watchlistId, params, extraProps = {}) => {
  // return empty stories obj if no lang or categories elected in filters
  if (
    (!params.languages && !params.all_languages)
    || !params.categories
    || !watchlistId
  ) {
    return {
      stories: [],
      summaries: [],
      nextPageToken: 'EOD',
      summariesKey: null,
    };
  }

  const newParams = {
    ...filterSourceDomains(params),
    limit: params.limit || STORIES_LIMIT,
    with_sentiments: true,
  };

  const key = `storiesList_${watchlistId}`;
  const { cancelToken, isRefresh } = extraProps || {};

  if (isRefresh && caches[key]) {
    caches[key] = caches[key]
      .filter(({ params: innerParams }) => !isEqual(innerParams, params));
  }

  const cacheData = returnAPICachingData(caches, key, newParams);
  if (cacheData && !isRefresh) return cacheData;

  const cacheType = getStoriesCacheType(params);

  try {
    const {
      data,
    } = await RetryAPICall(API, `watchlists/${watchlistId}/stories`, {
      params: newParams,
      cancelToken,
      ...(cacheType && {
        headers: {
          'Cache-control': isRefresh ? 'no-cache' : returnAPICachingHeader(cacheType),
        },
      }),
    });
    const {
      stories,
      summaries = [],
      summaries_key: summariesKey = null,
    } = data;
    let nextPageToken = data.next_page_token;

    // This trick was done to fix recommended stories loading issue
    // Manually setting page token to EOD if response don't have many stories
    // To let application know that this was last set,
    // and he could start showing recommended stories
    if (
      newParams.limit === STORIES_LIMIT
      && stories.length < 6
    ) {
      nextPageToken = 'EOD';
    }

    let newStories = stories;
    if (stories?.length) {
      const storiesId = stories.map(({ id }) => id).join(',');
      const { user_actions: userActions } = await getCurationActions({ story_ids: storiesId });
      newStories = stories
        .map((obj) => {
          const foundAction = (userActions || []).find((f) => f.story_id === obj.id);
          return {
            ...obj,
            ...foundAction,
          };
        })
        .filter((f) => !f.mark_as_irrelevant && !f.hide);
    }

    if (!caches[key]) {
      caches[key] = [];
    }

    if (cacheType) {
      caches[key].unshift({
        params: newParams,
        expDate: getAPICachingTime(cacheType),
        response: {
          nextPageToken,
          stories: newStories,
          summaries,
          summariesKey,
          cacheType,
        },
      });
    }

    return {
      nextPageToken,
      stories: newStories,
      summaries,
      summariesKey,
      cacheType,
      isRefresh: isRefresh ?? false,
    };
  } catch (e) {
    return {
      nextPageToken: 'EOD',
      stories: [],
      summaries: [],
      summariesKey: null,
      cancelled: axios.isCancel(e),
      error: true,
      isRefresh: false,
    };
  }
};

// request fro removing query from watchlist
export const removeQuery = (queryId, watchlistId) => API.delete(`watchlists/${watchlistId}/search_queries?search_query_id=${queryId}`);

// request for removing asset from watchlist(for old watchlists)
export const removeAsset = (assetId, watchlistId) => API.delete(`watchlists/${watchlistId}/assets?asset_id=${assetId}`);

// requst for check/uncheck wathclist query
export const updateWatchlistQuery = (query, queryId, queryHidden, watchlistId) => {
  const data = {
    query,
    search_query_id: queryId,
    hidden: queryHidden,
    id: watchlistId,
  };
  return API.patch(`watchlists/${watchlistId}/search_queries`, data);
};

// request for changing watchlist name
export const editName = (watchlistId, newName) => API.patch(`watchlists/${watchlistId}`, { name: newName });

// request for deleting watchlist
export const removeWatchlist = (watchlistId) => API.delete(`watchlists/${watchlistId}`);

// set active watchlist for user
export const changeActiveWatchlist = (watchlistId) => {
  const params = {
    active_watchlist_id: watchlistId,
  };
  return API.patch('users/preference', params);
};

export const getActiveWatchlist = (watchlistId) => RetryAPICall(API, `watchlists/${watchlistId}`);

// request for getting stories for guest user wl
export const getStories = (params) => {
  // return empty stories obj if no lang or categories elected in filters
  if (
    (!params.all_languages && !params.languages)
    || !params.categories
  ) return Promise.resolve({ data: { stories: [] } });

  return StoriesSvc.getStoriesData({
    ...params,
    limit: params.limit || STORIES_LIMIT,
    with_sentiments: true,
  },
  {
    ...(params.cancelToken ? { cancelToken: params.cancelToken } : {}),
  });
};

// req for removing all users wl's
export const removeAllWatchlist = () => API.delete('watchlists');
