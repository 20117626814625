import moment from 'moment';
import differenceBy from 'lodash-es/differenceBy';
import FilingsSvc from '../services/dbServices/FilingsSvc';

export const FETCH_FILINGS_BEGIN = 'FETCH_FILINGS_BEGIN';
export const FETCH_FILINGS_SUCCESS = 'FETCH_FILINGS_SUCCESS';
export const FETCH_FILINGS_FAILURE = 'FETCH_FILINGS_FAILURE';
export const FETCH_FILINGS_SOURCES_SUCCESS = 'FETCH_FILINGS_SOURCES_SUCCESS';
export const FETCH_FILINGS_SOURCES_FAILURE = 'FETCH_FILINGS_SOURCES_FAILURE';
export const FETCH_FILINGS_CATEGORIES_SUCCESS = 'FETCH_FILINGS_CATEGORIES_SUCCESS';
export const FETCH_FILINGS_CATEGORIES_FAILURE = 'FETCH_FILINGS_CATEGORIES_FAILURE';
export const SET_FILINGS_PAGE_LOADER = 'SET_FILINGS_PAGE_LOADER';
export const GET_NEW_FILINGS_BEGIN = 'GET_NEW_FILINGS_BEGIN';
export const GET_NEW_FILINGS_SUCCESS = 'GET_NEW_FILINGS_SUCCESS';
export const GET_NEW_FILINGS_FAILURE = 'GET_NEW_FILINGS_FAILURE';
export const GET_LATEST_FILINGS_BEGIN = 'GET_LATEST_FILINGS_BEGIN';
export const GET_LATEST_FILINGS_SUCCESS = 'GET_LATEST_FILINGS_SUCCESS';
export const GET_LATEST_FILINGS_FAILURE = 'GET_LATEST_FILINGS_FAILURE';

const fetchFilingsBegin = () => ({
  type: FETCH_FILINGS_BEGIN,
});

const fetchFilingsSuccess = (filings) => ({
  type: FETCH_FILINGS_SUCCESS,
  payload: { filings },
});

const fetchFilingsFailure = (error) => ({
  type: FETCH_FILINGS_FAILURE,
  payload: { error },
});

export const setFilingsPageLoader = (newState) => ({
  type: SET_FILINGS_PAGE_LOADER,
  payload: newState,
});

export const setLatestFilingData = (newData) => ({
  type: GET_LATEST_FILINGS_SUCCESS,
  payload: newData,
});

const getParams = (storyFilters, props, extra) => {
  const { hasSourceAccess, filings } = extra || {};
  const {
    filing_period_start_datetime: sDate,
    filing_period_end_datetime: eDate,
    filings_time_filter: timeFilterType,
    filing_categories_all: isAllCategories,
    filing_types_all: isAlltypes,
    filing_category_ids: categoryIds,
    filing_type_ids: typeIds,
    filing_source_ids: sourceIds,
    filing_sources_all: sourceAll,
  } = storyFilters;

  const startDate = sDate ? moment(sDate).unix() : moment().add(-1, 'y').unix();
  const endDate = eDate ? moment(eDate).unix() : moment().unix();
  const param = {
    ...props,
  };

  if (timeFilterType !== 'lifetime') {
    param.start_date = startDate;
    param.end_date = endDate;
  }

  if (!isAllCategories && (categoryIds || []).length) param.categoryIds = (categoryIds || []).join(',');

  if (!isAlltypes && (typeIds || []).length) param.typeIds = (typeIds || []).join(',');

  if (hasSourceAccess) {
    if (sourceAll || !(sourceIds || []).length) {
      param.sources_ids = filings.sources.map((f) => f.sources_id).join(',');
    } else {
      param.sources_ids = (sourceIds || []).join(',');
    }
  }
  if (hasSourceAccess && (sourceIds || []).length) param.sources_ids = (sourceIds || []).join(',');

  return param;
};

export const loadFilingsForUser = (props, extra) => (dispatch, getStore) => {
  const { isInitialCall, cancelToken } = extra;
  const { storyFilters, filings } = getStore();
  const param = getParams(storyFilters, props, { ...extra, filings });

  if (isInitialCall) {
    dispatch(fetchFilingsBegin());
  } else {
    dispatch(setFilingsPageLoader(true));
  }
  return FilingsSvc.getFillings({ param, cancelToken })
    .then(({ data }) => {
      dispatch(fetchFilingsSuccess(data || []));
      return data || [];
    })
    .catch((error) => {
      dispatch(fetchFilingsFailure(error));
      return [];
    });
};

export const getRefreshFilings = (props, extra) => (dispatch, getStore) => {
  const { storyFilters, filings } = getStore();
  const param = getParams(storyFilters, props, { ...extra, filings });
  const { cancelToken } = extra;

  return FilingsSvc.getFillings({ param, cancelToken })
    .then(({ data }) => data)
    .catch(() => []);
};

export const getNewFilings = (props, extra) => (dispatch, getStore) => {
  const { storyFilters, filings } = getStore();
  const param = getParams(storyFilters, props, { ...extra, filings });
  const { cancelToken } = extra;

  dispatch({ type: GET_NEW_FILINGS_BEGIN });

  return FilingsSvc.getFillings({ param, cancelToken })
    .then(({ data }) => {
      const oldData = filings.filings;
      const latestData = differenceBy(data, oldData, 'filingId');
      dispatch({
        type: GET_NEW_FILINGS_SUCCESS,
        payload: latestData,
      });
      return data;
    })
    .catch((error) => {
      dispatch({
        type: GET_NEW_FILINGS_FAILURE,
        payload: error,
      });
      return [];
    });
};

export const getLatestFilings = (props, extra) => (dispatch, getStore) => {
  const { storyFilters, filings } = getStore();
  const param = getParams(storyFilters, props, { ...extra, filings });
  const { cancelToken } = extra;

  dispatch({ type: GET_LATEST_FILINGS_BEGIN });

  return FilingsSvc.getFillings({ param, cancelToken })
    .then(({ data }) => {
      const oldData = filings.filings;
      const latestData = differenceBy(data, oldData, 'filingId');
      dispatch({
        type: GET_LATEST_FILINGS_SUCCESS,
        payload: latestData,
      });
      return latestData;
    })
    .catch((error) => {
      dispatch({
        type: GET_LATEST_FILINGS_FAILURE,
        payload: error,
      });
      return [];
    });
};

export const fetchFilingsSources = (props) => (dispatch) => (
  FilingsSvc.getFillingsSources(props)
    .then(({ data }) => {
      dispatch({
        type: FETCH_FILINGS_SOURCES_SUCCESS,
        payload: { sources: data },
      });
      return data;
    })
    .catch(() => {
      dispatch({
        type: FETCH_FILINGS_SOURCES_FAILURE,
      });
      return [];
    }));

export const fetchFilingsCategories = (props) => (dispatch) => (
  FilingsSvc.getFillingsCategories(props)
    .then(({ data }) => {
      dispatch({
        type: FETCH_FILINGS_CATEGORIES_SUCCESS,
        payload: { categories: data },
      });
      return data;
    })
    .catch(() => {
      dispatch({
        type: FETCH_FILINGS_CATEGORIES_FAILURE,
      });
      return [];
    }));
