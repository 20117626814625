import {
  LOAD_USER_PREFERENCES,
  UPDATE_USER_PREFERENCES,
  LOAD_USER_SUBSCRIPTION_TOPICS,
  ADD_USER_SUBSCRIPTION_TOPICS,
  REMOVE_SUBSCRIPTION_TOPICS,
  TOGGLE_MENU,
  TOGGLE_CURATION,
  SET_USER_DATA,
  TOGGLE_STORY_FILTERS,
  LOADING_SEARCH_QUERIES,
  LOADING_USER_INFO,
} from '../actions/preferencesActions';

const initialState = {
  preferences: {
    active_watchlist_id: null,
  },
  subscriptionTopics: [],
  menuOpened: false,
  curationMode: false,
  user: null,
  loading: false,
  loadingUserInfo: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_SUBSCRIPTION_TOPICS:
      return {
        ...state,
        subscriptionTopics: [...action.payload],
        loading: false,
      };
    case TOGGLE_STORY_FILTERS:
      return {
        ...state,
        filterOpened: action.payload,
      };
    case ADD_USER_SUBSCRIPTION_TOPICS:
      return {
        ...state,
        subscriptionTopics: [...action.payload],
        loading: false,
      };
    case REMOVE_SUBSCRIPTION_TOPICS:
      return {
        ...state,
        subscriptionTopics: [...action.payload],
        loading: false,
      };
    case LOAD_USER_PREFERENCES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.payload,
        },
      };
    case UPDATE_USER_PREFERENCES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.payload,
        },
      };
    case TOGGLE_MENU:
      return {
        ...state,
        menuOpened: !state.menuOpened,
      };
    case TOGGLE_CURATION:
      return {
        ...state,
        curationMode: !state.curationMode,
      };
    case SET_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case LOADING_SEARCH_QUERIES:
      return {
        ...state,
        loading: action.payload,
      };
    case LOADING_USER_INFO:
      return {
        ...state,
        loadingUserInfo: action.payload,
      };
    default:
      return state;
  }
};
