import { APIWithoutToken } from './api';
import { getAPICachingTime, returnAPICachingHeader } from '../../helpers/helpers';
import { cachingVeryHigh } from '../../data/webPageData';

const pressArticlesCacheKey = 'PRESS_ARTICLES';
const caches = {};

class PressSvc {
  static async getPressArticles(options = {}) {
    try {
      const { preview = false } = options;
      const key = `${pressArticlesCacheKey}_${preview}`;

      if (caches[key] && caches[key].response && caches[key].expDate > Date.now()) {
        return caches[key].response;
      }

      const { data } = await APIWithoutToken.get('press', {
        params: {
          preview,
        },
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        },
      });

      caches[key] = {
        expDate: getAPICachingTime(cachingVeryHigh),
        response: {
          latest_news: Array.isArray(data?.latest_news) ? data.latest_news : [],
          awards: Array.isArray(data?.awards) ? data.awards : [],
          videos: Array.isArray(data?.videos) ? data.videos : [],
        },
      };

      return caches[key].response;
    } catch (e) {
      console.warn(e);

      return {
        latest_news: [],
        awards: [],
        videos: [],
      };
    }
  }
}

export default PressSvc;
