/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';

import useFinprompt from '../../../providers/FinpromptProvider/useFinprompt';

const MyCustomChatMessage = (props) => {
  const { message: { text, date: dateProps, showDate } } = props;

  const { isFinpromptPages } = useFinprompt();

  const memoizedValues = useMemo(() => {
    const options = { day: 'numeric', month: 'long' };

    const currentDate = new Date().toLocaleDateString('en-GB', options);
    const messageTime = new Date(dateProps).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    const messageDate = new Date(dateProps).toLocaleDateString('en-GB', options);

    return {
      currentDate,
      messageTime,
      messageDate: currentDate === messageDate ? 'Today' : messageDate,
    };
  }, [text, dateProps, showDate]);

  const {
    messageDate, messageTime,
  } = memoizedValues;

  const formatText = (message) => {
    let text = message;

    if (message === '' || typeof message !== 'string') {
      text = 'Sorry, please try again or <a href="https://www.cityfalcon.ai/contact-us">contact us</a>.';
    }

    const tags = [
      {
        type: 'link', regex: /<a href="([^"]+)">([^<]+)<\/a>/g, match: false, matchData: null,
      },
      {
        type: 'bold', regex: /<b>(.*?)<\/b>/g, match: false, matchData: null,
      },
    ];

    const processTags = (inputText) => {
      let currentIndex = 0;
      const components = [];

      tags.forEach((tag) => {
        let matchData;
        const matches = [];

        while ((matchData = tag.regex.exec(inputText))) {
          matches.push(matchData);
        }

        matches.forEach((matchData) => {
          tag.match = true;
          tag.matchData = matchData;

          const prefix = inputText.substring(currentIndex, matchData.index);
          const tagContent = matchData[0];

          if (prefix) {
            components.push(<span key={currentIndex}>{prefix}</span>);
          }

          if (tag.type === 'bold') {
            components.push(<span key={`${currentIndex}_b`}><b>{matchData[1]}</b></span>);
          }

          if (tag.type === 'link') {
            const href = matchData[1];
            const linkText = matchData[2];
            components.push(
              <a
                key={`${currentIndex}_a`}
                href={href}
                target="_blank"
                rel="noreferrer"
              >
                {linkText}
              </a>,
            );
          }

          currentIndex = matchData.index + matchData[0].length;
        });
      });

      if (currentIndex < inputText.length) {
        components.push(<span key={currentIndex}>{inputText.substring(currentIndex)}</span>);
      }

      return components;
    };

    return (
      <span>
        {processTags(text).map((component, index) => (
          <React.Fragment key={index}>{component}</React.Fragment>
        ))}
      </span>
    );
  };

  return (
    <>
      <div data-date={dateProps} className={`react-chatbot-kit-chat-bot-message-date ${showDate ? '' : 'hide'}`}>{messageDate}</div>
      <div className="react-chatbot-kit-chat-bot-message">
        <span>{formatText(text)}</span>
      </div>
      <div className="react-chatbot-kit-chat-bot-message-time">{messageTime}</div>
    </>
  );
};

export default MyCustomChatMessage;
