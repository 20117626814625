import {
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';

import { editWatchlistName, editGuestUserWatchlistName } from '../../../actions/watchlistActions';
import Styles from './styles.module.scss';
import { checkWLNames } from '../../../helpers/watchlistHelperFunctions';

const cx = classNames.bind(Styles);

const RenameWatchlistModal = ({
  openModal,
  handleCloseModal,
  watchlistsArray,
  id,
  newName,
  activeWatchlist,
  closeInput,
  piwikEnabled,
}) => {
  const [nameWithoutDuplicates, setNameWithoutDuplicates] = useState('');
  const renameModalRef = useRef(null);
  const { userToken: token } = useSelector((state) => state.watchlistReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    const validRenamedName = checkWLNames(watchlistsArray, newName, id);
    setNameWithoutDuplicates(validRenamedName);
  }, [watchlistsArray, newName, id]);

  const renameHandler = () => {
    if (token) {
      dispatch(editWatchlistName(id, nameWithoutDuplicates, activeWatchlist.id));
    } else {
      dispatch(editGuestUserWatchlistName(id, nameWithoutDuplicates));
    }
    if (piwikEnabled) _paq.push(['trackEvent', 'WL page', 'Rename WL']);
    closeInput();
    handleCloseModal();
  };

  return (
    <Modal
      show={openModal}
      dialogClassName={cx('rename-watchlist-modal')}
      ref={renameModalRef}
      onHide={handleCloseModal}
      className={cx('dialog-modal-wrapper')}
      centered
    >
      <div className={cx('rename-text')}>
        <FontAwesomeIcon icon={faExclamationCircle} />
        <p>This name is already in use.</p>
        <p>
          Rename &quot;
          <span>{newName}</span>
          &quot; to &quot;
          <span>{nameWithoutDuplicates}</span>
          &quot;
        </p>
      </div>
      <div className={cx('rename-watchlists-modal-footer')}>
        <button
          type="button"
          onClick={handleCloseModal}
          className={cx('rename_cancel_button')}
        >
          Back
        </button>
        <button
          type="button"
          onClick={renameHandler}
          className={cx('rename_confirm_button')}
        >
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default memo(RenameWatchlistModal);
