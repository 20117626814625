export const UserLoginComponentLinks = [
  {
    title: 'Manage Watchlists',
    menuKey: 'manageWatchlist',
    route: '/watchlists/manage',
    comingSoon: false,
  },
  {
    title: 'Account',
    menuKey: 'account',
    route: '/preferences/account',
    comingSoon: false,
  },
  {
    title: 'Subscription',
    menuKey: 'subscription',
    route: '/preferences/subscription',
    comingSoon: false,
  },
  {
    title: 'Notifications',
    menuKey: 'notifications',
    route: '/preferences/notifications',
    comingSoon: false,
  },
];

export default UserLoginComponentLinks;
