import API, { APIWithoutToken } from './api';
import { getAPICachingTime, returnAPICachingHeader } from '../../helpers/helpers';
import { cachingVeryHigh } from '../../data/webPageData';
import { RetryAPICall } from '../../helpers/apiHelpers';
import { notAllowedTimeFilters } from '../../data/directory/constants';
import validateStartEndDateTimePeriod from '../../helpers/validateStartEndDateTimePeriod';

const caches = {};

export const fetchPreferences = () => RetryAPICall(API, 'users/preference')
  .then((res) => {
    res.data.preference.categories = res.data.preference.categories.filter((cat) => !['c', 'i', 'e'].includes(cat));

    const savedUserTimeFilter = res.data.preference.time_filter;
    const startDate = res.data.preference.start_datetime;
    const endDate = res.data.preference.end_datetime;
    res.data.preference.time_filter = notAllowedTimeFilters.includes(savedUserTimeFilter) ? 'month1' : savedUserTimeFilter;

    const savedUserTimePeriod = res.data.preference.time_period;
    if (savedUserTimeFilter) {
      res.data.preference.time_period = savedUserTimePeriod?.includes('years') ? '1 months' : savedUserTimePeriod;
    }
    if (res.data.preference.selected_time_filter && startDate && endDate) {
      const { newStartDate, newEndDate } = validateStartEndDateTimePeriod(startDate, endDate);
      res.data.preference.start_datetime = newStartDate;
      res.data.preference.end_datetime = newEndDate;
    }
    // if value is empty, set it to null
    // this is to avoid empty string values
    if (!res.data.translate_to) {
      res.data.translate_to = null;
    }
    if (res.data.sentiment_categories && Array.isArray(res.data.sentiment_categories)) {
      // sort sentiment categories to always have the same order
      res.data.sentiment_categories.sort();
    }
    return res;
  });

export const updatePreferences = (data) => API.patch('users/preference', data)
  .then((res) => {
    res.data.preference.categories = res.data.preference.categories.filter((cat) => !['c', 'i', 'e'].includes(cat));

    const savedUserTimeFilter = res.data.preference.time_filter;
    res.data.preference.time_filter = notAllowedTimeFilters.includes(savedUserTimeFilter) ? 'month1' : savedUserTimeFilter;
    const startDate = res.data.preference.start_datetime;
    const endDate = res.data.preference.end_datetime;
    const savedUserTimePeriod = res.data.preference.time_period;

    if (savedUserTimePeriod) {
      res.data.preference.time_period = savedUserTimePeriod.includes('years') ? '1 months' : savedUserTimePeriod;
    }

    if (res.data.preference.selected_time_filter && startDate && endDate) {
      const { newStartDate, newEndDate } = validateStartEndDateTimePeriod(startDate, endDate);
      res.data.preference.start_datetime = newStartDate;
      res.data.preference.end_datetime = newEndDate;
    }

    return res;
  });

export const getPageMetaData = async (url) => {
  const key = url;
  if (caches[key] && caches[key].response
    && caches[key].expDate > Date.now()
    && key !== '/') {
    return caches[key].response;
  }

  const { data } = await APIWithoutToken.get('/html_meta_tags', {
    params: {
      path: url,
    },
    headers: {
      'Cache-control': returnAPICachingHeader(cachingVeryHigh),
      'X-Requested-With': 'XMLHttpRequest',
    },
  });

  caches[key] = {
    expDate: getAPICachingTime(cachingVeryHigh),
    response: data,
  };

  return data;
};

export const getTranslationSupportedLanguages = async () => {
  try {
    const res = await RetryAPICall(API, 'languages/translation_langs', {
      headers: {
        'Cache-control': returnAPICachingHeader(cachingVeryHigh),
      },
    });

    return res.data;
  } catch (e) {
    return {
      from_languages: [],
    };
  }
};
