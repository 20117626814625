import { Component } from 'react';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';

import duplicateImg from '../../../../assets/trending/icons/icn-duplicates.svg';
import duplicateImgHover from '../../../../assets/trending/icons/icn-duplicates-hover.svg';
import withComponentName from '../../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export class DuplicateCount extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      hover: false,
      showDuplicateCountPopover: false,
      isToolTipTop: true,
    };
  }

  onMouseEnterHandle() {
    this.setState({
      hover: true,
    });
  }

  onMouseLeaveHandle() {
    this.setState({
      hover: false,
    });
  }

  togglePopOver(e) {
    e.stopPropagation();
    const { isFooter, parentCardDivRef, isRecommendedStories } = this.props;
    if (isFooter) {
      return this.setState(({ showDuplicateCountPopover }) => ({
        showDuplicateCountPopover: !showDuplicateCountPopover,
        isToolTipTop: true,
      }));
    }
    const rect = parentCardDivRef.getBoundingClientRect();
    const parentDiv = isRecommendedStories
      ? parentCardDivRef.parentNode.parentNode.parentNode
      : parentCardDivRef.parentNode;
    const rectParent = parentDiv.getBoundingClientRect();
    const isToolTipTop = rect.top - rectParent.top > 36;
    this.setState(({ showDuplicateCountPopover }) => ({
      showDuplicateCountPopover: !showDuplicateCountPopover,
      isToolTipTop,
    }));
  }

  render() {
    const {
      showDuplicateCountPopover,
      hover,
      isToolTipTop,
    } = this.state;
    const { count, showMobile, isFooter } = this.props;

    return (
      <>
        <div
          className={cx(
            'details-header__statistics--duplicate',
            { 'show-popover': showDuplicateCountPopover },
            { 'mobile-only': showMobile },
            { 'desktop-tablet-only': !showMobile },
          )}
          onClick={this.togglePopOver}
          onMouseEnter={this.onMouseEnterHandle}
          onMouseLeave={this.onMouseLeaveHandle}
        >
          {Boolean(count) && (
            <>
              <img src={hover ? duplicateImgHover : duplicateImg} alt="" />
              <span className={Styles['duplicate-count']}>{count}</span>
              <p>Duplicates</p>
            </>
          )}
          <span
            className={cx(
              'duplicate-popover',
              `duplicate-popover-${isToolTipTop ? 'top' : 'bottom'}`,
              { 'duplicate-popover-left': isFooter },
            )}
          >
            <span className={Styles['duplicate-popover-container']}>
              Number of similar stories on Twitter and news publications.
            </span>
          </span>
        </div>
        {showDuplicateCountPopover && (
          <span className={Styles.popover_backdrop} onClick={this.togglePopOver} />
        )}
      </>
    );
  }
}

export default withComponentName(DuplicateCount);
