/* eslint-disable no-case-declarations */
import {
  TOOGLE_BOT,
  TOOGLE_EXPAND,
  ADD_MESSAGE,
  TOOGLE_WIDGET_STATE,
  SAVE_MESSAGES,
  SAVE_CURRENT_DATE,
  SET_LOADING,
  TOOGLE_MODAL,
  TOOGLE_OUTSIDE_MODAL,
} from '../actions/chatbot.actions';

const initialState = {
  isChatbotOpened: false,
  expanded: false,
  messages: [],
  loading: false,
  currentDate: null,
  activeWidgetType: '',
  openedWidgetType: [
    { type: 'widgetBasic', opened: false },
    { type: 'widgetFullScreen', opened: false },
  ],
  isModalDisplayed: { displayed: false, type: '' },
  isModalOutsideDisplayed: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, payload],
      };
    case TOOGLE_BOT:
      return {
        ...state,
        isChatbotOpened: payload,
      };
    case TOOGLE_EXPAND:
      return {
        ...state,
        expanded: payload,
      };
    case TOOGLE_WIDGET_STATE:
      const currentOpenedWidgetType = state.openedWidgetType.map((widget) => {
        if (widget.type === payload.type) {
          return {
            ...widget,
            opened: payload.state,
          };
        }
        if (payload.type && (widget.type !== payload.type)) {
          return {
            ...widget,
            opened: false,
          };
        }
        return widget;
      });

      let activeWidgetType = '';

      if (payload.state) {
        activeWidgetType = payload.type || '';
      }

      return {
        ...state,
        openedWidgetType: currentOpenedWidgetType,
        isChatbotOpened: currentOpenedWidgetType.some((widget) => widget.opened),
        activeWidgetType,
      };
    case SAVE_MESSAGES:
      return {
        ...state,
        messages: [...payload],
      };
    case SAVE_CURRENT_DATE:
      return {
        ...state,
        currentDate: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case TOOGLE_MODAL:
      return {
        ...state,
        isModalDisplayed: payload,
      };
    case TOOGLE_OUTSIDE_MODAL:
      return {
        ...state,
        isModalOutsideDisplayed: payload,
      };
    default:
      return state;
  }
};
