import { forwardRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { subscribeToPermissions, unsubscribeFromPermissions } from '../actions/subsriptions.actions';

export default (Component) => {
  const PermissionsDecorator = forwardRef((props, ref) => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(subscribeToPermissions());

      return () => {
        unsubscribeFromPermissions();
      };
    }, [dispatch]);

    return <Component ref={ref} {...props} />;
  });

  PermissionsDecorator.displayName = Component.name || Component.displayName;

  return PermissionsDecorator;
};
