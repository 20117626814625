import { Component, useState } from 'react';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faExternalLinkSquare } from '@fortawesome/pro-light-svg-icons/faExternalLinkSquare';

import { returnCDNFoxyImageUrl } from '../../../../helpers/helpers';

import withComponentName from '../../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export class TwitterImgSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      isFoxy: true,
    };
  }

  componentDidMount() {
    const { images } = this.props;

    this.setState({
      images,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      images: nextProps.images,
    });
  }

  putImageInMainView = (index) => {
    this.setState({
      images: this.state.images.splice(index, 1),
    });
  };

  showNextImg = () => {
    const newRotatedImages = this.state.images;
    const currViewImg = newRotatedImages.shift(); // remove first image and return it
    newRotatedImages.push(currViewImg); // add curr view img at last

    this.setState({
      images: newRotatedImages,
    });
  };

  showPrevImg = () => {
    const newRotatedImages = this.state.images;
    const lastImage = newRotatedImages.pop(); // get last image and return it
    newRotatedImages.unshift(lastImage); // put the last image at first to be shown

    this.setState({
      images: newRotatedImages,
    });
  };

  showImg = (index) => {
    const newRotatedImages = this.state.images;
    const imagesBeforeCurrImg = newRotatedImages.splice(0, index + 1);
    newRotatedImages.push(...imagesBeforeCurrImg);

    this.setState({
      images: newRotatedImages,
    });
  };

  openCurrShownImageInNewTab = () => {
    const { images } = this.state;
    window.open(images[0], '_blank')?.focus?.();
  };

  openMainPreviewIntoModal = (e) => {
    const { fromModal } = this.props;

    if (!fromModal) {
      this.props.handleUserNewsfeedViewImagesModal(e);
    }
  };

  handleImageError = () => {
    const { isFoxy } = this.state;
    if (isFoxy) {
      this.setState({
        isFoxy: false,
      });
    }
  };

  render() {
    const { images, isFoxy } = this.state;
    const { fromModal, hasBigScreenDesign } = this.props;

    const imageURL = returnCDNFoxyImageUrl({
      isFoxy, imageURL: images[0],
    });

    return (
      <div className={cx('TwitterImgSlider', {
        'one-img-preview': images.length === 1,
        'from-modal': fromModal,
        big_screen: hasBigScreenDesign,
      })}
      >
        <div className={cx('main-preview')}>
          <img
            src={imageURL}
            alt=""
            className={cx('main-img')}
            onClick={this.openMainPreviewIntoModal}
            onError={this.handleImageError}
          />
          <div className={cx('slider-controlers', { 'open-btn-only': images.length === 1 })}>
            {images.length > 1
              && (
              <div className={cx('prev-btn')} onClick={this.showPrevImg}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
              )}
            <div className={cx('open-btn')} onClick={this.openCurrShownImageInNewTab}>
              <FontAwesomeIcon icon={faExternalLinkSquare} />
            </div>
            {images.length > 1
              && (
              <div className={cx('next-btn')} onClick={this.showNextImg}>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
              )}
          </div>
        </div>
        {images.length > 1
          && (
          <div className={cx('thumbnails')} onClick={(e) => e.stopPropagation()}>
            {images.filter((item, index) => index > 0).map((item, index) => (
              <InnerImageSection
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                item={item}
                showImg={this.showImg}
              />
            ))}
          </div>
          )}
      </div>
    );
  }
}

const InnerImageSection = ({ item, showImg, index }) => {
  const [isFoxy, setIsFoxy] = useState(true);
  const handleImageError = () => {
    if (isFoxy) {
      setIsFoxy(false);
    }
  };

  const handleClick = () => {
    showImg(index);
  };

  const imageURL = returnCDNFoxyImageUrl({
    isFoxy, imageURL: item,
  });

  return (
    <div
      className={cx('img-wrapper')}
      onClick={handleClick}
    >
      <img
        src={imageURL}
        alt=""
        onError={handleImageError}
      />
    </div>
  );
};

export default withComponentName(TwitterImgSlider);
