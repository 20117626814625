import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, DropdownButton, Dropdown, FormGroup, FormControl,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import isEqual from 'lodash-es/isEqual';
import filter from 'lodash-es/filter';
import autoBind from 'react-autobind';

import Alert from '../../Shared/ReactAlert';

import * as watchlistActions from '../../../actions/watchlistActions';

import { createGuestWatchlist } from '../../../helpers/guestUsersWatchlists';
import { getTrendingBarAssetQuery, queryItemWatchlistParser } from '../../../helpers/watchlistHelperFunctions';
import withComponentName from '../../../decorators/withComponentName';

import watchlistAddedImg from '../../../../../assets/images/watchlist_added.png';

import permissionsDecorator from '../../../decorators/permissionsDecorator';
import TopicsSvc from '../../../services/TopicsSvc';

import { SILVER } from '../../../data/permissions';
import { DESKTOP, TABLET, MOBILE } from '../../../data/screenSizes';
import copy from '../../../helpers/copy';

import Styles from './styles.module.scss';
import withFinprompt from '../../../decorators/withFinprompt';
import { CF_URL_FOR_FINPROMPT } from '../../../data/environment';

const cx = classNames.bind(Styles);

export class WatchlistModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedWatchlistName: '',
      selectedWatchlist: {},
      loadingWatchlists: false,
      newWatchlist: '',
      submitSuccessfully: false,
      mode: DESKTOP,
      currentWLFull: false,
    };
    this.mounted = true;
    autoBind(this);
  }

  async componentDidMount() {
    this.mounted = true;
    const { actions, token } = this.props;

    window.addEventListener('resize', this.onResize, { passive: true });
    this.onResize();

    const loadWatchlistMethod = token ? (
      actions.getWatchlists()
    ) : (
      actions.guestUserWatchlists()
    );

    this.setState({ loadingWatchlists: true });

    await loadWatchlistMethod;
    if (this.mounted) {
      this.setState({ loadingWatchlists: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedWatchlist } = this.state;
    const { watchlists } = this.props;

    if (!isEqual(watchlists, prevProps.watchlists)) {
      watchlists.forEach((watchlist) => {
        if (watchlist.id === selectedWatchlist.id) {
          this.setState({ selectedWatchlist: watchlist });
        }
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    let mode;

    switch (true) {
      case window.innerWidth >= 768:
        mode = DESKTOP;
        break;
      case window.innerWidth >= 480:
        mode = TABLET;
        break;
      default:
        mode = MOBILE;
        break;
    }

    this.setState({ mode });
  }

  getDropDownTitle() {
    const {
      watchlists,
      permissionsInitialized,
      accessLevels,
    } = this.props;

    const {
      loadingWatchlists,
      selectedWatchlistName,
    } = this.state;

    const basicUserDropdownPlaceholder = (
      permissionsInitialized && !accessLevels.includes(SILVER) && this.checkWatchlistsSizes()
    );

    if (basicUserDropdownPlaceholder) return 'Topic limit reached on all watchlists';
    if (loadingWatchlists) return 'Loading...';
    if (!watchlists.length) return 'No Watchlist added to select';
    if (!selectedWatchlistName) return 'Select Watchlist';
    return selectedWatchlistName;
  }

  handleClose() {
    const { handleCloseModal } = this.props;

    this.setState({
      submitSuccessfully: false,
      newWatchlist: '',
    });
    handleCloseModal();
  }

  handleChange(e) {
    this.setState({ newWatchlist: e.target.value }, () => {
      const { newWatchlist } = this.state;
      const { watchlists, maxWatchlistSize } = this.props;
      const hasWL = watchlists
        .find((f) => f.name === newWatchlist && f.topics.length >= maxWatchlistSize);
      this.setState({
        currentWLFull: !!hasWL,
      });
    });
  }

  selectWatchlist(selected) {
    this.setState({
      selectedWatchlistName: selected.name,
      selectedWatchlist: selected,
    });
  }

  resetSelection() {
    this.setState({
      selectedWatchlistName: '',
      selectedWatchlist: {},
      newWatchlist: '',
    });
  }

  saveSelection() {
    const { newWatchlist } = this.state;

    if (newWatchlist) return this.createNewWatchlist();
    return this.addToWatchlist();
  }

  async addToWatchlist() {
    const {
      actions,
      topic,
      token,
      type,
      accessLevels,
      createCompany,
    } = this.props;
    const { selectedWatchlist } = this.state;
    let newTopic = copy(topic);

    if (createCompany) {
      newTopic = await TopicsSvc.saveCompaniesData({
        legal_id: topic.companyNumber,
        source: topic.source,
      });
      newTopic.groups = [{
        groupName: newTopic.id,
        groupType: 'Topic',
      }];
    }

    const query = getTrendingBarAssetQuery(newTopic, type);

    // check if selected query is already present in the watchlist
    const presentedWatchlistTopic = (watchlistTopic) => (token
      ? watchlistTopic.query === query
      : watchlistTopic.query === topic.name);

    if (selectedWatchlist.topics.some(presentedWatchlistTopic)) {
      return Alert.warning(`This topic (${topic.name}) is already presented in this watchlist`, {
        position: 'top',
        effect: 'stackslide',
      });
    }

    // if there are more than 5 topics in watchlist
    if (selectedWatchlist.topics.length >= 5 && !accessLevels.includes(SILVER)) return;

    const addTrendingTopicMethod = !token
      ? actions.addNewGuestQuery(selectedWatchlist.id, [newTopic], '')
      : actions.addNewWatchlistQueries(query.replace(/\((.*?)\)/g, '\\($1\\)'), selectedWatchlist.id, token);

    try {
      await addTrendingTopicMethod;
      this.setState({
        submitSuccessfully: true,
      });
    } catch (e) {
      Alert.warning('Something went wrong please try again!', {
        position: 'top',
        effect: 'stackslide',
      });
    }
  }

  async createNewWatchlist() {
    const {
      actions,
      topic,
      token,
      type,
      createCompany,
    } = this.props;
    const { newWatchlist } = this.state;
    let newTopic = copy(topic);

    if (createCompany) {
      newTopic = await TopicsSvc.saveCompaniesData({
        legal_id: topic.companyNumber,
        source: topic.source,
      });
      newTopic.groups = [{
        groupName: newTopic.id,
        groupType: 'Topic',
      }];
    }

    const query = getTrendingBarAssetQuery(newTopic, type);

    const createWlMethod = !token
      ? createGuestWatchlist({
        topics: [newTopic],
        userQuery: '',
        watchlistName: newWatchlist,
      })
      : actions.createNewWatchlist(query, token, newWatchlist);

    try {
      const res = await createWlMethod;
      this.setState({ submitSuccessfully: true });
      if (token) return this.selectWatchlist(res);

      // for first guest watchlist
      if (Array.isArray(res)) {
        const current = filter(res, ['current', true]);
        this.selectWatchlist(current[0]);
      } else {
        this.selectWatchlist(res);
      }
      actions.guestUserWatchlists();
    } catch (e) {
      Alert.warning('Something went wrong please try again!', {
        position: 'top',
        effect: 'stackslide',
      });
    }
  }

  redirectToWatchlist() {
    const { selectedWatchlist } = this.state;
    const { actions, token, isFinpromptPages } = this.props;

    const redirectPath = isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}/watchlists` : '';
    actions.toggleActiveWatchlist(selectedWatchlist.id, token, true, redirectPath, isFinpromptPages);
  }

  checkWatchlistsSizes() {
    const { watchlists, maxWatchlist, maxWatchlistSize } = this.props;

    const checkSize = (watchlist) => (
      watchlist.search_queries.length + watchlist.watchlist_assets.length >= maxWatchlistSize
    );

    if (watchlists.length) {
      if (maxWatchlist === watchlists.length) return watchlists.every(checkSize);
      if (watchlists.every(checkSize) && maxWatchlist !== watchlists.length) return true;
    }
    return false;
  }

  render() {
    const {
      watchlists,
      openModal,
      topic,
      permissionsInitialized,
      accessLevels,
      maxWatchlist,
      maxWatchlistSize,
    } = this.props;

    const {
      loadingWatchlists,
      selectedWatchlistName,
      newWatchlist,
      submitSuccessfully,
      mode,
      currentWLFull,
    } = this.state;

    if (!openModal) return null;

    const watchlistsFull = watchlists.length
      ? watchlists.every(({ search_queries: searchQueries }) => searchQueries.length === maxWatchlistSize)
      : false;

    const resetBtnClass = cx({
      'watchlist-btn': true,
      'watchlist-btn--reset': true,
      disable: (!selectedWatchlistName && !newWatchlist)
         || watchlistsFull,
    });
    const saveBtnClass = cx({
      'watchlist-btn': true,
      'watchlist-btn--save': true,
      disable: (!selectedWatchlistName && !newWatchlist)
         || (watchlistsFull && watchlists.length >= maxWatchlist) || currentWLFull,
    });
    const creteNewWlClass = cx({
      'watchlist-modal__body--create-watchlist': true,
      CreateWatchlist: true,
      disabled: selectedWatchlistName && !watchlistsFull,
    });

    return (
      <Modal
        show={openModal}
        onHide={() => this.handleClose()}
        centered
        className={Styles['watchlist-modal']}
      >
        <Modal.Header
          className={Styles['watchlist-modal__header']}
          closeButton
        />
        {!submitSuccessfully ? (
          <Modal.Body className={Styles['watchlist-modal__body']}>
            <div className={Styles['watchlist-modal__body-container']}>
              <span className={Styles['watchlist-modal__title']}>
                Add
                {' '}
                {topic.name}
                {' '}
                to Watchlist
              </span>
              {permissionsInitialized && !accessLevels.includes(SILVER) && (
              <div className={Styles['limits-message']}>
                You can add only
                {' '}
                {maxWatchlistSize}
                {' '}
                topics and create
                {' '}
                {maxWatchlist}
                {' '}
                watchlists on your Basic plan.
                <br />
                <a href="/pricing">Upgrade your plan</a>
                {' '}
                to add up to 100 topics to unlimited watchlists.
              </div>
              )}
              <DropdownButton
                id="watchlist-modal-dropdown"
                title={this.getDropDownTitle()}
                className={cx('watchlist-dropdown', { disabled: newWatchlist })}
                disabled={
                  loadingWatchlists
                  || !watchlists.length
                  || this.checkWatchlistsSizes()
                }
              >
                {watchlists.map((item, index) => (
                  <Dropdown.Item
                    key={item.id}
                    eventKey={index}
                    active={selectedWatchlistName === item.name}
                    onClick={() => this.selectWatchlist(item)}
                    disabled={item.topics.length >= maxWatchlistSize}
                  >
                    {item.name}
                    {(permissionsInitialized
                      && item.topics.length >= maxWatchlistSize
                      && mode !== MOBILE
                    ) && (
                      <span className="limits-message">
                        You have reached the limit of
                        {' '}
                        {maxWatchlistSize}
                        {' '}
                        topics for this watchlist
                      </span>
                    )}
                    <div className={Styles['topics-list']}>
                      {item.topics.map(({ name }) => name).join(', ')}
                    </div>
                    {(permissionsInitialized
                      && item.topics.length >= maxWatchlistSize
                      && mode === MOBILE
                    ) && (
                      <span className="limits-message">
                        You have reached the limit of
                        {' '}
                        {maxWatchlistSize}
                        {' '}
                        topics for this watchlist
                      </span>
                    )}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <span className={Styles['watchlist-modal__body--or']}>OR</span>
              <div className={creteNewWlClass}>
                <span className={Styles.CreateWatchlist__title}>Create New Watchlist</span>
                {((permissionsInitialized && watchlists.length >= maxWatchlist) || currentWLFull)
                  && (
                    <div className={Styles['limits-message']}>
                      {currentWLFull ? (
                        <>
                          You have reached the limit of
                          {' '}
                          {maxWatchlistSize}
                          {' '}
                          topics for this watchlist
                        </>
                      ) : (
                        <>
                          You have reached the limit of
                          {' '}
                          {maxWatchlist}
                          {' '}
                          watchlists.
                        </>
                      )}
                    </div>
                  )}
                <FormGroup
                  controlId="formBasicText"
                  className={Styles['new-wl-form']}
                >
                  <FormControl
                    type="text"
                    value={newWatchlist}
                    placeholder="Type New Watchlist Name"
                    onChange={(evt) => this.handleChange(evt)}
                    disabled={watchlists.length >= maxWatchlist}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </div>
            </div>
            <div className={Styles['watchlist-modal__btns']}>
              <button
                className={resetBtnClass}
                onClick={this.resetSelection}
                type="button"
              >
                Reset Selection
              </button>
              <button
                className={saveBtnClass}
                onClick={this.saveSelection}
                type="button"
              >
                Save
              </button>
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body className={Styles['watchlist-modal__SuccessAddedBody']}>
            <img src={watchlistAddedImg} alt="Watchlist added icon" />
            <span className={Styles['watchlist-modal__SuccessAddedBody--topic-name']}>
              {topic.name}
            </span>
            <span className={Styles['watchlist-modal__SuccessAddedBody--text']}>
              has been successfully added
            </span>
            <span
              className={Styles['watchlist-modal__SuccessAddedBody--watchlist']}
              onClick={this.redirectToWatchlist}
            >
              {newWatchlist || selectedWatchlistName}
            </span>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

WatchlistModal.propTypes = {
  type: PropTypes.oneOf([
    'Topic',
    'Sector',
    'Industry',
    'Subindustry',
    'StartupCategory',
    'AssetClass',
    'Continent',
    'SubContinent',
    'Country',
    'State',
    'City',
    'Area',
    'Place',
    'Level1',
    'Level2',
    'Level3',
    'Level4',
  ]),
};

WatchlistModal.defaultProps = {
  type: 'Topic',
};

const mapStateToProps = (state) => ({
  watchlists: state.watchlistReducer.watchlistsList.map((item) => ({
    ...item,
    topics: [
      ...item.search_queries.map((searchQuery) => ({
        ...searchQuery,
        name: queryItemWatchlistParser(searchQuery.query).name,
      })),
      ...(Array.isArray(item.watchlist_assets) && item.watchlist_assets),
    ],
  })),
  token: state.watchlistReducer.userToken,
  activeWatchlist: state.watchlistReducer.activeWatchlist,
  permissionsInitialized: state.subscriptions.permissionsInitialized,
  accessLevels: state.subscriptions.permissions.access_levels,
  maxWatchlist: state.subscriptions.permissions.max_watchlists,
  maxWatchlistSize: state.subscriptions.permissions.max_watchlist_size,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...watchlistActions }, dispatch),
});

export default withComponentName(
  connect(mapStateToProps, mapDispatchToProps)(permissionsDecorator(
    withFinprompt(WatchlistModal),
  )),
);
