import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import StoriesModalDesktop from './StoriesModalDesktop';
import StoriesModalMobile from './StoriesModalMobile';
import StoriesSvc from '../../../../services/StoriesSvc';
import trendingStoriesParams from '../../../../data/trendingStoriesParams';
import withComponentName from '../../../../decorators/withComponentName';

import { CHART_MAX_DATE } from '../../../../data/filters/filterConstants';
import {
  ONE_YEAR,
  ALL,
  ON_CHART_MODAL_TABS,
  FIVE_YEAR,
} from '../../../../data/directory/topic_classes/ChartFilters';
import { SUMMARISATION } from '../../../../data/features';
import { GOLD } from '../../../../data/permissions';
import dummySummaries from '../../../../data/summaries/dummySummaries';

import { getChartStoriesDateInterval, getSentimentsChartStoriesDateInterval, getSentimentRange } from '../../../../helpers/chartHelpers';
import { removeDuplicates } from '../../../../helpers/removeDuplicates';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const StoriesModal = forwardRef((props, ref) => {
  const {
    date,
    timeFilter,
    query,
    allowWithoutQuery,
    showStories,
    showToggle,
    onStoriesLoadStart,
    onStoriesLoad,
    isSentiment,
    rangeStartDate,
    rangeEndDate,
    sentimentAvg,
  } = props;
  const [storiesData, setStoriesData] = useState({
    loading: true,
    stories: [],
    notAllowed: false,
  });
  const [summaryData, setSummaryData] = useState({
    loading: true,
    summaries: [],
    notAllowed: false,
  });
  const [tabs, setTabs] = useState([
    {
      id: ON_CHART_MODAL_TABS.STORIES,
      value: 'Top Stories',
      active: true,
      beta: false,
    },
  ]);
  const setActiveTab = useCallback((id) => {
    setTabs((prevTabs) => prevTabs.map((tab) => ({
      ...tab,
      active: tab.id === id,
    })));
  }, []);
  const { startDate, endDate } = useMemo(() => {
    if (isSentiment) {
      return getSentimentsChartStoriesDateInterval(
        date,
        timeFilter,
      );
    }
    if (timeFilter === ONE_YEAR || timeFilter === FIVE_YEAR || timeFilter === ALL) {
      return {
        startDate: rangeStartDate, endDate: rangeEndDate,
      };
    }
    return getChartStoriesDateInterval(
      new Date(date.replace(' ', 'T')).toISOString(),
      timeFilter,
    );
  }, [date, timeFilter, isSentiment]);

  const earliestSearchDate = useSelector((state) => (
    state.subscriptions.permissions.earliest_search_date));
  const storyFilters = useSelector((state) => (state.storyFilters));
  const maxSummarySize = useSelector((state) => (state.subscriptions.permissions.max_summary_size));
  const hasSummarisationAccess = useSelector((state) => (state.subscriptions.permissionsInitialized
    && state.subscriptions.permissions.features.includes(SUMMARISATION)));
  const isGold = useSelector((state) => (
    state.subscriptions.permissions.access_levels.includes(GOLD)));
  const isTabMobile = useSelector(({ common }) => (
    (common.width <= 1024)
  ));

  const prevStartDate = usePrevious(startDate);

  useEffect(() => {
    if (hasSummarisationAccess) {
      setTabs((currentTabs) => ([
        ...currentTabs,
        {
          id: ON_CHART_MODAL_TABS.SUMMARIES,
          value: 'Key Headlines',
          active: false,
          beta: false,
          isSummary: true,
        },
      ]));
    }
  }, [hasSummarisationAccess]);

  useEffect(() => {
    let mounted = true;

    if (
      (!query && !allowWithoutQuery)
      || (!isTabMobile && !showStories)
      || !moment(startDate).isAfter(CHART_MAX_DATE)
    ) return;

    let { id: activeTabId } = tabs.find((tab) => tab.active);

    if (prevStartDate !== startDate) {
      activeTabId = ON_CHART_MODAL_TABS.STORIES;
      setActiveTab(ON_CHART_MODAL_TABS.STORIES);
    }

    if (activeTabId === ON_CHART_MODAL_TABS.STORIES && !storiesData.loading) {
      setStoriesData({
        loading: true,
        stories: [],
        notAllowed: false,
      });
    } else if (activeTabId === ON_CHART_MODAL_TABS.SUMMARIES && !summaryData.loading) {
      setSummaryData({
        loading: true,
        summaries: [],
        notAllowed: false,
      });
    }

    onStoriesLoadStart();
    let summariesFetchingInstance = null;
    const dataFetchTimer = setTimeout(async () => {
      const categories = 'mp,op,r';
      const params = {
        ...trendingStoriesParams,
        categories,
        query,
        start_datetime: startDate,
        end_datetime: endDate,
        limit: 20,
        fold_similar_stories: storyFilters.group_similar_stories,
        all_languages: storyFilters.all_languages,
        ...(!storyFilters.all_languages && {
          languages: storyFilters.languages,
        }),
      };

      if (isSentiment) {
        const sentimentRange = getSentimentRange(sentimentAvg.value);
        params.sentiment_gte = sentimentRange.sentiment_gte;
        params.sentiment_lte = sentimentRange.sentiment_lte;
      }

      if (activeTabId === ON_CHART_MODAL_TABS.STORIES) {
        let { stories, notAllowed } = await StoriesSvc.getStories(
          params,
          earliestSearchDate,
        );
        if (mounted && !notAllowed && stories.length < 5) {
          const fetchedStories = stories;
          const newStoriesParams = {
            ...params,
            all_languages: true,
            languages: undefined,
          };
          if (isSentiment) {
            const sentimentRange = getSentimentRange(sentimentAvg.value);
            const newRequests = [];
            if (sentimentRange.sentiment_lte !== 100) {
              newRequests.push(StoriesSvc.getStories({
                ...newStoriesParams,
                sentiment_gte: sentimentRange.sentiment_lte,
                sentiment_lte: 100,
              }));
            }
            if (sentimentRange.sentiment_gte !== -100) {
              newRequests.push(StoriesSvc.getStories({
                ...newStoriesParams,
                sentiment_gte: -100,
                sentiment_lte: sentimentRange.sentiment_gte,
              }));
            }
            const storiesDataArr = await Promise.all(newRequests);
            stories = storiesDataArr.flatMap(({ stories: newStories, notAllowed }) => (
              !notAllowed ? newStories : []
            ));
          } else {
            ({ stories, notAllowed } = await StoriesSvc.getStories(
              newStoriesParams,
              earliestSearchDate,
            ));
          }
          stories = removeDuplicates(
            [
              ...fetchedStories,
              ...stories,
            ],
            'id',
          ).slice(0, 5);
        }

        if (mounted) {
          onStoriesLoad();
          setStoriesData({
            loading: false,
            stories: stories.slice(0, 5),
            notAllowed,
          });
        }
      } else if (activeTabId === ON_CHART_MODAL_TABS.SUMMARIES) {
        if (!isGold) {
          setSummaryData({
            loading: false,
            summaries: dummySummaries,
            notAllowed: false,
          });
          return;
        }

        const { notAllowed, summariesKey } = await StoriesSvc.getStories(
          {
            ...params,
            only_summaries: true,
            number_of_summaries: maxSummarySize,
            summarization_coverage: (query || '').split(':').slice(1, 2).join('#'),
            limit: 500,
            fold_similar_stories: false,
            languages: 'en',
            all_languages: false,
          },
          earliestSearchDate,
        );

        if (!mounted) {
          return;
        }

        if (notAllowed) {
          setSummaryData({
            loading: false,
            summaries: [],
            notAllowed,
          });
          return;
        }

        if (summariesKey) {
          summariesFetchingInstance = StoriesSvc.waitSummaries(summariesKey);
          const summaries = await summariesFetchingInstance.promise;
          onStoriesLoad();
          setSummaryData({
            loading: false,
            summaries,
            notAllowed: false,
          });
        } else {
          setSummaryData({
            loading: false,
            summaries: [],
            notAllowed: false,
          });
        }
      }
    }, 300);

    return () => {
      mounted = false;
      clearTimeout(dataFetchTimer);
      summariesFetchingInstance?.cancel?.();
    };
  }, [
    startDate, endDate, onStoriesLoadStart,
    earliestSearchDate, query, tabs, isGold,
    showStories, onStoriesLoad, maxSummarySize,
    hasSummarisationAccess,
  ]);

  const modalProps = {
    ...props,
    storiesData,
    summaryData,
    startDate,
    endDate,
    tabs,
    setActiveTab,
  };

  return (
    !isTabMobile || !showToggle ? (
      <StoriesModalDesktop {...modalProps} ref={ref} />
    ) : (
      <StoriesModalMobile {...modalProps} ref={ref} />
    )
  );
});

StoriesModal.defaultProps = {
  onStoriesLoadStart: () => null,
  onStoriesLoad: () => null,
};

export default withComponentName(StoriesModal);
