import { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import moment from 'moment';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';

import { calculateTimeAgo } from '../../../../../helpers/helpers';
import convertPermalinkToPath from '../../../../../helpers/urlHelpers';
import { sourceDataHelper } from '../../../../../helpers/filingsHelpers';

import withComponentName from '../../../../../decorators/withComponentName';
import withFinprompt from '../../../../../decorators/withFinprompt';

import { CF_URL_FOR_FINPROMPT } from '../../../../../data/environment';

import ButtonComponent from '../../../../Buttons/ButtonComponent';
import CFLink from '../../../../Shared/CFLink';

import FilingDate from '../../../FilingDate';

import Styles from '../styles.module.scss';

const cx = classNames.bind(Styles);

class ProviderFilingRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: props.isExpandAll || false,
      sourceData: {},
    };
    autoBind(this);
  }

  componentDidMount() {
    const { filingInformation } = this.props;
    const { sourceName } = filingInformation || {};
    const sourceData = sourceDataHelper(sourceName);
    this.setState({
      sourceData,
    });
  }

  componentDidUpdate(prevProps) {
    const { isExpandAll } = this.props;
    if (prevProps.isExpandAll !== isExpandAll) {
      this.setState({
        isExpanded: isExpandAll,
      });
    }
  }

  handleExpandClick() {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded,
    });
  }

  handleChildClick(e) {
    e.stopPropagation();
  }

  render() {
    const { isExpanded, sourceData } = this.state;
    const {
      cfUrl,
      title,
      description,
      filingInformation,
      companyInformation,
      descriptionValues,
      isSource,
      isCompany,
      isAllCategories,
      isAllTypes,
      width,
      homePage,
      isSingleTopic,
      className = '',
      hasBigScreenDesign,
      chatbot,
      expandedChatbot,
      isFinpromptPages,
    } = this.props;
    const {
      filingDate, actionDate, filingCategory, filingType,
    } = filingInformation || {};
    const singleUrl = `${isFinpromptPages ? CF_URL_FOR_FINPROMPT : ''}${cfUrl?.replace(/^.*\/\/[^\/]+/, '')}`;

    return (
      <div className={`${cx('filings_row',
        {
          big_screen: hasBigScreenDesign,
          chatbot,
          chatbot_mobile_view: chatbot && !expandedChatbot,
        })} ${className}`}
      >
        <div className={cx('filings_header_holder', { home: homePage })} onClick={this.handleExpandClick}>
          {chatbot && isExpanded && !isCompany && !isSingleTopic && (
          <CompanyChips
            companyInformation={companyInformation}
            handleChildClick={this.handleChildClick}
          />
          )}
          <div className={cx('row_header', { home: homePage })}>
            <CFLink external={isFinpromptPages} to={singleUrl} onClick={this.handleChildClick}>
              {title}
            </CFLink>
          </div>
          { isExpanded && chatbot ? (
            <div className={cx('row_description')}>
              {description}
            </div>
          ) : null}
          <div className={cx('row_details', { home: homePage })}>
            <div className={Styles.row_time}>
              {moment(filingDate).isAfter(moment()) ? (
                <FilingDate short date={filingDate} hasBigScreenDesign={hasBigScreenDesign} />
              ) : (
                calculateTimeAgo(filingDate, width <= 1500, true)
              )}
            </div>
            {chatbot && filingType && (
              <div className={Styles.row_type}>
                {filingType}
              </div>
            )}
            { !isSource && (
              <div className={Styles.row_provider}>
                {sourceData.name || ''}
              </div>
            )}
            { !chatbot && !isCompany && !isSingleTopic && (
              <CompanyChips
                companyInformation={companyInformation}
                handleChildClick={this.handleChildClick}
              />
            )}
          </div>
          <div className={cx('row_arrow', { home: homePage })}>
            <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
          </div>
        </div>
        { isExpanded && !chatbot
          ? (
            <ExpandedComponent
              description={description}
              actionDate={actionDate}
              filingDate={filingDate}
              descriptionValues={descriptionValues}
              singleUrl={singleUrl}
              filingCategory={filingCategory}
              filingType={filingType}
              isCompany={isCompany}
              isAllCategories={isAllCategories}
              isAllTypes={isAllTypes}
            />
          )
          : null}
      </div>
    );
  }
}

const CompanyChips = ({
  companyInformation = {},
  handleChildClick,
}) => Object.values(companyInformation)
  .map(({ companyName, cfURL }) => {
    const lowerCaseName = companyName.toLowerCase();
    return (
      <CFLink
        key={companyName}
        className={Styles.row_company}
        onClick={handleChildClick}
        to={cfURL ? convertPermalinkToPath(cfURL) : undefined}
      >
        {lowerCaseName}
      </CFLink>
    );
  });

const ExpandedComponent = ({
  description, actionDate, filingDate, descriptionValues, singleUrl,
  filingCategory, filingType, isCompany, isAllCategories, isAllTypes,
}) => {
  const {
    old_address: oldAdd, new_address: newAdd, change_date: changeDate,
  } = descriptionValues || {};

  const renderRow = (title, value) => {
    title === 'Filing Date' ? value = value.replace(/00:00, /, '') : value;

    return (
      <>
        <div className={Styles.extra_label}>{title}</div>
        <div className={Styles.extra_value}>
          {value}
        </div>
      </>
    );
  };

  return (
    <div className={cx('row_extra')}>
      {filingDate && renderRow('Filing Date', moment(filingDate).format('hh:mm, DD MMM YYYY'))}
      {actionDate && renderRow('Action Date', moment(actionDate).format('hh:mm, DD MMM YYYY'))}
      {filingCategory && !(isCompany && isAllCategories) && renderRow('Filing Category', filingCategory.replace(/,/, ', '))}
      {filingType && !(isCompany && isAllTypes) && renderRow('Filing Type', filingType)}
      {description && renderRow('Description', description)}
      {oldAdd && renderRow('Old Address', oldAdd)}
      {newAdd && renderRow('New Address', newAdd)}
      {changeDate && renderRow('Date', moment(changeDate).format('DD MMM YYYY'))}
      <ButtonComponent
        withoutReload
        value="More"
        path={singleUrl}
        className={Styles.more_button}
      />
    </div>
  );
};

const mapStateToProps = ({ storyFilters, common }) => ({
  isAllCategories: storyFilters.filing_categories_all,
  isAllTypes: storyFilters.filing_types_all,
  width: common.width,
});

export default withComponentName(
  connect(mapStateToProps, null)(withFinprompt(ProviderFilingRow)),
);
