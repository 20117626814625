import { Component } from 'react';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import { isIOS, isIOS13, isMobile } from 'react-device-detect';

import withComponentName from '../../../../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export class Tooltip extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      tooltipVisible: false,
      active: false,
      ios: false,
      isToolTipTop: true,
    };
    this.hidingTimeout = null;
  }

  componentDidMount() {
    /*
      Added isIOS13 because on device iPad Pro 12.9 2020 and iPad Pro 11 2020 safari browser
      return false for isIOS
    */
    if (isIOS || isIOS13 || isMobile) {
      document.addEventListener('touchend', this.hideTooltip, { passive: true });
      this.setState({ ios: isIOS || isIOS13 });
    }
  }

  componentWillUnmount() {
    if (isIOS || isIOS13 || isMobile) document.removeEventListener('touchend', this.hideTooltip);
    if (this.hidingTimeout) this.stopTimers();
  }

  showTooltip(e) {
    if (e.type === 'touchend') {
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
    }

    const { parentDivRef, isWatchlists } = this.props;

    let isToolTipTop = true;
    if (parentDivRef && parentDivRef.current && isWatchlists) {
      const rect = parentDivRef.current.getBoundingClientRect();
      const parentDiv = parentDivRef.current.parentNode;
      const rectParent = parentDiv.getBoundingClientRect();
      isToolTipTop = rect.top - rectParent.top > 36;
    }

    this.stopTimers();
    this.setState(({ active }) => ({
      tooltipVisible: true,
      active: !isIOS && !isIOS13 && !isMobile ? active : true,
      isToolTipTop,
    }));
  }

  hideTooltip() {
    this.stopTimers();
    this.hidingTimeout = setTimeout(() => {
      this.setState(({ active }) => ({
        tooltipVisible: false,
        active: !isIOS && !isIOS13 && !isMobile ? active : false,
      }));
    }, !isIOS && !isIOS13 && !isMobile ? 200 : 0);
  }

  stopTimers() {
    clearTimeout(this.hidingTimeout);
  }

  render() {
    const { children, tootipText, hasBigScreenDesign } = this.props;
    const {
      tooltipVisible, ios, isToolTipTop,
    } = this.state;

    const events = ios || isIOS13 || isMobile ? {
      onTouchEnd: this.showTooltip,
    } : {
      onMouseEnter: this.showTooltip,
      onMouseLeave: this.hideTooltip,
    };

    return (
      <div className={Styles.tooltip}>
        <div {...events} className={Styles['tooltip-icon']}>
          {tootipText}
        </div>
        <div
          className={cx('tooltip-content', { bottom: !isToolTipTop, big_screen: hasBigScreenDesign })}
          hidden={!(tooltipVisible && children)}
          {...events}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default withComponentName(Tooltip);
