import {
  SET_WL_LOAD_STATUS,
  FETCH_WL_STORIES_BEGIN,
  FETCH_INTERNAL_STORIES_BEGIN,
  REMOVE_WATCHLIST_TOPIC,
  UPDATE_WATCHLIST_PAGE_BEGIN,
  REMOVE_WATCHLIST,
  REMOVE_ALL_WATCHLISTS,
  UPDATE_WATCHLIST_ASSETS,
  LOAD_WATCHLIST_STORIES,
  LOAD_WATCHLIST_INTERNAL_STORIES,
  LOAD_ACTIVE_WATCHLIST,
  NEW_WATCHLIST_DROPDOWN,
  SET_ACTIVE_WATCHLIST_ID,
  SET_ACTIVE_WATCHLIST,
  SET_ALL_WATCHLISTS,
  FETCH_WATCHLIST_SUCCESS,
  SET_USER_TOKEN,
  UPDATE_WATCHLIST_TOPICS,
  UPDATE_WATCHLIST_TOPICS_BEGIN,
  UPDATE_WATCHLIST_TOPICS_SUCCESS,
  CHECK_WATCHLIST_TOPIC,
  NEW_WATCHLIST_CREATION_BEGIN,
  NEW_WATCHLIST_CREATION_SUCCESS,
  SHOW_WATCHLIST_STUB,
  GUEST_WATCHLIST_REPLACEMENT_BEGIN,
  GUEST_WATCHLIST_REPLACEMENT_SUCCESS,
  CLOSE_NEW_WATCHLIST_DROPDOWN,
  HIDE_WATCHLIST_STORY,
  ACTIVATE_PRELOADER, // main preloader activation
  DISACTIVATE_SHARED_WATCHLIST_STUB, // disactivate stub for guest when first wl create from shared
  ACTIVATE_SHARED_WATCHLIST_STUB, // activate stub for guest when first wl create from shared
  ACTIVATE_FILINGS_PRELOADER,
  DISACTIVATE_FILINGS_PRELOADER,
  SET_SUMMARIES_REQUEST_METHOD,
  RESET_WATCHLIST_REDUX_DATA,
  UPDATE_WATCHLIST_STORIES_INFO,
  UPDATE_WATCHLIST_STORIES_BOOKMARK,
  DISACTIVATE_MULTIPLE_REQUESTS_PRELOADER,
  ACTIVATE_MULTIPLE_REQUESTS_PRELOADER,
  ACTIVATE_WATCHLIST_LIST_PRELOADER,
  DEACTIVATE_WATCHLIST_LIST_PRELOADER,
} from '../actions/watchlistActions';

import { getUserToken } from '../helpers/getUserToken';

const initialState = {
  watchlistsList: [],
  activeWatchlistStories: [],
  activeWatchlistInternalStories: [],
  activeWatchlist: {},
  activeWatchlistTopics: [],
  activeWatchlistId: 0,
  loading: false,
  activatePreloader: true,
  isFetchingWatchlistList: false,
  activateFilingsPreloader: true,
  internalLoading: false,
  watchlistLoadError: false,
  watchlistPageLoad: false,
  newWatchlistDropDown: false,
  watchlistCreationBegin: false,
  watchlistUpdateBegin: false,
  userToken: getUserToken(),
  showWatchlistStub: false,
  guestUserWlReplacementBegin: false,
  loadingTrendingBar: false,
  nextPageToken: null,
  sharedWatchlistStubPage: false,
  summaryRequestMethod: null,
  multipleRequestsPreloader: false,
  wlPageLoadingStep: 'LOADING_WL',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_WL_LOAD_STATUS:
      return {
        ...state,
        wlPageLoadingStep: action.payload,
      };
    case DISACTIVATE_SHARED_WATCHLIST_STUB:
      return {
        ...state,
        sharedWatchlistStubPage: false,
      };
    case ACTIVATE_SHARED_WATCHLIST_STUB:
      return {
        ...state,
        sharedWatchlistStubPage: true,
      };
    case HIDE_WATCHLIST_STORY:
      return {
        ...state,
        activeWatchlistStories: [
          ...state.activeWatchlistStories.filter((item) => (
            item.id !== action.payload
          )),
        ],
      };
    case ACTIVATE_PRELOADER:
      return {
        ...state,
        activatePreloader: true,
      };
    case ACTIVATE_WATCHLIST_LIST_PRELOADER:
      return {
        ...state,
        isFetchingWatchlistList: true,
      };
    case DEACTIVATE_WATCHLIST_LIST_PRELOADER:
      return {
        ...state,
        isFetchingWatchlistList: false,
      };
    case GUEST_WATCHLIST_REPLACEMENT_SUCCESS:
      return {
        ...state,
        guestUserWlReplacementBegin: false,
        loading: false,
      };
    case GUEST_WATCHLIST_REPLACEMENT_BEGIN:
      return {
        ...state,
        guestUserWlReplacementBegin: true,
        loading: false,
      };
    case SHOW_WATCHLIST_STUB:
      return {
        ...state,
        showWatchlistStub: true,
        loading: false,
      };
    case NEW_WATCHLIST_CREATION_SUCCESS:
      return {
        ...state,
        watchlistCreationBegin: false,
      };
    case NEW_WATCHLIST_CREATION_BEGIN:
      return {
        ...state,
        watchlistCreationBegin: true,
      };
    case CHECK_WATCHLIST_TOPIC:
      return {
        ...state,
        activeWatchlistTopics: action.payload,
      };
    case SET_USER_TOKEN:
      return {
        ...state,
        userToken: action.payload,
      };
    case CLOSE_NEW_WATCHLIST_DROPDOWN:
      return {
        ...state,
        newWatchlistDropDown: false,
      };
    case NEW_WATCHLIST_DROPDOWN:
      return {
        ...state,
        newWatchlistDropDown: !state.newWatchlistDropDown,
      };
    case SET_ALL_WATCHLISTS:
      return {
        ...state,
        watchlistsList: [...action.payload],
        activatePreloader: false,
      };
    case SET_ACTIVE_WATCHLIST:
      return {
        ...state,
        activeWatchlist: { ...action.payload },
        activeWatchlistId: action.payload.id || state.activeWatchlist.id,
        showWatchlistStub: false,
      };
    case SET_ACTIVE_WATCHLIST_ID:
      return {
        ...state,
        activeWatchlistId: action.payload || state.activeWatchlist.id,
      };
    case FETCH_WL_STORIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_INTERNAL_STORIES_BEGIN:
      return {
        ...state,
        activeWatchlistInternalStories: [],
        internalLoading: true,
      };
    case FETCH_WATCHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        watchlistPageLoad: false,
      };
    case UPDATE_WATCHLIST_PAGE_BEGIN:
      return {
        ...state,
        watchlistPageLoad: true,
      };
    case LOAD_ACTIVE_WATCHLIST:
      return {
        ...state,
        activeWatchlist: { ...action.payload },
        activeWatchlistId: action.payload.id || state.activeWatchlist.id,
        watchlistsList: state.watchlistsList.map((watchlist) => (
          action.payload.id === watchlist.id ? { ...action.payload } : watchlist
        )),
      };
    case REMOVE_WATCHLIST:
      return {
        ...state,
        watchlistsList: [...state.watchlistsList.filter((item) => item !== action.payload)],
        loading: false,
      };
    case REMOVE_ALL_WATCHLISTS:
      return {
        ...state,
        watchlistsList: [],
        loading: false,
      };
    case REMOVE_WATCHLIST_TOPIC:
      return {
        ...state,
        activeWatchlistTopics: state.activeWatchlistTopics.filter((item) => (
          item.id !== action.payload
        )),
        watchlistsList: state.watchlistsList.map((watchlist) => (
          watchlist.id === state.activeWatchlist.id ? ({
            ...watchlist,
            search_queries: watchlist.search_queries.filter((item) => item.id !== action.payload),
            watchlist_assets: watchlist.watchlist_assets.filter((item) => (
              item.id !== action.payload
            )),
          }) : watchlist
        )),
      };
    case UPDATE_WATCHLIST_ASSETS:
      return {
        ...state,
        activeWatchlistTopics: [...action.payload],
      };
    case UPDATE_WATCHLIST_TOPICS:
      return {
        ...state,
        activeWatchlistTopics: [...state.activeWatchlistTopics],
      };
    case LOAD_WATCHLIST_STORIES:
      return {
        ...state,
        activeWatchlistStories: [...action.payload.stories],
        nextPageToken: action.payload.nextPageToken,
        watchlistPageLoad: false,
        loading: false,
        activatePreloader: false,
        watchlistLoadError: action.payload.error,
      };
    case LOAD_WATCHLIST_INTERNAL_STORIES:
      return {
        ...state,
        activeWatchlistInternalStories: action.payload,
        internalLoading: false,
      };
    case ACTIVATE_FILINGS_PRELOADER:
      return {
        ...state,
        activateFilingsPreloader: true,
      };
    case DISACTIVATE_FILINGS_PRELOADER:
      return {
        ...state,
        activateFilingsPreloader: false,
      };
    case SET_SUMMARIES_REQUEST_METHOD:
      return {
        ...state,
        summaryRequestMethod: action.payload || null,
      };
    case RESET_WATCHLIST_REDUX_DATA:
      return {
        ...initialState,
      };
    case UPDATE_WATCHLIST_STORIES_INFO:
      return {
        ...state,
        activeWatchlistStories: action.payload,
      };
    case UPDATE_WATCHLIST_STORIES_BOOKMARK:
      return {
        ...state,
        activeWatchlistStories: action.payload,
      };
    case DISACTIVATE_MULTIPLE_REQUESTS_PRELOADER:
      return {
        ...state,
        multipleRequestsPreloader: false,
      };
    case ACTIVATE_MULTIPLE_REQUESTS_PRELOADER:
      return {
        ...state,
        multipleRequestsPreloader: true,
      };
    case UPDATE_WATCHLIST_TOPICS_SUCCESS:
      return {
        ...state,
        watchlistUpdateBegin: false,
      };
    case UPDATE_WATCHLIST_TOPICS_BEGIN:
      return {
        ...state,
        watchlistUpdateBegin: true,
      };
    default:
      return state;
  }
};
