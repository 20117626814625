/* eslint-disable no-case-declarations */
import {
  SAVE_HEIGHT_NOTICEBAR_CONFIRM_EMAIL,
} from '../actions/finprompt.actions';

const initialState = {
  heightNoticebarConfirmEmail: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVE_HEIGHT_NOTICEBAR_CONFIRM_EMAIL:
      return {
        ...state,
        heightNoticebarConfirmEmail: payload,
      };
    default:
      return state;
  }
};
