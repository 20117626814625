import { createRef, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { connect } from 'react-redux';

import ValuePropositionTabs from './ValuePropositionTabs';

import { headerProductsMenu } from '../../../data/headerData/headerProductsLinks';

import ComingSoon from '../../Shared/ComingSoon';

import withComponentName from '../../../decorators/withComponentName';
import withRouter from '../../../decorators/withRouter';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export class ReactNavMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      consumerDropdown: false,
      entepriseDropdown: false,
      pricingDropdown: false,
      keyFeaturesDropdown: false,
      activeTab: '',
      tabs: this.props?.items || headerProductsMenu,
    };
    this.menuRef = createRef();
    this.menuOpenedDropdown = createRef();
    autoBind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.trackClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location?.pathname !== this.props?.location?.pathname) {
      this.trackClickOutside();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.trackClickOutside);
  }

  setActiveTab(key) {
    this.setState({
      activeTab: key,
    });
  }

  closeMenu() {
    this.setState({
      consumerDropdown: false,
      entepriseDropdown: false,
      pricingDropdown: false,
      keyFeaturesDropdown: false,
      activeTab: '',
      sectionType: false,
      [this.menuOpenedDropdown.current]: false,
    });

    this.menuOpenedDropdown.current = null;
  }

  toggleMenu(e, sectionType, sectionTypeOpened, pathname) {
    const { isFinpromptPages } = this.props;

    if (isFinpromptPages && pathname) {
      window.location.href = pathname;
      return;
    }

    this.setState({
      consumerDropdown: false,
      entepriseDropdown: false,
      pricingDropdown: false,
      keyFeaturesDropdown: false,
      [sectionType]: !sectionTypeOpened,
      activeTab: '',
    });

    if (!sectionTypeOpened) this.menuOpenedDropdown.current = sectionType;
  }

  trackClickOutside(e = {}) {
    if (this.menuRef.current.contains(e.target)) return;
    this.closeMenu();
  }

  // eslint-disable-next-line react/sort-comp
  handleClickItem(e, menuItem) {
    const { isFinpromptPages } = this.props;
    const { state } = this;

    if (isFinpromptPages) {
      if (!menuItem.comingSoon) window.location.href = menuItem.pathname;
    } else {
      this.toggleMenu(e, menuItem.menuKey, state[menuItem.menuKey], menuItem.pathname);
    }
  }

  render() {
    const { state } = this;
    const { activeTab, tabs } = this.state;
    const { isFinpromptPages, user } = this.props;

    return (
      <nav
        className={cx('nav', { finprompt: isFinpromptPages })}
        ref={this.menuRef}
      >
        {tabs.map((menuItem) => (
          <div
            className={cx(
              'dropdown',
              {
                dropdown_relative: menuItem.menuKey === 'pricingDropdown' || menuItem.menuKey === 'keyFeaturesDropdown',
                active: state[menuItem.menuKey],
                dropdown_feature: menuItem.menuKey === 'keyFeaturesDropdown' && menuItem.menuSubItems.length,
                finprompt: isFinpromptPages,
              },
            )}
            key={menuItem.menuKey}
          >
            <button
              className={cx('dropdown_toggle',
                {
                  finprompt: isFinpromptPages,
                  'finprompt-demo': isFinpromptPages && !user && (menuItem.pathname === '/demo'),
                  'coming-soon': menuItem.comingSoon,
                })}
              onClick={(e) => this.handleClickItem(e, menuItem)}
              type="button"
            >
              <span>{menuItem.menuTitle}</span>
              {!isFinpromptPages && <FontAwesomeIcon icon={faChevronDown} />}
              {isFinpromptPages && menuItem.comingSoon && <ComingSoon position="bottom" />}
            </button>
            <div
              className={cx('dropdown_menu',
                {
                  show: (menuItem?.menuSubItems?.length || menuItem?.links?.length)
                    && state[menuItem.menuKey],
                })}
            >
              <div
                className={cx(
                  'dropdown_menu_container',
                  {
                    dropdown_menu_container_flex: menuItem.menuSubItems.length,
                    finprompt: isFinpromptPages,
                  },
                )}
              >
                <div className={cx('dropdown-menu-links', { finprompt: isFinpromptPages })}>
                  {
                    !menuItem.menuSubItems.length && !isFinpromptPages && (
                    <>
                      <h2>{menuItem.menuTitle}</h2>
                      <p>
                        We provide a range of solutions for the needs of a wide audience.
                      </p>
                    </>
                    )
                  }
                  {
                    menuItem.menuSubItems.length ? (
                      <>
                        {
                          menuItem.menuSubItems.map((menuSubItem) => (
                            <div
                              className={Styles.dropdown_menu_subitems_container}
                              key={menuSubItem.subItemKey}
                            >
                              <h2 className={Styles.dropdown_menu_subitems_title}>
                                {menuSubItem.subItemTitle}
                              </h2>
                              {
                                menuSubItem.subItemLinks.map((subItemLink) => (
                                  <div
                                    className={Styles.dropdown_menu_item}
                                    key={subItemLink.route}
                                  >
                                    {/* eslint-disable-next-line no-nested-ternary */}
                                    {subItemLink.route ? (
                                      subItemLink.useAnchor ? (
                                        <a
                                          onClick={this.closeMenu}
                                          className={Styles.dropdown_menu_link}
                                          href={subItemLink.route}
                                        >
                                          {subItemLink.linkItem}
                                        </a>
                                      ) : (
                                        <Link
                                          onClick={this.closeMenu}
                                          className={Styles.dropdown_menu_link}
                                          to={subItemLink.route}
                                        >
                                          {subItemLink.linkItem}
                                        </Link>
                                      )
                                    ) : (
                                      <span className={Styles.dropdown_menu_link}>
                                        <i className={Styles.coming_soon}>coming soon</i>
                                        {subItemLink.linkItem}
                                      </span>
                                    )}
                                  </div>
                                ))
                              }
                            </div>
                          ))
                        }
                        <div
                          className={Styles.dropdown_menu_item}
                          key="https://www.cityfalcon.ai/features"
                        >
                          <a
                            onClick={this.closeMenu}
                            className={Styles.dropdown_menu_link}
                            href="https://www.cityfalcon.ai/features"
                          >
                            All Features and Content
                          </a>
                        </div>
                      </>
                    ) : (
                      menuItem.links.map((dropdownMenuLink) => (
                        <div
                          className={Styles.dropdown_menu_item}
                          key={dropdownMenuLink.route}
                        >
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {dropdownMenuLink.route ? (
                            dropdownMenuLink.useAnchor ? (
                              <a
                                onClick={this.closeMenu}
                                className={Styles.dropdown_menu_link}
                                href={dropdownMenuLink.route}
                              >
                                {dropdownMenuLink.linkItem}
                              </a>
                            ) : (
                              <Link
                                onClick={this.closeMenu}
                                className={Styles.dropdown_menu_link}
                                to={dropdownMenuLink.route}
                              >
                                {dropdownMenuLink.linkItem}
                              </Link>
                            )
                          ) : (
                            <span className={Styles.dropdown_menu_link}>
                              <i className={Styles.coming_soon}>coming soon</i>
                              {dropdownMenuLink.linkItem}
                            </span>
                          )}
                        </div>
                      ))
                    )
                  }
                </div>
                {!!menuItem.tabsData.length && (
                  <ValuePropositionTabs
                    tabsData={menuItem.tabsData}
                    defaultTab={menuItem.defaultTab}
                    activeTab={activeTab}
                    setActiveTab={this.setActiveTab}
                    closeMenu={this.closeMenu}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userPreferencesReducer.user,
});

export default withComponentName(
  connect(mapStateToProps, null, null, { forwardRef: true })(withRouter(ReactNavMenu)),
);
