import { Component } from 'react';
import classNames from 'classnames/bind';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import withComponentName from '../../../../decorators/withComponentName';
import TwitterImgSlider from '../TwitterImgSlider';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export class TwitterImgSliderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState) {
    const { show } = this.state;
    const { onClose } = this.props;

    if (onClose instanceof Function && show !== prevState.show && !show) {
      // sending onClose call to the end of event loop
      // to fire it after click event firing
      setTimeout(() => onClose());
    }
  }

  handleClose() {
    const { onFreezeStories } = this.props;
    if (onFreezeStories) {
      onFreezeStories(false);
    }
    this.setState({
      show: false,
    });
  }

  handleShow() {
    this.setState({
      show: true,
    });
  }

  render() {
    const { show } = this.state;
    const { images, hasBigScreenDesign } = this.props;

    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Modal
          className={cx('TwitterImgSliderModal', 'show', { big_screen: hasBigScreenDesign })}
          show={show}
          onHide={() => this.handleClose()}
          centered
        >
          <Modal.Header className={cx('TwitterImgSliderModal__header')} closeButton />
          <Modal.Body className={cx('TwitterImgSliderModal__body')}>
            <TwitterImgSlider
              fromModal
              images={images}
              hasBigScreenDesign={hasBigScreenDesign}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

TwitterImgSliderModal.propTypes = {
  onClose: PropTypes.func,
};

TwitterImgSliderModal.defaultProps = {
  onClose: null,
};

export default withComponentName(TwitterImgSliderModal);
