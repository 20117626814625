import {
  DCSC_RELEVENT_COMPANIES_CSV_START,
  DCSC_RELEVENT_COMPANIES_CSV_END,
  DCSC_RELEVENT_COMPANIES_CSV_CANCEL,
  DCSC_RELEVENT_COMPANIES_CSV_ERROR,
} from '../actions/dcscSectors.actions';

const initialState = {
  SmartWatchlistProcessCSV: false,
  SmartWatchlistCancleCSV: false,
  SmartWatchlistErrorCSV: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case DCSC_RELEVENT_COMPANIES_CSV_START:
      return {
        ...state,
        SmartWatchlistProcessCSV: true,
        SmartWatchlistCancleCSV: false,
        SmartWatchlistErrorCSV: false,
      };
    case DCSC_RELEVENT_COMPANIES_CSV_END:
      return {
        ...state,
        SmartWatchlistProcessCSV: false,
        SmartWatchlistCancleCSV: false,
        SmartWatchlistErrorCSV: false,
      };
    case DCSC_RELEVENT_COMPANIES_CSV_CANCEL:
      return {
        ...state,
        SmartWatchlistProcessCSV: false,
        SmartWatchlistCancleCSV: true,
        SmartWatchlistErrorCSV: false,
      };
    case DCSC_RELEVENT_COMPANIES_CSV_ERROR:
      return {
        ...state,
        SmartWatchlistProcessCSV: false,
        SmartWatchlistCancleCSV: false,
        SmartWatchlistErrorCSV: true,
      };
    default:
      return state;
  }
};
