// change time for first api call
import moment from 'moment';

import { getTimePeriodStartDate } from '../../helpers/storyHelpers';

export const changeRecommendedTimePeriod = ({
  timeFilterValues,
  hasTwitterAccess,
  events,
  sectors,
  assetClasses,
}) => {
  const params = {
    categories: hasTwitterAccess ? 'mp,op,r,c,i,e' : 'mp,op,r',
    order_by: 'latest',
    all_languages: true,
    min_score: 5,
    domains_filtering_mode: 'all',
    events: events || undefined,
    sectors: sectors || undefined,
    asset_classes: assetClasses || undefined,
    limit: 30,
    selected_time_filter: 'predefined',
  };

  const {
    activeTimeFilter, timeFilter, timePeriod, maximumAllowedTime,
  } = timeFilterValues;

  // check stories for one week for basic user
  if (activeTimeFilter === 'predefined') {
    if (['minutes5', 'minutes15', 'hour1', 'hours8', 'day1'].includes(timeFilter)) {
      params.time_filter = 'week1';
    } else if (timeFilter === 'week1' || !maximumAllowedTime || maximumAllowedTime === 'month1') {
      params.time_filter = 'month1';
    } else {
      params.time_filter = maximumAllowedTime;
    }
  } else if (activeTimeFilter === 'time_period') {
    const startDate = getTimePeriodStartDate(timePeriod);
    if (moment(startDate).isAfter(moment().subtract(1, 'weeks'))) {
      params.time_filter = 'week1';
    } else if (moment(startDate).isAfter(moment().subtract(1, 'months')) || !maximumAllowedTime || maximumAllowedTime === 'month1') {
      params.time_filter = 'month1';
    } else {
      params.time_filter = maximumAllowedTime;
    }
  } else if (activeTimeFilter === 'custom') {
    params.time_filter = 'week1';
  }

  return params;
};

// helper for creating request params for recommended stories request
export const getRecommendedStoriesRequestParams = (
  timeFilter,
  hasTwitterAccess,
  events,
  sectors,
  assetClasses,
  similarStories,
  sentimentLevels,
) => {
  const params = {
    categories: hasTwitterAccess ? 'mp,op,r,c,i,e' : 'mp,op,r',
    order_by: 'latest',
    all_languages: true,
    min_score: 5,
    fold_similar_stories: similarStories,
    events: events || undefined,
    sectors: sectors || undefined,
    asset_classes: assetClasses || undefined,
    sentiment_levels: sentimentLevels,
    limit: 30,
  };

  if (['minutes5', 'minutes15', 'hour1', 'hours8', 'day1', 'week1'].includes(timeFilter)) {
    params.time_filter = 'week1';
  } else {
    params.time_filter = 'month1';
  }

  return params;
};
