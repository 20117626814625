import { memo } from 'react';
import classNames from 'classnames/bind';

import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const defaultMessage = `
No data is available for this time period.
Causes may be low liquidity or data is missing
from the exchange.`;

export function NoDataMessage({ hasBigScreenDesign, message = defaultMessage }) {
  return (
    <div className={cx('no-data', { big_screen: hasBigScreenDesign })}>
      <div className={Styles.message}>
        {message}
      </div>
    </div>
  );
}

export default memo(withComponentName(NoDataMessage));
