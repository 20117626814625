import { faBookmark as faBookmarkLight } from '@fortawesome/pro-light-svg-icons/faBookmark';
import { faBookmark as faBookmarkSolid } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import { faThumbsUp as faThumbsUpLight } from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import { faThumbsUp as faThumbsUpSolid } from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import { faThumbsDown as faThumbsDownLight } from '@fortawesome/pro-light-svg-icons/faThumbsDown';
import { faThumbsDown as faThumbsDownSolid } from '@fortawesome/pro-solid-svg-icons/faThumbsDown';
import { faExclamationTriangle as faExclamationTriangleLight } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faExclamationTriangle as faExclamationTriangleSolid } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';

export const storyFooterActions = [
  {
    actionId: 1,
    blockClass: true,
    actionName: 'bookmarkAction',
    params: [],
    active: 'bookmarked',
    icon: faBookmarkLight,
    iconActive: faBookmarkSolid,
    actionTypeClass: 'bookmark',
    actionTitle: 'Bookmark',
    tooltipText: 'Bookmark the story to read later.',
  },
  {
    actionId: 2,
    blockClass: false,
    params: ['like'],
    actionName: 'storyAction',
    active: 'liked',
    icon: faThumbsUpLight,
    iconActive: faThumbsUpSolid,
    actionTypeClass: 'like',
    actionTitle: 'Like',
    tooltipText: 'Like the story and teach our \n algorithms to refine what you see.',
  },
  {
    actionId: 3,
    blockClass: false,
    params: ['dislike'],
    actionName: 'storyAction',
    active: 'disliked',
    icon: faThumbsDownLight,
    iconActive: faThumbsDownSolid,
    actionTypeClass: 'dislike',
    actionTitle: 'Dislike',
    tooltipText: 'Dislike the story and teach our\n algorithms to refine what you see.',
  },
  {
    actionId: 4,
    blockClass: false,
    params: ['articleIrrelevant'],
    actionName: 'updateRating',
    active: false,
    icon: faExclamationTriangleLight,
    iconActive: faExclamationTriangleSolid,
    actionTypeClass: 'irrelevant',
    actionTitle: 'Irrelevant',
    tooltipText: 'Mark as irrelevant to teach our algorithms\n to refine what you and what others see.',
    lastChild: true,
  },
];

export const mobileStoryActions = [
  {
    actionId: 3,
    blockClass: false,
    params: ['like'],
    actionName: 'likeArticle',
    active: 'liked',
    icon: faThumbsUpLight,
    iconActive: faThumbsUpSolid,
    actionTypeClass: 'like',
    actionTitle: 'Like',
  },
  {
    actionId: 2,
    blockClass: false,
    params: ['dislike'],
    actionName: 'dislikeArticle',
    active: 'disliked',
    icon: faThumbsDownLight,
    iconActive: faThumbsDownSolid,
    actionTypeClass: 'dislike',
    actionTitle: 'Dislike',
  },
  {
    actionId: 1,
    blockClass: true,
    actionName: 'bookmarkAction',
    params: [],
    active: 'bookmarked',
    icon: faBookmarkLight,
    iconActive: faBookmarkSolid,
    actionTypeClass: 'bookmark',
    actionTitle: 'Bookmark',
  },
];
