import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import withComponentName from '../../../decorators/withComponentName';

import Tooltip from '../../Tooltip';

import StoriesApi from '../../../services/dbServices/StoriesApiService';

import Styles from '../styles.module.scss';

const cx = classNames.bind(Styles);

const BookmarkStoryIcon = ({ bookmark, toggleRemovedBookmark, chatbot }) => {
  const bookmarkHandler = async (e) => {
    toggleRemovedBookmark(e, bookmark);
    return StoriesApi.removeStoryBookmark(bookmark.id);
  };

  return (
    <div
      className={cx('bookmarks_icon_container', { chatbot })}
    >
      <Tooltip
        className={cx('bookmarks_tooltip', { chatbot })}
        icon={(<FontAwesomeIcon icon={faBookmark} onClick={(e) => bookmarkHandler(e)} className={cx('bookmarks_icon', { chatbot })} />)}
        hasBigScreenDesign
        isBigScreen
      >
        Remove story from the list
      </Tooltip>
    </div>
  );
};

export default withComponentName(BookmarkStoryIcon);
