import differenceBy from 'lodash-es/differenceBy';

import StorageSvc from '../services/StorageSvc';
import PresentationsSvc from '../services/dbServices/PresentationsSvc';
import { getESGParams } from '../helpers/presentationsHelpers';
import { GOLD } from '../data/permissions';

export const FETCH_ESG_BEGIN = 'FETCH_ESG_BEGIN';
export const FETCH_ESG_SUCCESS = 'FETCH_ESG_SUCCESS';
export const FETCH_ESG_FAILURE = 'FETCH_ESG_FAILURE';
export const SET_ESG_PAGE_LOADER = 'SET_ESG_PAGE_LOADER';
export const GET_NEW_ESG_BEGIN = 'GET_NEW_ESG_BEGIN';
export const GET_NEW_ESG_SUCCESS = 'GET_NEW_ESG_SUCCESS';
export const GET_NEW_ESG_FAILURE = 'GET_NEW_ESG_FAILURE';
export const SET_ESG_STORAGE_FILTERS = 'SET_ESG_STORAGE_FILTERS';
export const SET_ESG_TYPES_FILTER = 'SET_ESG_TYPES_FILTER';
export const SET_ESG_YEARS_FILTER = 'SET_ESG_YEARS_FILTER';
export const SET_ESG_PERIODS_FILTER = 'SET_ESG_PERIODS_FILTER';
export const RESET_ESG_ALL_FILTERS = 'RESET_ESG_ALL_FILTERS';

const fetchPresentationsBegin = () => ({
  type: FETCH_ESG_BEGIN,
});

const fetchPresentationsSuccess = (presentations) => ({
  type: FETCH_ESG_SUCCESS,
  payload: { presentations },
});

const fetchPresentationsFailure = (error) => ({
  type: FETCH_ESG_FAILURE,
  payload: { error },
});

const setPresentationsPageLoader = (newState) => ({
  type: SET_ESG_PAGE_LOADER,
  payload: newState,
});

export const getPresentationsData = (props, extra) => (dispatch, getStore) => {
  const { isInitialCall } = extra || {};
  const { esgReducer, subscriptions } = getStore();
  const param = getESGParams(esgReducer, props);

  const { permissions } = subscriptions;
  const { access_levels: accessLevels } = permissions;
  const isPremium = accessLevels.includes(GOLD);

  if (isPremium) {
    param.is_premium = isPremium;
  }

  if (isInitialCall) {
    dispatch(fetchPresentationsBegin());
  } else {
    dispatch(setPresentationsPageLoader(true));
  }

  return PresentationsSvc.getPresentationsEsgData(param)
    .then(({ data }) => {
      dispatch(fetchPresentationsSuccess(data || []));
      return data || [];
    })
    .catch((error) => {
      dispatch(fetchPresentationsFailure(error));
      return [];
    });
};

export const getNewPresentationsData = (props) => (dispatch, getStore) => {
  const { esgReducer, subscriptions } = getStore();
  const param = getESGParams(esgReducer, props);

  const { permissions } = subscriptions;
  const { access_levels: accessLevels } = permissions;
  const isPremium = accessLevels.includes(GOLD);

  if (isPremium) {
    param.is_premium = isPremium;
  }

  dispatch({ type: GET_NEW_ESG_BEGIN });

  return PresentationsSvc.getPresentationsEsgData(param)
    .then(({ data }) => {
      const oldData = esgReducer.presentations;
      const latestData = differenceBy(data, oldData, 'id');
      dispatch({
        type: GET_NEW_ESG_SUCCESS,
        payload: latestData,
      });
      return data;
    })
    .catch((error) => {
      dispatch({
        type: GET_NEW_ESG_FAILURE,
        payload: error,
      });
      return [];
    });
};

export const loadESGStorageDataIntoRedux = () => (dispatch) => {
  let params = { };

  const types = StorageSvc.getItem('esgTypes');
  if (types) {
    try {
      params = {
        ...JSON.parse(types),
      };
    } catch (e) {
      console.error(e);
    }
  }

  const years = StorageSvc.getItem('esgYears');
  if (years) {
    try {
      params = {
        ...params,
        ...JSON.parse(years),
      };
    } catch (e) {
      console.error(e);
    }
  }

  dispatch({
    type: SET_ESG_STORAGE_FILTERS,
    payload: params,
  });
};

export const setTypesFilter = (newState) => (dispatch) => {
  StorageSvc.setItem('esgTypes', JSON.stringify(newState));
  dispatch({
    type: SET_ESG_TYPES_FILTER,
    payload: newState,
  });
};

export const setYearsFilter = (newState) => (dispatch) => {
  StorageSvc.setItem('esgYears', JSON.stringify(newState));
  dispatch({
    type: SET_ESG_YEARS_FILTER,
    payload: newState,
  });
};

export const resetPeriodsFilter = () => (dispatch) => {
  StorageSvc.setItem('esgTypes', JSON.stringify({
    isAllTypesSelected: true,
    selectedTypes: [],
  }));
  const years = StorageSvc.getItem('esgYears');
  StorageSvc.setItem('esgYears', JSON.stringify({
    ...(years ? JSON.parse(years) : {}),
    isAllYearsSelected: true,
    showUndated: true,
  }));
  dispatch({
    type: RESET_ESG_ALL_FILTERS,
  });
};
