import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames/bind';
import autoBind from 'react-autobind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';

import withComponentName from '../../../../../decorators/withComponentName';

import API from '../../../../../services/dbServices/api';

import Alert from '../../../../Shared/ReactAlert';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export class ShareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      coppied: false,
      coppiedMessage: '',
      expandShareByEmail: false,
      story: {},
      emailForm: {
        sender_email: '',
        recipient_email: '',
        message: '',
        id: '',
      },
    };
    autoBind(this);
  }

  getStoryShareUrl(copy) {
    const { story: { type, showPath, url } } = this.state;

    if (copy) {
      return type === 'Story' ? `${window.location.origin}${showPath}` : url;
    }
    return encodeURIComponent(type === 'Story' ? `${window.location.origin}${showPath}` : url);
  }

  shareViaSocialMedia(link, source) {
    const { story: { id } } = this.state;
    const { isNotStoryPage } = this.props;
    if (!isNotStoryPage) {
      API.post(`stories/${id}/share_on_social_media`, { share_type: source });
    }

    const y = window.outerHeight / 2 + window.screenY - (570 / 2);
    const x = window.outerWidth / 2 + window.screenX - (520 / 2);
    window.open(link, '_blank', `location=yes,height=570,width=520,scrollbars=yes,status=yes,top=${y},left=${x}`);
  }

  reset() {
    const { emailForm } = this.state;

    this.setState({
      expandShareByEmail: false,
      emailForm: {
        ...emailForm,
        recipient_email: '',
        message: '',
      },
    });
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  sendEmail() {
    const { emailForm, story } = this.state;

    if (
      !this.validateEmail(emailForm.sender_email)
      || !this.validateEmail(emailForm.recipient_email)
    ) {
      Alert.warning('Please enter a valid email!', {
        position: 'top',
        effect: 'stackslide',
      });
      return;
    }

    API.post(`stories/${story.id}/share_by_email`, {
      ...emailForm,
    }).then(() => {
      Alert.success(`E-mail sent to ${emailForm.recipient_email}`, {
        position: 'top',
      });
      this.handleClose();
    }).catch(() => {
      Alert.warning('Something went wrong please try again!', {
        position: 'top',
        effect: 'stackslide',
      });
    });
  }

  handleShow(story) {
    const { emailForm } = this.state;

    this.setState({
      show: true,
      story,
      emailForm: {
        ...emailForm,
        id: story.id,
      },
    });
  }

  expandShareByEmail() {
    const { expandShareByEmail } = this.state;
    this.setState({
      expandShareByEmail: !expandShareByEmail,
    });
  }

  updateInputValue(type, value) {
    const { emailForm } = this.state;

    this.setState({
      emailForm: {
        ...emailForm,
        [type]: value,
      },
    });
  }

  handleClose() {
    this.setState({
      show: false,
    });
    this.reset();
  }

  handleCopyTextBtn() {
    window.navigator.clipboard.writeText(this.getStoryShareUrl(true)).then(
      () => {
        this.setState({
          coppied: true,
          coppiedMessage: 'Link is copied',
        });
      },
      (error) => {
        console.log(error);
      },
    );
  }

  render() {
    const { isNotStoryPage, hideIcons, hasBigScreenDesign } = this.props;
    const {
      show, coppied, coppiedMessage, story, expandShareByEmail, emailForm,
    } = this.state;

    return (
      <Modal
        className={cx('story__share-modal', 'show', { big_screen: hasBigScreenDesign })}
        show={show}
        onHide={() => this.handleClose()}
        centered
      >

        <Modal.Body className={cx('story__share-modal--body', { big_screen: hasBigScreenDesign })}>
          <div className={cx('sharing-header')}>
            <h2>You are about to share:</h2>
            <FontAwesomeIcon
              icon={faXmark}
              onClick={() => this.handleClose()}
            />
          </div>
          <div className={cx('sharing-content')}>
            <p>{story.title}</p>
          </div>
          <div className={cx('sharing-options', { coppied })}>
            <span>{coppiedMessage}</span>
            {!hideIcons && (
              <div
                className={cx('coppy-btn-share-icon')}
                onClick={() => this.handleCopyTextBtn()}
              >
                <FontAwesomeIcon icon={coppied ? faCheck : faLink} />
              </div>
            )}
            {!hideIcons && (
              <a
                className={cx('facebook-share-icon', 'sharing-popup')}
                onClick={() => this.shareViaSocialMedia(`https://www.facebook.com/sharer/sharer.php?u=${this.getStoryShareUrl()}`, 'facebook')}
              />
            )}

            {!hideIcons && (
              <a
                className={cx('twitter-share-icon')}
                onClick={() => this.shareViaSocialMedia(`https://twitter.com/intent/tweet?via=cityfalcon&text=${encodeURIComponent(story.title)}&url=${this.getStoryShareUrl()}`, 'twitter')}
              />
            )}

            <a
              className={cx('linkedin-share-icon', 'sharing-popup')}
              onClick={() => this.shareViaSocialMedia(`https://www.linkedin.com/shareArticle?mini=true&source=http://cityfalcon.com&url=${this.getStoryShareUrl()}`, 'linkedin')}
            />

            {!isNotStoryPage && (
              <a
                className={cx('email-share-icon')}
                onClick={() => this.expandShareByEmail()}
              >
                <span />
              </a>
            )}
          </div>

          <div className={cx('email-sharing', { show: expandShareByEmail })}>
            <input
              className={cx('email-share-recipient', 'form-control')}
              type="email"
              name="recipient_email"
              placeholder="Recipient's email address"
              value={emailForm.recipient_email}
              onChange={(evt) => this.updateInputValue('recipient_email', evt.target.value)}
            />
            <input
              className={cx('email-share', 'form-control')}
              type="email"
              name="email"
              placeholder="Your email address"
              value={emailForm.sender_email}
              onChange={(evt) => this.updateInputValue('sender_email', evt.target.value)}
            />
            <textarea
              cols="30"
              name="message"
              placeholder="Personal message (optional)"
              rows="5"
              value={emailForm.message}
              onChange={(evt) => this.updateInputValue('message', evt.target.value)}
            />
            <input className={cx('email-sharing-button')} type="submit" value="Send" onClick={this.sendEmail} />
            <span>or</span>
            <input className={cx('email-share-cancel')} type="reset" value="Cancel" onClick={this.expandShareByEmail} />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withComponentName(ShareModal);
