import moment from 'moment';

import API from './dbServices/api';
import { returnAPICachingHeader } from '../helpers/helpers';
import { getUserToken } from '../helpers/getUserToken';
import { cachingVeryHigh } from '../data/webPageData';
import { RetryAPICall } from '../helpers/apiHelpers';

const cache = new Map();
const caches = {};

export default class SubscriptionsSvc {
  static async getPermissions() {
    if (!getUserToken() && caches.permissionsData && caches.permissionsData.expDate > Date.now()) {
      return caches.permissionsData.response;
    }

    const { data } = await RetryAPICall(API, 'users/permissions', {
      ...(!getUserToken() && {
        headers: {
          'Cache-control': returnAPICachingHeader(cachingVeryHigh),
        },
      }),
    });

    if (!getUserToken() && data) {
      caches.permissionsData = {
        expDate: moment(data.valid_until).toDate().getTime(),
        response: data,
      };
    }

    return data;
  }

  static async getCurrentPlan() {
    const CACHE_KEY = 'CURRENT_PLAN';
    if (cache.has(CACHE_KEY)) {
      const { expDate, data } = cache.get(CACHE_KEY) || {};

      if (expDate > Date.now()) {
        return data;
      }
    }

    const { data } = await RetryAPICall(API, 'users/plans/current');
    cache.set(CACHE_KEY, {
      data,
      expDate: Date.now() + 1000 * 60 * 5,
    });

    return data;
  }

  static async getPlans() {
    const CACHE_KEY = 'PURCHASABLE_PLANS';

    if (cache.has(CACHE_KEY)) {
      return cache.get(CACHE_KEY);
    }

    const { data } = await RetryAPICall(API, 'purchasable_plans');
    cache.set(CACHE_KEY, data.purchasable_plans);
    return data.purchasable_plans;
  }

  static async getRenewableSubscription() {
    const { data } = await RetryAPICall(API, 'users/renewable_premium_subscriptions');
    return data;
  }
}
