import { Component as ComponentClass } from 'react';
import autoBind from 'react-autobind';

export default (Component) => {
  class CheckWidth extends ComponentClass {
    constructor(props) {
      super(props);
      autoBind(this);
      this.state = {
        width: 0,
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.resizeHandler);
      this.setState({
        width: window.innerWidth,
      });
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.resizeHandler);
    }

    resizeHandler() {
      this.setState({
        width: window.innerWidth,
      });
    }

    render() {
      const { width } = this.state;

      return (
        <Component
          {...this.props}
          windowWidth={width}
        />
      );
    }
  }

  CheckWidth.displayName = Component.name || Component.displayName;

  return CheckWidth;
};
