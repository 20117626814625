import StorageSvc from './StorageSvc';

export const loadState = () => {
  try {
    const serializedState = StorageSvc.getItem('state');
    if (serializedState === null) return undefined;

    const presistedState = JSON.parse(serializedState);
    presistedState.storyFilters.conflictsChecked = false;

    return presistedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    // do not save opened story filters state value
    if (state.filterOpened) state.delete('filterOpened');

    const serializedState = JSON.stringify(state);
    StorageSvc.setItem('state', serializedState);
  } catch (err) {
    // ignore
  }
};

export const clearSavedStoryFiltersStates = () => {
  try {
    const keysToRemove = ['AdvancedSettingsFilter', 'LanguageFilter', 'ScoreFilter', 'SourceFilter', 'expandStories', 'orderFilter', 'periodFilter', 'refreshFilter'];
    keysToRemove.forEach((k) => StorageSvc.removeItem(k));
  } catch (err) {
    // ignore
  }
};

export const clearSavedTrendingFiltersStates = () => {
  try {
    const keysToRemove = ['asset_classesFilter', 'sectorsFilter'];
    keysToRemove.forEach((k) => StorageSvc.removeItem(k));
  } catch (err) {
    // ignore
  }
};

export const clearFilingsFiltersStates = () => {
  try {
    const keysToRemove = ['expandFilings', 'FilingsTimeFilter', 'FilingsSourceFilter', 'FilingsCategoryFilter'];
    keysToRemove.forEach((k) => StorageSvc.removeItem(k));
  } catch (err) {
    // ignore
  }
};
