export const transactionChartTypes = [
  {
    value: 'value',
    label: 'Value',
  },
  {
    value: 'numberOfShares',
    label: 'Volume',
  },
];

export default transactionChartTypes;
