export const convertPermalinkToPath = (url) => {
  try {
    const urlObject = new URL(url);
    return urlObject.pathname;
  } catch (e) {
    console.error(e);
    return url;
  }
};

export default convertPermalinkToPath;
