import { countryList } from '../data/filing';

export const sourceDataHelper = (sourceName) => {
  for (let i = 0; i < countryList.length; i += 1) {
    const currentCountry = countryList[i];
    for (let j = 0; j < currentCountry.sources.length; j += 1) {
      const currentSource = currentCountry.sources[j];
      if (currentSource.ticker === sourceName) {
        return currentSource;
      }
    }
  }
  return {};
};

export default sourceDataHelper;
