import {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';

import SummarisationList from './SummarisationList';
import Tooltip from '../Tooltip';
import CFLink from '../Shared/CFLink';
import Preloader from '../Preloader';
import PlayVideoComponent from '../PlayVideoComponent';

import { SUMMARISATION } from '../../data/features';
import { GOLD } from '../../data/permissions';
import dummySummaries from '../../data/summaries/dummySummaries';
import { CF_URL_FOR_FINPROMPT } from '../../data/environment';

import { toggleSummarisation } from '../../actions/storyFilters.action';
import loadable from '../../utils/loadable';
import useSizes from '../../hooks/sizes';
import useFinprompt from '../../providers/FinpromptProvider/useFinprompt';

import StoriesSvc from '../../services/StoriesSvc';

import withComponentName from '../../decorators/withComponentName';
import permissionsDecorator from '../../decorators/permissionsDecorator';
import withRouter from '../../decorators/withRouter';

import Styles from './styles.module.scss';

const SummarisationHideModal = loadable(() => import('../Modals/SummarisationHideModal'));

const cx = classNames.bind(Styles);

export const Summarisation = ({
  className, storiesEndpoint, page, isTilesView, isHidden, isHomePage,
  hasBigScreenDesign, history, isWatchlist, directlyData, chatbot,
}) => {
  const [{ summaries, loading }, setSummariesState] = useState({
    summaries: [],
    loading: true,
  });
  const [modalOpened, setModalOpened] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const firstUpdate = useRef(true);

  const { width } = useSizes();
  const { isFinpromptPages } = useFinprompt();

  const enabled = useSelector(({ storyFilters }) => storyFilters.summarization_enabled ?? true);
  const englishSelected = useSelector(({ storyFilters }) => Boolean(
    storyFilters.languages && storyFilters.languages.split(',').includes('en'),
  ));
  const hasSummarisationAccess = useSelector(({ subscriptions }) => (
    subscriptions.permissionsInitialized
    && subscriptions.permissions.features.includes(SUMMARISATION)
  ));
  const maxSummarySize = useSelector(({ subscriptions }) => (
    subscriptions.permissions.max_summary_size
  ));
  const isGold = useSelector(({ subscriptions }) => (
    subscriptions.permissions.access_levels.includes(GOLD)
  ));
  const token = useSelector(({ watchlistReducer }) => watchlistReducer.userToken);
  const isChatbotOpened = useSelector(({ chatbot }) => chatbot.isChatbotOpened);
  const chatbotExpanded = useSelector(({ chatbot }) => chatbot.expanded);

  const dispatch = useDispatch();
  const setSummarisationEnabled = useCallback((status) => {
    setModalOpened(false);
    dispatch(toggleSummarisation(status, token));
  }, [dispatch, token]);

  const showSummaries = hasSummarisationAccess && (enabled || chatbot) && !isHidden;

  useEffect(() => {
    if (!showSummaries || !englishSelected) {
      if (!isGold) {
        setSummariesState({
          loading: false,
          summaries: dummySummaries,
        });
      } else {
        setSummariesState({
          loading: false,
          summaries: [],
        });
      }
      return;
    }
    if (!storiesEndpoint && !directlyData?.length) return;
    let mounted = true;
    let summariesFetchingInstance = null;
    setSummariesState((state) => ({
      ...state,
      loading: true,
    }));
    if (!isGold) {
      setSummariesState({
        loading: false,
        summaries: dummySummaries,
      });
      return;
    }
    if (storiesEndpoint) {
      storiesEndpoint(maxSummarySize, {
        limit: 500,
        fold_similar_stories: false,
        languages: 'en',
        all_languages: false,
      })?.then(async (data) => {
        if (!mounted) {
          return;
        }
        if (data.summariesKey) {
          summariesFetchingInstance = StoriesSvc.waitSummaries(
            data.summariesKey,
            data.cacheType,
            data.isRefresh,
          );
          const summariesResponse = await summariesFetchingInstance.promise;
          setSummariesState({
            loading: false,
            summaries: summariesResponse,
          });
        } else {
          setSummariesState({
            loading: false,
            summaries: [],
          });
        }
      });
    } else if (directlyData?.length) {
      if (!mounted) {
        return;
      }
      setSummariesState({
        loading: false,
        summaries: [...directlyData],
      });
    }

    return () => {
      mounted = false;
      summariesFetchingInstance?.cancel?.();
    };
  }, [storiesEndpoint, maxSummarySize, showSummaries, englishSelected, isGold]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  }, []);

  const handleClickPremiumTooltip = () => {
    if (isFinpromptPages) {
      window.open(`${CF_URL_FOR_FINPROMPT}/pricing`, '_blank')?.focus?.();
      return;
    }
    history.replace('/pricing');
  };

  if (!showSummaries) return null;

  return (
    <section
      className={`${cx('summarisation', page,
        {
          tiles: isTilesView,
          big_screen: hasBigScreenDesign,
          'summarisation-gold': (isGold && englishSelected),
          'summarisation-with-loading': loading,
          'chatbot--small': chatbot && isChatbotOpened && !chatbotExpanded,
          chatbot,
        })} ${className}`}
    >
      {!chatbot && (
        <FontAwesomeIcon
          icon={faXmark}
          className={cx('summarisation-close')}
          onClick={() => setModalOpened(true)}
        />
      )}
      {loading && (firstUpdate.current || width <= 1024 || (width > 1024 && !collapsed)) ? (
        <>
          <Preloader loading static centered className={cx('summarisation-preloader')} />
          <div className={cx('summarisation-loading-text')}>
            Key Headlines are loading…
          </div>
        </>
      ) : (
        <>
          <div className={cx('summarisation-container', { 'summarisation-full': isGold })}>
            <header className={cx('summarisation-header')}>
              <h3 className={cx('summarisation-header-title')}>
                Key Headlines
              </h3>
              <Tooltip
                className={cx('summarisation-header-tooltip', { homePageTooltip: isHomePage })}
                hasBigScreenDesign={hasBigScreenDesign}
              >
                {isHomePage
                  ? (
                    <>
                      A machine-learning-driven set of key headlines of relevant content. &nbsp;
                      <CFLink
                        to="https://www.cityfalcon.ai/features/summarisation"
                        external
                      >
                        Learn more
                      </CFLink>
                    </>
                  )
                  : (
                    <>
                      A machine-learning-driven set of key headlines for this feed.
                      Not to be used as the sole basis for investments. &nbsp;
                      <CFLink
                        to="https://www.cityfalcon.ai/features/summarisation"
                        external
                      >
                        Learn more about Key Headlines.
                      </CFLink>
                    </>
                  )}
              </Tooltip>
              <PlayVideoComponent
                videoLink="https://cityfalcon.wistia.com/medias/k5usrkqi3k"
                videoLinkMobile="https://cityfalcon.wistia.com/medias/2eznzz1ra6"
                additionalStyles={cx('summary_play_icon')}
                tooltipAdditionalContentStyle={cx('summary_play_icon_tooltip')}
              />
            </header>
            <SummarisationList
              summaries={summaries}
              blurred={!isGold}
              collapsed={isGold && collapsed}
              loading={loading && !firstUpdate.current && collapsed}
              hasBigScreenDesign={hasBigScreenDesign}
            />
            <footer className={cx('summarisation-footer', { 'summarisation-gold-footer': (isGold && englishSelected), 'summarisation-gold-colapsed-footer': collapsed })}>
              <div
                className={cx('summarisation-footer-collapse', { collapsed })}
                onClick={() => setCollapsed((state) => !state)}
              >
                {isGold && summaries.length > 2 && (
                  <>
                    <span>
                      Show&nbsp;
                      {collapsed ? 'Less' : 'More'}
                    </span>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </>
                )}
              </div>
              {page === 'watchlist' && isGold && collapsed && (
                <div className={cx('summarisation-footer-help')}>
                  <span>
                    Some topics are missing?&nbsp;
                  </span>
                  <CFLink
                    to="https://help.cityfalcon.com/hc/en-us/articles/360006073397#missingtopics"
                    external
                  >
                    Find out why
                  </CFLink>
                </div>
              )}
            </footer>
          </div>
          {!isGold && (
            <div className={cx('summarisation-premium')}>
              <Tooltip
                icon={(
                  <span className={Styles['summarisation-premium-badge']}>
                    GOLD
                  </span>
                )}
                className={cx('summarisation-premium-tooltip', {
                  'summarisation-premium-tooltip-no-stories': !summaries.length,
                })}
                onClick={handleClickPremiumTooltip}
                hasBigScreenDesign={hasBigScreenDesign}
              >
                <CFLink
                  to={isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}/pricing` : '/pricing'}
                  external={isFinpromptPages}
                >
                  Upgrade to Gold
                </CFLink>
                to see the full summary
              </Tooltip>
              <div className={Styles['summarisation-premium-message']}>
                Understand more in less time.&nbsp;
                <br />
                <CFLink
                  to={isFinpromptPages ? `${CF_URL_FOR_FINPROMPT}/pricing` : '/pricing'}
                  external={isFinpromptPages}
                >
                  Upgrade
                </CFLink>
                &nbsp;for access or&nbsp;
                <CFLink
                  to="https://help.cityfalcon.com/hc/en-us/articles/360006073397"
                  external
                >
                  see examples
                </CFLink>
                &nbsp;first.
              </div>
            </div>
          )}
        </>
      )}
      <SummarisationHideModal
        show={modalOpened}
        closeModal={() => setModalOpened(false)}
        onConfirm={() => setSummarisationEnabled(false)}
        hasBigScreenDesign={hasBigScreenDesign}
      />
    </section>
  );
};

Summarisation.propTypes = {
  className: PropTypes.string,
  storiesEndpoint: PropTypes.func,
  page: PropTypes.string,
};

Summarisation.defaultProps = {
  className: '',
  storiesEndpoint: null,
  page: '',
};

export default permissionsDecorator(
  withComponentName(withRouter(Summarisation)),
);
