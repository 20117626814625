export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';
export const SET_SHOW_HP_HEADER_SEARCHBAR = 'SET_SHOW_HP_HEADER_SEARCHBAR';
export const SET_SHOW_HP_HEADER_BOX_SHADOW = 'SET_SHOW_HOME_HP_BOX_SHADOW';
export const SET_IS_HIDDEN_HEADER_SEARCHBAR = 'SET_IS_HIDDEN_HEADER_SEARCHBAR';

export const setHeaderHeight = (payload) => ({
  type: SET_HEADER_HEIGHT,
  payload,
});

export const setShowHPHeaderSearchbar = (payload) => ({
  type: SET_SHOW_HP_HEADER_SEARCHBAR,
  payload,
});

export const setShowHPHeaderBoxShadow = (payload) => ({
  type: SET_SHOW_HP_HEADER_BOX_SHADOW,
  payload,
});

export const setIsHiddenHeaderSearchbar = (payload) => ({
  type: SET_IS_HIDDEN_HEADER_SEARCHBAR,
  payload,
});
