/* eslint-disable no-plusplus */
import Chatbot, { createChatBotMessage } from 'react-chatbot-kit';

import { useDispatch, useSelector } from 'react-redux';
import {
  memo, useEffect, useMemo, useRef, useState,
} from 'react';
import isEqual from 'lodash-es/isEqual';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import { isIOS } from 'react-device-detect';
import * as chatbotActions from '../../../actions/chatbot.actions';
import * as authActions from '../../../actions/authentication.actions';

import config from '../configs/chatbotConfig';
import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';

import SkeletonChatbotPage from '../SkeletonChatbotPage/SkeletonChatbotPage';

import StorageSvc from '../../../services/StorageSvc';

import useSizes from '../../../hooks/sizes';
import useFinprompt from '../../../providers/FinpromptProvider/useFinprompt';

import withComponentName from '../../../decorators/withComponentName';

const ChatbotComponent = (props) => {
  const {
    widgetType,
    handleShowMoreButton: handleShowMoreButtonFromProps,
    contentWrapperRef, isChatbotPage,
    setShowPreloaderOnScrollMessages,
    datePositionFinPrompt,
  } = props;
  const history = useHistory();
  const { width } = useSizes();

  const isChatbotOpened = useSelector(({ chatbot }) => chatbot.isChatbotOpened);
  const expanded = useSelector(({ chatbot }) => chatbot.expanded);
  const messagesInStore = useSelector(({ chatbot }) => chatbot.messages);
  const currentDateStore = useSelector(({ chatbot }) => chatbot.currentDate);
  const loadingResponse = useSelector(({ chatbot }) => chatbot.loading);
  const userId = useSelector(({ userPreferencesReducer }) => userPreferencesReducer?.user?.id);
  const user = useSelector(({ userPreferencesReducer }) => userPreferencesReducer?.user);
  const cookies = useSelector(({ common }) => common.cookiesPolicyStatus);

  const { isFinpromptPages } = useFinprompt();

  const [currentDate, setCurrentDate] = useState(null);
  const [chatMessageContainer, setChatMessageContainer] = useState(null);
  const [chatInnerContainer, setChatInnerContainer] = useState(null);
  const [showPreloader, setShowPreloader] = useState(true);

  const scrollEventAdded = useRef(null);
  const scrollEventAddedWidget = useRef(null);
  const currentDateSavedOnInitialRender = useRef(null);
  const currentDateSavedOnScreollRef = useRef(null);
  const timeoutScrollAdded = useRef(null);
  const timeoutPreloader = useRef(null);
  const lastSuitableMessageIndex = useRef(null);

  const dispatch = useDispatch();
  const actions = useMemo(() => (
    bindActionCreators({
      ...chatbotActions,
      ...authActions,
    }, dispatch)
  ), [dispatch]);

  const getTopRectContentWrapperPosition = (contentWrapperRef) => {
    const { y } = contentWrapperRef.current?.getBoundingClientRect();

    return isChatbotPage || isFinpromptPages ? (y) : (y + ((width > 2800) && 120) || 55);
  };

  const handleScroll = (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    const options = { day: 'numeric', month: 'long' };

    const chatInnerContHeight = chatInnerContainer?.clientHeight;
    const contentWrapperHeight = contentWrapperRef.current?.clientHeight;
    const contentWrapperScrolTop = contentWrapperRef.current?.scrollTop;

    const wrapperTopPosition = getTopRectContentWrapperPosition(contentWrapperRef);

    const wasScrolled = (isFinpromptPages || isChatbotPage) ? (
      (chatInnerContHeight > contentWrapperHeight)
      && ((chatInnerContHeight + contentWrapperScrolTop) >= (contentWrapperHeight + 5))
    ) : (
      (chatMessageContainer?.clientHeight > contentWrapperRef.current?.clientHeight)
        && (chatInnerContainer?.scrollTop > 20)
    );

    const isDateEqualCurrentDate = (date) => (date === currentDateSavedOnScreollRef.current);

    const getDateFromMessage = (message) => {
      if (!message) return;
      let date = null;

      if (message.className?.includes('chat-bot-message')) {
        date = message.children[1]?.getAttribute('data-date');
      }
      if (message.className?.includes('user-chat-message')) {
        date = message.children[0]?.getAttribute('data-date');
      }
      if (message.className?.includes('react-chatbot-kit__widget__wrapper')) {
        date = message.getAttribute('data-date');
      }
      if (!date) return null;

      return new Date(date).toLocaleDateString('en-GB', options);
    };

    const childrens = Array.from(chatMessageContainer?.children || []);

    for (let index = 0; index < childrens.length; index++) {
      const rect = childrens[index]?.getBoundingClientRect() || {};
      const { bottom, top } = rect || {};

      if (
        (top <= (wrapperTopPosition + 10))
      ) {
        lastSuitableMessageIndex.current = index;
      }
      if ((bottom > wrapperTopPosition) && (top < wrapperTopPosition)) {
        lastSuitableMessageIndex.current = index;
      }
    }
    if ((lastSuitableMessageIndex.current >= 0) && wasScrolled) {
      const date = getDateFromMessage(childrens[lastSuitableMessageIndex.current]);
      if (isDateEqualCurrentDate(date)) return;
      currentDateSavedOnScreollRef.current = date;
      setCurrentDate(date);

      lastSuitableMessageIndex.current = null;
    }

    if (!wasScrolled) {
      currentDateSavedOnScreollRef.current = null;
      setCurrentDate(null);
    }
  };

  const scrollToBottom = (showPreloader, behavior = 'instant') => {
    if (showPreloader && setShowPreloaderOnScrollMessages) setShowPreloaderOnScrollMessages(true);

    if (isChatbotPage || isFinpromptPages) {
      const isScrolledToBottom = contentWrapperRef.current?.scrollTop === 0;
      // trigers scrolling on IOS
      const wrapper = document.querySelector('[id*="GlobalRoutes-react-component"]');
      if (window !== 'undefined' && isIOS && (width < 767) && wrapper) {
        wrapper.scrollIntoView({ behavior, block: 'end' });
        window.scrollTo(0, 1000);
      }
      handleScroll();
      if (timeoutScrollAdded.current || isScrolledToBottom) return;

      timeoutScrollAdded.current = true;
      setTimeout(() => {
        chatMessageContainer?.scrollIntoView({ behavior, block: 'end' });
        timeoutScrollAdded.current = false;
      }, 100);
    } else if (chatMessageContainer) {
      handleScroll();
      timeoutScrollAdded.current = true;

      setTimeout(() => {
        chatMessageContainer?.scrollIntoView({ behavior, block: 'end' });
        timeoutScrollAdded.current = false;
      }, 200);
      setTimeout(() => {
        if (showPreloader && setShowPreloaderOnScrollMessages) {
          setShowPreloaderOnScrollMessages(false);
        }
      }, 500);
    }
  };

  const modifyMessagesArray = (messages) => {
    const copiedMessages = [...messages];
    const options = { day: 'numeric', month: 'long' };

    let prevDate = new Date(copiedMessages[0].message.date).toLocaleDateString('en-GB', options);
    copiedMessages[0].message.showDate = true;

    for (let i = 1; i < copiedMessages.length; i++) {
      const currentDate = new Date(copiedMessages[i].message.date).toLocaleDateString('en-GB', options);

      if (currentDate !== prevDate) {
        copiedMessages[i].message.showDate = true;
        prevDate = currentDate;
      } else {
        copiedMessages[i].message.showDate = false;
      }
    }

    return copiedMessages;
  };

  const memoizedLoadMessages = useMemo(() => {
    if (messagesInStore.length) return modifyMessagesArray(messagesInStore);

    const history = JSON.parse(StorageSvc?.getItem('chatbotMessages'));

    const currentUserHistory = history?.filter((item) => item.userId === (userId || 0)) || [];
    const messages = currentUserHistory[0]?.messages || [];

    if (messages.length) {
      const modifiedMessages = modifyMessagesArray(messages);
      if (!isEqual(modifiedMessages, messagesInStore)) {
        actions.saveMessages(modifiedMessages);
      }
      return modifiedMessages;
    }

    const initialMessage = createChatBotMessage({
      text: 'Ask me to do ANYTHING on our site. Here are some suggestions, or make your own request.',
      date: new Date(),
      showDate: true,
      initialMessage: true,
    },
    {
      widget: 'options',
      payload: { date: new Date() },
    });

    actions.createNewMessage(initialMessage, userId);
  }, [userId, messagesInStore, history?.location?.pathname]);

  const validateInput = (input) => {
    if (!user) {
      actions.setFinPromptAuthModalOpened(true);
      return false;
    }
    if (loadingResponse || (!input?.trim()?.length)) {
      return false;
    }
    return true;
  };

  const handleShowMoreButton = (path = '/news') => {
    if (isFinpromptPages && path.startsWith('http')) {
      window.open(path, '_blank')?.focus?.();
    } else if (handleShowMoreButtonFromProps) {
      handleShowMoreButtonFromProps(path);
    } else if ((history?.location?.pathname !== path)) {
      history?.push(path);
      window?.scrollTo(0, 0);
    }
  };

  const getCorrectDate = () => {
    const options = { day: 'numeric', month: 'long' };
    const today = new Date().toLocaleDateString('en-GB', options);

    return today === currentDateStore ? 'Today' : currentDateStore;
  };

  // make chatbot opened cause it works on separate routes for FinPrompt
  useEffect(() => {
    if (!isFinpromptPages || isChatbotOpened) return;

    actions.toggleBot(true);
    actions.toggleExpand(true);
  }, [isFinpromptPages]);

  // scroll when user send message
  useEffect(() => {
    setTimeout(() => {
      if (chatMessageContainer) {
        scrollToBottom(false, 'smooth');
      }
    }, 50);
  }, [messagesInStore]);

  // make send button disabled on loading web-6873
  useEffect(() => {
    if (!chatMessageContainer) setChatMessageContainer(document.getElementsByClassName('react-chatbot-kit-chat-message-container')[0]);
    if (!chatInnerContainer) setChatInnerContainer(document.getElementsByClassName('react-chatbot-kit-chat-inner-container')[0]);

    const input = document.getElementsByClassName('react-chatbot-kit-chat-input')[0];
    const buttonSend = document.getElementsByClassName('react-chatbot-kit-chat-btn-send')[0];
    buttonSend?.classList.add('disabled-on-loading');

    if (input?.value.length) {
      buttonSend?.classList.remove('disabled-on-loading');
    }

    const handleInputChanges = (e) => {
      if (!e?.target.value?.length || loadingResponse) {
        buttonSend?.classList.add('disabled-on-loading');
        return;
      }
      buttonSend?.classList.remove('disabled-on-loading');
    };

    input?.addEventListener('input', handleInputChanges);

    return () => {
      input?.removeEventListener('input', handleInputChanges);
    };
  }, [loadingResponse, isChatbotOpened]);

  // added scroll to handle saving current date from messages
  useEffect(() => {
    if ((isFinpromptPages || isChatbotPage) && !scrollEventAdded.current) {
      contentWrapperRef?.current?.addEventListener('scroll', handleScroll);
      scrollEventAdded.current = true;
    } else if (chatInnerContainer && !scrollEventAddedWidget.current) {
      chatInnerContainer.addEventListener('scroll', handleScroll);
      scrollEventAddedWidget.current = true;
    }

    return () => {
      if (isFinpromptPages || isChatbotPage) {
        contentWrapperRef?.current?.removeEventListener('scroll', handleScroll);
        scrollEventAdded.current = false;
      } else {
        chatInnerContainer?.removeEventListener('scroll', handleScroll);
        scrollEventAddedWidget.current = false;
      }
    };
  }, [isChatbotOpened, chatInnerContainer, chatMessageContainer, isFinpromptPages, isChatbotPage]);

  // save currentData  value web-6873
  useEffect(() => {
    if (currentDate !== currentDateStore) {
      actions.saveCurrentDate(currentDate);
    }
  }, [currentDate]);

  // saved current date on initial render cause page wasn't scrolled
  // and with delay cause widgets have not rendered yet
  useEffect(() => {
    if (isChatbotPage) currentDateSavedOnInitialRender.current = false;

    if (!chatMessageContainer || currentDateSavedOnInitialRender.current) return;
    setTimeout(() => { handleScroll(); }, 1000);
    currentDateSavedOnInitialRender.current = true;
  }, [chatMessageContainer, isChatbotPage]);

  // scrolled chatbot messages when bot was opened in widget view
  useEffect(() => {
    if (!isChatbotOpened || isFinpromptPages) return;

    scrollToBottom(true);
  }, [isChatbotOpened, isChatbotPage, isFinpromptPages, expanded]);

  // show skeleton on fullScreen widget while widgets are rendering
  useEffect(() => {
    if (timeoutPreloader.current
      || (!isChatbotPage && !isFinpromptPages)) return;

    const timeOut = setTimeout(() => {
      setShowPreloader(false);
      timeoutPreloader.current = true;
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [isChatbotOpened, expanded, isChatbotPage, isFinpromptPages]);
  const hasEmailProp = user?.hasOwnProperty('email_confimed');

  const dateClassname = `chatbot-date ${expanded ? 'expanded' : ''} ${isFinpromptPages && user && hasEmailProp && !user?.email_confimed ? 'email-not-confirmed' : ''}`;

  return (
    <>
      {showPreloader && (isChatbotPage || isFinpromptPages) ? (
        <div className={`widget__preloader-wrapper ${isChatbotPage ? 'chatbot-page' : ''} ${cookies ? 'cookies' : ''} ${isFinpromptPages ? 'finprompt-page' : ''}`}>
          <SkeletonChatbotPage isFinpromptPages={isFinpromptPages} cookies={cookies} />
        </div>
      ) : null}
      {currentDate ? <div style={{ top: `${datePositionFinPrompt}px` }} className={dateClassname}>{getCorrectDate()}</div> : null}
      <Chatbot
        config={config}
        actionProvider={ActionProvider}
        messageParser={MessageParser}
        messageHistory={memoizedLoadMessages}
        placeholderText="Ask a question here"
        validator={validateInput}
        handleShowMoreButton={handleShowMoreButton}
        isChatbotOpened={isChatbotOpened}
        disableScrollToBottom
        scrollToBottomOnWidgetRendered={scrollToBottom}
        widgetType={widgetType}
        expanded={expanded}
        history={history}
        isFinpromptPages={isFinpromptPages}
      />
    </>
  );
};

export default memo(withComponentName(ChatbotComponent));
