/* eslint-disable max-len */
import isEqual from 'lodash-es/isEqual';
import axios from 'axios';
import API, { APIWithoutToken } from './dbServices/api';
import StorageSvc from './StorageSvc';
import { getAPICachingTime, returnAPICachingHeader } from '../helpers/helpers';
import { cachingHigh } from '../data/webPageData';
import { buildAdvancedSearchQuery } from '../helpers/searchbarHelpers';

const caches = {
  searchMetaItems: [],
};

export default class SearchSvc {
  static async getSearchMetadataItems(passedParams = {}) {
    const { cancelToken, ...params } = passedParams;

    const cachedItems = caches.searchMetaItems.find((cache) => isEqual(cache.params, params));
    if (cachedItems && cachedItems.expDate > Date.now()) {
      return cachedItems.response;
    }

    try {
      const res = await APIWithoutToken.get('search/metadata/items', {
        params,
        cancelToken,
        headers: {
          'Cache-control': returnAPICachingHeader(cachingHigh),
        },
      });

      delete res.data.regular['Startup categories'];
      delete res.data.fuzzy['Startup categories'];

      caches.searchMetaItems.unshift({
        params,
        expDate: getAPICachingTime(cachingHigh),
        response: res,
      });

      return res;
    } catch (e) {
      return {
        error: true,
        cancelled: axios.isCancel(e),
      };
    }
  }

  static async getMetadataItems(params = {}) {
    const queryParams = {};
    const allowedParams = ['limit', 'fuzzy_limit', 'query', 'popular_first', 'subsection'];
    const defaultParams = {
      limit: 10,
      fuzzy_limit: 15,
      popular_first: true,
    };

    if (!(params instanceof Object)) {
      throw new TypeError('Invalid argument passed to the method getMetadataItems. Object required');
    }

    allowedParams.forEach((param) => {
      switch (true) {
        case param in params:
          queryParams[param] = params[param];
          break;
        case param in defaultParams:
          queryParams[param] = defaultParams[param];
          break;
        default:
          return null;
      }
    });

    const { data } = await this.getSearchMetadataItems(queryParams);

    if (!data) return {};

    return Object.values(data).reduce((prev, topicsGroup) => {
      Object.assign(prev, topicsGroup);
      return prev;
    }, {});
  }

  static SEARCH_HISTORY_KEY = 'search_history';

  static addSearchHistory({ activeSearchType, item }) {
    if (!item || (Array.isArray(item) && !item.filter(Boolean).length)) {
      return;
    }

    let searchHistory = this.getSearchHistory();

    searchHistory.unshift(
      {
        activeSearchType,
        item,
      },
    );

    // remove duplicates
    const cachedItems = new Set();
    searchHistory = searchHistory.filter((history) => {
      if (cachedItems.has(JSON.stringify(history.item))) {
        return false;
      }

      cachedItems.add(JSON.stringify(history.item));
      return true;
    });

    StorageSvc.setItem(
      this.SEARCH_HISTORY_KEY,
      JSON.stringify(
        searchHistory.slice(0, 5),
      ),
    );
  }

  static getSearchHistory() {
    try {
      return JSON.parse(
        StorageSvc.getItem(this.SEARCH_HISTORY_KEY),
      ) || [];
    } catch {
      return [];
    }
  }

  static async parseSearchQuery(query, topics = []) {
    const attachAssetClasses = (query, topics) => {
      const findEntity = (query, id, name) => {
        const operators = ['AND', 'OR', 'NOT'];

        if (Array.isArray(query)) {
          const item = query.find((item) => (
            String(item.group_name) === String(id)
            && String(item.value) === String(name)
          ));
          if (item) {
            return item;
          }
        } else if (
          String(query.group_name) === String(id)
          && String(query.value) === String(name)
        ) {
          return query;
        } else {
          const operator = operators.find((operator) => (
            query.hasOwnProperty(operator)
          ));
          if (operator) {
            const item = findEntity(query[operator], id, name);
            if (item) {
              return item;
            }
          }
        }

        return null;
      };

      topics.forEach((topic) => {
        if (topic.itemType === 'OPERATOR') {
          return;
        }
        const item = findEntity(query, topic.id, topic.name);
        if (item) {
          item.asset_class = topic?.asset_class ?? {
            id: topic?.attributes?.assetClassId,
            slug: topic?.attributes?.assetClassSlug,
            title: topic?.attributes?.assetClassTitle,
          };
        }
      });
    };

    try {
      const { data } = await APIWithoutToken.get('search/query/parse', {
        params: { query },
      });

      if (topics.length) {
        attachAssetClasses(data.parsed_query, topics);
      }

      return data.parsed_query;
    } catch (e) {
      return undefined;
    }
  }

  static async trackUserSelection(item, userQuery) {
    const advancedQuery = buildAdvancedSearchQuery([item]);

    // eslint-disable-next-line no-unused-vars
    const [_, entityType, entityId, entityName] = advancedQuery.split(':');
    const query = userQuery.toLowerCase();

    try {
      await API.post('search_bar_query_selection', {
        query,
        selected_entity_type: entityType,
        selected_entity_id: entityId,
      });
    } catch (e) {
      console.log(e);
    }
  }
}
