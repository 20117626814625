import { useSelector } from 'react-redux';

const UserAvatar = () => {
  const user = useSelector(({ userPreferencesReducer }) => (userPreferencesReducer.user));

  const avatar = user?.image_url;

  return (
    <div className="react-chatbot-kit-chat-bot-user-avatar">
      {avatar ? (
        <img className="user-avatar" src={avatar} alt="ava" />
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 36 35">
          <path d="M18 .063C8.367.063.562 7.867.562 17.5c0 9.633 7.805 17.437 17.438 17.437S35.437 27.133 35.437 17.5C35.437 7.867 27.633.063 18 .063zm9 29.643c-2.524 1.864-5.632 2.981-9 2.981-3.368 0-6.476-1.117-9-2.98v-.957c0-2.482 2.018-4.5 4.5-4.5.78 0 1.934.802 4.5.802 2.573 0 3.712-.802 4.5-.802 2.482 0 4.5 2.018 4.5 4.5v.956zm2.151-1.933C28.673 24.51 25.896 22 22.5 22c-1.441 0-2.138.802-4.5.802-2.363 0-3.052-.802-4.5-.802-3.396 0-6.173 2.51-6.652 5.773-2.496-2.707-4.036-6.307-4.036-10.273C2.812 9.126 9.626 2.313 18 2.313S33.187 9.126 33.187 17.5c0 3.966-1.54 7.566-4.036 10.273zM18 7.938a6.188 6.188 0 1 0 0 12.375 6.188 6.188 0 0 0 0-12.375zm0 10.125a3.94 3.94 0 0 1-3.938-3.938A3.94 3.94 0 0 1 18 10.188a3.94 3.94 0 0 1 3.937 3.937A3.94 3.94 0 0 1 18 18.063z" />
        </svg>
      )}
    </div>
  );
};

export default UserAvatar;
