export const timeFilter = [
  {
    value: '3m',
    text: '3M',
    tooltip: '3 months',
    active: false,
  },
  {
    value: '1y',
    text: '1Y',
    tooltip: '1 year',
    active: true,
  },
  {
    value: '5y',
    text: '5Y',
    tooltip: '5 years',
    active: false,
  },
  {
    value: 'all',
    text: 'ALL',
    tooltip: 'ALL',
    active: false,
  },
];

export default timeFilter;
