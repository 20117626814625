import PropTypes from 'prop-types';

import Styles from './styles.module.scss';

export const NewTag = ({ className }) => (
  <span className={`${Styles['new-tag']} ${className}`.trim()}>NEW</span>
);

NewTag.propTypes = {
  className: PropTypes.string,
};

NewTag.defaultProps = {
  className: '',
};

export default NewTag;
