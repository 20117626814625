const parseBodyCode = (bodyCode, payload) => {
  const messageDate = payload.date;

  const scriptTagStart = '<script>';
  const scriptTagEnd = '</script>';

  const scriptStartIndex = bodyCode.indexOf(scriptTagStart);
  const scriptEndIndex = bodyCode.indexOf(scriptTagEnd, scriptStartIndex + scriptTagStart.length);

  if (scriptStartIndex !== -1 && scriptEndIndex !== -1) {
    const beforeScript = bodyCode.substring(0, scriptStartIndex);
    const scriptContent = bodyCode.substring(
      scriptStartIndex + scriptTagStart.length,
      scriptEndIndex,
    );
    const afterScript = bodyCode.substring(scriptEndIndex + scriptTagEnd.length);

    const regex = /document\.getElementById\('([^']+)'\)/g;
    const modifiedScriptCode = scriptContent.replace(regex, `document.getElementById('${messageDate}')`);

    return {
      beforeScript,
      scriptContent: modifiedScriptCode,
      afterScript,
    };
  }

  return {
    beforeScript: bodyCode,
    scriptContent: null,
    afterScript: null,
  };
};

const extractCode = (html) => {
  const headRegex = /<head>([\s\S]*?)<\/head>/i;
  const bodyRegex = /<body>([\s\S]*?)<\/body>/i;

  const headMatch = html.match(headRegex);
  const bodyMatch = html.match(bodyRegex);

  const headCode = headMatch ? headMatch[1] : '';
  const bodyCode = bodyMatch ? bodyMatch[1] : '';

  return { headCode, bodyCode };
};

const processCodeAndReturnCodeBodyScript = (payload) => {
  const code = payload.component.generic_component;
  const { bodyCode } = extractCode(code);

  const { scriptContent } = parseBodyCode(bodyCode, payload);

  return scriptContent;
};

export default processCodeAndReturnCodeBodyScript;
