import { SET_PERMISSIONS, SET_PERMISSIONS_ERROR } from '../actions/subsriptions.actions';

const initialState = {
  permissions: {
    valid_until: (new Date()).toISOString(),
    access_levels: [],
    search_period: null,
    search_period_duration: 0,
    allowed_search_periods: [],
    features: [],
    max_summary_size: 2,
    max_watchlists: 3,
    max_watchlist_size: 5,
    advertising: false,
    curated_content: false,
    push_notifications: false,
    translations: false,
    offline_reading: false,
    group_token: undefined,
    states: {
      new_messages: false,
    },
    system: {
      premium_plans: {},
    },
    user: {
      obtainable_levels: {},
      premium_plan_keys: [],
    },
  },
  permissionsInitialized: false,
  permissionsError: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload || state.permissions,
        permissionsInitialized: true,
        permissionsError: null,
      };
    case SET_PERMISSIONS_ERROR:
      return {
        ...state,
        permissionsError: payload,
      };
    default:
      return state;
  }
};
