import { RetryAPICall } from '../../helpers/apiHelpers';
import API from './api';

export default class NotificationsDbService {
  static async getNotificationQueries() {
    try {
      const { data } = await RetryAPICall(API, 'users/subscription/search_queries');
      return data;
    } catch {
      return {
        search_queries: [],
      };
    }
  }

  static async postNotificationQuery(watchlistId, topics, queryId) {
    const subscribtionsTopics = {
      search_query_id: queryId,
      watchlist_id: watchlistId,
      search_queries: topics,
    };

    const { data } = await API.post('users/subscription/search_queries', subscribtionsTopics);
    return data;
  }

  static async removeNotificationQuery(queryId, removeAll = false) {
    const params = {
      search_query_id: queryId || undefined,
      all: removeAll,
    };

    const { data } = await API.delete('users/subscription/search_queries', {
      params: {
        ...params,
      },
    });

    return data;
  }
}
