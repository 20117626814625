import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import StoryCard from '../../../../TrendingStories/StoryCard';
import Preloader from '../../../../Preloader';

import { SILVER } from '../../../../../data/permissions';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const StoriesList = ({ storiesData, piwikEnabled, hasBigScreenDesign }) => {
  const accessLevels = useSelector(({ subscriptions }) => (
    subscriptions.permissions.access_levels
  ));

  return (
    <>
      {storiesData.loading && <Preloader loading static />}
      {!storiesData.loading && (
        storiesData.stories.length ? (
          storiesData.stories.map((story) => (
            <StoryCard
              key={story.id}
              expandFilter={false}
              showTranslation={false}
              type={story.type}
              story={story}
              onHide={() => null}
              onFreezeStories={() => null}
              piwikEnabled={piwikEnabled}
              trendingBarCard
              popup
              hasBigScreenDesign={hasBigScreenDesign}
              skipSimilarStories
              trimTitle
              isModal
            />
          ))
        ) : (
          <div className={cx('chart-data-modal-content-no-stories', { big_screen: hasBigScreenDesign })}>
            {storiesData.notAllowed ? (
              <>
                <a href="/pricing">
                  Upgrade
                </a>
                &nbsp;to&nbsp;
                {accessLevels.includes(SILVER) ? (
                  'Gold to access all content.'
                ) : (
                  'Silver to access content up to 1 year old.'
                )}
              </>
            ) : (
              'No content was found for this period'
            )}
          </div>
        )
      )}
    </>
  );
};

export default StoriesList;
