import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import autoBind from 'react-autobind';
import KebabCase from 'lodash-es/kebabCase';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faShareAlt } from '@fortawesome/pro-regular-svg-icons/faShareAlt';

import { setAuthModalOpened } from '../../../../../actions/authentication.actions';

import { calculateTimeAgo } from '../../../../../helpers/helpers';
import getUserToken from '../../../../../helpers/getUserToken';
import { GOLD } from '../../../../../data/permissions';
import withComponentName from '../../../../../decorators/withComponentName';
import Tooltip from './Tooltip';
import ShareModal from '../../../../TrendingStories/StoryCard/StoryCardFooter/ShareModal';
import Styles from '../styles.module.scss';
import convertPermalinkToPath from '../../../../../helpers/urlHelpers';

const cx = classNames.bind(Styles);

class StoryCardRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      singleUrl: '',
    };
    autoBind(this);
    this.storyCardRef = createRef();
  }

  componentDidMount() {
    const {
      cardURL, id, companyName, title, year,
    } = this.props;
    const linkArr = KebabCase(`${id}-${companyName || ''}-${title || ''}-${year || ''}`);
    this.setState({
      singleUrl: `${cardURL}/${linkArr}`,
    });
  }

  handleExpandClick() {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded,
    });
  }

  handleChildClick(e) {
    e.stopPropagation();
  }

  handleInfoClick() {
    const { singleUrl } = this.state;
    const { history } = this.props;
    history.push(singleUrl);
  }

  handleTopicRedirect(e, permalink) {
    e.stopPropagation();
    const { history } = this.props;
    if (permalink) {
      history.push(convertPermalinkToPath(permalink));
    }
  }

  getLinkExtension(link) {
    return link && link.split('/').reverse()[0].split('.').reverse()[0];
  }

  fetchIcon(linkExt, ext) {
    const extension = ext || linkExt;
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'xls':
        return 'xls';
      case 'xlsx':
        return 'xls';
      case 'doc':
        return 'doc';
      case 'rft':
        return 'rft';
      case 'zip':
        return 'zip';
      default:
        return null;
    }
  }

  openShareModal() {
    if (!getUserToken()) return this.openSignModal();

    const { id, title } = this.props;
    const { singleUrl } = this.state;
    this.ShareModal.handleShow({
      id, title, url: `${window.location.origin}${singleUrl}`,
    });
  }

  openSignModal() {
    const { setAuthModalOpened: propsSetAuth } = this.props;
    propsSetAuth(true);
  }

  render() {
    const { isExpanded } = this.state;
    const {
      title,
      link,
      generationDate,
      type,
      year,
      period,
      companyName,
      homePage,
      width,
      isPremium,
      isWatchlists,
      isSingleTopic,
      showCompanyTag,
      extension,
      className = '',
      hasBigScreenDesign,
      permalink,
      chatbot,
    } = this.props;
    return (
      <div
        className={`${cx('presentations_row',
          {
            big_screen: hasBigScreenDesign,
            chatbot,
          })} ${className}`}
        ref={this.storyCardRef}
      >
        <div className={cx('presentations_header_holder', { home: homePage })} onClick={this.handleExpandClick}>
          <div className={cx('row_header', { home: homePage })}>
            {isPremium ? (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.handleChildClick}
                className={Styles.row_header_content}
              >
                <div>
                  {title}
                  {((['pdf', 'xls', 'xlsx', 'rft', 'zip', 'doc'].includes(extension) || ['pdf', 'xls', 'xlsx', 'rft', 'zip', 'doc'].includes(this.getLinkExtension(link)))) && <span className={Styles[this.fetchIcon(this.getLinkExtension(link), extension)]} />}
                </div>
              </a>
            ) : (
              <Tooltip
                tootipText={title}
                parentDivRef={this.storyCardRef}
                isWatchlists={isWatchlists}
                hasBigScreenDesign={hasBigScreenDesign}
              >
                <Link to="/pricing" className={Styles.plan_url}>Upgrade to Gold plan</Link>
                &nbsp;to use this feature
              </Tooltip>
            )}
          </div>
          <div className={cx('row_details', { home: homePage })}>
            <div className={cx('row_info')}>
              {generationDate && (
                <div className={Styles.row_time}>
                  {calculateTimeAgo(generationDate, width <= 1500)}
                </div>
              )}
              {type && (
                <div className={Styles.row_type}>
                  {type}
                </div>
              )}
              {(year || period) && (
                <div className={cx('row_years', { home: homePage })}>
                  {year || ''}
                  {year && period && (<span />)}
                  {period || ''}
                </div>
              )}
            </div>
            {companyName && showCompanyTag && !isSingleTopic && (
              <div className={cx('row_company', { home: homePage })} onClick={(e) => this.handleTopicRedirect(e, permalink)}>
                {companyName}
              </div>
            )}
          </div>
          <div className={cx('row_arrow', { home: homePage })}>
            <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
          </div>
        </div>
        {
          isExpanded
            ? (
              <ExpandedComponent
                handleInfoClick={this.handleInfoClick}
                openShareModal={this.openShareModal}
              />
            )
            : null
        }

        <ShareModal
          isNotStoryPage
          ref={(el) => { this.ShareModal = el; }}
          hasBigScreenDesign={hasBigScreenDesign}
        />
      </div>
    );
  }
}

const ExpandedComponent = ({ handleInfoClick, openShareModal }) => (
  <div className={Styles.row_extra}>
    <div className={Styles.row_extra_icon_holder} onClick={handleInfoClick}>
      <FontAwesomeIcon icon={faFileAlt} />
      <div className={Styles.icon_text}>Info</div>
    </div>
    <div className={Styles.row_extra_icon_holder} onClick={openShareModal}>
      <FontAwesomeIcon icon={faShareAlt} />
      <div className={Styles.icon_text}>Share</div>
    </div>
  </div>
);

const mapStateToProps = ({ common, subscriptions }) => {
  const { permissions } = subscriptions;
  const { access_levels: accessLevels } = permissions;

  return {
    width: common.width,
    isPremium: accessLevels.includes(GOLD),
  };
};

const mapDispatchToProps = {
  setAuthModalOpened,
};

export default withComponentName(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(StoryCardRow)),
);
