import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';

import WatchlistNameActions from '../../../WatchlistNameActions';

import permissionsDecorator from '../../../../../decorators/permissionsDecorator';
import withComponentName from '../../../../../decorators/withComponentName';

import { parseQueryLogicalStructure, queryItemWatchlistParser } from '../../../../../helpers/watchlistHelperFunctions';

import Styles from './styles.module.scss';

const WLManageItem = ({
  item,
  handleSelectedWLItem,
  checkSelectedItem,
  selectedItemsArray,
  watchlistsListNew,
  piwikEnabled,
  maxWatchlistSize,
  onboarding,
  chatbot,
  manageWlPage,
}) => {
  const cx = classNames.bind(Styles);

  const [selected, setSelected] = useState(checkSelectedItem(item) > -1);
  const [active, setActive] = useState(false);

  const getTextFromQueries = (item) => {
    const values = [];

    item?.search_queries.forEach((item) => {
      if (item?.logical_structure instanceof Object && (
        item?.query_supertype === 'advanced_groups_search'
        || item?.query_supertype === 'full_text'
        || item?.query_supertype === 'advanced_text_search'
      )) {
        values.push(parseQueryLogicalStructure(item.logical_structure).replace(/['\\"]+/g, ''));
        return;
      }
      if (item?.query) {
        const { name } = queryItemWatchlistParser(item.query);
        values.push(name.replace(/['"]+/g, ''));
      }
    });

    if (values.join(', ').length >= 130) {
      return `${values.join(', ').slice(0, 130)}...`;
    }
    return values.join(', ');
  };

  const hideNameActionsIcons = () => {
    setActive(false);
  };

  const handleMouseOver = () => {
    setActive(true);
  };
  const handleMouseOut = () => {
    setActive(false);
  };

  const handleChange = (e, item) => {
    handleSelectedWLItem(item);
  };

  useEffect(() => {
    if (checkSelectedItem(item) > -1) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedItemsArray]);

  const topicsText = `${item.search_queries?.length}/${maxWatchlistSize}`;

  return (
    <div
      onMouseOver={handleMouseOver}
      onFocus={() => null}
      onMouseOut={handleMouseOut}
      onBlur={() => null}
      className={cx('manage-item__wrapper', { selected }, { active }, { onboarding }, { chatbot })}
      onClick={(e) => handleChange(e, item)}
    >
      <div
        className={cx('manage-item__checkbox')}
      >
        <span className={cx('checkbox_box', { checked: selected })}>
          {selected && <FontAwesomeIcon icon={faCheck} />}
        </span>
      </div>
      <WatchlistNameActions
        watchlist={item}
        watchlistsArray={watchlistsListNew}
        piwikEnabled={piwikEnabled}
        activeOnHover={active}
        manageWlPage={manageWlPage}
        hasBigScreenDesign
        selected={selected}
        hideNameActionsIcons={hideNameActionsIcons}
        chatbot={chatbot}
      />
      <div className={cx('manage-item__assets')}>{getTextFromQueries(item)}</div>
      <div className={cx('manage-item__footer', 'footer__items')}>
        <div className={cx('footer__item')}>
          Topics
          <span>{topicsText}</span>
        </div>
        {/* will recomment after we will have these data from backend */}
        {/* <div className={cx('footer__item')}>Created</div>
        <div className={cx('footer__item')}>Updated</div> */}
      </div>
    </div>
  );
};

export default withComponentName((permissionsDecorator(WLManageItem)));

WLManageItem.propTypes = {
  item: PropTypes.shape({}).isRequired,
  handleSelectedWLItem: PropTypes.func,
  checkSelectedItem: PropTypes.func,
  selectedItemsArray: PropTypes.arrayOf(PropTypes.shape({})),
  watchlistsListNew: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  piwikEnabled: PropTypes.bool,
  maxWatchlistSize: PropTypes.number,
  onboarding: PropTypes.bool,
  chatbot: PropTypes.bool,
};

WLManageItem.defaultProps = {
  handleSelectedWLItem: () => {},
  checkSelectedItem: () => {},
  selectedItemsArray: [],
  onboarding: false,
  chatbot: false,
  piwikEnabled: false,
  maxWatchlistSize: 3,
};
