import { forwardRef, memo, useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';

import { checkAndRoun } from '../../../../helpers/topicsHelpers';
import { roundTo } from '../../../../helpers/helpers';

import useSizes from '../../../../hooks/sizes';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

export const TransactionDetails = ({
  x,
  y,
  toggleExpandedView,
  sharesData,
  valuesData,
  chatbot,
}, ref) => {
  const { width } = useSizes();

  const style = useMemo(() => ({
    top: `${y}px`,
    left: `${Math.min(x, width - 170)}px`,
  }), [x, y, width]);

  return (
    <div
      ref={ref}
      className={cx('transaction-details', { chatbot })}
      style={style}
    >
      <div className={cx('transaction-details-header')}>
        <div className={Styles.date}>
          {moment(sharesData.date).format('MMM YYYY')}
        </div>
      </div>
      <div className={cx('transaction-details-body')}>
        <div className={cx('transaction-details-row')}>
          <div className={cx('transaction-details-label')}>
            Avg Stock Price:
          </div>
          <div className={cx('transaction-details-value')}>
            {sharesData.avgStockPrice !== null ? (
              `$${roundTo(sharesData.avgStockPrice)}`
            ) : '-'}
          </div>
        </div>
        <div className={cx('transaction-details-row')}>
          <div className={cx('transaction-details-label')}>
            Purchased:
          </div>
          <div className={cx('transaction-details-value')}>
            {checkAndRoun(sharesData.buy, 2, true, false)}
            &nbsp;/ $
            {checkAndRoun(valuesData.buy, 2, true, false)}
          </div>
        </div>
        <div className={cx('transaction-details-row')}>
          <div className={cx('transaction-details-label')}>
            Sold:
          </div>
          <div className={cx('transaction-details-value')}>
            {checkAndRoun(sharesData.sell, 2, true, false)}
            &nbsp;/ $
            {checkAndRoun(valuesData.sell, 2, true, false)}
          </div>
        </div>
        <div className={cx('transaction-details-row')}>
          <div className={cx('transaction-details-label')}>
            Other:
          </div>
          <div className={cx('transaction-details-value')}>
            {checkAndRoun(sharesData.other, 2, true, false)}
            &nbsp;/ $
            {checkAndRoun(valuesData.other, 2, true, false)}
          </div>
        </div>
      </div>
      <div className={cx('transaction-details-footer')}>
        <div className={cx('see-more')} onClick={toggleExpandedView}>
          <span>Show Top Transactions</span>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>
    </div>
  );
};

export default memo(forwardRef(TransactionDetails));
